import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TextboxModule } from '@semmie/components/presentational/core/textbox/textbox.module';
import { RadioButtonComponent } from '@semmie/components/presentational/core/radio-button/radio-button.component';
import { RadioButtonGroupModule } from '@semmie/components/presentational/core/radio-button-group/radio-button-group.module';
import { CheckboxModule } from '@semmie/components/presentational/core/checkbox/checkbox.module';
import { CheckboxGroupModule } from '@semmie/components/presentational/core/checkbox-group/checkbox-group.module';
import { ExternalDataModule } from '@semmie/components/presentational/core/external-data/external-data.module';
import { DropdownModule } from '@semmie/components/presentational/core/dropdown/dropdown.module';
import { ToggleModule } from '@semmie/components/presentational/core/toggle/toggle.module';
import { UploadComponent } from '@semmie/components/presentational/core/upload/upload.component';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { CarouselSelectModule } from '@semmie/components/presentational/core/carousel-select/carousel-select.module';
import { IconCardsModule } from '@semmie/components/presentational/core/icon-cards/icon-cards.module';
import { DateModule } from '@semmie/components/presentational/core/date/date.module';
import { SliderModule } from '@semmie/components/presentational/core/slider/slider.module';
import { GraphModule } from '@semmie/components/presentational/core/graph/graph.module';
import { AutocompleteListComponent } from '@semmie/components/presentational/core/autocomplete-list/autocomplete-list.component';
import { AutocompleteInputComponent } from '@semmie/components/presentational/core/autocomplete-input/autocomplete-input.component';
import { InputCardModule } from '@semmie/components/presentational/core/input-card/input-card.module';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { FormInputComponent } from './form-input.component';
import { CoverImageSelectorComponent } from '@semmie/components/presentational/core/cover-image-selector/cover-image-selector.component';
import { PayoutSummaryComponent } from '@semmie/components/presentational/core/payout-summary/payout-summary.component';
import { TextareaModule } from '@semmie/components/presentational/core/textarea/textarea.module';

@NgModule({
  declarations: [FormInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LabelModule,
    ImageModule,
    TextboxModule,
    TextareaModule,
    CheckboxModule,
    CheckboxGroupModule,
    DateModule,
    RadioButtonComponent,
    RadioButtonGroupModule,
    DropdownModule,
    ToggleModule,
    UploadComponent,
    ExternalDataModule,
    CarouselSelectModule,
    IconCardsModule,
    SliderModule,
    GraphModule,
    AutocompleteListComponent,
    AutocompleteInputComponent,
    InputCardModule,
    CoverImageSelectorComponent,
    PayoutSummaryComponent,
  ],
  exports: [FormInputComponent],
})
export class FormInputModule {}
