import { Pipe, PipeTransform } from '@angular/core';
import { Formatter } from '@onyxx/utility/formatter';

@Pipe({
  name: 'ibanFormatter',
})
export class IbanFormatterPipe implements PipeTransform {
  transform(value: string): string {
    return Formatter.formatIban(value);
  }
}
