import { Injectable } from '@angular/core';
import { PaginationParams, PaginatedResponse } from '@onyxx/model/pagination';
import { Task } from '@semmie/models/bi/task';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { iTask } from '@semmie/schemas/bi/task';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TasksProvider extends BaseProvider<Task> {
  constructor() {
    super('tasks');
  }

  /**
   * List paginated tasks
   *
   * @param params: PaginationParams
   * @returns
   */
  list(params?: PaginationParams): Observable<PaginatedResponse<Task>> {
    return super.__list('tasks', params).pipe(
      map((response) => {
        return {
          data: response['tasks'].map((task: iTask) => new Task(task)),
          meta: response.meta,
        };
      }),
    );
  }

  /**
   * Get a task
   *
   * ? Developer note: Explicitly remap data here for consistency
   *
   * @param id task id
   */
  get(id: string) {
    return super.__get('tasks', id).pipe(map((response) => (response && response['task'] ? new Task(response['task']) : null)));
  }
}
