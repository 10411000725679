import { Injectable } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ConfigProvider } from '@semmie/providers';
import { AppStorageService } from '@semmie/services/app/app-storage.service';
import { ConfigStore } from '@semmie/store/config/config.store';
import { iConfiguration } from '@semmie/schemas/bi/configuration/configuration';

import defaultConfig from '@semmie/shared/config/default.json';
import { defer, firstValueFrom, from, of } from 'rxjs';
import { filterNil } from '@onyxx/utility/observables';
import { ConfigStorageKeys } from '@semmie/schemas';
import { Utils } from '@onyxx/utility/general';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  payment$ = this.configStore.payment$;
  private readonly configurationStorageReader = this.appStorageService.createSecuredStorageReader<iConfiguration>(ConfigStorageKeys.Config);

  readonly config$ = this.configStore.config.pipe(
    switchMap((config) => (config == null ? defer(() => this.getConfigFromAppStorage()) : of(config))),
    filterNil(),
  );

  constructor(
    private appStorageService: AppStorageService,
    private configProvider: ConfigProvider,
    private configStore: ConfigStore,
  ) {}

  /**
   * Loads the configuration and stores it locally.
   */
  async loadConfig() {
    await firstValueFrom(
      this.configProvider.get().pipe(
        catchError(() => from(this.getConfigFromAppStorage())),
        tap((conf) => {
          const merged = Utils.deepMergeObjects(defaultConfig as iConfiguration, conf);
          this.configStore.update(merged);
          this.configurationStorageReader.set(merged);
        }),
      ),
    );
  }

  async getConfigFromAppStorage() {
    return this.configurationStorageReader.get();
  }

  async clearStorageOnLogout() {
    // config should not be removed on logout
  }
}
