import { Injectable, inject } from '@angular/core';
import { AccountStoreFacade } from '@onyxx/store/account';
import { map } from 'rxjs';
import { filterNil } from '@onyxx/utility/observables';
import { accountAdapter } from '@semmie/schemas/bi/account';
/**
 * @deprecated
 *
 * This store is a adapter for the NgRx account store, that wraps the pure functions of accounts into a class,
 * so that it can be called from the forms module. The store should not be used directly or outside of the
 * forms module.
 */
@Injectable({
  providedIn: 'root',
})
export class AccountAdapterStore {
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  readonly account$ = this.accountStoreFacade.account$.pipe(filterNil(), map(accountAdapter));
}
