import { Route } from '@angular/router';
import { TwoFactorValidateComponent } from './two-factor-validate.component';
import { twoFactorAvailableGuard } from './two-factor-available.guard';
import { unAuthenticatedGuard } from '../un-authenticated.guard';

export const twoFactorValidateRoutes: Route[] = [
  {
    path: '',
    component: TwoFactorValidateComponent,
    canActivate: [unAuthenticatedGuard(), twoFactorAvailableGuard()],
  },
];
