import { Observable, combineLatest, tap, startWith, map, Subject, switchMap } from 'rxjs';

export const switchMapWithLoading =
  <TSource, TResult>(observable: (input: TSource) => Observable<TResult>) =>
  (source$: Observable<TSource>): Observable<{ loading: boolean; result: TResult | null }> => {
    const loadingSubject$$ = new Subject<boolean>();

    return combineLatest([
      source$.pipe(
        tap(() => {
          loadingSubject$$.next(true);
        }),
        switchMap((x) =>
          observable(x).pipe(
            tap({
              complete: () => setTimeout(() => loadingSubject$$.next(false)),
            }),
          ),
        ),
        startWith(null),
      ),
      loadingSubject$$,
    ]).pipe(map(([result, loading]) => ({ loading, result })));
  };
