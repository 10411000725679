import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { SharedModule } from '@semmie/shared/shared.module';

import { DateComponent } from './date.component';
import { ButtonModule } from '@semmie/components/presentational/core/button/button.module';
import { FormInputLabelComponent } from '@semmie/components/containers/form-input-label/form-input-label.component';
import { ModalHeaderModule } from '@semmie/components/containers/modals/modal-header/modal-header.component';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    SharedModule,
    IonicModule,
    LabelModule,
    FormsModule,
    ButtonModule,
    FormInputLabelComponent,
    ModalHeaderModule,
    MaskitoDirective,
  ],
  exports: [DateComponent],
  declarations: [DateComponent],
})
export class DateModule {}
