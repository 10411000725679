import {
  AfterContentInit,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ImpactStyle } from '@capacitor/haptics';
import { CarouselSlideComponent } from '@semmie/components/presentational/core/carousel-slide/carousel-slide.component';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { Utils } from '@semmie/shared/utils';
import { BehaviorSubject } from 'rxjs';

import SwiperCore, { Autoplay, Navigation, Pagination, Swiper, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AutoplayOptions } from 'swiper/types';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay]);

@Component({
  selector: 'semmie-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterContentInit {
  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;

  @ContentChildren(CarouselSlideComponent, { descendants: true }) contents!: QueryList<CarouselSlideComponent>;

  @Input() autoplay: AutoplayOptions | boolean = false;
  @Input() breakpoints: SwiperOptions['breakpoints'];
  @Input() initialSlide? = 0;

  @Input() set activeIndex(value: number) {
    this.activeIndexInputCurrentValue.set(value);
    if (!this.swiper.swiperRef) return;

    const newActiveIndex = this.getClosestActiveIndex(value, this.activeIndex$.value ?? 0);

    if (newActiveIndex != this.activeIndex$.value) {
      this.swiper.swiperRef.slideTo(newActiveIndex);
    }
  }

  @Input() loop = false;
  @Input() pagination: { clickable?: boolean; enabled?: boolean } = { clickable: true };

  /**
   * When navigation is enabled, left hand right hand options are displayed with
   * a gradual fade. This property controls how heavy the fade is.
   */
  @Input() fade: 'default' | 'heavy' = 'default';

  @Input() navigation = false;
  @Input() slidesPerView: number | 'auto' = 'auto';
  @Input() centeredSlides = true;
  @Input() spaceBetween = 0;
  @Input() haptic = true;

  @Output() activeSlideChanged = new EventEmitter<number>();

  loopedSlides = 2;
  selectedIndex$ = new BehaviorSubject<number | null>(null);
  activeIndex$ = new BehaviorSubject<number | null>(null);

  private destroyRef = inject(DestroyRef);
  private hapticFeedbackService = inject(HapticFeedbackService);
  private activeIndexInputCurrentValue = signal(0);
  private initialized = signal(false);

  activeIndexChange([event]: any): void {
    const normalizedActiveIndex = this.getIndexFromActiveIndex(event.activeIndex);
    const currentNormalizedActiveIndex = this.getIndexFromActiveIndex(this.activeIndex$.value ?? this.initialSlide ?? 0);

    const valueChanged = currentNormalizedActiveIndex != normalizedActiveIndex;
    this.activeIndex$.next(event.activeIndex);

    if (!this.initialized() || !valueChanged) {
      return;
    }

    if (this.haptic) {
      this.hapticFeedbackService.interact(ImpactStyle.Light);
    }
    this.activeSlideChanged.emit(normalizedActiveIndex);
  }

  onSwiperAfterInitialized() {
    this.initialized.set(true);
  }

  click(swiper: Swiper | null, pointerEvent: PointerEvent | MouseEvent | TouchEvent): void {
    if (Utils.isNonNullOrUndefined(swiper) && Utils.isNonNullOrUndefined(pointerEvent)) {
      this.selectedIndex$.next(swiper.clickedIndex);

      // when looping, the built in navigation on click is broken and jumps around when
      // crossing the loop boundaries therefore, slide using slideNext and slidePrev instead.
      if (this.loop) {
        const activeIndex = this.activeIndex$.value ?? 0;

        const nextIndex = this.getIndexFromActiveIndex(swiper.clickedIndex);
        const currentIndex = this.getIndexFromActiveIndex(activeIndex);
        const delta = nextIndex - currentIndex;

        if (delta === 0) {
          return;
        }

        // only support single step navigation
        if (Math.abs(delta) === 1) {
          if (delta < 0) {
            this.swiper.swiperRef.slidePrev();
          } else {
            this.swiper.swiperRef.slideNext();
          }

          return;
        }

        // check for loop around
        if (currentIndex == 0 && nextIndex === this.contents.length - 1) {
          this.swiper.swiperRef.slidePrev();
          return;
        }

        if (currentIndex == this.contents.length - 1 && nextIndex === 0) {
          this.swiper.swiperRef.slideNext();
          return;
        }

        console.warn('Click navigation of more than 1 is not supported');
      }
    }
  }

  ngAfterContentInit(): void {
    this.contents.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      // when the content changes, re-evaluate if the active index matches the input
      const newActiveIndex = this.getClosestActiveIndex(this.activeIndexInputCurrentValue(), this.activeIndex$.value ?? 0);

      if (newActiveIndex != this.activeIndex$.value) {
        this.swiper.swiperRef.slideTo(newActiveIndex);
      }
    });
  }

  private getIndexFromActiveIndex(activeIndex: number) {
    if (!this.loop || Utils.isNullOrUndefined(this.contents)) return activeIndex;

    // when looping the active index offset by this.loopedSlides while looping
    const offset = this.loop ? this.loopedSlides : 0;

    let loopIndex = activeIndex - offset;
    if (loopIndex < 0) {
      loopIndex += this.contents.length;
    }

    const realIndex = loopIndex % this.contents.length;
    return realIndex;
  }

  private getClosestActiveIndex(index: number, currentActiveIndex: number) {
    if (!this.loop || Utils.isNullOrUndefined(this.contents)) return index;

    // when looping the active index offset by this.loopedSlides while looping
    const offset = this.loop ? this.loopedSlides : 0;
    const targetActiveIndex = index + offset;

    const possibleTargets = [targetActiveIndex];

    // the active instance in swiper can go a bit further than allowed (because of the offset)
    if (index < 1) {
      possibleTargets.push(targetActiveIndex + this.contents.length);
    }
    if (this.contents.length - index < offset) {
      possibleTargets.push(targetActiveIndex - this.contents.length);
    }

    const distanceToTargets = possibleTargets.map((target) => Math.abs(target - currentActiveIndex));
    const minimumDistance = Math.min(...distanceToTargets);
    const minIndex = distanceToTargets.indexOf(minimumDistance);
    const closestTarget = possibleTargets[minIndex];

    return closestTarget;
  }
}
