import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { Goal, Person } from '@semmie/models';
import { Account, AccountAdditionalData, AccountHelpers } from '@onyxx/model/account';

import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { iUser } from '@onyxx/model/user';

import { BaseOnboardingService } from './__abstract/base-onboarding.service';
import { Utils } from '@semmie/shared/utils';
import { CommonQueryParams } from '@semmie/views/shared/common-query-params.enum';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { MainRouteNames } from '@onyxx/model/main';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';

@Injectable({
  providedIn: 'root',
})
export class ChildOnboardingService extends BaseOnboardingService {
  private child: Person;

  override initialize(account: Account, person: Person, user: iUser, goal?: Goal, additionalData?: AccountAdditionalData): void {
    this.account = account;
    this.person = person;
    this.user = user;
    this.accountAdditionalData = additionalData;

    this.handleAdvisorOnboarding();

    // because of the previous line
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const accountPerson = this.account.people.find((p) => p.id === this.user!.person?.id);
    this.accountPerson = new AccountPerson(accountPerson);

    const child = AccountHelpers.child(this.account);

    this.personService
      .getPersonById(child?.id, false, false)
      .pipe(take(1), filter(Utils.isNonNullOrUndefined))
      .subscribe((child) => {
        this.child = child;
        this.handleOnboarding();
      });
  }

  handleOnboarding(): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(finished)),
        switchMap((finished) => this.handleFinishedPerson(finished)),
        switchMap((finished) => this.handleFinishedIdentification(finished)),
        switchMap((finished) => this.handleFinishedChildData(finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(finished)),
        switchMap((finished) => this.handleFinishedStrategy(finished)),
        switchMap((finished) => this.handleFinishedPlan(finished)),
        switchMap((finished) => {
          if (this.accountPerson.isInvitee()) {
            return this.inviteeOnboarding(finished);
          } else {
            return this.initiatorOnboarding(finished);
          }
        }),
      )
      .subscribe(() => {
        this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  handleFinishedIdentification(finished: boolean): Observable<any> {
    if (!finished) {
      this.navigationService.navigate(
        [
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.Identification,
          {
            step: 'identification_type',
          },
        ],
        {
          queryParams: this.isAdvisorOnboarding
            ? {
                [CommonQueryParams.PersonId]: this.person?.id,
              }
            : undefined,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedChildData();
  }

  handleFinishedChildData(finished: boolean): Observable<any> {
    if (!finished) {
      this.navigationService.navigate([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Child,
        this.child.id,
        {
          step: this.currentStep.value,
        },
      ]);

      return EMPTY;
    }

    return this.hasFinishedQuestionnaire();
  }

  handleFinishedPlan(finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('plan');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Plan]);

      return EMPTY;
    }

    if (this.accountPerson.isInvitee()) {
      return this.hasBeenWidValidated();
    }

    return this.hasFinishedFirstPayment();
  }

  handleInvitationCompleted(finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('invite');

      this.navigationService.navigate([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Invitation,
        { step: InvitationStep.INVITE },
      ]);

      return EMPTY;
    }

    if (this.accountAdditionalData?.skipInvitation) return this.hasFinishedSigning('me');

    if (this.account.invitation?.id) {
      this.navigationService.navigate([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Invitation,
        this.account.invitation.id,
      ]);
    } else {
      this.navigationService.navigate([this.onboardingPathPrefix(this.account.id), MainRouteNames.Onboarding, this.account.id]);
    }

    return this.hasFinishedSigning('other');
  }

  handleInviteeHasSigned(finished: boolean): Observable<any> {
    if (this.accountAdditionalData?.skipInvitation) return this.hasFinishedSummary();

    if (!finished) {
      return EMPTY;
    }
    this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Summary]);

    return this.hasFinishedSummary();
  }

  handleCompletedWidPayment(finished: boolean): Observable<any> {
    if (!finished) {
      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'wid' }]);

      return EMPTY;
    }

    this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Summary]);

    return this.hasFinishedSummary();
  }

  override handleFinishedFirstPayment(finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('payment');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'first' }]);

      return EMPTY;
    }

    return this.hasFinishedSendingInvitation();
  }

  private hasFinishedChildData(): Observable<boolean> {
    if (!this.child.name_casual || !this.child.birth_at || !this.child.gender) {
      this.currentStep.next('basic');
    } else if (!this.child.hasCompletedAddress()) {
      this.currentStep.next('address');
    } else if (!this.child.hasCompletedNationality() || !this.child.question_tax_netherlands) {
      this.currentStep.next('nationality');
    } else if (!this.child.hasCompletedBsn() || !this.child.finished_at) {
      this.currentStep.next('details');
    } else {
      this.currentStep.next(null);
    }

    return of(!!this.child.finished_at);
  }

  private initiatorOnboarding(finished: boolean): Observable<any> {
    return this.handleFinishedFirstPayment(finished).pipe(
      switchMap((finished) => this.handleFinishedSendingInvitation(finished)),
      switchMap((finished) => this.handleInvitationCompleted(finished)),
      switchMap((finished) => this.handleInviteeHasSigned(finished)),
      switchMap((finished) => this.handleFinishedSummary(finished)),
      switchMap((finished) => this.handleFinishedSigning(finished)),
    );
  }

  private inviteeOnboarding(finished: boolean): Observable<any> {
    return this.handleCompletedWidPayment(finished).pipe(
      switchMap((finished) => this.handleFinishedSummary(finished)),
      switchMap((finished) => this.handleFinishedSigning(finished)),
    );
  }
}
