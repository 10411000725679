import { BaseModel } from '@semmie/models/_abstract';
import { RiskProfileIdentifier, RiskProfile as iRiskProfile } from '@onyxx/model/risk-profile';

export class RiskProfile extends BaseModel<iRiskProfile> implements iRiskProfile {
  identifier: RiskProfileIdentifier;
  title: string;
  invested: boolean;
  selected: boolean;
  active_at: string;
  advice: boolean;
  enabled: boolean;
  target: boolean;
  /** Hide the profile in FE, to be refactored later */
  hidden: boolean;

  constructor(props?: Partial<RiskProfile>) {
    super(props);
  }

  get outOfReach(): boolean {
    return this.selected && !this.enabled;
  }
}
