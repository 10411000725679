export enum AccountStatus {
  ONBOARDING = 'onboarding',
  TASK = 'task',
  CHECKING = 'checking',
  HOLD = 'hold',
  CREATED = 'created',
  INVESTING = 'investing',
  ACTIVE = 'active',
  MIGRATION_HOLD = 'migration_hold',
  BLOCKED = 'blocked',
  TERMINATED = 'terminated',
  ARCHIVED = 'archived',
  INVITE_SENT = 'invite_sent',
  MIGRATING = 'migrating',
}
