import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Payment } from '@semmie/models/bi/payment/payment.model';
import { DirectDebit } from '@semmie/models/bi/payment/direct-debit.model';

import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { iBank } from '@onyxx/model/bank';

@Injectable({
  providedIn: 'root',
})
export class PaymentProvider extends BaseProvider<any> {
  constructor() {
    super('payments');
  }

  listBanks(): Observable<iBank[]> {
    return super.__query('payments/banks').pipe(map((response) => response));
  }

  get(id: string) {
    return super
      .__query(`payments/${id}`)
      .pipe(map((response) => (response && response['payment'] ? new Payment(response['payment']) : null)));
  }

  startPayment(account: string, params: any): Observable<Payment> {
    return super.__post(`accounts/${account}/payments`, params).pipe(map((response) => new Payment(response['payment'])));
  }

  /**
   * Specific method to start a first payment.
   * !todo: remove once the logic is consolidated by the backend.
   */
  startFirstPayment(account: string, params: any): Observable<Payment> {
    const workaroundParams = {
      ...params.payment,
    };

    return super
      .__post(`accounts/${account}/payments/first`, workaroundParams)
      .pipe(map((response) => (response['payment'] ? new Payment(response['payment']) : new DirectDebit(response?.auto_incasso))));
  }

  clear(): void {
    this.__clear();
  }
}
