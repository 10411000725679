import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { goalFeature } from './goal.reducer';
import { GoalEffects } from './goal.effects';
import { GoalFacade } from './goal.facade';

export const providers = [provideState(goalFeature), provideEffects(GoalEffects), GoalFacade];

@NgModule({ providers })
export class GoalStateModule {}

export * from './goal.facade';
