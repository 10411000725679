import { BaseSize } from '@semmie/schemas';

export type IconSize = 'xxxxs' | 'xxxs' | 'xxs' | BaseSize | 'xl2' | 'xl3';

export type IconTheme = 'black' | 'white' | 'primary' | 'contrast' | 'success' | 'warning' | 'danger';

export enum Icon {
  TWOFACTOR = 'icon-2fa',
  VISIBLE = 'icon-visible',
  USER = 'icon-user',
  UPLOAD = 'icon-upload',
  TRANSACTIONS = 'icon-transactions',
  TASK = 'icon-task',
  SUPPORT = 'icon-support',
  SETTINGS = 'icon-settings',
  OVERVIEW = 'icon-overview',
  PHOTO = 'icon-photo',
  PLUS = 'icon-plus',
  PORTFOLIO = 'icon-portfolio',
  QR = 'icon-qr',
  QUESTIONMARK = 'icon-questionmark',
  QUESTIONNAIRE = 'icon-questionnaire',
  RATE = 'icon-rate',
  REDEEM = 'icon-redeem',
  RETRY = 'icon-retry',
  SEARCH = 'icon-search',
  SECURITY = 'icon-security',
  SECURITY2 = 'icon-security2',
  NOTIFICATIONS = 'icon-notifications',
  MOBILE = 'icon-mobile',
  MINUS = 'icon-minus',
  MAGAZINE = 'icon-magazine',
  LOCKED = 'icon-locked',
  LOCATION = 'icon-location',
  LISTVIEW = 'icon-listview',
  LISTSORT = 'icon-listsort',
  LINKEDACCOUNT = 'icon-linkedaccount',
  LEGAL = 'icon-legal',
  LEARN = 'icon-learn',
  INVESTMENTPROFILE = 'icon-investmentprofile',
  INFO = 'icon-info',
  EMAIL = 'icon-email',
  EXCLAMATION = 'icon-exclamation',
  EXCLAMATIONCIRCLE = 'icon-exclamation-circle',
  FACEID = 'icon-faceid',
  FILTER = 'icon-filter',
  FINGERPRINT = 'icon-fingerprint',
  FLAG = 'icon-flag',
  GRAPH = 'icon-graph',
  HANDLE = 'icon-handle',
  HEART = 'icon-heart',
  HIDE = 'icon-hide',
  HOME = 'icon-home',
  INBOX = 'icon-inbox',
  EDIT2 = 'icon-edit2',
  EDIT = 'icon-edit',
  DOWNLOAD = 'icon-download',
  DOT = 'icon-dot',
  DOCUMENTS = 'icon-documents',
  DISPLAY = 'icon-display',
  DISCOVER = 'icon-discover',
  DIRECTIONS = 'icon-directions',
  DELETE = 'icon-delete',
  CODE = 'icon-code',
  CLOSE = 'icon-close',
  CLOCK = 'icon-clock',
  CIRCLE = 'icon-circle',
  ACCOUNTHOLDERS = 'icon-accountholders',
  ARCHIVE = 'icon-archive',
  BOOSTER = 'icon-booster',
  CALCULATOR = 'icon-calculator',
  CALENDAR = 'icon-calendar',
  CALL = 'icon-call',
  CHAT = 'icon-chat',
  CHECKMARK = 'icon-checkmark',
  CHEVRONDOWN = 'icon-chevrondown',
  CHEVRONLEFT = 'icon-chevronleft',
  CHEVRONRIGHT = 'icon-chevronright',
  CHEVRONUP = 'icon-chevronup',
  ATOM = 'icon-atom',
  HAMBURGER = 'icon-hamburger',
  APPICON = 'icon-appicon',
  COPY = 'icon-copy',
  DIAMOND = 'icon-diamond',
  FILE = 'icon-file',
  GLOBE = 'icon-globe',
  LANGUAGE = 'icon-language',
  MORE = 'icon-more',
  MOON = 'icon-moon',
  LOGOUT = 'icon-logout',
  PUSHNOTIFICATION = 'icon-pushnotification',
  SETTINGS2 = 'icon-settings2',
  ACCESS = 'icon-access',
  LEAVES = 'icon-leaves',
  TREE = 'icon-tree',
  TREE2 = 'icon-tree2',
  FINANCE = 'icon-finance',
  TIME = 'icon-time',
  CHILD = 'icon-child',
  BABY = 'icon-baby',
  BACKSPACE = 'icon-backspace',
  BUILDING = 'icon-building',
  DIVIDEND = 'icon-dividend',
  CURRENCY = 'icon-currency',
  SAILBOAT = 'icon-sailboat',
  FUNDS = 'icon-funds',
  ATTRIBUTES = 'icon-attributes',
  KVK = 'icon-kvk',
  FINANCIAL = 'icon-financial',
  REVENUE = 'icon-revenue',
  STRUCTURE = 'icon-structure',
  ARROWDOWN = 'icon-arrowdown',
  ARROWUP = 'icon-arrowup',
  LIKE = 'icon-like',
  DISLIKE = 'icon-dislike',
  ARROWSUPDOWN = 'icon-arrows-up-down',
  COINS = 'icon-coins',
  EUROCIRCLE = 'icon-euro-circle',
  LEGAL2 = 'icon-legal2',
  PAPERPLANE = 'icon-paper-plane',
  TOOLS = 'icon-tools',
  SPEAKER = 'icon-speaker',
  USERPLUS = 'icon-user-plus',
  WALLET = 'icon-wallet',
  CHAT2 = 'icon-chat2',
  CLAIM = 'icon-claim',
  COMMENT = 'icon-comment',
  EXPORT = 'icon-export',
  NOTE = 'icon-note',
  SNOOZE = 'icon-snooze',
  TODO = 'icon-todo',
  PRINTER = 'icon-printer',
  SCALE = 'icon-scale',
  SAVE = 'icon-save',
  PIN = 'icon-pin',
  PASSPORT = 'icon-passport',
  PARTY = 'icon-party',
  PAPERCLIP = 'icon-paperclip',
  REPLY = 'icon-reply',
  SYNC = 'icon-sync',
  TABLE = 'icon-table',
  SNOOZE2 = 'icon-snooze2',
  LEAF = 'icon-leaf',
  MOUNTAIN = 'icon-mountain',
  SCALE2 = 'icon-scale2',
  THUNDER = 'icon-thunder',
  X = 'icon-x',
  WHATSAPP = 'icon-whatsapp',
  LINKEDIN = 'icon-linkedin',
  BOLD = 'icon-bold',
  ITALIC = 'icon-italic',
  IMAGE = 'icon-image',
  LINK = 'icon-link',
  LIST = 'icon-list',
  UNDERLINE = 'icon-underline',
}
