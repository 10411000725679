import { Message } from '@semmie/models/bi/message/message.model';
import { iMessageDocument } from '@semmie/schemas/bi/message/message';
import { iMessageDetail } from '@semmie/schemas/bi/message/message-detail.interface';

export class MessageDetail extends Message implements iMessageDetail {
  body: string;
  account: any;
  documents?: Array<iMessageDocument>;

  constructor(message: Partial<MessageDetail>) {
    super(message);
  }
}
