import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { userTaskApiActions } from './user-task-api.actions';
import { userTaskCommonActions } from './user-task-common.actions';
import { TaskHelpers, UserTask } from '@onyxx/model/task';
import { createPaginatedEntityAdapter, PaginatedEntityState } from '@onyxx/utility/paginated-entity-state';
import { Utils } from '@onyxx/utility/general';

export interface State extends PaginatedEntityState<UserTask> {
  initialized: boolean;
  skippedHighPriorityTaskIds: string[];
  busyLoading: boolean;
}

const adapter = createPaginatedEntityAdapter<UserTask>();

const initialState: State = adapter.getInitialState({
  initialized: false,
  skippedHighPriorityTaskIds: [],
  busyLoading: false,
});

const reducer = createReducer(
  initialState,
  on(
    userTaskApiActions.reloadTasks,
    userTaskApiActions.reloadTaskList,
    userTaskApiActions.loadNextPage,
    (state): State => ({ ...state, busyLoading: true }),
  ),
  on(userTaskApiActions.reloadTasksFailure, userTaskApiActions.loadPageFailure, (state): State => ({ ...state, busyLoading: false })),
  on(
    userTaskApiActions.reloadTasksSuccess,
    (state, { tasks, paginationMeta }): State => ({
      ...adapter.setAll(tasks, paginationMeta, state),
      initialized: true,
      busyLoading: false,
    }),
  ),

  on(
    userTaskApiActions.loadPageSuccess,
    (state, { tasks, paginationMeta }): State => ({
      ...adapter.addNextPage(tasks, paginationMeta, state),
      initialized: true,
      busyLoading: false,
    }),
  ),

  on(userTaskCommonActions.skipTask, (state, { id }): State => {
    const task = state.entities[id];
    if (Utils.isNil(task) || !TaskHelpers.canSkipTask(task)) {
      return { ...state };
    }

    return {
      ...state,
      skippedHighPriorityTaskIds: [...state.skippedHighPriorityTaskIds, id],
    };
  }),

  on(userTaskCommonActions.removeTask, (state, { id }): State => ({ ...adapter.removeOne(id, state) })),
  on(userTaskApiActions.loadTaskDetailFailure, (state, { id }): State => ({ ...adapter.removeOne(id, state) })),
  on(userTaskCommonActions.clear, (): State => initialState),
);

export const userTaskFeature = createFeature({
  name: 'userTask',
  reducer,
  extraSelectors: ({ selectUserTaskState, selectInitialized, selectEntities, selectPaginationMeta, selectSkippedHighPriorityTaskIds }) => {
    const paginatedStoreSelectors = adapter.getSelectors(selectUserTaskState, selectEntities, selectPaginationMeta);
    return {
      ...paginatedStoreSelectors,
      selectUrgentTasks: createSelector(
        selectEntities,
        selectInitialized,
        selectSkippedHighPriorityTaskIds,
        (entities, initialized, skippedIds) => {
          return !initialized
            ? null
            : Object.values(entities)
                .filter(Utils.isNotNil)
                .filter((task) => TaskHelpers.isUrgentTask(task) && !skippedIds.includes(task.id));
        },
      ),
      selectTaskCount: createSelector(selectPaginationMeta, (meta) => meta?.total_count ?? 0),
    };
  },
});
