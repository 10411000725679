import { NgModule } from '@angular/core';

import {
  AdvancedChartComponent,
  AdvancedChartModule,
  ButtonComponent,
  ButtonModule,
  CardComponent,
  CardModule,
  CarouselComponent,
  CarouselModule,
  CarouselSlideComponent,
  CarouselSlideModule,
  CheckboxComponent,
  CheckboxGroupComponent,
  CheckboxGroupModule,
  CheckboxModule,
  ContentContainerBottomComponent,
  ContentContainerComponent,
  ContentContainerModule,
  ContentContainerTopComponent,
  DropdownComponent,
  DropdownModule,
  ExternalDataComponent,
  ExternalDataModule,
  FormComponent,
  FormGroupComponent,
  FormGroupModule,
  FormModule,
  FormPageComponent,
  FormPageModule,
  HeaderComponent,
  HeaderModule,
  IconComponent,
  ImageComponent,
  ImageModule,
  InfoModalModule,
  IntroContentComponent,
  IntroContentModule,
  ItemCardComponent,
  LabelComponent,
  LabelModule,
  LoadingRippleComponent,
  LoadingRippleModule,
  MagazineCardComponent,
  MagazineCardModule,
  MenuItemComponent,
  MenuItemModule,
  PageHeaderComponent,
  PageHeaderModule,
  PageHeaderRightComponent,
  RadioButtonGroupComponent,
  RadioButtonGroupModule,
  ScrollableListComponent,
  ScrollableListModule,
  ScrollContainerComponent,
  ScrollContainerModule,
  SliderComponent,
  SliderModule,
  StepsComponent,
  StepsModule,
  SwitchComponent,
  SwitchModule,
  TableComponent,
  TableModule,
  TextareaComponent,
  TextareaModule,
  TextboxComponent,
  TextboxModule,
  TileComponent,
  TileModule,
  ToggleComponent,
  ToggleModule,
} from '@semmie/components';

@NgModule({
  imports: [
    ButtonModule,
    TextboxModule,
    TextareaModule,
    SwitchModule,
    SliderModule,
    ScrollableListModule,
    MagazineCardModule,
    LoadingRippleModule,
    ImageModule,
    LabelModule,
    ContentContainerModule,
    PageHeaderModule,
    CardModule,
    AdvancedChartModule,
    MenuItemModule,
    FormModule,
    FormPageModule,
    ExternalDataModule,
    IntroContentModule,
    ScrollContainerModule,
    RadioButtonGroupModule,
    CheckboxModule,
    CheckboxGroupModule,
    DropdownModule,
    TileModule,
    FormGroupModule,
    HeaderModule,
    IconComponent,
    ItemCardComponent,
    CarouselModule,
    CarouselSlideModule,
    InfoModalModule,
    ToggleModule,
    TableModule,
    StepsModule,
  ],
  exports: [
    ButtonComponent,
    TextboxComponent,
    TextareaComponent,
    SwitchComponent,
    SliderComponent,
    ScrollableListComponent,
    MagazineCardComponent,
    LoadingRippleComponent,
    ImageComponent,
    LabelComponent,
    ContentContainerComponent,
    ContentContainerTopComponent,
    ContentContainerBottomComponent,
    PageHeaderComponent,
    PageHeaderRightComponent,
    CardComponent,
    AdvancedChartComponent,
    MenuItemComponent,
    FormComponent,
    FormPageComponent,
    ExternalDataComponent,
    IntroContentComponent,
    ScrollContainerComponent,
    RadioButtonGroupComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DropdownComponent,
    TileComponent,
    FormGroupComponent,
    HeaderComponent,
    IconComponent,
    ItemCardComponent,
    CarouselComponent,
    CarouselSlideComponent,
    ToggleComponent,
    TableComponent,
    StepsComponent,
  ],
})
export class SemmieComponents {}
