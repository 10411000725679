import { Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export enum VibrationStyles {
  TAP = 10,
}
@Injectable({ providedIn: 'root' })
export class HapticFeedbackService {
  async interact(duration: ImpactStyle | VibrationStyles): Promise<void> {
    try {
      if (Object.values(ImpactStyle).includes(duration as any)) {
        await Haptics.impact({ style: duration as ImpactStyle });
      } else {
        await Haptics.vibrate({ duration: duration as VibrationStyles });
      }
    } catch (e) {
      // TODO: use correct error/logging service
      console.error(e);
    }
  }
}
