import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Highlights } from '@onyxx/model/highlights';

export const HighlightsAPIActions = createActionGroup({
  source: 'Highlights API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ highlights: Array<Highlights> }>(),
    'Load Failure': props<{ errorMsg: string }>(),

    MarkAsRead: props<{ highlightIds: string[] }>(),
    'MarkAsRead success': props<{ highlightIds: Array<string> }>(),
    'MarkAsRead failure': props<{ errorMsg: string }>(),
  },
});
