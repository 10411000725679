import { inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePickerOptions } from '@pantrist/capacitor-date-picker';
import { DateComponent, iDatePickerMinMax } from '@semmie/components/presentational/core/date/date.component';
import { ModalSize } from '@semmie/schemas/components/modal/modal-size';
import { DatePickerResult, DatePickerService } from '@semmie/services/datepicker/datepicker.service';
import { ModalService } from '@semmie/services/modal/modal.service';
import moment from 'moment';

export class DatePickerWebService extends DatePickerService {
  private modalService = inject(ModalService);

  async present(options: DatePickerOptions): Promise<DatePickerResult> {
    const currentDate = moment();

    const minFromToday: iDatePickerMinMax = {
      type: 'substract',
      days: currentDate.diff(moment(options.min), 'days'),
    };

    const maxFromToday: iDatePickerMinMax = {
      type: 'add',
      days: moment(options.max).diff(currentDate, 'days'),
    };

    const modal = await this.modalService.open(
      DateComponent,
      {
        componentProps: {
          inputMode: 'inline',
          formControl: new FormControl(),
          minFromToday,
          maxFromToday,
        },
      },
      { size: ModalSize.Auto },
    );

    await modal.present();

    const { data: value } = await modal.onDidDismiss();

    return { value };
  }
}
