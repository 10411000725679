import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { boostersFeature } from '@semmie/store/boosters/boosters.reducer';
import { BoostersEffects } from '@semmie/store/boosters/boosters.effects';
import { BoostersStoreFacade } from '@semmie/store/boosters/boosters.facade';

/**
 * The providers array is used to provide the booster feature state, effects, and facade.
 * This can be used from standalone routing and modules, but not from standalone components.
 * In order to include it in standalone modules use the module grouping below.
 */
export const providers = [provideState(boostersFeature), provideEffects(BoostersEffects), BoostersStoreFacade];

/**
 * The BoostersSoreModule class is an angular module that provides services for managing user state.
 */
@NgModule({ providers })
export class BoostersSoreModule {}

export * from './boosters.facade';
