import { DatePicker, DatePickerOptions } from '@pantrist/capacitor-date-picker';
import moment from 'moment';
import { DatePickerResult, DatePickerService } from './datepicker.service';
import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeService } from '@semmie/services/theme/theme.service';

export class DatePickerNativeService extends DatePickerService {
  private themeService = inject(ThemeService);
  private currentTheme = toSignal(this.themeService.currentTheme$);

  present(options: DatePickerOptions): Promise<DatePickerResult> {
    const params: DatePickerOptions = {
      mode: 'date',
      locale: 'nl_NL',
      format: 'yyyy/MM/dd',
      date: moment().format('yyyy/MM/DD'),
      theme: this.currentTheme()?.useDarkTheme ? 'dark' : 'light',
      ...options,
    };

    return DatePicker.present(params);
  }
}
