import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PlatformService } from '@semmie/services/platform/platform.service';

@Injectable()
export class IsAppGuard {
  constructor(
    private platformService: PlatformService,
    private router: Router,
  ) {}

  canActivate() {
    if (this.platformService.isApp) {
      return true;
    }
    return this.router.createUrlTree(['']);
  }
}
