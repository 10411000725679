import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { pskParticipantsFeature } from './psk-participants.reducer';
import { PskParticipant } from '@onyxx/model/psk-participants';

export type PskParticipantsResponse = { PskParticipants: PskParticipant[] };
@Injectable()
export class PskParticipantsStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  readonly loading$ = this.store.select(pskParticipantsFeature.selectLoading);
  readonly pskParticipants$ = this.store.select(pskParticipantsFeature.selectPsk_participants);
}
