import { createReducer, on, createFeature, createSelector } from '@ngrx/store';
import { userTaskCommonActions } from './user-task-common.actions';

import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { userTaskApiActions } from './user-task-api.actions';
import { TaskDetail } from '@onyxx/model/task';

export interface State extends EntityState<TaskDetail> {
  busyLoading: boolean;
  staleTasks: string[];
}

const adapter = createEntityAdapter<TaskDetail>();

const initialState: State = adapter.getInitialState({
  busyLoading: false,
  staleTasks: [],
});

const reducer = createReducer(
  initialState,
  on(userTaskApiActions.loadTaskDetail, (state): State => ({ ...state, busyLoading: true })),
  on(
    userTaskApiActions.loadTaskDetailSuccess,
    (state, { taskDetail }): State => ({
      ...adapter.upsertOne(taskDetail, state),
      busyLoading: false,
      staleTasks: state.staleTasks.filter((id) => id !== taskDetail.id),
    }),
  ),
  on(userTaskApiActions.loadTaskDetailFailure, (state): State => ({ ...state, busyLoading: false })),
  on(userTaskCommonActions.clear, userTaskApiActions.reloadTasks, (): State => ({ ...initialState })),
  on(userTaskCommonActions.ensureTaskDetailIsLoaded, (state): State => ({ ...state, busyLoading: true })),
  on(userTaskCommonActions.removeTask, (state, { id }): State => ({ ...adapter.removeOne(id, state) })),
  on(userTaskCommonActions.taskDetailIsLoaded, (state): State => ({ ...state, busyLoading: false })),
  on(userTaskCommonActions.markTaskAsStale, (state, { id }): State => ({ ...state, staleTasks: [...state.staleTasks, id] })),
);

export const userTaskDetailFeature = createFeature({
  name: 'userTaskDetail',
  reducer,
  extraSelectors: ({ selectUserTaskDetailState }) => {
    const adapterSelectors = adapter.getSelectors(selectUserTaskDetailState);
    return {
      ...adapterSelectors,
      selectTaskById: (id: string) =>
        createSelector(adapterSelectors.selectEntities, (entities) => {
          return entities[id] ?? null;
        }),
    };
  },
});
