import { PushNotifications } from '@capacitor/push-notifications';
import { PushNotificationEvent } from './push-notification-event.interface';
import { Utils } from '@onyxx/utility/general';
import { Capacitor } from '@capacitor/core';

const eventsReceivedBeforeListener: Array<PushNotificationEvent> = [];
const listeners: Map<number, (event: PushNotificationEvent) => void> = new Map();

export const addListener = (callback: (event: PushNotificationEvent) => void) => {
  const id = Date.now();
  listeners.set(id, callback);

  if (Utils.arrayIsNotEmpty(eventsReceivedBeforeListener)) {
    eventsReceivedBeforeListener.forEach((event) => {
      callback(event);
    });
  }

  return () => {
    listeners.delete(id);
  };
};

export const clearListeners = () => {
  listeners.clear();
  PushNotifications.removeAllListeners();
};

export const initializePushNotificationListeners = () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  PushNotifications.addListener('registration', (result) => {
    if (listeners.size > 0) {
      listeners.forEach((listener) => {
        listener({
          type: 'registration',
          data: result,
        });
      });
      return;
    }

    eventsReceivedBeforeListener.push({
      type: 'registration',
      data: result,
    });
    return;
  });

  PushNotifications.addListener('registrationError', (result) => {
    if (listeners.size > 0) {
      listeners.forEach((listener) => {
        listener({
          type: 'registrationError',
          data: result,
        });
      });
      return;
    }

    eventsReceivedBeforeListener.push({
      type: 'registrationError',
      data: result,
    });
  });

  PushNotifications.addListener('pushNotificationReceived', (result) => {
    if (listeners.size > 0) {
      listeners.forEach((listener) => {
        listener({
          type: 'pushNotificationReceived',
          data: result,
        });
      });
      return;
    }

    eventsReceivedBeforeListener.push({
      type: 'pushNotificationReceived',
      data: result,
    });
  });

  PushNotifications.addListener('pushNotificationActionPerformed', (result) => {
    if (listeners.size > 0) {
      listeners.forEach((listener) => {
        listener({
          type: 'pushNotificationActionPerformed',
          data: result,
        });
      });
      return;
    }

    eventsReceivedBeforeListener.push({
      type: 'pushNotificationActionPerformed',
      data: result,
    });
  });
};
