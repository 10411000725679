import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, combineLatest, concatMap, finalize, map, of, retry, switchMap, take, tap } from 'rxjs';
import { AuthProvider } from '@onyxx/provider/auth';
import { authCommonActions } from '../auth-common.actions';
import { ConfigService, SentryService } from '@semmie/services';
import { AuthFacade } from '../auth.facade';
import { authApiActions } from '../auth-api.actions';
import { HttpStatusCode } from '@angular/common/http';
import { authFeature } from '../auth.reducer';
import { Store } from '@ngrx/store';

export class TokenManagementEffects {
  private readonly actions$ = inject(Actions);
  private readonly authProvider = inject(AuthProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly configService = inject(ConfigService);
  private readonly sentryService = inject(SentryService);
  private readonly store = inject(Store);

  /**
   * This effects handles token refreshing and revoking in one. We have to make sure that we handle effects
   * that change the token one at a time (not in parallel), and we should also ignore multiple events of the same type
   * while the previous one is still in progress.
   */
  readonly changeToken$ = createEffect(() => {
    return this.actions$.pipe(ofType(authApiActions.refreshToken, authApiActions.revokeToken), (source) => {
      let refreshInProgress = false;
      let revokeInProgress = false;

      return source.pipe(
        concatMap((action) => {
          if (action.type === authApiActions.refreshToken.type && !refreshInProgress) {
            refreshInProgress = true;
            return of(action);
          }

          if (action.type === authApiActions.revokeToken.type && !revokeInProgress) {
            revokeInProgress = true;
            return of(action);
          }

          return EMPTY;
        }),

        concatMap((action) => {
          if (action.type === authApiActions.revokeToken.type) {
            return this.handleRevokeToken(action.skipNavigation).pipe(
              finalize(() => {
                revokeInProgress = false;
              }),
            );
          }

          if (action.type === authApiActions.refreshToken.type) {
            return this.handleRefreshToken().pipe(
              finalize(() => {
                refreshInProgress = false;
              }),
            );
          }

          return EMPTY;
        }),
      );
    });
  });

  readonly logoutOnRefreshTokenFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.refreshTokenFailure),
      tap(() => {
        this.sentryService.captureMessage('Token refresh failed - logging out');
      }),
      map(() => authCommonActions.logOut({ skipNavigation: false })),
    );
  });

  handleRefreshToken() {
    return combineLatest([this.store.select(authFeature.selectSession), this.configService.config$]).pipe(
      take(1),
      switchMap(([session, config]) => {
        return this.authProvider
          .requestToken({
            grant_type: 'refresh_token',
            client_id: config.oauth?.client_id ?? '',
            client_secret: config.oauth?.client_secret ?? '',
            refresh_token: session?.refresh_token,
          })
          .pipe(
            map((session) => authApiActions.refreshTokenSuccess({ session: session })),

            catchError((error) => {
              if (error.status === HttpStatusCode.BadRequest) {
                // failed because token is not valid, no need to retry
                return of(authApiActions.refreshTokenFailure({ error }));
              }

              throw error;
            }),
            retry(2),
            catchError((error) => {
              return of(authApiActions.refreshTokenFailure({ error }));
            }),
          );
      }),
    );
  }

  handleRevokeToken(skipNavigation: boolean) {
    return combineLatest([this.authFacade.token$, this.configService.config$]).pipe(
      take(1),
      switchMap(([token, config]) =>
        this.authProvider
          .revokeToken({
            token: token ?? '',
            client_id: config.oauth?.client_id ?? '',
            client_secret: config.oauth?.client_secret ?? '',
          })
          .pipe(
            map(() => authApiActions.revokeTokenSuccess({ skipNavigation })),
            catchError((error) => {
              this.sentryService.captureException(error);
              return of(authApiActions.revokeTokenFailure({ error, skipNavigation }));
            }),
          ),
      ),
    );
  }
}
