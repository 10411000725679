import { iBaseDynamicFormField } from './base-dynamic-field';

export enum CheckboxGroupLayout {
  Card = 'card',
}

export interface iCheckboxGroupOption {
  label: string;
  description?: string;
  value: string;
  disabled?: boolean;
}

export interface iCheckboxGroupFormField extends iBaseDynamicFormField {
  options: iCheckboxGroupOption[];
  layout?: CheckboxGroupLayout;
}
