import { createFeature, createReducer, on } from '@ngrx/store';
import { UserActivityStoreActions } from './actions/user-activity-store.actions';

export interface State {
  ready: boolean;
  dueDate: number | null;
  sessionExpired: boolean | null;
}

const initialState: State = {
  ready: false,
  dueDate: null,
  sessionExpired: null,
};

const reducer = createReducer(
  initialState,
  on(UserActivityStoreActions.initializeDone, (state): State => ({ ...state, ready: true })),
  on(
    UserActivityStoreActions.dueDateChanged,
    (state, { timestamp }): State => ({ ...state, dueDate: timestamp, sessionExpired: timestamp < Date.now() }),
  ),
  on(UserActivityStoreActions.sessionExpired, (state): State => {
    if (state.sessionExpired) return { ...state };
    return { ...state, sessionExpired: true };
  }),
);

export const userActivityFeature = createFeature({
  name: 'userActivity',
  reducer,
});
