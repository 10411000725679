import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdvisorAccountStoreActions } from '@semmie/store/advisor-account/actions/advisor-store.actions';
import { advisorAccountFeature } from '@semmie/store/advisor-account/advisor-account.reducer';
import { filter } from 'rxjs';
import { Utils } from '@semmie/shared/utils';
import { AdvisorAccountApiActions } from '@semmie/store/advisor-account/actions/advisor-api.actions';

@Injectable()
export class AdvisorAccountStoreFacade {
  private readonly store = inject(Store);

  readonly account$ = this.store.select(advisorAccountFeature.selectAccount).pipe(filter(Utils.isNonNullOrUndefined));
  readonly sendingProposal$ = this.store.select(advisorAccountFeature.selectSendingProposal);

  returnUrlForAccount(accountId: string) {
    return this.store.select(advisorAccountFeature.selectReturnUrlByAccountId(accountId));
  }

  dispatchSetReturnUrlForAccount(accountId: string, returnUrl: string) {
    this.store.dispatch(
      AdvisorAccountStoreActions.saveReturnUrl({
        accountId,
        returnUrl,
      }),
    );
  }

  dispatchSendProposal(accountId: string) {
    this.store.dispatch(AdvisorAccountApiActions.sendProposal({ accountId }));
  }
}
