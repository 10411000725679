<div class="flex items-center gap-x-6">
  <div class="relative flex h-[5.5rem] w-[5.5rem] flex-shrink-0 rounded-full" [style.background]="assetIndicatorBackground$$()">
    <div class="donut-filler"></div>
  </div>
  <div class="flex grow flex-col gap-y-2">
    <div *ngIf="stocksPct$$() !== 0" class="flex items-center justify-between">
      <div class="flex items-center gap-x-2">
        <div class="asset-indicator stocks"></div>
        <semmie-label class="asset-label" weight="medium" i18n-text="@@portfolio-assets-allocation.stocks" text="Shares"></semmie-label>
      </div>
      <semmie-label class="asset-percentage" weight="medium" [text]="stocksPct$$() * 100 | semmiePercentage"></semmie-label>
    </div>
    <div *ngIf="bondsPct$$() !== 0" class="flex items-center justify-between">
      <div class="flex items-center gap-x-2">
        <div class="asset-indicator bonds"></div>
        <semmie-label class="asset-label" weight="medium" i18n-text="@@portfolio-assets-allocation.bonds" text="Bonds"></semmie-label>
      </div>
      <semmie-label class="asset-percentage" weight="medium" [text]="bondsPct$$() * 100 | semmiePercentage"></semmie-label>
    </div>
    <div *ngIf="privateMarketPct$$() !== 0" class="flex items-center justify-between">
      <div class="flex items-center gap-x-2">
        <div class="asset-indicator private-markets"></div>
        <semmie-label
          class="asset-label"
          weight="medium"
          i18n-text="@@portfolio-assets-allocation.private-markets"
          text="Private markets"
        ></semmie-label>
      </div>
      <semmie-label class="asset-percentage" weight="medium" [text]="privateMarketPct$$() * 100 | semmiePercentage"></semmie-label>
    </div>
    <div *ngIf="cashPct$$() !== 0" class="flex items-center justify-between">
      <div class="flex items-center gap-x-2">
        <div class="asset-indicator cash"></div>
        <semmie-label
          class="asset-label"
          weight="medium"
          i18n-text="@@portfolio-assets-allocation.cash"
          text="Money market fund"
        ></semmie-label>
      </div>
      <semmie-label class="asset-percentage" weight="medium" [text]="cashPct$$() * 100 | semmiePercentage"></semmie-label>
    </div>
  </div>
</div>
