import { inject } from '@angular/core';
import { catchError, distinctUntilChanged, exhaustMap, filter, map, switchMap, take } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { visibleAccountsListApiActions } from './visible-accounts-list-api.actions';
import { Store } from '@ngrx/store';
import { visibleAccountsListFeature } from './visible-accounts-list.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountProvider } from '@onyxx/provider/account';
import { combineLatest, of } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { visibleAccountsListCommonActions } from './visible-accounts-list-common.actions';
import { UserStoreFacade } from '@semmie/store/user';
import { filterNil } from '@onyxx/utility/observables';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountKind, AccountState } from '@onyxx/model/account';
import { ConfigService } from '@semmie/services';
import { iConfigurationView } from '@semmie/schemas/bi/configuration/configuration';
import { Utils } from '@onyxx/utility/general';

export class VisibleAccountsListEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly accountProvider = inject(AccountProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly userStoreFacade = inject(UserStoreFacade);
  private readonly configService = inject(ConfigService);

  private readonly user$ = this.userStoreFacade.user$;
  private readonly archivedAccountParams$ = this.user$.pipe(
    filterNil(),
    map((user) => user.preferences?.archive),
    distinctUntilChanged(),
    map((showArchived) => ({
      state_from: AccountState.ONBOARDING,
      state_till: showArchived ? AccountState.ARCHIVED_2 : AccountState.TERMINATED,
    })),
  );

  private readonly accountKindFilter$ = this.configService.config$.pipe(
    // TODO: the types of config.views does not match the actual data. Therefore I had to cast it here
    // If we fix the types of the config service, we can remove the cast
    map((config) => config?.config.views?.['accounts'] as Record<string, iConfigurationView>),
    map((viewConfig) => viewConfig?.['list']?.excludedKinds),
    distinctUntilChanged(),
    map((excludedKinds) =>
      Utils.isNil(excludedKinds)
        ? {}
        : {
            kind: Object.values(AccountKind).filter((kind) => !excludedKinds.includes(kind)),
          },
    ),
  );

  private readonly accountsFilter$ = combineLatest([this.accountKindFilter$, this.archivedAccountParams$]).pipe(
    map(([kinds, archiveParams]) => ({ ...kinds, ...archiveParams })),
  );

  readonly reloadIfAccountFiltersChange$ = createEffect(() => {
    return this.accountsFilter$.pipe(
      concatLatestFrom(() => this.store.select(visibleAccountsListFeature.selectInitialized)),
      filter(([, initialized]) => initialized),
      map(() => visibleAccountsListApiActions.reloadAccounts()),
    );
  });

  readonly reloadAccounts = createEffect(() => {
    return this.actions$.pipe(
      ofType(visibleAccountsListApiActions.reloadAccounts),
      concatLatestFrom(() => this.accountsFilter$),
      switchMap(([, filter]) => {
        return this.accountProvider.list(filter).pipe(
          map(({ data, meta }) =>
            visibleAccountsListApiActions.reloadAccountsSuccess({
              accounts: data,
              paginationMeta: meta,
            }),
          ),
          catchError((error) => of(visibleAccountsListApiActions.reloadAccountsFailure({ errorMsg: error.message }))),
        );
      }),
    );
  });

  readonly loadNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(visibleAccountsListApiActions.loadNextPage),
      concatLatestFrom(() => [this.store.select(visibleAccountsListFeature.selectPaginationMeta), this.accountsFilter$]),
      exhaustMap(([, pageInfo, accountFilter]) => {
        const nextPage = (pageInfo?.current_page ?? 0) + 1;
        return this.accountProvider.list({ page: nextPage, ...accountFilter }).pipe(
          take(1),
          map(({ data, meta }) =>
            visibleAccountsListApiActions.loadPageSuccess({
              accounts: data,
              paginationMeta: meta,
              ...accountFilter,
            }),
          ),
          catchError((error) => of(visibleAccountsListApiActions.loadPageFailure({ errorMsg: error.message }))),
        );
      }),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => visibleAccountsListCommonActions.clear()));
  });
}
