import { Account } from '../account.interface';
import { AccountStatus } from '../account-status.enum';
import { AccountState } from '../account-state.enum';
import { isSimpleRole } from './account-access-selectors';
import { othersHaveSigned } from './account-state-selectors';
import { hasAdvisor } from './account-people-selectors';

export const status = (account: Account, userId?: string | null): AccountStatus => {
  switch (account.state) {
    case AccountState.PROPOSAL:
    case AccountState.PROPOSAL_SENT:
    case AccountState.ONBOARDING:
      if (
        account.invitation &&
        userId &&
        !account.invitation.declined &&
        othersHaveSigned(account, userId) === false &&
        !hasAdvisor(account)
      )
        return AccountStatus.INVITE_SENT;
      return AccountStatus.ONBOARDING;
    case AccountState.SIGNED:
    case AccountState.CHECKING:
      if (isSimpleRole(account) && account.invitation && !account.invitation.declined) return AccountStatus.INVITE_SENT;
      return AccountStatus.CHECKING;
    case AccountState.TASK:
      if (isSimpleRole(account) && account.invitation && !account.invitation.declined) return AccountStatus.INVITE_SENT;
      return AccountStatus.TASK;
    case AccountState.MIGRATING:
      return AccountStatus.MIGRATING;
    case AccountState.HOLD:
      return AccountStatus.HOLD;
    case AccountState.CREATED:
      return AccountStatus.CREATED;
    case AccountState.MIGRATION_HOLD:
      return AccountStatus.MIGRATION_HOLD;
    case AccountState.INVESTING:
      return AccountStatus.INVESTING;
    case AccountState.ACTIVE:
      return AccountStatus.ACTIVE;
    case AccountState.BLOCKED:
      return AccountStatus.BLOCKED;
    case AccountState.TERMINATED:
      return AccountStatus.TERMINATED;
    case AccountState.ARCHIVED:
    case AccountState.ARCHIVED_2:
      return AccountStatus.ARCHIVED;
  }
};
