import { NgModule } from '@angular/core';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { SharedModule } from '@semmie/shared/shared.module';

import { HapticOnTapDirective } from '@semmie/directives/haptic-on-tap.directive';
import { IconCardsComponent } from './icon-cards.component';

@NgModule({
  imports: [SharedModule, LabelModule, ImageModule, HapticOnTapDirective],
  exports: [IconCardsComponent],
  declarations: [IconCardsComponent],
})
export class IconCardsModule {}
