import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { ButtonModule, CardModule, LabelModule } from '@semmie/components';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, CardModule, ImageModule, ButtonModule, LabelModule],
  selector: 'semmie-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCardComponent extends BaseComponent {
  @Input() cta: string;
  @Input() ctaDisabled: boolean;
  @Input() ctaSecondary: string;
  @Input() description?: string | null;
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() image?: string | null;
  @Input() imageStyle = 'mx-auto w-1/2 h-24';
  @Output() ctaInteraction: EventEmitter<boolean> = new EventEmitter();
  @Output() ctaSecondaryInteraction: EventEmitter<boolean> = new EventEmitter();
}
