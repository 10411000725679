import { Injectable, inject } from '@angular/core';
import { SecureApplicationOptions } from '@onyxx/store/auth';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { AdvisorAccountStoreFacade } from '@semmie/store/advisor-account';
import { AppRouteNames, MainRouteNames } from '@onyxx/model/main';
import { environment } from 'environments/environment';
import { firstValueFrom, map, of } from 'rxjs';
import { SecurityModalResult, SecurityModalService } from '@semmie/services/security-modal/security-modal.service';

import { AuthStoreConfig } from '@onyxx/store/auth';
import { PushNotificationsFacade } from '@onyxx/store/push-notifications';
import { AccountMessagesService } from '@semmie/services/accounts/account-messages.service';
import { AccountsService } from '@semmie/services/accounts/accounts.service';
import { AppService } from '@semmie/services/app/app.service';
import { ConfigService } from '@semmie/services/config/config.service';
import { InboxService } from '@semmie/services/inbox/inbox.service';
import { InvitationService } from '@semmie/services/invitation/invitation.service';
import { OpenTasksService } from '@semmie/services/open-tasks';
import { PersonService } from '@semmie/services/person/person.service';
import { PinCodeService } from '@semmie/services/pincode/pincode.service';
import { TaskService } from '@semmie/services/task/task.service';
import { TaskMessagesService } from '@semmie/services/tasks/task-messages.service';
import { SecureApplicationStatus } from '@onyxx/store/auth';

@Injectable({ providedIn: 'root' })
export class AuthStoreConfigService implements AuthStoreConfig {
  private readonly platformService = inject(PlatformService);
  private readonly advisorAccountStoreFacade = inject(AdvisorAccountStoreFacade);
  private readonly securityModalService = inject(SecurityModalService);
  private readonly pushNotificationsFacade = inject(PushNotificationsFacade);

  private readonly appService = inject(AppService);
  private readonly personService = inject(PersonService);
  private readonly accountsService = inject(AccountsService);
  private readonly taskService = inject(TaskService);
  private readonly invitationService = inject(InvitationService);
  private readonly openTasksService = inject(OpenTasksService);
  private readonly inboxService = inject(InboxService);
  private readonly taskMessagesService = inject(TaskMessagesService);
  private readonly accountMessagesService = inject(AccountMessagesService);
  private readonly pinCodeService = inject(PinCodeService);
  private readonly configService = inject(ConfigService);

  async loginRedirectUrl() {
    return this.platformService.isApp ? { url: AppRouteNames.PinCode, external: false } : { url: '', external: false };
  }

  async twoFactorRedirectUrl() {
    return AppRouteNames.TwoFactor;
  }

  async logoutRedirectUrl() {
    // This handler can be called before the route is activated, therefore, we cannot user
    // the Angular router to check if the user is on the Advisor route.
    const onAdvisorRoute = location.pathname.startsWith(`/${MainRouteNames.Advisor}`);

    if (onAdvisorRoute) {
      const account = await firstValueFrom(this.advisorAccountStoreFacade.account$);
      const returnUrl = await firstValueFrom(this.advisorAccountStoreFacade.returnUrlForAccount(account.id));

      return {
        url: returnUrl || environment.advisorPortalUrl,
        external: true,
      };
    }

    return {
      url: '',
      external: false,
    };
  }

  onSecureApplication(options: SecureApplicationOptions) {
    if (this.platformService.isApp) {
      return this.securityModalService
        .show({
          ignoreBiometrics: options.strict,
        })
        .pipe(
          map((result) => {
            switch (result) {
              case SecurityModalResult.Opening:
                return SecureApplicationStatus.Securing;
              case SecurityModalResult.Opened:
                return SecureApplicationStatus.ModalShown;
              default:
                return SecureApplicationStatus.Secured;
            }
          }),
        );
    }
    return of(SecureApplicationStatus.Logout);
  }

  async onBeforeLogout(): Promise<void> {
    // unenroll from push notifications
    this.pushNotificationsFacade.dispatchClearPushNotification();

    // wait for notification clear to complete
    await firstValueFrom(this.pushNotificationsFacade.clearDone$);

    // clean up services that use app storage
    await this.appService.clearAppPreferences();
    await this.pinCodeService.clearStorageOnLogout();
    await this.configService.clearStorageOnLogout();
  }

  // TODO, the stores can clear their data in response to the logout action rather then being called from here
  onAfterLogout() {
    this.accountsService.clear();
    this.personService.clear();
    this.taskService.clear();
    this.invitationService.clear();
    this.openTasksService.reset();
    this.inboxService.clear();
    this.taskMessagesService.clear();
    this.accountMessagesService.clear();
  }
}
