import { inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { Account, AccountState, AccountKind, AccountHelpers } from '@onyxx/model/account';

import { Goal } from '@semmie/models/bi/goal';
import { Invitation } from '@semmie/models/bi/invitation';
import { Message } from '@semmie/models/bi/message';
import { DirectDebit } from '@semmie/models/bi/payment/direct-debit.model';
import { RiskProfile } from '@semmie/models/bi/risk-profile/';
import { Strategy } from '@semmie/models/bi/strategy/strategy.model';
import { AccountsProvider as LegacyAccountsProvider } from '@semmie/providers/accounts/accounts.provider';
import { PersonRoleType } from '@semmie/schemas';
import { GoalFocus } from '@semmie/schemas/bi/goal/goal-focus.enum';
import { iDirectDebit } from '@semmie/schemas/bi/payment';
import { iTermination, iTerminationResponse } from '@semmie/schemas/bi/termination';
import { DestroyLinkedAccount } from '@semmie/schemas/bi/linked-accounts/linked-account-destroy';
import { iBaseListableService } from '@semmie/schemas/services/list/base-service';
import { AccountsStore } from '@semmie/store/accounts/accounts.store';
import { iDirectDebitPayload } from '@semmie/schemas/bi/payment/direct-debit-payload.schema';
import { GoalKind } from '@semmie/schemas';
import { NavigationEnd, Router } from '@angular/router';
import { Utils } from '@onyxx/utility/general';
import { iAccountBalanceSheet } from '@semmie/schemas/bi/account';
import { LinkedAccount } from '@onyxx/model/linked-account';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, PaginatedResponse } from '@onyxx/model/pagination';
import { AccountUser } from '@onyxx/model/account';
import { filterNil } from '@onyxx/utility/observables';
import { AccountProvider } from '@onyxx/provider/account';
import { AccountStoreFacade, ACCOUNT_CACHE_TIMEOUT } from '@onyxx/store/account';
import { VisibleAccountsListStoreFacade } from '@onyxx/store/visible-accounts-list';
import { AccountRouteNames } from '@semmie/views/account/account.common';
import { MainRouteNames } from '@onyxx/model/main';
import { DirectDebitsStoreFacade } from '@onyxx/store/direct-debits';

@Injectable({
  providedIn: 'root',
})
export class AccountsService implements iBaseListableService<Account> {
  private readonly accountsStore = inject(AccountsStore);
  private readonly legacyAccountsProvider = inject(LegacyAccountsProvider);
  private readonly accountProvider = inject(AccountProvider);
  private readonly translate = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly semmieCurrencyPipe = inject(SemmieCurrencyPipe);
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly visibleAccountsListStoreFacade = inject(VisibleAccountsListStoreFacade);
  private readonly directDebitsStoreFacade = inject(DirectDebitsStoreFacade);

  /** When last the account and other data was loaded, this used to bust the account cache */
  private cacheLoadTimestamps = {
    goal: 0,
    performance: 0,
  };

  readonly accountKinds = Object.values(AccountKind) as Array<string>;

  readonly account$ = this.accountStoreFacade.account$.pipe(filterNil());

  readonly accountId$ = this.account$.pipe(
    map((account) => account.id),
    distinctUntilChanged(),
  );

  static goalKindForAccountKind(kind: AccountKind) {
    switch (kind) {
      case AccountKind.ANNUITY: {
        const goal = new Goal();
        goal.focus = GoalFocus.TIME;
        goal.kind = AccountsService.goalForKind(AccountKind.ANNUITY);
        return goal;
      }
      case AccountKind.CHILD: {
        const goal = new Goal();

        goal.kind = AccountsService.goalForKind(AccountKind.CHILD);
        return goal;
      }
      default:
        return new Goal();
    }
  }

  static goalForKind(kind: AccountKind): GoalKind {
    switch (kind) {
      case AccountKind.ANNUITY: {
        return GoalKind.RETIREMENT;
      }
      case AccountKind.CHILD: {
        return GoalKind.CHILDREN;
      }
      default:
        return GoalKind.OTHER;
    }
  }

  update(id: string, data: Partial<Account>): Observable<Account> {
    this.accountStoreFacade.dispatchUpdateAccount({ ...data, id });
    return this.accountStoreFacade.accountUpdated$.pipe(
      map((response) => response.account),
      filterNil(),
    );
  }

  list(params?: any, refresh?: boolean): Observable<PaginatedResponse<Account>> {
    if (refresh) {
      this.visibleAccountsListStoreFacade.dispatchReloadAccounts();
      return this.visibleAccountsListStoreFacade.loadDone$.pipe(
        switchMap(() =>
          this.visibleAccountsListStoreFacade.visibleAccounts$.pipe(
            filterNil(),
            map((data) => {
              // the scrollable list mutates the date, and needs to be made mutable. The
              // response from the store is readonly
              return structuredClone(data);
            }),
          ),
        ),
      );
    }

    const page = params?.page ?? 1;
    return this.visibleAccountsListStoreFacade.visibleAccounts$.pipe(
      map((data) => {
        const dataIsAvailable = (data?.meta?.current_page ?? 0) >= page;

        if (!dataIsAvailable) {
          this.visibleAccountsListStoreFacade.dispatchLoadNextPage();
          return null;
        }
        return data;
      }),
      filterNil(),
      map((data) => {
        // the scrollable list mutates the date, and needs to be made mutable. The
        // response from the store is readonly
        return structuredClone(data);
      }),
    );
  }

  /**
   * Retrieve a list of account cards of type annuity
   * @returns account + card properties
   */
  retrieveAnnuityAccountCards() {
    return this.accountProvider
      .list({ kind: [AccountKind.ANNUITY], state_from: AccountState.ACTIVE, state_till: AccountState.ARCHIVED })
      .pipe(
        map((response) => {
          return response.data.map((account) => {
            return {
              ...account,
              formattedValue: this.semmieCurrencyPipe.transform(account.value),
              formattedAccountKind: this.translate.instant(`core.common.account.label.${account.kind}`),
            };
          });
        }),
      );
  }

  loadAccountProposalsToStore(): Observable<PaginatedResponse<Account>> {
    return this.accountProvider
      .list({ state_from: AccountState.PROPOSAL_SENT, state_till: AccountState.PROPOSAL_SENT })
      .pipe(tap((response) => this.accountsStore.accountProposals$.next(response.data)));
  }

  getAccountRatesById(id: string): Observable<any> {
    const cache = this.accountsStore.getCachedRatesById(id);

    if (!cache) {
      return this.legacyAccountsProvider.getRates(id).pipe(
        tap((rates) => {
          this.accountsStore.updateCachedRateById(id, rates);
        }),
      );
    }

    return of(cache);
  }

  getAccountPerformancesById(id: string, refresh?: boolean) {
    const cache = this.accountsStore.getPerformancesRatesById(id);

    if (this.isCacheStale('performance') || !cache || refresh) {
      return this.legacyAccountsProvider.getPerformances(id).pipe(
        tap((rates) => {
          this.setCacheLoadTimestamp('performance');
          this.accountsStore.updateCachePerformancesById(id, rates);
        }),
      );
    }

    return of(cache);
  }

  /**
   * Retrieve the direct debits on a account
   * @param id Account id
   * @returns Observable<PaginatedResponse<iDirectDebit>>
   */
  getAccountDirectDebitList(id: string): Observable<PaginatedResponse<iDirectDebit>> {
    return this.legacyAccountsProvider.getDirectDebitList(id).pipe(
      map((response) => {
        this.directDebitsStoreFacade.dispatchUpdateDirectDebitsInStore(id, response?.data ?? []);

        return {
          data: response?.data,
          meta: response?.meta,
        };
      }),
    );
  }

  getAccountDirectDebitById(id: string, directDebitId: string) {
    return this.legacyAccountsProvider.getDirectDebitById(id, directDebitId);
  }

  postDirectDebit(id: string, params: Partial<iDirectDebitPayload>) {
    return this.legacyAccountsProvider.postDirectDebit(id, params);
  }

  updateDirectDebit(id: string, directDebitId: string, params: Partial<DirectDebit>): Observable<DirectDebit> {
    return this.legacyAccountsProvider.updateDirectDebit(id, directDebitId, params);
  }

  deleteDirectDebit(id: string, directDebitId: string): Observable<boolean> {
    return this.legacyAccountsProvider.deleteDirectDebit(id, directDebitId);
  }

  getAccountBalanceSheet(id: string): Observable<iAccountBalanceSheet> {
    return this.legacyAccountsProvider.getBalanceSheet(id);
  }

  getAccountGoal(id: string, refresh?: boolean): Observable<Goal> {
    const cache = this.accountsStore.getCachedGoalById(id);

    if (this.isCacheStale('goal') || !cache || refresh) {
      return this.legacyAccountsProvider.getGoal(id).pipe(
        take(1),
        tap((goal) => {
          this.setCacheLoadTimestamp('goal');
          this.accountsStore.updateCachedGoalById(id, goal);
        }),
      );
    }

    return of(cache);
  }

  updateAccountGoal(accountId: string, goal: Partial<Goal>): Observable<Goal> {
    return this.legacyAccountsProvider.updateGoal(accountId, goal).pipe(
      tap((g) => {
        this.setCacheLoadTimestamp('goal');
        this.accountsStore.updateCachedGoalById(accountId, g);
      }),
    );
  }

  updateSorting(accountIds: string[]): Observable<void> {
    return this.legacyAccountsProvider.updateSorting({ accounts: { sort: accountIds } });
  }

  getAccountMessages(
    accountId: string,
    page: number = DEFAULT_PAGE,
    size: number = DEFAULT_PER_PAGE,
  ): Observable<PaginatedResponse<Message>> {
    return this.legacyAccountsProvider.getAccountMessages(accountId, page, size);
  }

  getAccountMessage(accountId: string, messageId: string): Observable<Message> {
    return this.legacyAccountsProvider.getAccountMessage(accountId, messageId);
  }

  /**
   * Get the linked bank account(s) of the account
   *
   * @param {string} id
   * @param {boolean} refresh
   * @return {*} Observable<Array<iLinkedAccount>>
   * @memberof AccountsService
   */
  getLinkedAccounts(id: string, refresh?: boolean): Observable<Array<LinkedAccount>> {
    const cached = this.accountsStore.getLinkedAccountsById(id);

    if (!cached || refresh) {
      return this.legacyAccountsProvider.getLinkedAccounts(id).pipe(
        take(1),
        tap((linked_accounts) => {
          this.accountsStore.updateCacheLinkedAccountsById(id, linked_accounts);
        }),
      );
    }

    return of(cached);
  }

  /**
   * Post linked bank account
   *
   * @param {string} id
   * @param {LinkedAccount} linked_account
   * @return {*} Observable<LinkedAccount>
   * @memberof AccountsService
   */
  postLinkedAccount(id: string, params: Partial<LinkedAccount>, return_url: string): Observable<LinkedAccount> {
    return this.legacyAccountsProvider.postLinkedAccount(id, params, return_url).pipe(
      filterNil(),
      tap((account) => {
        const linked_accounts = this.accountsStore.getLinkedAccountsById(id).value;
        if (linked_accounts) {
          this.accountsStore.updateCacheLinkedAccountsById(id, [linked_accounts, new LinkedAccount({ ...account })]);
        } else {
          this.accountsStore.updateCacheLinkedAccountsById(id, [new LinkedAccount({ ...account })]);
        }
      }),
    );
  }

  /**
   * Make linked bank account destroy request from account
   *
   * @param {string} id
   * @param {LinkedAccount} linked_account
   * @return {*} Observable<any>
   * @memberof AccountsService
   */
  destroyLinkedAccount(id: string, params: Partial<LinkedAccount>, return_url: string): Observable<DestroyLinkedAccount> {
    return this.legacyAccountsProvider.destroyLinkedAccount(id, params, return_url);
  }

  getRiskProfiles(id: string): Observable<Array<RiskProfile>> {
    return this.legacyAccountsProvider.getRiskProfiles(id).pipe(
      tap((riskProfiles: RiskProfile[]) => {
        this.accountsStore.updateRiskProfiles(riskProfiles);
      }),
    );
  }

  getStrategies(id: string): Observable<Array<Strategy>> {
    return this.legacyAccountsProvider.getStrategies(id).pipe(tap((strategies) => this.accountsStore.updateStrategies(strategies)));
  }

  sign(id: string): Observable<Account> {
    return this.legacyAccountsProvider.sign(id).pipe(tap((account) => this.accountStoreFacade.dispatchUpdateAccountInStore(account)));
  }

  invite(id: string, invitation: Partial<Invitation>): Observable<Invitation> {
    return this.legacyAccountsProvider.invite(id, invitation).pipe(
      map((invitation) => {
        this.accountStoreFacade.dispatchUpdateInvitationInStore(id, invitation);
        return invitation;
      }),
    );
  }

  updateInvitation(accountId: string, invitationId: string, params: Partial<Invitation>): Observable<Invitation> {
    return this.legacyAccountsProvider.updateInvitation(accountId, invitationId, params).pipe(
      tap((invitation) => {
        this.accountStoreFacade.dispatchUpdateInvitationInStore(accountId, invitation);
      }),
    );
  }

  deleteInvitation(accountId: string, invitationId: string): Observable<boolean> {
    return this.legacyAccountsProvider.deleteInvitation(accountId, invitationId).pipe(
      map((deleted) => {
        this.accountStoreFacade.dispatchDeleteInvitationInStore(accountId);

        return deleted;
      }),
    );
  }

  getTransactions(accountId: string, amountPerPage: number, page: number, kind?: string[]): Observable<any> {
    return this.legacyAccountsProvider.getTransactions(accountId, amountPerPage, page, kind);
  }

  /**
   * Return a list of all available account kinds that the current user may create
   *
   * @returns Array<{ id: string, label: string}>
   */
  getAvailableAccountKinds(excludedKinds?: Array<string>): Array<{ id: string; label: string }> {
    const accountKinds = Object.values(AccountKind);

    return accountKinds
      .filter((id) => !(excludedKinds?.includes(id) ?? false))
      .map((id) => ({
        id,
        label: this.translate.instant(`core.common.account.label.${id}`),
      }));
  }

  hasAnnuityAccount() {
    return this.accountProvider
      .list({ page: 1, per_page: 999, state_from: AccountState.ONBOARDING, state_till: AccountState.ARCHIVED, kind: [AccountKind.ANNUITY] })
      .pipe(map((accounts) => accounts.data.length > 0));
  }

  terminateAccount(id: Account['id'], termination: iTermination): Observable<iTerminationResponse> {
    return this.legacyAccountsProvider.terminate(id, termination);
  }

  createQRInvitation(account: Account): Observable<Invitation | null | undefined> {
    if (Utils.isNil(account)) return of(null);

    return of(account.invitation).pipe(
      switchMap((invitation) => {
        if (invitation?.declined) return this.deleteInvitation(account.id, invitation?.id);
        return of(!!invitation);
      }),
      switchMap((invitation) => {
        if (!invitation) {
          return this.invite(account.id, {
            recipient_email: '',
            recipient_name: '',
            role: AccountHelpers.invitationRole(account) as PersonRoleType,
          });
        }

        return of(account.invitation);
      }),
    );
  }

  deleteQRInvitationIfUnopened(account: Account) {
    if (
      account?.invitation &&
      account.invitation.hasInvitationBeenAccepted() === false &&
      account.invitation.hasEmailInvitation() === false
    ) {
      this.deleteInvitation(account.id, account.invitation.id)
        .pipe(
          take(1),
          catchError(() => of(null)),
        )
        .subscribe();
    }
  }

  getInvestmentPlanUrl(id: Account['id']) {
    return this.legacyAccountsProvider.getInvestmentPlanUrl(id);
  }

  checkGoalValidity() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      withLatestFrom(this.account$.pipe(filterNil(), take(1))),
      filter(
        ([event, account]) => event['urlAfterRedirects'] === `/${MainRouteNames.Accounts}/${account.id}/${AccountRouteNames.Overview}`,
      ),
      switchMap(([, account]) =>
        this.accountsStore.getCachedGoalForCurrentAccount$.pipe(
          filterNil(),
          take(1),
          map((goal) => [goal, account]),
        ),
      ),
    );
  }

  refresh(params?: any): void {
    this.list(params ?? {}, true)
      .pipe(take(1))
      .subscribe();
  }

  acceptAdvisorProposal(accountId: string): Observable<void> {
    return this.legacyAccountsProvider.acceptAdvisorProposal(accountId);
  }

  listAccountUsers(accountId: string): Observable<AccountUser[]> {
    return this.legacyAccountsProvider.listAccountUsers(accountId);
  }

  getOpeningPositionDocument(accountId: string, date: string): Observable<string> {
    return this.legacyAccountsProvider.getOpeningPositionDocument(accountId, date);
  }

  clear(): void {
    this.accountsStore.clear();
    this.legacyAccountsProvider.clear();
  }

  bustAccountCaches() {
    this.cacheLoadTimestamps.goal = 0;
    this.cacheLoadTimestamps.performance = 0;
  }

  private isCacheStale(cache: keyof typeof this.cacheLoadTimestamps) {
    return this.cacheLoadTimestamps[cache] < Date.now() - ACCOUNT_CACHE_TIMEOUT;
  }

  private setCacheLoadTimestamp(cache: keyof typeof this.cacheLoadTimestamps) {
    this.cacheLoadTimestamps[cache] = Date.now();
  }
}
