import { AccountKind } from '../account-kind.enum';
import { AccountState } from '../account-state.enum';
import { Account } from '../account.interface';
import { Utils } from '@onyxx/utility/general';
import { Organisation } from '@semmie/models';

export const isOnboarding = (account: Account): boolean => {
  return account.state <= AccountState.ONBOARDING;
};

export const isActive = (account: Account): boolean => {
  return account.state > AccountState.ONBOARDING && account.state <= AccountState.ACTIVE;
};

export const isBlockedFromViewingAccount = (account: Account): boolean => {
  return [AccountState.MIGRATING, AccountState.MIGRATION_HOLD].includes(account.state);
};

export const isInvesting = (account: Account): boolean => {
  return account.state >= AccountState.INVESTING;
};

export const isInvested = (account: Account): boolean => {
  return account.state >= AccountState.ACTIVE && Utils.isNotNil(account.invested_at);
};

export const isCreated = (account: Account): boolean => {
  return account.state >= AccountState.CREATED;
};

export const hasSigned = (account: Account, userId?: string): boolean => {
  if (!account.signing) return false;
  return account.signing.signing_users.find((u) => u?.user?.id === userId)?.state === 'signed';
};

export const othersHaveSigned = (account: Account, userId: string): boolean => {
  if (account.finished_at) return true;
  if (!account.signing) return false;

  return !!account.signing.signing_users.filter((u) => u?.user?.id !== userId).every((su) => su.state === 'signed');
};

export const hasCompletedSigning = (account: Account): boolean => {
  if (account.state > AccountState.ONBOARDING) return true;

  if (!account.signing) return false;

  return account.signing.signing_users.every((u) => u.state === 'signed');
};

export const hasValue = (account: Account): boolean => {
  return account.value > 0;
};

export const isFinished = (account: Account): boolean => {
  return Utils.isNotNil(account.finished_at);
};

export const hasFinishedPlan = (account: Account): boolean => {
  return [account.goal?.importance, account.goal?.risk_appetite, account.goal?.max_loss].every(Utils.isNotNil);
};

export const hasOrganisation = (account: Account): account is Account & { organisation: Organisation } => {
  return account.kind === AccountKind.ORGANISATION && !!account.organisation;
};
