import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { notificationsFeature } from './notifications.reducer';
import { NotificationsAPIActions } from './notifications-api.actions';

@Injectable()
export class NotificationsFacade {
  readonly store = inject(Store);

  readonly notifications$ = this.store.select(notificationsFeature.selectNotifications);
  readonly unreadNotifications$ = this.store.select(notificationsFeature.selectUnreadCount);
  readonly loading$ = this.store.select(notificationsFeature.selectLoading);
  readonly loaded$ = this.store.select(notificationsFeature.selectLoaded);

  load() {
    this.store.dispatch(NotificationsAPIActions.load());
  }

  markAsRead(notificationIds: string[]) {
    this.store.dispatch(NotificationsAPIActions.markAsRead({ notificationIds }));
  }

  markAllAsRead() {
    this.store.dispatch(NotificationsAPIActions.markAllAsRead());
  }

  dispatchUpdateUnreadCount(unread_count: number) {
    this.store.dispatch(NotificationsAPIActions.updateUnreadCount({ unread_count }));
  }
}
