import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EerService {
  validateAddress(...[country]: any): boolean {
    return country && country.eer;
  }

  validateNationality(...[country, nationality]: any): boolean {
    if (!country || !nationality) return false;
    return ['NLD'].includes(country?.iso3) || (country?.eer && ['NLD', 'BEL'].includes(nationality?.iso3));
  }

  validateAnnuityNationality(...[country, nationality, tax_netherlands]: any): boolean {
    if (!country || !nationality) return false;
    if (!tax_netherlands) return false;

    return ['NLD'].includes(country?.iso3) || (country?.eer && ['NLD'].includes(nationality?.iso3));
  }
}
