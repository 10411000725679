import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Tin } from '@semmie/shared/tin';
import { minLength, minMaxLength } from '@semmie/validators';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { Utils } from '@onyxx/utility/general';

export function tin(country?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    if (!value) return null;

    let invalid = Utils.isNotNil(minLength(5)(control));
    let validationMessage = $localize`:@@validation.tin.default:The Tax Identification Number (TIN) you entered is not valid.`;

    switch (country) {
      case 'BEL': {
        invalid = Tin.checkBelgiumNumber(value) || Utils.isNotNil(minLength(5)(control));
        validationMessage = $localize`:@@validation.tin.bel:The National Register Number (Rijksregisternummer) you entered is not valid.`;
        break;
      }
      case 'NLD': {
        invalid = Tin.checkDutchNumber(value) || Utils.isNotNil(minMaxLength(9, 9)(control));
        validationMessage = $localize`:@@validation.tin.nld:The Citizen Service Number (BSN) you entered is not valid.`;
        break;
      }
    }

    if (invalid) {
      return new ValidatorMessage({
        code: 12,
        message: validationMessage,
      });
    }

    return null;
  };
}
