import { inject } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { concatLatestFrom } from '@ngrx/operators';
import { userTaskCommonActions } from './user-task-common.actions';
import { userTaskApiActions } from './user-task-api.actions';
import { catchError, exhaustMap, filter, map, of, switchMap, take, tap } from 'rxjs';
import { UserTaskProvider } from '@onyxx/provider/user-task';
import { userTaskFeature } from './user-task.reducer';
import { Store } from '@ngrx/store';
import { ToastService } from '@semmie/services';
import { GenericErrorToast } from '@semmie/schemas/components/toast';
import { Utils } from '@onyxx/utility/general';
import { userTaskDetailFeature } from './user-task-detail.reducer';

export class UserTaskEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly authFacade = inject(AuthFacade);
  private readonly userTaskProvider = inject(UserTaskProvider);
  private readonly toastService = inject(ToastService);

  readonly initializeStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userTaskCommonActions.initialize),
      switchMap(() => this.authFacade.isAuthenticated$.pipe(filter((isAuthenticated) => isAuthenticated))),
      switchMap(() => [userTaskApiActions.reloadTasks({ showToastOnError: false })]),
    );
  });

  readonly loadUserTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userTaskApiActions.reloadTasks, userTaskApiActions.reloadTaskList),
      switchMap(({ showToastOnError }) => {
        return this.userTaskProvider.list().pipe(
          map(({ data, meta }) => {
            return userTaskApiActions.reloadTasksSuccess({
              tasks: data,
              paginationMeta: meta,
            });
          }),
          catchError((error) => of(userTaskApiActions.reloadTasksFailure({ errorMsg: error.message, showToastOnError }))),
        );
      }),
    );
  });

  readonly loadNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userTaskApiActions.loadNextPage),
      concatLatestFrom(() => [this.store.select(userTaskFeature.selectPaginationMeta)]),
      exhaustMap(([, pageInfo]) => {
        const nextPage = (pageInfo?.current_page ?? 0) + 1;
        return this.userTaskProvider.list({ page: nextPage }).pipe(
          take(1),
          map(({ data, meta }) =>
            userTaskApiActions.loadPageSuccess({
              tasks: data,
              paginationMeta: meta,
            }),
          ),
          catchError((error) => of(userTaskApiActions.loadPageFailure({ errorMsg: error.message, showToastOnError: true }))),
        );
      }),
    );
  });

  readonly loadTasksFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userTaskApiActions.loadPageFailure, userTaskApiActions.reloadTasksFailure),
        tap(({ errorMsg, showToastOnError }) => {
          console.error(errorMsg);
          if (showToastOnError) {
            this.toastService.show(GenericErrorToast());
          }
        }),
      );
    },
    { dispatch: false },
  );

  readonly loadUserTaskDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userTaskApiActions.loadTaskDetail),
      exhaustMap(({ id, showToastOnError }) => {
        return this.userTaskProvider.get(id).pipe(
          map((taskDetail) => {
            return userTaskApiActions.loadTaskDetailSuccess({ taskDetail });
          }),
          catchError((error) => {
            return of(userTaskApiActions.loadTaskDetailFailure({ errorMsg: error.message, showToastOnError, id }));
          }),
        );
      }),
    );
  });

  readonly loadDetailFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userTaskApiActions.loadTaskDetailFailure),
        tap(({ showToastOnError }) => {
          if (showToastOnError) {
            this.toastService.show(GenericErrorToast());
          }
        }),
      );
    },
    { dispatch: false },
  );

  readonly ensureTaskDetailIsLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userTaskCommonActions.ensureTaskDetailIsLoaded),
      concatLatestFrom(() => [
        this.store.select(userTaskDetailFeature.selectEntities),
        this.store.select(userTaskDetailFeature.selectStaleTasks),
      ]),
      map(([{ id, showToastOnError }, entities, staleTasks]) => {
        const loadedTaskDetail = entities[id];

        if (Utils.isNotNil(loadedTaskDetail) && !staleTasks.includes(id)) {
          return userTaskCommonActions.taskDetailIsLoaded({ id });
        }

        return userTaskApiActions.loadTaskDetail({ id, showToastOnError });
      }),
    );
  });

  readonly handleTaskCountsUpdates = createEffect(() => {
    return this.actions$.pipe(
      ofType(userTaskCommonActions.updateTaskCounts),
      concatLatestFrom(() => this.store.select(userTaskFeature.selectTaskCount)),
      filter(([{ taskCounts }, loadedTaskCount]) => {
        const taskCountTotal = taskCounts.normal + taskCounts.critical + taskCounts.high;
        return taskCountTotal !== loadedTaskCount;
      }),
      map(() => userTaskApiActions.reloadTaskList({ showToastOnError: false })),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => userTaskCommonActions.clear()));
  });

  ngrxOnInitEffects() {
    return userTaskCommonActions.initialize();
  }
}
