import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Signing } from '@onyxx/model/signing';
import { iSigningRequest } from '@semmie/schemas/bi/signing/signing-request';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { iSigningResult } from '@semmie/schemas/bi/signing/signing-result';

@Injectable()
export class SigningProvider extends BaseProvider<iSigningResult> {
  constructor() {
    super('signings');
  }

  /**
   * Get a signing
   *
   * ? Developer note: Explicitely remap data here for consistency
   *
   * @param id Signing id
   * @returns
   */
  get(id: string): Observable<iSigningResult> {
    return super.__get('signings', id).pipe(map((signing: any) => (signing.signing ? signing.signing : signing)));
  }

  /**
   * Update a signing
   *
   * @param id Signing id
   * @param params Signing data
   * @returns
   */
  update(id: string, params: Partial<iSigningRequest>): Observable<Signing> {
    return super.__patch<{ signing: Signing }>(`signings/${id}/sign`, { signing: params }).pipe(map((response) => response['signing']));
  }

  clear(): void {
    this.__clear();
  }
}
