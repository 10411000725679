import { createActionGroup, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Person } from '@onyxx/model/person';

export const personApiActions = createActionGroup({
  source: 'person API',
  events: {
    'Load person': props<{ personId: string }>(),
    'Load Success': props<{ person: Person }>(),
    'Load Failure': props<{ errorMsg: string }>(),

    Edit: props<{ person: Partial<Person> }>(),
    'Edit Success': props<{ person: Person }>(),

    'Edit Failure': props<{ error: unknown }>(),
  },
});
