import { Account } from '@onyxx/model/account';
import { BaseModel } from '@semmie/models/_abstract';
import { BoosterType } from '@semmie/schemas/bi/booster';
import { iReward, RewardKind } from '@semmie/schemas/bi/reward';

export class Reward extends BaseModel<iReward> implements iReward {
  /**
   * Account title and id
   */
  account: Partial<Account>;
  amount: number;

  /**
   * Represents the endDate
   *
   * @info remapped from _'end_date'_
   */
  endDate: Date; // api reference 'endDate'
  id: string;

  /**
   * Represents the paidDate
   *
   * @info remapped from _'paid_at'_
   */
  paidDate: Date; // api reference 'paid_at'
  parentTitle: string;
  parentType: BoosterType;
  referral: string;

  /**
   * Represents the RewardKind
   *
   * @info remapped from _'kind'_
   */
  rewardKind: RewardKind; // api reference 'kind'

  /**
   * Represents the startDate
   *
   * @info remapped from _'starts_at'_
   */
  startDate: Date; // api reference 'starts_at'
  description?: string;

  constructor(props?: Partial<Reward>) {
    super(props, [
      { api: 'ends_at', local: 'endDate' },
      { api: 'paid_at', local: 'paidDate' },
      { api: 'kind', local: 'rewardKind' },
      { api: 'starts_at', local: 'startDate' },
    ]);
  }
}
