import { NgModule } from '@angular/core';

import { ModalHeaderModule } from '@semmie/components/containers/modals/modal-header/modal-header.component';
import { ModalScrollModule } from '@semmie/components/containers/modals/modal-scroll/modal-scroll.component';
import { SharedModule } from '@semmie/shared/shared.module';

import { ScrollableModalComponent } from './scrollable-modal.component';

@NgModule({
  imports: [SharedModule, ModalScrollModule, ModalHeaderModule],
  declarations: [ScrollableModalComponent],
})
export class ScrollableModalModule {}
