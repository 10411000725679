export type BaseTheme = 'primary' | 'primary' | 'light' | 'dark' | 'error' | 'warning' | 'info' | 'current' | 'white' | 'link';

export enum AppTheme {
  DEFAULT = 'default-theme',
  LIGHT = 'light-theme',
  DARK = 'dark-theme',
  PREMIUM = 'premium-theme',
}

export enum AppIconTheme {
  IconWord = 'app_icon_word',
  IconBlack = 'app_icon_black',
  IconWhite = 'app_icon_white',
}

export enum AppIconStorageKeys {
  AppIcon = 'app_icon',
}
