import { NgModule } from '@angular/core';
import { CarouselSlideModule } from '@semmie/components/presentational/core/carousel-slide/carousel-slide.module';
import { CarouselModule } from '@semmie/components/presentational/core/carousel/carousel.module';
import { ProjectionCardModule } from '@semmie/components/presentational/core/projection-card/projection-card.module';
import { SharedModule } from '@semmie/shared/shared.module';

import { HapticOnTapDirective } from '@semmie/directives/haptic-on-tap.directive';
import { CarouselSelectComponent } from './carousel-select.component';

@NgModule({
  imports: [SharedModule, CarouselModule, CarouselSlideModule, ProjectionCardModule, HapticOnTapDirective],
  exports: [CarouselSelectComponent],
  declarations: [CarouselSelectComponent],
})
export class CarouselSelectModule {}
