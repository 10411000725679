// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account, AccountProfileAudit } from '@onyxx/model/account';
import { AccountApiModel } from './account-api-model.interface';
import { stringifyMetadata } from './stringify-metadata';
import { advisorAuditApprovalMethodsToString } from './advisor-audit-approval-method-to-string';

export const modelToApiModel = (account: Partial<Account & AccountProfileAudit>): Partial<AccountApiModel> => {
  return {
    ...account,

    meta:
      account.meta === undefined
        ? undefined
        : {
            data: stringifyMetadata(account.meta),
          },
    value: account.value?.toString(),

    current_auto_incasso_value: account.current_auto_incasso_value?.toString(),
    current_boost_value: account.current_boost_value?.toString(),
    current_cash_value: account.current_cash_value?.toString(),
    current_payment_value: account.current_payment_value?.toString(),
    current_performance_amount: account.current_performance_amount?.toString(),
    current_position_cash_value: account.current_position_cash_value?.toString(),
    current_position_security_value: account.current_position_security_value?.toString(),
    current_stornation_value: account.current_stornation_value?.toString(),
    current_available_for_withdrawal_value: account.current_available_for_withdrawal_value?.toString(),
    total_deposit: account.total_deposit,
    profile_change: account.profile_change
      ? {
          ...account.profile_change,
          approval_method: advisorAuditApprovalMethodsToString(account.profile_change.approval_method),
        }
      : undefined,
  };
};
