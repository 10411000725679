import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { advisorAccountFeature } from '@semmie/store/advisor-account/advisor-account.reducer';
import { AdvisorAccountEffects } from '@semmie/store/advisor-account/advisor-account.effects';
import { AdvisorAccountStoreFacade } from '@semmie/store/advisor-account/advisor-account.facade';

/**
 * The providers array is used to provide the advisor account feature state, effects, and facade.
 * This can be used from standalone routing and modules, but not from standalone components.
 * In order to include it in standalone modules use the module grouping below.
 */
export const providers = [provideState(advisorAccountFeature), provideEffects(AdvisorAccountEffects), AdvisorAccountStoreFacade];

/**
 * The AdvisorStoreModule class is an angular module that provides services for managing user state.
 */
@NgModule({ providers })
export class AdvisorAccountStoreModule {}

export * from './advisor-account.facade';
