import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { AccountAdditionalDataStoreFacade } from './account-additional-data.facade';
import { accountAdditionalDataFeature } from './account-additional-data.reducer';
import { AccountAdditionalDataEffects } from './account-additional-data.effects';

export const providers = [
  provideState(accountAdditionalDataFeature),
  provideEffects(AccountAdditionalDataEffects),
  AccountAdditionalDataStoreFacade,
];

@NgModule({ providers })
export class AccountAdditionalDataStateModule {}

export * from './account-additional-data.facade';
