export enum Denotation {
  Characters = 'characters',
  Character = 'character',
  Letters = 'letters',
  Letter = 'letter',
  Capitals = 'capitals',
  Capital = 'capital',
  Numbers = 'numbers',
  Number = 'number',
  Amount = 'amount',
}
