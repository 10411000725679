import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { paymentFeature } from '@semmie/store/payment/payment.reducer';
import { PaymentEffects } from '@semmie/store/payment/payment.effects';
import { PaymentStoreFacade } from '@semmie/store/payment/payment.facade';

/**
 * The providers array is used to provide the payment feature state, effects, and facade.
 * This can be used from standalone routing and modules, but not from standalone components.
 * In order to include it in standalone modules use the module grouping below.
 */
export const providers = [provideState(paymentFeature), provideEffects(PaymentEffects), PaymentStoreFacade];

/**
 * The PaymentStoreModule class is an angular module that provides services for managing user state.
 */
@NgModule({ providers })
export class PaymentStoreModule {}

export * from './payment.facade';
