import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { appLoadingStatusCommonActions } from './app-loading-status-common.actions';
import { tap } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';

export class AppLoadingStatusEffects {
  private readonly actions$ = inject(Actions);

  readonly hideSplashScreen$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(appLoadingStatusCommonActions.hideSplashscreen),
        tap(() => {
          SplashScreen.hide();
        }),
      );
    },
    { dispatch: false },
  );
}
