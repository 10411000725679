import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';

export const visibleAccountsListCommonActions = createActionGroup({
  source: 'Visible Accounts List Common',
  events: {
    Clear: emptyProps(),
    'Update account cache': props<{ account: Account }>(),
    'Add to account cache': props<{ account: Account }>(),
    'Remove from account cache': props<{ id: Account['id'] }>(),
  },
});
