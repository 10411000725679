import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SemmieCurrencyPipe } from './currency.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [SemmieCurrencyPipe],
  declarations: [SemmieCurrencyPipe],
})
export class CurrencyModule {}
