import { BaseModel } from '@semmie/models/_abstract';
import { GoalKind, iGoal } from '@semmie/schemas';
import { GoalFocus } from '@semmie/schemas/bi/goal/goal-focus.enum';
import { iGoalGraphData } from '@semmie/schemas/bi/goal/goal-graph-data.interface';
import { GoalState } from '@semmie/schemas/bi/goal/goal-state.enum';
import { iPropertyMap } from '@semmie/schemas/generics/property-map/property-map.interface';
import { Utils } from '@onyxx/utility/general';
import * as moment from 'moment';

export class Goal extends BaseModel<iGoal> implements iGoal {
  amount?: number;
  amount_start?: number;
  deposit?: number;
  focus: GoalFocus;
  period?: number;
  kind?: GoalKind;
  importance?: number;
  risk_appetite?: number;
  max_loss?: number;
  state: GoalState;
  graph?: iGoalGraphData;
  ends_at?: string;
  annuity_ends_at_age?: number;
  formattedGraphData: any;
  suggestions?: Partial<iGoal>;
  amount_transfer_active?: boolean;
  amount_transfer?: number;
  invalid: boolean;

  constructor(props?: Partial<iGoal>, mapping?: iPropertyMap[]) {
    super(props, mapping);

    /**
     * Parse deposit to a float as it is being returned as a string
     */
    if (typeof props?.deposit === 'string') {
      this.deposit = parseFloat(props.deposit);
    }

    // TODO: Assess how risky is to use the account.goal property in the forms using the goal
    // Nodoby likes this solution but since invalid is only being used in the invalid service
    // I'm creating this property so the invalid check can also work with mustache,  because the class functions don’t work
    //  because the goal is already spread out
    this.invalid = Utils.isNil(this.state) || this.state === GoalState.INVALID;

    this.formatChartData();
  }

  get recalculating() {
    return this.state === GoalState.RECALCULATING;
  }

  get ontrack() {
    return this.state === GoalState.ONTRACK;
  }

  get offtrack() {
    return this.state === GoalState.OFFTRACK;
  }

  get learning() {
    return this.state === GoalState.LEARNING;
  }

  get isEmpty() {
    const goal = new Goal(this).toRequest(false, true);
    // skip focus and state, as the goal endpoint always returns a value even if empty
    return Object.entries(goal).every(([key, value]) => ['state', 'focus'].includes(key) || Utils.isNil(value));
  }

  /**
   * Format the graph data provided by the api to a readable format for highcharts
   * @param graph (optional) iGoalGraphData
   * @returns [[], []]
   */
  formatChartData(graph?: iGoalGraphData): number[][][] {
    const data = graph || this.graph;
    if (Utils.isNil(data) || Utils.isNil(data.realistic) || Utils.isNil(data.unrealistic)) return [];
    const result: number[][][] = [[], []];
    data.realistic.forEach((item) => {
      result[0].push([Number(moment(item.date).format('x')), Number(item.from), Number(item.to)]);
    });
    data.unrealistic.forEach((item) => {
      result[1].push([Number(moment(item.date).format('x')), Number(item.from), Number(item.to)]);
    });
    this.formattedGraphData = result;
    return this.formattedGraphData;
  }

  /**
   * The pension age for annuity accounts
   */
  getPensionAge(datesPension: any): number {
    let age = 0;
    if (this.annuity_ends_at_age) {
      age = Number(this.annuity_ends_at_age);
    } else if (datesPension?.birth_at) {
      if (this.period) {
        age = Number(moment().diff(moment(datesPension.birth_at), 'years') + this.period);
      } else if (this.ends_at) {
        age = Number(moment(this.ends_at).diff(moment(datesPension.birth_at), 'years'));
      }
    }
    return age;
  }

  /** Strip the obsolete unnecessary (empty) properties */
  toRequest(stripByType = false, finishedAccount = false): this {
    const defaultKeys = [
      'amount_start',
      'focus',
      'kind',
      'importance',
      'risk_appetite',
      'max_loss',
      'amount_transfer_active',
      'amount_transfer',
    ];

    const allowedKeys = [...defaultKeys];
    const nullValues: string[] = [];

    if (stripByType) {
      switch (this.focus) {
        case GoalFocus.AMOUNT:
        case GoalFocus.TIME:
          allowedKeys.push('amount', 'deposit', finishedAccount ? 'ends_at' : 'period');
          if (finishedAccount) {
            nullValues.push('period');
          }
          break;
        case GoalFocus.CONTINUOUS:
          allowedKeys.push('deposit');
          nullValues.push('amount', 'ends_at', 'period');
          break;
      }
    } else {
      allowedKeys.push('amount', 'deposit', 'period', 'ends_at');
    }

    if (!finishedAccount) {
      this.deposit ??= 0;
    }

    Object.entries(this).forEach(([key, value]) => {
      if (Utils.isNil(value) || !allowedKeys.includes(key)) delete this[key];
      if (nullValues.includes(key)) this[key] = null;
    });

    return this;
  }
}
