import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { phoneNumberFeature } from './phone-number.reducer';
import { phoneNumberApiActions } from './phone-number-api.actions';
import { Actions, ofType } from '@ngrx/effects';
import { phoneNumberCommonActions } from './phone-number-common.actions';
import { UserStoreFacade } from '@semmie/store/user';
import { PhoneNumberVerificationDetails } from './phone-number-verification-details';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberStoreFacade {
  readonly store = inject(Store);
  readonly actions = inject(Actions);
  readonly userStoreFacade = inject(UserStoreFacade);

  readonly phoneNumberPendingVerification$ = this.store.select(phoneNumberFeature.selectPhoneNumberPendingVerification);
  readonly resendAllowedAt$ = this.store.select(phoneNumberFeature.selectResendAllowedAt);

  readonly verificationBusy$ = this.store.select(phoneNumberFeature.selectVerificationBusy);
  readonly verificationSuccess$ = this.actions.pipe(ofType(phoneNumberApiActions.verifySuccess));
  readonly verificationFailure$ = this.actions.pipe(ofType(phoneNumberApiActions.verifyFailure));

  dispatchSetupVerification(details: PhoneNumberVerificationDetails) {
    this.store.dispatch(phoneNumberCommonActions.setupVerification(details));
  }

  /**
   * Manually set the set the resend allowed at timestamp to 30s from now
   * This is used when the code is triggered outside of this store. When using
   * `dispatchResendCode` the resend allowed at timestamp is automatically set.
   */
  dispatchSetResendAllowedAt() {
    this.store.dispatch(phoneNumberCommonActions.setResendAllowedAt());
  }

  dispatchResendCode() {
    this.store.dispatch(phoneNumberApiActions.resendCode());
  }

  dispatchVerify(code: string) {
    this.store.dispatch(phoneNumberApiActions.verify({ code }));
  }
}
