import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AuthProvider } from '@onyxx/provider/auth';
import { authCommonActions } from '../auth-common.actions';
import { ConfigService } from '@semmie/services';
import { NavController } from '@ionic/angular';

export class ManualTokenRefreshEffects {
  private readonly actions$ = inject(Actions);
  private readonly authProvider = inject(AuthProvider);
  private readonly configService = inject(ConfigService);
  private readonly navCtrl = inject(NavController);

  readonly manualTokenRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authCommonActions.manualTokenRefresh),
      concatLatestFrom(() => this.configService.config$),
      switchMap(([{ token, redirectAfterLogin }, config]) =>
        this.authProvider
          .requestToken({
            grant_type: 'refresh_token',
            client_id: config.oauth?.client_id ?? '',
            client_secret: config.oauth?.client_secret ?? '',
            refresh_token: token,
          })
          .pipe(
            map((session) => authCommonActions.manualTokenRefreshSuccess({ session, redirectAfterLogin })),
            catchError((error: Error) => of(authCommonActions.manualTokenRefreshFailure({ errorMsg: error.message }))),
          ),
      ),
    );
  });

  readonly navigateAfterManualTokenRefresh$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authCommonActions.manualTokenRefreshSuccess),
        tap(({ redirectAfterLogin }) => {
          this.navCtrl.navigateRoot(redirectAfterLogin, { replaceUrl: true, animated: false });
        }),
      );
    },
    { dispatch: false },
  );

  readonly logoutAfterManualTokenRefreshFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authCommonActions.manualTokenRefreshFailure),
      map(() => authCommonActions.logOut({ skipNavigation: false })),
    );
  });
}
