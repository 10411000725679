// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountMetadata } from '@onyxx/model/account';
import { Utils } from '@onyxx/utility/general';

/**
 * Create a JSON string of the meta data the metadata object but delete any null values fields
 */
export const stringifyMetadata = (metaData: AccountMetadata | undefined) => {
  // clear null properties
  const cleanedMetaData: Partial<AccountMetadata> = { ...metaData };

  let key: keyof AccountMetadata;
  for (key in cleanedMetaData) {
    if (Utils.isNil(cleanedMetaData[key])) {
      delete cleanedMetaData[key];
    }
  }

  if (Object.keys(cleanedMetaData).length === 0) {
    return '';
  }

  return JSON.stringify(cleanedMetaData);
};
