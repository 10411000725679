import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { payoutFeature } from './payout.reducer';
import { payoutApiActions } from './payout-api.actions';
import { NewPayout } from '@onyxx/model/payout';
import { ofType, Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class PayoutFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  readonly payout$ = this.store.select(payoutFeature.selectPayout);
  readonly loading$ = this.store.select(payoutFeature.selectLoading);
  readonly loaded$ = this.store.select(payoutFeature.selectLoaded);

  // Events
  readonly loadActionDone$ = this.actions$.pipe(ofType(payoutApiActions.loadSuccess, payoutApiActions.loadFailure));
  readonly createActionDone$ = this.actions$.pipe(ofType(payoutApiActions.createSuccess, payoutApiActions.createFailure));

  dispatchLoad() {
    this.store.dispatch(payoutApiActions.load());
  }

  dispatchCreatePayout(accountId: string, payout: NewPayout) {
    return this.store.dispatch(payoutApiActions.create({ accountId, payout }));
  }
}
