import { Injectable } from '@angular/core';
import { IPollingEvent } from '@semmie/schemas/bi/polling/polling-event.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PollingService {
  private _pollingEvent$$: BehaviorSubject<IPollingEvent | null> = new BehaviorSubject(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  pollingEvent$ = this._pollingEvent$$.asObservable();

  createEvent(event: IPollingEvent): void {
    this._pollingEvent$$.next(event);
  }
}
