export enum ButtonThemeEnum {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  WHITE = 'white',
  CONTRAST = 'contrast',
  TRANSPARENT = 'transparent',
  DANGER = 'danger',
}

export enum ButtonThemeShape {
  CIRCLE = 'circle',
  ROUNDED = 'rounded',
}

export type ButtonTheme = `${ButtonThemeEnum}`;
export type ButtonShape = `${ButtonThemeShape}`;
