import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { PayoutFacade } from './payout.facade';
import { payoutFeature } from './payout.reducer';
import { PayoutEffects } from './payout.effects';

export const providers = [provideState(payoutFeature), provideEffects(PayoutEffects), PayoutFacade];

@NgModule({ providers })
export class PayoutStateModule {}

export * from './payout.facade';
