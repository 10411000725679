import { Type } from '@semmie/shared/type';

export class Transformer {
  static recursivelyFilter(objToBeFiltered: Record<string, unknown>, values: Array<any>): any {
    const filterValues = [undefined, ...values];
    const result = {};

    for (const key in objToBeFiltered) {
      const val = objToBeFiltered[key];

      if (Type.isRecord(val)) {
        result[key] = this.recursivelyFilter(val, filterValues);
      } else {
        if (!filterValues.includes(val)) {
          result[key] = val;
        }
      }
    }

    return result;
  }
}
