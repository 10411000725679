import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as Url from 'url-parse';

import { environment } from 'environments/environment';
import { AppRouteNames } from '@onyxx/model/main';
import { NavigationService } from '@semmie/services';

const MAINTENANCE_STATUS_CODE = 502;
const BLACKLISTED_PATHS = ['up'];

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  private navigationService = inject(NavigationService);

  private backendApiUrl: Url = new Url(environment.apiUrl);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        /**
         * If the request is not from our backend, handle the error like normal
         */
        if (!this.isOurBackend(request)) {
          return throwError(() => error);
        }

        /**
         * If the request is from our backend *BUT* it is on a blacklisted endpoint, handle the error like normal
         */
        if (BLACKLISTED_PATHS.some((url) => request.url.endsWith(url))) {
          return throwError(() => error);
        }

        if (error.status === MAINTENANCE_STATUS_CODE) {
          this.navigationService.navigate([AppRouteNames.Maintenance], { replaceUrl: true }); // replaceUrl to clear all history
          return EMPTY;
        }

        return throwError(() => error);
      }),
    );
  }

  private isOurBackend(request: HttpRequest<any>): boolean {
    return request.url.indexOf(this.backendApiUrl.hostname) > -1;
  }
}
