import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { BaseModel } from '@semmie/models/_abstract';
import { iPerson } from '@semmie/schemas';
import { Utils } from '@onyxx/utility/general';
import { PersonKind } from '@semmie/schemas/bi/person/person-kind.enum';
import { iPersonAdvisorDetails } from '@semmie/schemas/bi/person/person';
import { Address } from '@onyxx/model/address';
import { iCountry, iCountryPhone } from '@onyxx/model/countries';
import { PersonIdentification } from '@onyxx/model/person';

/**
 * A person
 *
 * This is a list of data that doesn't get returned after a Person has been finished.
 * When finished, a Person will have 'finished_at' set to a datestring.
 *
 * - country_birth
 * - gender
 * - birth_city
 * - job
 * - bsn
 * - identification_number
 * - identification_expires_at
 * - meta
 * - birth_at
 * - tin_bsn
 * - tin_other
 *
 * @deprecated in favour of @onyxx/model/person
 */
export class Person extends BaseModel<iPerson> implements iPerson {
  id: string;
  name_casual?: string;
  name_first: string;
  name_middle_and_last: string;
  initials: string;
  email: string;
  phone: string;
  phone_confirmed: boolean;
  address: Address;
  country_birth: iCountry;
  country_phone: iCountryPhone;
  country_nationality: iCountry;
  meta: any;
  finished_at: string;
  identifications?: Array<PersonIdentification>;
  /**
   * - Can be patched by FE.
   * - Child (and possible UBO) is always set by BE for validation reasons so reliable to use.
   * - Adult and GRANDPARENT is not always set so NOT reliable
   */
  onboarding_kind: PersonKind;
  question_source_of_income: number;
  question_income: number;
  question_equity: number;
  question_country_other_reason: number;
  question_source_of_wealth: number;
  question_employment_status: number;
  question_industrial_classification_branch: string;
  question_industrial_classification: number;
  question_industrial_classification_answer: string;
  question_insider: boolean;
  question_insider_answer: string;
  question_interest: boolean;
  question_interest_answer: string;
  question_relation_netherlands: number;
  question_relation_netherlands_answer: string;
  question_pep: boolean;
  question_pep_name: string;
  question_pep_function: string;
  question_pep_function_description: string;
  question_tax_netherlands?: boolean;
  question_tax_other?: boolean;
  bsn: string;
  gender: string;
  birth_at: string;
  retirement_at: string;
  tin_bsn: string;
  advisor_details?: iPersonAdvisorDetails;

  /**
   * Determines whether the persons identification has been validated
   */
  wid_validated: boolean;

  constructor(props?: Partial<Person>) {
    super(props);
  }

  static isPerson(value: Person | AccountPerson): value is Person {
    return 'finished_at' in value && 'name_middle_and_last' in value;
  }

  get fullName(): string {
    return [this.name_first, this.name_middle_and_last].filter((n) => !!n).join(' ');
  }

  get formalName(): string {
    return `${this.initials} ${this.name_middle_and_last}`;
  }

  /** Country object abstracted from CountryPhone */
  get CountryPhone(): iCountry {
    return {
      title: this.country_phone.title,
      eer: this.country_phone.eer,
      iso3: this.country_phone.iso3,
      phone: this.country_phone.phone,
    };
  }

  get datesPension() {
    return {
      birth_at: this.birth_at,
      retirement_at: this.retirement_at,
    };
  }

  isChild(): boolean {
    return Utils.isNotNil(this.onboarding_kind) && this.onboarding_kind === PersonKind.CHILD;
  }

  hasPhoneNumber(): boolean {
    return !Utils.isAnyNil([this.phone, this.country_phone]);
  }

  hasIdentification(): boolean {
    return !!this.finished_at;
  }

  isFinished(): boolean {
    return Utils.isNotNil(this.finished_at) && Utils.isNotNil(this.id);
  }

  hasCompletedAddress(): boolean {
    return !!this.address;
  }

  /**
   * Checks whether the nationality fields have ben filled in
   * Note: 'country_birth' doesn't get returned anymore after the person has finished
   * @returns boolean
   */
  hasCompletedNationality(): boolean {
    const requiredNationalityFields: unknown[] = [this.country_nationality, this.country_birth];
    if (Utils.isNil(this.country_nationality) || Utils.isNil(this.address)) {
      // When either `country_nationality` or `address` is nil we know for sure the person hasn't completed the nationality step
      return false;
    } else if (
      (this.country_nationality.iso3 === 'NLD' || this.country_nationality.iso3 === 'BEL') &&
      this.address.country.iso3 != this.country_nationality.iso3
    ) {
      // When nationality country is `NLD` or `BEL` and they are not the same as address country `question_country_other_reason` is required
      requiredNationalityFields.push(this.question_country_other_reason);
    } else if (this.address.country.iso3 != 'NLD' && this.country_nationality.iso3 != 'NLD') {
      // When both address country and nationality country aren't `NLD` `question_relation_netherlands` is required
      requiredNationalityFields.push(this.question_relation_netherlands);
      // When `question_relation_netherlands` is `3` also `question_relation_netherlands_answer` is required
      if (this.question_relation_netherlands == 3) {
        requiredNationalityFields.push(this.question_relation_netherlands_answer);
      }
    }
    return !Utils.isAnyNil(requiredNationalityFields);
  }

  hasCompletedFinance(): boolean {
    return !Utils.isAnyNil([this.question_source_of_income, this.question_income, this.question_equity, this.question_source_of_wealth]);
  }

  hasCompletedEmployment(): boolean {
    return !Utils.isAnyNil([this.question_employment_status, this.question_industrial_classification]);
  }

  hasCompletedPhone(): boolean {
    return !Utils.isAnyNil([this.phone]);
  }

  hasConfirmedPhone(): boolean {
    return this.phone_confirmed;
  }

  /**
   * !dev note: Temporarily added; refactor later
   * @returns
   */
  hasCompletedBsn(): boolean {
    return this.bsn?.length > 8;
  }
}
