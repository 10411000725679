<ion-app surpressDoubleTap surpressOn="ios">
  @if (loading$ | async) {
    <semmie-loading-ripple></semmie-loading-ripple>
  } @else {
    <ion-router-outlet></ion-router-outlet>

    <div *ngIf="showAppOverlay$ | async" class="appOverlay">
      <semmie-image src="img/logos/logo_dark.svg" width="150"></semmie-image>
    </div>
  }
</ion-app>
