import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthSession } from '@onyxx/model/auth';

export const authApiActions = createActionGroup({
  source: 'Auth API',
  events: {
    Login: props<{ username: string; password: string; code?: string }>(),
    'Login success': props<{ session: AuthSession }>(),
    'Login failure': props<{ error: unknown }>(),

    'Two factor required for login': props<{ username: string; password: string; twoFactorSmsNumber: string; due?: number }>(),
    'Two factor send code': emptyProps(),
    'Two factor send code success': emptyProps(),
    'Two factor send code failure': props<{ errorMsg: string }>(),
    'Two factor send code limit': props<{ due: number }>(),
    'Two factor login': props<{ code: string }>(),

    'Refresh token': emptyProps(),
    'Refresh token success': props<{ session: AuthSession }>(),
    'Refresh token failure': props<{ error: unknown }>(),

    'Revoke token': props<{ skipNavigation: boolean }>(),
    'Revoke token success': props<{ skipNavigation: boolean }>(),
    'Revoke token failure': props<{ error: unknown; skipNavigation: boolean }>(),

    Register: props<{ name: string; email: string; password: string }>(),
    'Register success': props<{ email: string; password: string }>(),
    'Register failure': props<{ error: unknown }>(),

    'Request password reset': props<{ email: string }>(),
    'Request password reset success': emptyProps(),
    'Request password reset failure': props<{ errorMessage: string }>(),

    'Set password': props<{ email: string; token: string; password: string }>(),
    'Set password success': props<{ email: string; password: string }>(),
    'Set password failure': props<{ error: unknown }>(),

    'Unlock user': props<{ token: string }>(),
    'Unlock user success': emptyProps(),
    'Unlock user failure': props<{ errorMessage: string }>(),
  },
});
