import { NgModule, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppLoadingStatusStoreFacade } from '@onyxx/store/app-loading-status';
import { AuthFacade } from '@onyxx/store/auth';
import { UserActivityStoreFacade } from '@onyxx/store/user-activity';
import { AppStateStorage, AppStateStorageKeys, AppPreferences, PreferencesStorageKeys } from '@semmie/schemas';
import { PlatformService, AppStorageService, AppIconService, AppService, NavigationService, SentryService } from '@semmie/services';
import { AppStore } from '@semmie/store/app/app.store';
import { map, firstValueFrom, EMPTY, switchMap, of, take, shareReplay, distinctUntilChanged, combineLatest, filter } from 'rxjs';
import { Utils } from '@onyxx/utility/general';
import { UserStoreFacade } from '@semmie/store/user';
import { authenticatedAreaRoutes } from './authenticated-area.routes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRouteNames } from '@onyxx/model/main';

@NgModule({
  imports: [RouterModule.forChild(authenticatedAreaRoutes)],
})
export class AuthenticatedAreaModule {
  private readonly authFacade = inject(AuthFacade);
  private readonly userActivityStoreFacade = inject(UserActivityStoreFacade);
  private readonly platformService = inject(PlatformService);
  private readonly appStorageService = inject(AppStorageService);
  private readonly appIconService = inject(AppIconService);
  private readonly appService = inject(AppService);
  private readonly appStore = inject(AppStore);
  private readonly navigationService = inject(NavigationService);
  private readonly sentryService = inject(SentryService);
  private readonly appLoadingStatusStoreFacade = inject(AppLoadingStatusStoreFacade);
  private readonly userStoreFacade = inject(UserStoreFacade);

  private readonly appStateStorage = this.appStorageService.createStorageReader<AppStateStorage>(AppStateStorageKeys.AppState);
  private readonly preferencesStorage = this.appStorageService.createStorageReader<AppPreferences>(PreferencesStorageKeys.Preferences);

  private readonly hasUncompletedAppOnboarding$ = this.appService
    .getUncompletedAppOnboarding()
    .pipe(map((uncompletedAppOnboarding) => uncompletedAppOnboarding.length > 0));

  /** Whether the user store is ready i.e. if logged in, whether the user is loaded,
   * and if not logged in then it's assumed to be ready */
  private readonly userStoreReady$ = this.authFacade.isAuthenticated$.pipe(
    switchMap((isAuthenticated) => {
      if (isAuthenticated) {
        return this.userStoreFacade.readyNotification$;
      }
      return of(true);
    }),
    // take 1 because isAuthenticated can go false when logging out, and then we don't want to
    // prevent loading the login page.
    take(1),
    shareReplay(1),
  );

  /**
   * Whether the app is ready to be used i.e. the authentication store has been initialized
   */
  private readonly doneLoading$ = combineLatest([this.authFacade.ready$, this.userActivityStoreFacade.ready$, this.userStoreReady$]).pipe(
    map(([authReady, userActivityReady, userStoreReady]) => authReady && userActivityReady && userStoreReady),
    distinctUntilChanged(),
    filter((ready) => ready),
  );

  constructor() {
    this.load();

    this.doneLoading$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.appLoadingStatusStoreFacade.dispatchDoneLoading();
    });
  }

  async load() {
    // The order of initialization makes a different.
    // First we initialize the authentication store cause other checks need to know
    // if the user is authenticated
    this.authFacade.dispatchInitialize();
    await firstValueFrom(this.authFacade.ready$);

    // Secondly, we do all the startup checks for the mobile app
    // because we want to show the results as fast as possible (upgrade required,
    // security modal, or logging out the user if they did not complete pin setup)
    await this.bootstrapApp();

    this.appLoadingStatusStoreFacade.dispatchHideSplashScreen();

    // then we start checking for inactivity
    // this has to happen after authentication and potentially logging
    // the user out when they did not complete pin setup
    this.userActivityStoreFacade.dispatchInitialize({ skipNavigationOnLogout: false });
    await firstValueFrom(this.userActivityStoreFacade.ready$);

    // The lowest priority (because user loading is happening in the background)
    await firstValueFrom(this.userStoreReady$);
  }

  private async bootstrapApp() {
    if (!this.platformService.isApp) {
      return;
    }

    const appStateStorage = await this.appStateStorage.get();
    const preferencesStorage = await this.preferencesStorage.get();
    if (Utils.isNil(appStateStorage) && Utils.isNil(preferencesStorage)) {
      // Note: preferencesStorage check is to ensure it won't affect current app installations
      this.appIconService.resetIcon();
      await this.appStateStorage.set({ launchedBefore: true, nrOfLaunches: 1 });
    } else if (Utils.isNil(appStateStorage) && Utils.isNotNil(preferencesStorage)) {
      // If preferencesStorage is not nil, then it means the app has been installed before
      // But the appStateStorage is nil, so we set the launchedBefore to true for current installations
      await this.appStateStorage.set({ launchedBefore: true, nrOfLaunches: 1 });
    } else if (Utils.isNotNil(appStateStorage)) {
      await this.appStateStorage.set({ launchedBefore: true, nrOfLaunches: appStateStorage.nrOfLaunches + 1 });
    }

    // wait for app preference to be loaded
    await firstValueFrom(this.appStore.appPreferences$);
    const isUpToDate = await firstValueFrom(this.appService.checkAppVersionUpToDate());

    if (!isUpToDate) {
      this.navigationService.navigateByUrl(AppRouteNames.Update, { replaceUrl: true });

      return EMPTY;
    }

    const hasUncompletedAppOnboarding = await firstValueFrom(this.hasUncompletedAppOnboarding$);
    const isAuthenticated = await firstValueFrom(this.authFacade.isAuthenticated$);
    if (hasUncompletedAppOnboarding) {
      this.sentryService.captureMessage('Uncompleted app onboarding - logging out');
      if (isAuthenticated) {
        this.authFacade.dispatchLogOut();
        await firstValueFrom(this.authFacade.loggedOut$);
      }
      return;
    }

    const { pinCodeEnabled } = await firstValueFrom(this.appService.pinCodeStatus$);
    if (pinCodeEnabled) {
      if (isAuthenticated) {
        this.authFacade.dispatchSecureApplication();
      } else {
        // trigger logout to clear all onboarding and pin data
        this.authFacade.dispatchLogOut();
      }
    }
  }
}
