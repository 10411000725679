import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { directDebitsFeature } from './direct-debits.reducer';
import { directDebitsCommonActions } from './direct-debits-common.actions';
import { directDebitsApiActions } from './direct-debits-api.actions';
import { filter } from 'rxjs';
import { iDirectDebit } from '@semmie/schemas/bi/payment';

@Injectable()
export class DirectDebitsStoreFacade {
  readonly store = inject(Store);

  readonly directDebits$ = this.store
    .select(directDebitsFeature.selectDirectDebits)
    .pipe(filter((directDebits): directDebits is iDirectDebit[] => directDebits !== false));

  dispatchLoad() {
    this.store.dispatch(directDebitsApiActions.load());
  }

  dispatchUpdateDirectDebitsInStore(accountId: string, directDebits: iDirectDebit[]) {
    this.store.dispatch(directDebitsCommonActions.updateDirectDebitsInStore({ accountId, directDebits }));
  }
}
