import { inject } from '@angular/core';
import { catchError, distinctUntilChanged, exhaustMap, filter, map, of, switchMap, take, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filterNil } from '@onyxx/utility/observables';
import { goalApiActions } from './goal-api.actions';
import { HttpErrorResponse } from '@angular/common/http';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { GoalProvider } from '@onyxx/provider/goal';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountStoreFacade } from '@onyxx/store/account';
import { goalCommonActions } from './goal-common.actions';
import { GenericErrorToast } from '@semmie/schemas/components/toast';
import { ToastService } from '@semmie/services';
import { concatLatestFrom } from '@ngrx/operators';

export class GoalEffects {
  private readonly actions$ = inject(Actions);
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly goalProvider = inject(GoalProvider);
  private readonly toastService = inject(ToastService);

  private readonly accountId$ = this.accountStoreFacade.account$.pipe(map((account) => account?.id));

  readonly loadGoal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(goalApiActions.load),
      // switchMap instead of concatLatestFrom because the load might be triggered before
      // the accountId is ready
      switchMap(() => this.accountId$.pipe(filterNil(), take(1))),
      exhaustMap((accountId) =>
        this.goalProvider.get(accountId).pipe(
          map((goal) => goalApiActions.loadSuccess({ goal, accountId })),
          catchError((error: Error) => {
            if (error instanceof HttpErrorResponse && error.status === 404) {
              return of(goalApiActions.loadSuccess({ accountId, goal: null }));
            }

            return of(goalApiActions.loadFailure({ errorMsg: error.message }));
          }),
        ),
      ),
    );
  });

  readonly loadForAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(goalApiActions.loadForAccount),
      concatLatestFrom(() => this.accountId$),
      filter(([{ accountId }, currentAccountId]) => currentAccountId === accountId),
      map(() => {
        return goalApiActions.load();
      }),
    );
  });

  readonly updateGoal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(goalApiActions.update),
      switchMap(({ accountId, goal }) =>
        this.goalProvider.update(accountId, goal).pipe(
          map((_goal) => goalApiActions.updateSuccess({ accountId, goal: _goal })),
          catchError(({ message }: Error) => of(goalApiActions.updateFailure({ errorMsg: message }))),
        ),
      ),
    );
  });

  readonly updateFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(goalApiActions.updateFailure),
        tap(({ errorMsg }) => {
          console.error(errorMsg);
          this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Clear goal when account ID changes to avoid showing a goal corresponding to another account
   */
  readonly clearGoal$ = createEffect(() => {
    // DEVNOTE: Could this create a racecondition with a load dispatch and a clear at the same time, clearing the just loaded goal? For now we do not worry about it.
    return this.accountId$.pipe(
      distinctUntilChanged(),
      map(() => goalCommonActions.clear()),
    );
  });
}
