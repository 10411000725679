<semmie-card class="text-center">
  <div class="flex h-[28rem] flex-col justify-between">
    <div class="h-6">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="flex-grow space-y-4">
      <div class="flex min-h-[11rem] w-full flex-col justify-center" *ngIf="image">
        <div [ngClass]="imageStyle">
          <semmie-image [src]="image" [alt]="title"></semmie-image>
        </div>
      </div>
      <div *ngIf="title">
        <semmie-label class="content-card-title" type="heading" size="h2" weight="medium" [text]="title"></semmie-label>
      </div>
      <div *ngIf="description">
        <semmie-label class="content-card-description" [text]="description"></semmie-label>
      </div>
    </div>
    <div class="flex h-[6.5rem] flex-col space-y-5 text-center">
      <semmie-button *ngIf="cta" [disabled]="ctaDisabled" (onClick)="ctaInteraction.emit(true)">{{ cta }}</semmie-button>
      <semmie-label *ngIf="ctaSecondary" type="small" size="small" class="content-card-cta-label" [text]="ctaSecondary"></semmie-label>
    </div>
  </div>
</semmie-card>
