import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LabelModule } from '@semmie/components/presentational/core/label';

import { TableComponent } from './table.component';

@NgModule({
  imports: [CommonModule, LabelModule],
  exports: [TableComponent],
  declarations: [TableComponent],
})
export class TableModule {}
