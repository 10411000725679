<div class="info-modal-content" semmieModalStopScrollGestureOverlapDirective>
  <ng-container *ngIf="!customTemplate; else custom">
    <semmie-modal-header [hideCloseButton]="hideCloseButton" (onCloseClick)="closeModal()"></semmie-modal-header>
    <semmie-intro-content
      [title]="title | translate"
      [label]="subtitle | translate"
      [description]="description | translate"
      [image]="image | translate"
      [button]="button | translate"
      [link_label]="linkButton | translate"
      [footer]="footer"
      (onButtonClick)="onButtonClickHandler()"
      (onLinkLabelClick)="onLinkLabelClickHandler()"
    ></semmie-intro-content>
  </ng-container>
</div>

<ng-template #custom>
  <ng-content *ngTemplateOutlet="customTemplate"></ng-content>
</ng-template>
