import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authApiActions } from '../auth-api.actions';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { AuthProvider } from '@onyxx/provider/auth';
import { ToastService } from '@semmie/services';
import { ToasterStyle } from '@semmie/schemas/components/toast';

export class UnlockUserEffects {
  private readonly actions$ = inject(Actions);
  private readonly authProvider = inject(AuthProvider);
  private readonly toastService = inject(ToastService);

  readonly $unlockUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.unlockUser),
      exhaustMap(({ token }) => {
        return this.authProvider.unlockUser(token).pipe(
          map(() => authApiActions.unlockUserSuccess()),
          tap(() => {
            this.toastService.show({
              header: $localize`:@@auth.unlock-user.success-toast.header:Your account has been unlocked successfully`,
              message: $localize`:@@auth.unlock-user.success-toast.message:You can log in using the form below or in the app.`,
              style: ToasterStyle.SUCCESS,
            });
          }),
          catchError((error: Error) => of(authApiActions.unlockUserFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });
}
