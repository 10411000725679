import { Injectable, Injector } from '@angular/core';
import { BaseStore } from '@semmie/models/_abstract';

import { PersonService } from '@semmie/services/person/person.service';
import { AccountsStore } from '@semmie/store/accounts/accounts.store';
import { ConfigStore } from '@semmie/store/config/config.store';
import { QuestionnaireStore } from '@semmie/store/questionnaire/questionnaire.store';
import { UserStoreFacade } from '@semmie/store/user';
import { CountriesStoreFacade } from '@onyxx/store/countries';
import { QuestionsStoreFacade } from '@onyxx/store/questions';
import { PskParticipantsStoreFacade } from '@onyxx/store/psk-participants';
import { PayoutFacade } from '@onyxx/store/payout';
import { AccountStoreFacade } from '@onyxx/store/account';
import { AccountAdapterStore } from '@semmie/store/accounts/account-adapter.store';

@Injectable({
  providedIn: 'root',
})
export class StoreHelper {
  constructor(private injector: Injector) {}

  getStoreByName(name: string) {
    let store:
      | BaseStore<any>
      | PersonService
      | UserStoreFacade
      | CountriesStoreFacade
      | QuestionsStoreFacade
      | PskParticipantsStoreFacade
      | PayoutFacade
      | AccountsStore
      | AccountStoreFacade
      | AccountAdapterStore
      | null = null;

    switch (name) {
      // TODO: only two usages remain. Refactor them so we can remove the AccountStore from the forms module
      case 'AccountsStore':
        store = this.injector.get(AccountsStore);
        break;
      case 'AccountStoreFacade':
        store = this.injector.get(AccountStoreFacade);
        break;
      case 'AccountAdapterStore':
        store = this.injector.get(AccountAdapterStore);
        break;
      case 'ConfigStore':
        store = this.injector.get(ConfigStore);
        break;
      case 'PersonStore':
        /**
         * !todo: replace with real PersonStore when it exists
         */
        store = this.injector.get(PersonService);
        break;
      case 'QuestionnaireStore':
        store = this.injector.get(QuestionnaireStore);
        break;
      case 'UserStore':
        store = this.injector.get(UserStoreFacade);
        break;
      case 'CountriesStore':
        store = this.injector.get(CountriesStoreFacade);
        break;
      case 'QuestionsStore':
        store = this.injector.get(QuestionsStoreFacade);
        break;
      case 'PskParticipantsStore':
        store = this.injector.get(PskParticipantsStoreFacade);
        break;
      case 'PayoutStore':
        store = this.injector.get(PayoutFacade);
        break;
      default:
        console.error('Cannot find store for parameter: ', name);
        return null;
    }

    return store;
  }
}
