import { Injectable, inject } from '@angular/core';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { NewPayout, Payout, PayoutCalculation } from '@onyxx/model/payout';
import { map } from 'rxjs';
import { Utils } from '@onyxx/utility/general';

@Injectable({ providedIn: 'root' })
export class PayoutProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  /**
   * retrieve the payout for the specified account
   * @returns payout<Payout>
   */
  getPayout(accountId: string) {
    return this.http.get<{ payout: Payout }>(`${this.apiUrl}/accounts/${accountId}/payout`).pipe(map(({ payout }) => payout));
  }

  /**
   * create a new payout for the specified account
   * @param accountId: Id of the account we setup the payout for
   * @returns payout<Payout>
   */
  createPayout(accountId: string, payout: NewPayout) {
    return this.http.post<{ payout: Payout }>(`${this.apiUrl}/accounts/${accountId}/payout`, payout).pipe(map(({ payout }) => payout));
  }

  /**
   * Calculate payout
   * @returns PayoutCalculation
   */
  calculatePayout(payout: PayoutCalculation) {
    return this.http
      .get(`${this.apiUrl}/payout/calculate?${Utils.urlEncodeObject(payout)}`)
      .pipe(map((response) => response as PayoutCalculation));
  }
}
