import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { Message } from '@semmie/models/bi/message';
import { MessageDetail } from '@semmie/models/bi/message/message-detail.model';
import { InboxProvider } from '@semmie/providers/inbox/inbox.provider';
import { iBaseListableService } from '@semmie/schemas/services/list/base-service';
import { InboxStore } from '@semmie/store/inbox/inbox.store';
import { UserStoreFacade } from '@semmie/store/user';
import { concatLatestFrom } from '@ngrx/operators';
import { PaginationParams, PaginatedResponse } from '@onyxx/model/pagination';

@Injectable({
  providedIn: 'root',
})
export class InboxService implements iBaseListableService<Message> {
  constructor(
    private inboxProvider: InboxProvider,
    private inboxStore: InboxStore,
    private userFacade: UserStoreFacade,
  ) {}

  list(params?: PaginationParams, refresh?: boolean): Observable<PaginatedResponse<Message>> {
    const page = params?.page ?? 1;
    const pageCache = this.inboxStore.getCachedPage(page);

    if (refresh || !pageCache?.data || !pageCache?.data?.length) {
      if (refresh) {
        this.inboxStore.clearList();
      }

      return this.inboxProvider.list(params).pipe(tap((response) => this.inboxStore.updateCachedPageByPage(page, response)));
    }

    return this.inboxStore.messages$;
  }

  getMessage(id: string, refresh?: boolean) {
    const cache = this.inboxStore.details$.value;

    if (!cache || cache?.id !== id || refresh) {
      return this.inboxProvider.get(id).pipe(
        tap((message) => {
          this.inboxStore.updateMessageDetail(message);
        }),
      );
    }

    return this.inboxStore.details$.asObservable();
  }

  updateMessage(data: MessageDetail) {
    this.inboxStore.flatList$
      .pipe(
        concatLatestFrom(() => this.userFacade.user$),
        take(1),
        tap(([list, user]) => {
          const cachedMessage = list.data.find((msg) => msg.id === data.id);

          if (cachedMessage && !cachedMessage.read_at) {
            this.userFacade.updateUserStore({
              unread_inbox_count: Math.max((user?.unread_inbox_count ?? 1) - 1, 0),
            });
          }
        }),
      )
      .subscribe(() => {
        this.inboxStore.update(data);
        this.inboxStore.updateCachedPage(data);
      });
  }

  clear() {
    this.inboxStore.clear();
  }
}
