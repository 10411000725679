import { Injectable } from '@angular/core';
import { UserRole, iUser } from '@onyxx/model/user';
import { Utils } from '@onyxx/utility/general';
import { filterNil } from '@onyxx/utility/observables';
import { Organisation } from '@semmie/models/bi/organisation/organisation.model';
import { OrganisationProvider } from '@semmie/providers/organisation/organisation.provider';
import { iOrganisationPerson } from '@semmie/schemas';
import { KvkForm } from '@semmie/schemas/bi/kvk/kvk-form';
import { OrganisationPersonRole, UboType } from '@semmie/schemas/bi/organisation';
import { OrganisationStore } from '@semmie/store/organisation/organisation.store';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrganisationService {
  constructor(
    private organisationProvider: OrganisationProvider,
    private organisationStore: OrganisationStore,
  ) {}

  get cache() {
    return this.organisationStore.store.value ?? new Map<string, Organisation>();
  }

  get(id: string, force?: boolean): Observable<Organisation> {
    const cachedOrganisation = this.cache.get(id);
    if (cachedOrganisation && !force) {
      return this.organisationStore.store$.pipe(
        filterNil(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        map((store) => store.get(id)!),
      );
    }
    return this.organisationProvider.get(id).pipe(
      tap((organisation) => {
        this.organisationStore.store.next(this.cache.set(id, organisation));
      }),
      switchMap(() =>
        this.organisationStore.store$.pipe(
          filterNil(),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          map((store) => store.get(id)!),
        ),
      ),
    );
  }

  list() {
    return this.organisationProvider.list();
  }

  create(params: { accountId: string; kvk_number: string }): Observable<Organisation> {
    if (!(params?.accountId && params?.kvk_number)) return EMPTY;
    return this.organisationProvider.create(params.accountId, params.kvk_number).pipe(
      tap((organisation) => {
        this.organisationStore.store.next(this.cache.set(organisation.id, organisation));
      }),
    );
  }

  createSubOrganisation(params: { organisationId: string; kvk_number: string }): Observable<Organisation> {
    if (!(params?.organisationId && params?.kvk_number)) return EMPTY;
    return this.organisationProvider.createSubOrganisation(params.organisationId, params.kvk_number);
  }

  createOrganisationPerson(organisationId: string, role: OrganisationPersonRole, personId?: string): Observable<iOrganisationPerson> {
    return this.organisationProvider.createOrganisationPerson(organisationId, role, personId);
  }

  updateOrganisationPerson(
    organisationId: string,
    organisationPersonId: string,
    organisationPerson: Partial<iOrganisationPerson>,
  ): Observable<boolean> {
    return this.organisationProvider.updateOrganisationPerson(organisationId, organisationPersonId, organisationPerson);
  }

  deleteOrganisationPerson(organisationId: string, personId: string): Observable<boolean> {
    return this.organisationProvider.deleteOrganisationPerson(organisationId, personId);
  }

  update(organisationId: string, organisation: Partial<Organisation>): Observable<Organisation> {
    return this.organisationProvider.update(organisationId, organisation).pipe(
      tap((organisation) => {
        this.organisationStore.store.next(this.cache.set(organisationId, organisation));
      }),
    );
  }

  refresh(organisationId: string): Observable<Organisation> {
    return this.organisationProvider.refresh(organisationId).pipe(
      tap((organisation) => {
        this.organisationStore.store.next(this.cache.set(organisationId, organisation));
      }),
    );
  }

  /**
   * TODO: Get this order from a form/flow definition (communication with BE) to make it configurable.
   * @param organisation {Organisation}
   * @returns { part: string; step?: string }
   */
  calculateLastActiveStep(organisation?: Organisation, user?: iUser): { part: string; step?: string } {
    if (!organisation || organisation.finished) return { part: 'flow', step: 'create' };

    switch (true) {
      case Utils.isNil(organisation.kvk_number):
        return { part: 'flow', step: 'create' };

      case Utils.isNil(organisation.question_participations_count) && organisation.kvk_form !== KvkForm.eenmanszaak:
        return { part: 'flow', step: 'participation' };

      case !organisation.hasLei:
        return { part: 'flow', step: 'lei' };

      case organisation.question_participations_count === 1 &&
        (Utils.isNil(organisation.organisations) || organisation.organisations.length === 0) &&
        organisation.kvk_form !== KvkForm.eenmanszaak:
        return { part: 'participation-select' };

      case Utils.isNil(organisation.question_sign_authority) &&
        organisation.kvk_form !== KvkForm.eenmanszaak &&
        user?.role !== UserRole.Advisor:
        return { part: 'flow', step: 'sign-authority' };

      case Utils.isNil(organisation.question_ubo) && organisation.kvk_form !== KvkForm.eenmanszaak && user?.role !== UserRole.Advisor:
        return { part: 'ubo', step: 'ubo-type' };

      case (([UboType.UBO.toString(), UboType.NO_UBO.toString()].includes(organisation.question_ubo) &&
        Utils.isNil(organisation.organisation_people)) ||
        organisation.organisation_people?.length === 0) &&
        organisation.kvk_form !== KvkForm.eenmanszaak:
        return { part: 'ubo', step: 'ubo-overview' };

      case ([UboType.UBO.toString(), UboType.NO_UBO.toString()].includes(organisation.question_ubo) || user?.role === UserRole.Advisor) &&
        organisation.organisation_people?.some((p) => Utils.isNil(p.share) || p.share === '0') &&
        organisation.kvk_form !== KvkForm.eenmanszaak:
        return { part: 'ubo', step: 'ubo-division' };

      case Utils.isNil(organisation.question_balance):
        return { part: 'flow', step: 'revenue' };

      case Utils.isNil(organisation.question_equity_invest_obligation):
        return { part: 'flow', step: 'finance' };

      case Utils.isNil(organisation.question_group) && organisation.kvk_form !== KvkForm.eenmanszaak:
        return { part: 'flow', step: 'structure' };

      case Utils.isNil(organisation.question_employees):
        return { part: 'flow', step: 'properties' };

      default:
        return { part: 'flow', step: 'documentation' };
    }
  }

  finish(organisationId: string): Observable<Organisation> {
    return this.organisationProvider.finish(organisationId).pipe(
      tap((organisation) => {
        this.organisationStore.store.next(this.cache.set(organisationId, organisation));
      }),
    );
  }

  /**
   * @deprecated Update organisation request data when only legacy meta data is set
   * TODO: Remove this method when the meta data is migrated by BE
   * More info about this implementation: https://app.clickup.com/t/2559043/SD-2902
   */
  updateOrganisationRequestData(organisation: Partial<Organisation>, requestData: Partial<Organisation>) {
    const organisationMetaData = organisation.metaData;
    // add `question_sign_authority` to request data when empty and only signAuthority in meta is set
    if (Utils.isNotNil(organisation.metaData) && Utils.isNotNil(organisation.metaData.signAuthority)) {
      requestData.question_sign_authority = organisation.metaData.signAuthority;
      // remove `signAuthority` from meta data
      delete organisationMetaData['signAuthority'];
    }

    // add `question_ubo` to request data when empty and only uboType in meta is set
    if (Utils.isNotNil(organisation.metaData) && Utils.isNotNil(organisation.metaData.uboType)) {
      switch (organisation.metaData.uboType) {
        case 'fully-owner':
          requestData.question_ubo = UboType.FULLY_OWNER;
          break;
        case 'ubo':
          requestData.question_ubo = UboType.UBO;
          break;
        case 'no-ubo':
          requestData.question_ubo = UboType.NO_UBO;
          break;
      }
      // remove `uboType` from meta data
      delete organisationMetaData['uboType'];
    }

    requestData.meta = organisationMetaData;

    return requestData;
  }

  /** Used in the organisation onboarding to load previously uploaded docs*/
  getDocuments(options: { organisationId: string }) {
    return this.organisationProvider.getOrganisationDocuments(options.organisationId);
  }
}
