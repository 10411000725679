import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-page-header-right',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderRightComponent extends BaseComponent {}
