import { Injectable } from '@angular/core';
import { Task } from '@semmie/models/bi/task';
import { BaseStore } from '@semmie/models/_abstract';

interface TasksState {
  tasks: Task[];
  loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class TasksStore extends BaseStore<TasksState> {
  readonly tasks$ = this.select((state) => state.tasks);
  readonly loading$ = this.select((state) => state.loading);

  constructor() {
    super({
      loading: true,
      tasks: [],
    });
  }

  get tasks(): Task[] {
    return this.store.value?.tasks ?? [];
  }

  updateTask(id: Task['id'], task: Task): void {
    const tasks = this.tasks.reduce((acc, t) => {
      return [...acc, t.id === id ? { ...t, ...task } : t];
    }, [] as Task[]);
    this.patchState({ tasks: tasks as Task[] });
  }

  deleteTask(id: Task['id']) {
    this.patchState({
      tasks: this.tasks.filter((task) => task.id !== id),
    });
  }

  selectTask(taskId: string) {
    return this.select(({ tasks }) => tasks.find((task) => task.id === taskId));
  }

  clear() {
    this.store.next({ loading: true, tasks: [] });
  }
}
