import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { iCheckboxGroupFormField } from '@semmie/schemas/components/dynamic-form';

@Component({
  selector: 'semmie-checkbox-group',
  templateUrl: './checkbox-group.component.html',
})
export class CheckboxGroupComponent extends BaseFormComponent implements OnInit {
  /** Disables the field and makes it read-only. */
  @Input() isDisabled: boolean;
  @Input() form: UntypedFormGroup;
  /** The display text of the field */
  @Input() label: iCheckboxGroupFormField['label'];
  @Input() layout: iCheckboxGroupFormField['layout'];
  @Input() options?: iCheckboxGroupFormField['options'] | null;

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
