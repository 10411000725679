import { Injectable } from '@angular/core';

@Injectable()
export class DynamoDBMarshallService {
  DynamoDBKeyTypes: Array<string> = ['N', 'BOOL', 'S', 'L'];

  unmarshall(data: any): any {
    return this.unwrap(data);
  }

  private unwrap(data: any, previousNode?: string) {
    const unwrapped = {};

    for (const key in data) {
      const value = data[key];

      if (key === 'M') {
        unwrapped[previousNode || key] = this.unwrap(value, key);
      } else if (typeof value === 'object') {
        const valueKey = String(Object.keys(value)[0]);
        const isDynamoDBKeyType = this.DynamoDBKeyTypes.includes(valueKey);

        if (isDynamoDBKeyType) {
          if (Array.isArray(value[valueKey])) {
            unwrapped[key] = [];

            for (const arrayVal of value[valueKey]) {
              const nArrayVal = this.unwrap(arrayVal, key);
              unwrapped[key].push(nArrayVal[key]);
            }
          } else if (!unwrapped[key]) unwrapped[key] = this.parseValueWithType(value[valueKey], valueKey);
          else Object.assign(unwrapped[key], this.parseValueWithType(value[valueKey], valueKey));
        } else {
          const node = previousNode && !this.DynamoDBKeyTypes.includes(previousNode) ? previousNode : null;
          if (!node) unwrapped[key] = this.unwrap(value, key);
          else Object.assign(unwrapped, this.unwrap(value, key));
        }
      } else {
        if (!unwrapped[previousNode || key]) unwrapped[previousNode || key] = value;
        else Object.assign(unwrapped[key], value);
      }
    }

    return unwrapped;
  }

  private parseValueWithType(value: any, dbType: string) {
    switch (dbType) {
      case 'N':
        return Number(value);
      case 'BOOL':
        return Boolean(value);
      default:
        return value;
    }
  }
}
