<ng-container *ngIf="(isLoading$$ | async) === false; else loadingTemplate">
  <ng-container *ngIf="data$$ | async as data">
    <ng-container *ngIf="hasSearchQuery$$ | async">
      <div *ngIf="!value && data?.length > 0 && data !== null; else noDataTemplate" class="autocomplete-list">
        <div *ngFor="let item of data" (click)="selectResult(item)" class="autocomplete-item">
          <div class="title flex">
            <semmie-label *ngIf="list.title?.label" [text]="list.title!.label" type="heading" size="h3" weight="medium"></semmie-label>
            <semmie-label
              *ngIf="list.title?.value"
              [text]="item[list.title!.value]"
              type="heading"
              size="h3"
              weight="medium"
            ></semmie-label>
          </div>
          <div class="subtitle flex">
            <semmie-label *ngIf="list.subtitle?.label" size="base_3" [htmlContent]="list.subtitle!.label" type="display"></semmie-label>
            <semmie-label *ngIf="list.subtitle?.value" size="base_3" [text]="item[list.subtitle!.value]"></semmie-label>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <div *ngIf="!value" class="no-data">
    <div class="m-auto h-10 w-10">
      <semmie-loading-ripple position="inline"></semmie-loading-ripple>
    </div>
  </div>
</ng-template>

<ng-template #noDataTemplate>
  <div *ngIf="!value" class="no-data">
    <semmie-image src="illustrations/small/satellite-signal.png" width="45" class="mx-auto"></semmie-image>
    @if (hasError$$ | async) {
      <semmie-label class="mt-2" size="base_3" i18n-text="@@autocomplete-list.error" text="Something went wrong" />
    } @else {
      <semmie-label class="mt-2" size="base_3" i18n-text="@@autocomplete-list.no-results" text="No results found" />
    }
  </div>
</ng-template>
