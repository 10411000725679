import { HttpMethod } from '@semmie/schemas/generics/http/http-method.enum';
import { iBaseDynamicFormField } from './base-dynamic-field';
import { CardStyle } from '@semmie/schemas';

export enum UploadLayout {
  Document = 'document',
  Image = 'image',
  Button = 'button',
}

export enum UploadState {
  empty = 'empty',
  uploading = 'uploading',
  processing = 'processing',
  uploaded = 'uploaded',
  error = 'error',
  deleting = 'deleting',
  selected = 'selected',
}

export interface iUploadComponent {
  layout: UploadLayout;
  state: UploadState;
  title: string | null;
  subtitle: string | null;
  imgSrc: string | null;
  fileSelected: boolean;
  error: string | null;
}

export interface iUploadFormField extends iBaseDynamicFormField {
  url: string;
  method: HttpMethod;
  label: string;
  optionsModalTitle?: string;
  layout: UploadLayout;
  cardStyle: CardStyle;
  uploadAfterCreation: string | boolean;
  refresh?: string;
  params: { [key: string]: any };
  useParamsForDeletion?: boolean;
  /** array of string specifying which file types are allowed ('image' for images and 'document' for PDFs) */
  fileTypes?: ('image' | 'document')[];
  subtitle?: string;
}
