import { createFeature, createReducer, on } from '@ngrx/store';
import { PushNotificationCommonActions } from './push-notifications-common.actions';

export interface State {
  deviceToken: string | null;
  enrolled: boolean;
  /**
   * Whether the user has granted permission, null if not asked
   */
  hasPermission: boolean | null;
  requestPermissionBusy: boolean;
}

const initialState: State = {
  deviceToken: null,
  enrolled: false,
  hasPermission: null,
  requestPermissionBusy: false,
};

const reducer = createReducer(
  initialState,
  on(PushNotificationCommonActions.registrationSuccess, (state, { token }): State => ({ ...state, deviceToken: token })),
  on(PushNotificationCommonActions.registrationError, (state): State => ({ ...state, deviceToken: null })),
  on(PushNotificationCommonActions.enroll, (state): State => ({ ...state, requestPermissionBusy: true })),
  on(
    PushNotificationCommonActions.enrolledAndPermissionUpdated,
    PushNotificationCommonActions.permissionUpdated,
    (state, { permission }): State => {
      switch (permission.receive) {
        case 'granted':
          return { ...state, hasPermission: true, requestPermissionBusy: false };
        case 'denied':
          return { ...state, hasPermission: false, requestPermissionBusy: false };
        default:
          return { ...state, hasPermission: null, requestPermissionBusy: false };
      }
    },
  ),
  on(
    PushNotificationCommonActions.enrolledAndPermissionUpdated,
    PushNotificationCommonActions.enrolledUpdated,
    (state, { enrolled }): State => {
      return { ...state, enrolled, requestPermissionBusy: false };
    },
  ),
  on(PushNotificationCommonActions.clearDeviceToken, (state): State => ({ ...state, deviceToken: null, enrolled: false })),
);

export const pushNotificationsFeature = createFeature({
  name: 'push-notifications',
  reducer,
});
