import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '@semmie/components/presentational/core/checkbox/checkbox.module';
import { CheckboxGroupComponent } from '@semmie/components/presentational/core/checkbox-group/checkbox-group.component';

@NgModule({
  declarations: [CheckboxGroupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CheckboxModule],
  exports: [CheckboxGroupComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CheckboxGroupModule {}
