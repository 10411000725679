import { BaseModel } from '@semmie/models/_abstract';
import { iUploadDocument } from '@semmie/schemas/bi/upload-document/upload-document.interface';

export class UploadDocument extends BaseModel<UploadDocument> implements iUploadDocument {
  /**
   * Document created date
   * @info remapped from _'created_at'_
   */
  createdAt: Date;
  created_at: string;
  document_type: string;
  id: string;
  title: string;
  url: string;

  constructor(props?: Partial<UploadDocument>) {
    super(props);
    this.createdAt = new Date(this.initialProps?.created_at);
  }
}
