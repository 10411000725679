import {
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil, tap } from 'rxjs/operators';

import { PaginatedResponse } from '@onyxx/model/pagination';
import { BaseComponent } from '@semmie/components/_abstract/base-component.component';
import { Utils } from '@onyxx/utility/general';
import { RefresherCustomEvent } from '@ionic/angular';

@Directive()
export abstract class BaseListComponent<T> extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ContentChild('template', { static: false }) templateRef: TemplateRef<any>;
  @ContentChild('emptyTemplate', { static: false }) emptyTemplateRef: TemplateRef<any>;
  @ContentChild('placeholderTemplate', { static: false }) placeholderTemplateRef: TemplateRef<any>;
  @ContentChild('beforeItemsTemplate', { static: false }) beforeItemsTemplateRef: TemplateRef<any>;
  @ContentChild('afterItemsTemplate', { static: false }) afterItemsTemplateRef: TemplateRef<any>;

  @Input() asyncItems: boolean;
  @Input() items?: PaginatedResponse<T> | Omit<PaginatedResponse<T>, 'meta'> | null;
  @Input() items$: Observable<PaginatedResponse<T>>;
  @Input() serviceProvider: any;
  @Input() transformer: (items: any[]) => any[] = (items: any[]) => items;
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input() extraParams: Record<string, any> | null = null;
  @Input() hideEmptyState = false;
  @Input() pageSize = 30;

  @Output() onLoadMore: EventEmitter<void> = new EventEmitter();
  @Output() onRefresh: EventEmitter<RefresherCustomEvent | undefined> = new EventEmitter();
  @Output() onItemsLoaded: EventEmitter<any> = new EventEmitter();

  readonly trackByFn = (index: number, item: any) => (item && item.id ? item.id : index);

  destroyed$: Subject<void> = new Subject();

  placeholderItems: Array<any> = new Array(0);
  canLoadMore = false;

  bufferedItems$$ = new BehaviorSubject<T[]>([]);
  loading$$ = new BehaviorSubject(true);
  isEmptyList$ = this.bufferedItems$$.pipe(map((items) => items.length === 0));

  shouldShowLoader$ = this.loading$$.asObservable();

  private itemsRetrieved$: Subject<boolean> = new Subject();

  private readonly placeholderChunkSize = 3;

  private currentPage = 1;
  private totalPages = 0;

  /* eslint-enable @typescript-eslint/member-ordering */

  get current_page() {
    return this.currentPage;
  }

  set current_page(value: number) {
    this.currentPage = value;
  }

  get total_pages() {
    return this.totalPages;
  }

  get hasProvider() {
    return !!this.serviceProvider;
  }

  get provider() {
    return this.serviceProvider;
  }

  ngOnInit(): void {
    if (!this.bufferedItems$$.value.length) {
      this.bufferedItems$$.next([]);

      if (this.hasProvider && !this.asyncItems) {
        this.getItems(this.current_page, this.pageSize, true);
      }
    }

    if (this.items$) {
      this.items$.pipe(distinctUntilChanged(Utils.isEqual), takeUntil(this.destroyed$)).subscribe((items) => {
        this.prepareItems(items, true);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue?.data) {
      this.bufferedItems$$.next([]);
      this.prepareItems(this.items, false);
    }

    if (changes.extraParams && !changes.extraParams.firstChange) {
      this.refreshList();
    }
  }

  getItems(page: number, total_per_page?: number, refresh?: boolean): void {
    this.provider
      .list({ page, per_page: total_per_page, ...(Utils.isNotNil(this.extraParams) && this.extraParams) }, refresh)
      .pipe(
        tap(() => this.itemsRetrieved$.next(true)),
        take(1),
      )
      .subscribe((response) => {
        if (!this.items$) this.prepareItems(response, refresh);
        this.loading$$.next(false);
      });
  }

  prepareItems(response: any, refresh?: boolean): void {
    const hasMeta = response?.meta ?? false;

    if (hasMeta) {
      const meta = response.meta;
      this.canLoadMore = response?.data?.length && this.currentPage !== meta.total_pages;

      this.totalPages = Number(meta.total_pages) ?? 0;
    }

    const items = this.transformer(response.data);
    this.onItemsLoaded.emit(items);

    if (Utils.isNotNil(this.placeholderTemplateRef)) {
      this.placeholderItems = items.length > this.placeholderChunkSize ? Array(0) : Array(this.placeholderChunkSize - items.length);
    }

    if (refresh) {
      this.bufferedItems$$.next([]);
    }

    if (items && items.length) {
      this.bufferedItems$$.next([...this.bufferedItems$$.value, ...items]);
    }

    /**
     * If we resolve our items elsewhere, then stop the loading,
     * otherwise `getItems` takes care of the loading state.
     *
     * !todo: split the list into a smart and dumb component (after release)
     */
    if (this.asyncItems) {
      this.loading$$.next(false);
    }
  }

  loadMore(): void {
    if (this.current_page >= this.total_pages) {
      return;
    }

    this.getItems(++this.current_page, this.pageSize);
    this.onLoadMore.emit();
  }

  resetPagination(): void {
    this.current_page = 1;
  }

  /**
   * Now that we can't query an arbitrary amount of items,
   * we're simply resetting to the first page when the list gets refreshed.
   */
  refreshList($event?: RefresherCustomEvent): void {
    this.currentPage = 1;

    if (this.hasProvider) {
      this.getItems(this.currentPage, this.currentPage * this.pageSize, true);

      this.itemsRetrieved$.pipe(take(1)).subscribe(() => {
        $event?.target.complete();
      });
    }

    this.onRefresh.emit($event);
  }
}
