import * as moment from 'moment';

export const formatTimeDifferenceInMinutes = (start: number, end: number) => {
  const endDate = moment(end);
  const duration = endDate.diff(moment(start));

  if (duration < 0) return '00:00';

  const minutes = Math.floor(moment.duration(duration).asMinutes()).toString().padStart(2, '0');
  const seconds = moment.utc(duration).seconds().toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};
