import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Referral } from '@semmie/models';
import { iUserReferral } from '@onyxx/model/user';

export const ReferralApiActions = createActionGroup({
  source: 'Referral API',
  events: {
    'Load User Referral': emptyProps(),
    'Load User Referral Success': props<{ referral: iUserReferral }>(),
    'Load User Referral Failure': props<{ error: Error }>(),
    'Load Referrals': emptyProps(),
    'Load Referrals Success': props<{ referrals: Referral[]; canLoadMore: boolean }>(),
    'Load Referrals Failure': props<{ error: Error }>(),
    'Load Referrals Next Page': emptyProps(),
    'Load Referrals Page Success': props<{ referrals: Referral[]; canLoadMore: boolean }>(),
    'Load Referrals Page Failure': props<{ error: Error }>(),
  },
});
