import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { PskParticipantsEffects } from './psk-participants.effects';
import { pskParticipantsFeature } from './psk-participants.reducer';
import { PskParticipantsStoreFacade } from './psk-participants.facade';

export const providers = [provideState(pskParticipantsFeature), provideEffects(PskParticipantsEffects), PskParticipantsStoreFacade];

@NgModule({ providers })
export class PskParticipantsStoreModule {}

export * from './psk-participants.facade';
