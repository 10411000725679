import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AccountKind, AccountHelpers } from '@onyxx/model/account';
import { AccountStoreFacade } from '@onyxx/store/account';
import { Utils } from '@onyxx/utility/general';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { GenericErrorToast } from '@semmie/schemas/components/toast';
import { ModalService, NavigationService, ToastService } from '@semmie/services';
import { BehaviorSubject, firstValueFrom, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'semmie-account-convert-modal',
  templateUrl: './account-convert-modal.component.html',
  styleUrls: ['./account-convert-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountConvertModalComponent extends BaseComponent {
  @Input() set accountId(accountId: string) {
    this.accountId$$.next(accountId);
  }
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() description: string;
  @Input() subtitle: string;
  @Input() image: string;
  @Input() button: string;
  @Input() linkLabel: string;
  @Input() customTemplate: TemplateRef<any>;

  private readonly accountId$$ = new BehaviorSubject<string | null>(null);
  private readonly account$ = this.accountId$$.pipe(
    tap((accountId) => {
      if (Utils.isNil(accountId)) return;

      this.accountStoreFacade.dispatchEnsureAccountIsLoaded(accountId);
    }),
    switchMap((accountId) => (Utils.isNil(accountId) ? of(null) : this.accountStoreFacade.account(accountId))),
  );

  private readonly account$$ = toSignal(this.account$, { initialValue: null });
  readonly conversionEnabled$$ = computed(() => {
    const account = this.account$$();
    if (Utils.isNil(account)) return false;

    return !AccountHelpers.hasAdvisor(account);
  });

  Icon = Icon;

  private readonly modalService = inject(ModalService);
  private readonly navigationService = inject(NavigationService);
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly toastService = inject(ToastService);

  onCloseClick(): void {
    this.closeModal();
  }

  closeModal(confirmed = false): void {
    this.modalService.close(confirmed);
  }

  async convertAccount() {
    const account = this.account$$();
    if (Utils.isNil(account)) return;

    // Organisation accounts will be deleted, others can be patched to a different kind (not implemented yet)
    this.accountStoreFacade.dispatchDeleteAccount(account.id);
    const { success } = await firstValueFrom(this.accountStoreFacade.accountDeleted$);
    if (!success) {
      this.toastService.show(GenericErrorToast());
      return;
    }
    await this.navigationService.navigate(['/', 'onboarding', 'account', { kind: this.convertTo(account.kind) }]);
    this.closeModal(true);
  }

  /** Determine what account kind should be created based on the deleted account */
  private convertTo(accountKind: AccountKind): AccountKind | undefined {
    if (accountKind === AccountKind.ORGANISATION) {
      return AccountKind.PERSONAL;
    }
  }
}
