import { Formatter } from '@onyxx/utility/formatter';
import { Signing } from '@onyxx/model/signing';
import { BankCode, bankNames } from '@onyxx/model/bank';

import { LinkedAccountKind } from './linked-account-kind';
import { iLinkedAccount } from './linked-account.interface';

// TODO: replace this model with just the interface and functions
export class LinkedAccount implements iLinkedAccount {
  id?: string;
  kind?: LinkedAccountKind;
  number?: string;
  person?: string;
  active?: boolean;
  bic?: string;
  signing?: Signing;
  account?: string;

  constructor(props?: Partial<iLinkedAccount>) {
    Object.assign(this, props);
  }

  /**
   * Get the bank name from BIC/SWIFT code
   * !TODO: fix the ignores by refactoring
   * Used in `linked-account-detail.form.ts`
   */
  get bankName(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore implicit any
    const bankCode: number = BankCode[this.bic.substring(0, 4)];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore implicit any
    return bankNames[bankCode] ?? bankNames[BankCode.DEFAULT];
  }

  /**
   * Get the formatted IBAN number
   * Used in `linked-account-detail.form.ts`
   */
  get formattedIban(): string {
    return Formatter.formatIban(this.number);
  }
}
