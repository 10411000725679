import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { loadTranslations } from '@angular/localize';
import { HttpClient } from '@angular/common/http';

import { EMPTY, firstValueFrom } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { ConfigStore } from '@semmie/store';
import { SentryService } from '@semmie/services/sentry/sentry.service';

import { environment } from 'environments/environment';
import { Utils } from '@onyxx/utility/general';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  private readonly configStore = inject(ConfigStore);
  private readonly httpClient = inject(HttpClient);
  private readonly translate = inject(TranslateService);
  private readonly sentry = inject(SentryService);
  private readonly LOCALE_ID = inject(LOCALE_ID);

  /**
   * Fetches the external translations if it is enabled in the config.
   *
   * todo: allow clients to 'poll' every _x_ mins down the line to receive the latest translations <- @markpeterfejes please don't do this. Service Workers are way better for handling caching.
   *
   * @param locale string of the locale to be fetched
   */
  initializeExternalTranslations(locale: string) {
    this.configStore.store$
      .pipe(
        filter(Utils.isNotNil),
        map((config) => config.config),
        filter(Utils.isNotNil),
        switchMap((config) => {
          if (config.translations) {
            /**
             * By default the root call will return the 'nl' locale.
             * If you want to request a specific locale, it is also possible to query the desired locale by
             * adding the locale as a path.
             */
            return this.httpClient.get(`${environment.resourcesUrls.i18nLegacy}`, { responseType: 'json' });
          }

          return EMPTY;
        }),
        take(1),
      )
      .subscribe((externalTranslations) => {
        this.translate.setTranslation(locale, externalTranslations, true);
        this.translate.use(locale);
      });
  }

  async initializeAngularI18n() {
    try {
      const [translations, externalTranslations] = await Promise.all([
        this.fetchLocalTranslations(this.LOCALE_ID),
        this.fetchOtaTranslations(this.LOCALE_ID).catch((err) => {
          this.sentry.captureException(err);
          return {};
        }),
      ]);

      // TODO: remove this method when localizely can export a runtime ARB file (without the @/metadata keys)
      this.filterAtKeys(translations);
      loadTranslations(translations);

      if (Utils.isNotNil(externalTranslations)) {
        this.filterAtKeys(externalTranslations);
        loadTranslations(externalTranslations);
      }
    } catch (err) {
      this.sentry.captureException(err);
      console.warn(`Failed to load translation for locale file: ${this.LOCALE_ID}`);
    } finally {
      await this.initializeAngularLocale(this.LOCALE_ID);
    }
  }

  private async initializeAngularLocale(localeId: string) {
    try {
      const localeModule = await import(
        /* webpackMode: "lazy-once", webpackInclude: /(en-GB|en|nl)\.mjs$/, webpackChunkName: "angular-locales" */
        `node_modules/@angular/common/locales/${localeId}.mjs`
      );

      const localeExtraModule = await import(
        /* webpackMode: "lazy-once", webpackInclude: /(en-GB|en|nl)\.mjs$/, webpackChunkName: "angular-locales-extra" */
        `node_modules/@angular/common/locales/extra/${localeId}.mjs`
      );

      return registerLocaleData(localeModule.default, localeId, localeExtraModule.default);
    } catch {
      const dutchLocale = await import(
        /* webpackMode: "lazy-once" */
        'node_modules/@angular/common/locales/nl.mjs'
      );

      const dutchLocaleExtra = await import(
        /* webpackMode: "lazy-once"*/
        'node_modules/@angular/common/locales/extra/nl.mjs'
      );

      return registerLocaleData(dutchLocale.default, localeId, dutchLocaleExtra.default);
    }
  }

  private filterAtKeys(obj: Record<string, unknown>): void {
    for (const key in obj) {
      if (key.startsWith('@') && !key.startsWith('@@')) {
        delete obj[key];
      }
    }
  }

  private fetchLocalTranslations(locale: string) {
    return firstValueFrom(this.fetchTranslations(`assets/i18n/locale-${locale}.arb`));
  }
  private fetchOtaTranslations(locale: string) {
    return firstValueFrom(this.fetchTranslations(`${environment.resourcesUrls.i18n}/locale-${locale}.arb`));
  }

  private fetchTranslations(translationFileLocation: string) {
    return this.httpClient.get<Record<string, string>>(translationFileLocation, { responseType: 'json' });
  }
}
