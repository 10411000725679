<div class="thumbnail-container">
  <div class="thumbnail-container-holder" class="{{ size }}">
    <div *ngIf="title || label" class="image-overlay"></div>
    <div *ngIf="title || label" class="account-info">
      <div class="top-half">
        <semmie-tag tagTheme="dark" [text]="label"></semmie-tag>
      </div>
      <div class="bottom-half">
        <semmie-label *ngIf="title" class="title text-white" type="heading" size="h3" weight="medium" [text]="title"></semmie-label>
      </div>
    </div>
    <semmie-image [src]="image$ | async" objectFit="objectCover"></semmie-image>
  </div>
</div>
