import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Person } from '@semmie/models/bi/person';
import { PersonProvider } from '@semmie/providers/person/person.provider';
import { Utils } from '@semmie/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  person = new BehaviorSubject<Person | null>(null);
  people = new BehaviorSubject<Map<string, Person>>(new Map());

  constructor(private personProvider: PersonProvider) {}

  getPersonById(id?: string, skipCache = false, updateCurrentPerson = true): Observable<Person | undefined | null> {
    if (Utils.isNullOrUndefined(id)) {
      return of(null);
    }

    const cache = this.people?.value?.get(id);

    if (!cache || cache?.id !== id || skipCache) {
      return this.personProvider.get(id).pipe(
        take(1),
        tap((person) => this.updateCache(id, person, updateCurrentPerson)),
        switchMap(() => this.people.pipe(map((peopleMap) => peopleMap.get(id)))),
      );
    } else if (updateCurrentPerson) {
      this.person.next(cache);
    }

    return this.people.pipe(map((peopleMap) => peopleMap.get(id)));
  }

  refresh() {
    const cache = this.person.value;

    if (cache === null) {
      return of(null);
    }

    return this.personProvider.get(cache.id).pipe(
      take(1),
      tap((person) => {
        this.updateCache(cache.id, person);
      }),
      switchMap(() => this.person),
    );
  }

  update(id: string, params: Partial<Person>, updateStore = true) {
    return this.personProvider.update(id, params).pipe(
      tap((person) => {
        /**
         * !dev note: Not ideal. This is here because the backend doesn't store the 'phone' until it is confirmed.
         * !thus we need to think about this in more depth and refactor this later.
         */
        if (updateStore) this.updateCache(id, new Person({ ...person, ...params }));
      }),
    );
  }

  finish(id: string) {
    return this.personProvider.finish(id).pipe(
      filter(Utils.isNonNullOrUndefined),
      tap((person) => this.people.next(this.people.value.set(id, person))),
    );
  }

  /**
   * !todo: this should clear data from PersonStore (once it is available) instead
   */
  clear(): void {
    this.person.next(null);
    this.people.next(new Map());
  }

  private updateCache(id: string, person: Person | null, updateCurrentPerson = true) {
    if (updateCurrentPerson) {
      this.person.next(person);
    }

    if (Utils.isNonNullOrUndefined(person)) {
      this.people.next(this.people.value.set(id, person));
    }
  }
}
