import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { authApiActions } from './auth-api.actions';
import { authFeature, selectIsSessionExpired } from './auth.reducer';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { authCommonActions } from './auth-common.actions';
import { SecureApplicationOptions } from './auth-store-config.interface';

@Injectable()
export class AuthFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  /**
   * The session token
   *
   * The first emission will be done once the session has been loaded from storage
   */
  readonly token$ = this.store.select(authFeature.selectToken).pipe(
    filter(({ loaded }) => loaded),
    // eslint-disable-next-line @ngrx/avoid-mapping-selectors
    map(({ token }) => token),
  );

  /**
   * Whether the user is authenticated
   */
  readonly isAuthenticated$ = this.token$.pipe(
    map((token) => !!token),
    distinctUntilChanged(),
  );

  /**
   * Event emitted when the user is logged out
   */
  readonly loggedOut$ = this.actions$.pipe(
    ofType(authCommonActions.logOutDone),
    map(() => void 0 as void),
  );

  readonly sessionExpired$ = selectIsSessionExpired(this.store.select(authFeature.selectSession));

  readonly ready$ = this.store.select(authFeature.selectSessionLoaded).pipe(filter((loaded) => loaded));

  readonly loginBusy$ = this.store.select(authFeature.selectLoginBusy);
  readonly registerBusy$ = this.store.select(authFeature.selectRegisterAndLoginBusy);
  readonly requestPasswordResetBusy$ = this.store.select(authFeature.selectRequestPasswordResetBusy);
  readonly requestPasswordResetSuccess$ = this.actions$.pipe(ofType(authApiActions.requestPasswordResetSuccess));
  readonly setPasswordBusy$ = this.store.select(authFeature.selectSetPasswordAndLoginBusy);

  /** When app secure checks have passed */
  readonly appSecured$ = this.store.select(authFeature.selectAppSecured);

  /** The app secure is initializing. When false the app is either secure or
   * the appropriate security modal has been shown */
  readonly appSecureInitializing$ = this.store.select(authFeature.selectAppSecureInitializing);

  readonly twoFactorSmsNumber$ = this.store.select(authFeature.selectTwoFactorSmsNumber);
  readonly twoFactorDetailsAvailable$ = this.store.select(authFeature.selectTwoFactorDetailsAvailable);
  readonly twoFactorResendAllowedAt$ = this.store.select(authFeature.selectTwoFactorResendAllowedAt);
  readonly loginFailure$ = this.actions$.pipe(ofType(authApiActions.loginFailure));

  dispatchInitialize() {
    this.store.dispatch(authCommonActions.initialize());
  }

  dispatchRefreshToken() {
    this.store.dispatch(authApiActions.refreshToken());
  }

  dispatchLogin(userDetails: { username: string; password: string }) {
    this.store.dispatch(authApiActions.login(userDetails));
  }

  dispatchTwoFactorLogin(code: string) {
    this.store.dispatch(authApiActions.twoFactorLogin({ code }));
  }

  dispatchSendTwoFactorSms() {
    this.store.dispatch(authApiActions.twoFactorSendCode());
  }

  dispatchRegister(userDetails: { name: string; email: string; password: string }) {
    this.store.dispatch(authApiActions.register(userDetails));
  }

  dispatchRequestPasswordReset(email: string) {
    this.store.dispatch(authApiActions.requestPasswordReset({ email }));
  }

  dispatchSetPassword(passwordDetails: { email: string; password: string; token: string }) {
    this.store.dispatch(authApiActions.setPassword(passwordDetails));
  }

  dispatchManualTokenRefresh(tokenRefreshDetails: { token: string; redirectAfterLogin: string[] }) {
    this.store.dispatch(authCommonActions.manualTokenRefresh(tokenRefreshDetails));
  }

  dispatchLogOut() {
    this.store.dispatch(authCommonActions.logOut({ skipNavigation: false }));
  }

  dispatchClearToken() {
    this.store.dispatch(authCommonActions.logOut({ skipNavigation: true }));
  }

  dispatchUnlockUser(token: string) {
    this.store.dispatch(authApiActions.unlockUser({ token }));
  }

  dispatchSecureApplication(options: Partial<SecureApplicationOptions> = { strict: false, skipNavigation: false }) {
    this.store.dispatch(
      authCommonActions.secureApplication({
        strict: false,
        skipNavigation: false,
        ...options,
      }),
    );
  }
}
