import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  selector: 'semmie-progress-bar',
  template: `<div class="progress-bar">
    <div class="progress-bar--inner" [style.width.%]="width"></div>
  </div> `,
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule],
})
export class ProgressBarComponent extends BaseComponent {
  @Input() width = 0;
}
