import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IonDatetime, IonModal } from '@ionic/angular';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { iDateFormField } from '@semmie/schemas/components/dynamic-form';
import { MODAL_SIZE, ModalSize } from '@semmie/schemas/components/modal';
import { ModalService } from '@semmie/services/modal/modal.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Utils } from '@semmie/shared/utils';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { dateMask } from './date.mask';

export interface iDatePickerMinMax {
  type: 'add' | 'substract';
  days?: number;
  months?: number;
  years?: number;
}
@Component({
  selector: 'semmie-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @ViewChild(IonDatetime) datetime: IonDatetime;
  @ViewChild(IonModal) modal: IonModal;

  @Input() label?: string;
  @Input() max: string;
  @Input() min: string;
  @Input() placeholder?: string;
  @Input() locale: string = this.localeId;

  @Input() set inputMode(value: iDateFormField['inputMode'] | null) {
    if (value) {
      this._inputMode = value;
    }
  }

  get inputMode() {
    return this._inputMode;
  }

  @Input() set minFromToday(value: iDatePickerMinMax) {
    const date = this.calculateMinMaxDate(value);

    if (date) {
      this.min = date.toISOString();
    }
  }

  @Input() set maxFromToday(value: iDatePickerMinMax) {
    const date = this.calculateMinMaxDate(value);

    if (date) {
      this.max = date.toISOString();
    }
  }

  @Input() set presentation(value: IonDatetime['presentation'] | null) {
    if (value) {
      this._presentation = value;
    }
  }

  get presentation() {
    return this._presentation;
  }

  @Input() set displayFormat(displayFormat: string | undefined) {
    if (displayFormat) {
      this._displayFormat = displayFormat;
    }
  }

  get displayFormat() {
    return this._displayFormat;
  }

  formattedValue: string;

  isApp = this.platformService.isApp;
  datePickerWebBreakpoints = MODAL_SIZE[ModalSize.Auto];

  readonly maskitoOptions = dateMask();

  private _inputMode: iDateFormField['inputMode'] = 'text';

  // datepicker should receive dd-MM-YYYY format
  private _displayFormat = 'DD-MM-YYYY';

  private _presentation: IonDatetime['presentation'] = 'date';

  private destroy$ = new Subject<void>();

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private platformService: PlatformService,
    private modalService: ModalService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.value) {
      this.setFormattedValue(this.value, false);
    }

    this.formControl.valueChanges?.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((value) => {
      if (this.datetime && value) {
        this.datetime.reset(new Date(value).toISOString());
      }
      this.setFormattedValue(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectDate(value?: string | string[] | null): void {
    this.value = value;

    if (!this.isApp) {
      this.modalService.close(this.value);
    }
  }

  onValueChange(event: KeyboardEvent) {
    const value = (event?.target as HTMLInputElement)?.value;
    const date = moment(value, 'DD-MM-YYYY', true);
    this.value = value && date.isValid() ? date.format('YYYY-MM-DD') : '';
  }

  private calculateMinMaxDate(value: iDatePickerMinMax): moment.Moment | undefined {
    if (!value) return;
    const date = moment();
    if (Utils.isNonNullOrUndefined(value.days)) value.type === 'add' ? date.add(value.days, 'days') : date.subtract(value.days, 'days');
    if (Utils.isNonNullOrUndefined(value.months))
      value.type === 'add' ? date.add(value?.months, 'months') : date.subtract(value?.months, 'months');
    if (Utils.isNonNullOrUndefined(value.years))
      value.type === 'add' ? date.add(value?.years, 'years') : date.subtract(value?.years, 'years');
    return date;
  }

  private setFormattedValue(value: string, strict = true) {
    const isValid = moment(value, this.displayFormat, true).isValid();
    if (!strict || (strict && isValid)) {
      this.formattedValue = moment(value).format(this.displayFormat);
    }
  }
}
