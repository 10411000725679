import type { MaskitoOptions } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';

const SEPARATOR = '-';

const defaultDateOptions = maskitoDateOptionsGenerator({
  mode: 'dd/mm/yyyy',
  separator: SEPARATOR,
});

export const dateMask = (): Required<MaskitoOptions> => {
  return {
    ...defaultDateOptions,
    preprocessors: [
      ...defaultDateOptions.preprocessors,
      ({ data, elementState }, actionType) => {
        if (actionType === 'insert') {
          // insert a `-` automatically after the day or month
          if (elementState.value.length === 2 || elementState.value.length === 5) {
            return {
              data: data + SEPARATOR,
              elementState,
            };
          }
        }
        return { data, elementState };
      },
    ],
  };
};
