import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { biometricsFeature } from './biometrics.reducer';
import { biometricsCommonActions } from './biometrics-common.actions';
import { filterNil } from '@onyxx/utility/observables';
import { map } from 'rxjs';

@Injectable()
export class BiometricsStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  readonly enabled$ = this.store.select(biometricsFeature.selectEnabled).pipe(filterNil());
  readonly permissionRequestBusy$ = this.store.select(biometricsFeature.selectPermissionRequestBusy);
  readonly authenticationBusy$ = this.store.select(biometricsFeature.selectAuthenticationBusy);

  readonly availability$ = this.store.select(biometricsFeature.selectAvailability).pipe(filterNil());

  readonly authenticationDoneEvent$ = this.actions$.pipe(
    ofType(biometricsCommonActions.authenticateSuccess, biometricsCommonActions.authenticateFailed),
    map((event) => ({
      success: event.type === biometricsCommonActions.authenticateSuccess.type,
    })),
  );

  readonly enableDoneEvent$ = this.actions$.pipe(
    ofType(biometricsCommonActions.enableSuccess, biometricsCommonActions.enableFailure, biometricsCommonActions.enablePermissionFailure),
    map((event) => ({
      success: event.type === biometricsCommonActions.enableSuccess.type,
      permissionDenied: event.type === biometricsCommonActions.enablePermissionFailure.type,
    })),
  );

  dispatchAuthenticate() {
    this.store.dispatch(biometricsCommonActions.authenticate());
  }

  dispatchEnable() {
    this.store.dispatch(biometricsCommonActions.enable());
  }

  dispatchDisable() {
    this.store.dispatch(biometricsCommonActions.disable());
  }
}
