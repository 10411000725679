import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';

import { TileComponent } from './tile.component';

@NgModule({
  declarations: [TileComponent],
  imports: [CommonModule, LabelModule, ImageModule],
  exports: [TileComponent],
})
export class TileModule {}
