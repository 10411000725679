import { NgModule } from '@angular/core';

import { IntroContentModule } from '@semmie/components/containers/intro-content/intro-content.module';
import { ModalHeaderModule } from '@semmie/components/containers/modals/modal-header/modal-header.component';
import { PageHeaderModule } from '@semmie/components/containers/page-header/page-header.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

import { SharedModule } from '@semmie/shared/shared.module';

import { InfoModalComponent } from './info-modal.component';
import { ModalStopScrollGestureOverlapDirective } from '@semmie/directives/modal-stop-scroll-gesture-overlap.directive';

@NgModule({
  imports: [SharedModule, IconComponent, PageHeaderModule, IntroContentModule, ModalHeaderModule, ModalStopScrollGestureOverlapDirective],
  exports: [InfoModalComponent],
  declarations: [InfoModalComponent],
})
export class InfoModalModule {}
