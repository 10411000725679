import { NgModule } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { accountFeature } from './account.reducer';
import { AccountStoreFacade } from './account.facade';
import { AccountEffects } from './account.effects';

export const providers = [provideState(accountFeature), provideEffects(AccountEffects), AccountStoreFacade];

@NgModule({ providers })
export class AccountStateModule {}

export * from './account.facade';

// TODO: remove this export once the accounts service does not need it any more
export { ACCOUNT_CACHE_TIMEOUT } from './account.effects';
