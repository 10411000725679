import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { NotificationMessage, NotificationMessagesResponse } from '@onyxx/model/notifications';

export const NotificationsAPIActions = createActionGroup({
  source: 'Notifications API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ notifications: NotificationMessagesResponse<NotificationMessage> }>(),
    'Load Failure': props<{ errorMsg: string }>(),

    MarkAsRead: props<{ notificationIds: string[] }>(),
    'MarkAsRead success': props<{ notificationIds: string[]; unread_count: number }>(),
    'MarkAsRead failure': props<{ errorMsg: string }>(),

    MarkAllAsRead: emptyProps(),
    'MarkAllAsRead success': props<{ unread_count: number }>(),
    'MarkAllAsRead failure': props<{ errorMsg: string }>(),

    UpdateUnreadCount: props<{ unread_count: number }>(),
  },
});
