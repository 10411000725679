import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { iUserCredentialsPayload, iUser, User } from '@onyxx/model/user';

export type FailureMessage = {
  error: Error;
};

export const UserAPIActions = createActionGroup({
  source: 'User API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ user: User }>(),
    'Load Failure': props<FailureMessage>(),

    'Update Credentials': props<{ payload: iUserCredentialsPayload }>(),
    'Update Credentials Success': props<{ payload: iUserCredentialsPayload }>(),
    'Update Credentials Failure': props<FailureMessage>(),

    Update: props<{ user: Partial<iUser> }>(),
    'Update Metadata': props<{ newData: object }>(),
    'Update Success': props<{ user: User }>(),
    'Update Failure': props<FailureMessage>(),

    'Local Update': props<{ user: Partial<iUser> }>(),

    'Send Two Factor Authentication Code': emptyProps(),
    'Send Two Factor Authentication Success': emptyProps(),
    'Send Two Factor Authentication Failure': props<FailureMessage>(),
    'Update Two Factor Authentication': props<{ enabled: true; code: string } | { enabled: false }>(),
    'Update Two Factor Authentication Success': props<{ enabled: boolean }>(),
    'Update Two Factor Authentication Failure': props<FailureMessage>(),
  },
});
