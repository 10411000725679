export const belgiumBanks = {
  BELF: [
    [50, 99],
    [550, 560],
    [562, 569],
    [624, 625],
    [638, 638],
    [657, 657],
    [672, 672],
    [680, 680],
    [775, 799],
    [830, 839],
  ],
  BNPB: [
    [1, 49],
    [137, 137],
    [140, 149],
    [200, 214],
    [220, 298],
    [508, 508],
  ],
  BPOST: [
    [0, 0],
    [299, 299],
    [679, 679],
    [873, 873],
    [981, 984],
    [985, 988],
  ],
  CENT: [],
  CITI: [[570, 579]],
  FINT: [],
  KBCB: [
    [400, 499],
    [640, 640],
    [725, 727],
    [730, 731],
    [733, 741],
    [743, 749],
    [868, 868],
  ],
} as const;
