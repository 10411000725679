import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function pattern(regex: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    if (!regex.test(value)) {
      return new ValidatorMessage({
        code: 9,
        message: 'Gevraagde indeling komt niet overeen',
      });
    }

    return null;
  };
}
