import { formatCurrency } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

type DigitsInfo = '1.2-2' | '1.0-0' | 'decimalsIfAvailable' | null | undefined;
type CurrencyFormat = '1.2-2' | '1.0-0';

@Pipe({
  name: 'semmieCurrency',
})
export class SemmieCurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
  ) {}

  transform(
    value: number | string | null = 0,
    showPositiveNotation = false,
    digitsInfo?: DigitsInfo,
    currencySymbol?: string,
    currencyCode?: string,
  ) {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (value === null || isNaN(value)) {
      value = 0;
    }

    let prefix = '';
    const isNegative = value < 0;
    const positiveSymbol = showPositiveNotation ? '+' : '';
    const hasDecimals = !Number.isInteger(value);

    value = Math.abs(value);

    if (value !== 0) {
      prefix = isNegative ? '-' : positiveSymbol;
    }

    const formattedCurrency = formatCurrency(
      value,
      this.locale,
      currencySymbol || '€',
      currencyCode || this.defaultCurrencyCode,
      this.currencyFormat(digitsInfo, hasDecimals),
    );

    return `${prefix}${formattedCurrency}`;
  }

  private currencyFormat(digitsInfo: DigitsInfo, hasDecimals: boolean): CurrencyFormat {
    switch (digitsInfo) {
      case '1.0-0':
        return '1.0-0';
      case '1.2-2':
        return '1.2-2';
      case 'decimalsIfAvailable':
        return hasDecimals ? '1.2-2' : '1.0-0';
      default:
        return '1.2-2';
    }
  }
}
