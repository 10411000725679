export const openUrl = (url: string, options?: { newTab: boolean }) => {
  if (options?.newTab) {
    const handle = window.open(url, '_blank');
    if (!handle) {
      /**
       * !dev note: Fallback if window didn't open -> open in same window
       * e.g. when popups are blocked or popups are disabled in ios safari settings
       */
      window.location.href = url;
    }
    return;
  }

  window.location.href = url;
  return;
};
