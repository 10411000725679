import { ChangeDetectorRef, Component, Host, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgControl, ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { DynamicField, DynamicFieldType, GroupLayout } from '@semmie/schemas/components/dynamic-form';
import { FormComponent } from '@semmie/components/containers/form/form.component';
import { FormService } from '@semmie/services/form/form.service';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { Utils } from '@semmie/shared/utils';

@Component({
  selector: 'semmie-form-group',
  template: `
    <semmie-card *ngIf="showAsCard(field)" [cardStyle]="interpolateFieldProperty('cardStyle') ?? field.cardStyle">
      <ng-container *ngTemplateOutlet="formTpl; context: { sideSpacing: false }"></ng-container>
    </semmie-card>
    <ng-container *ngIf="!showAsCard(field)">
      <ng-container *ngTemplateOutlet="formTpl; context: { sideSpacing: enableSideSpacing }"></ng-container>
    </ng-container>

    <ng-template #formTpl let-sideSpacing="sideSpacing">
      <ng-container *ngIf="field.repeat">
        <ng-container *ngTemplateOutlet="groupTpl; context: { sideSpacing: sideSpacing }"></ng-container>
      </ng-container>

      <ng-container *ngIf="!field.repeat">
        <form [formGroup]="form">
          <ng-container [ngSwitch]="field.nested">
            <semmie-form-input
              *ngSwitchCase="true"
              [error]="getFormControlErrors(field)"
              [form]="form"
              [field]="field"
              [group]="field"
              [ngStyle]="field.styles"
              [classes]="field.classes"
              [enableSideSpacing]="sideSpacing"
              [$meta]="$meta"
            ></semmie-form-input>
            <semmie-form-input
              *ngSwitchDefault
              [error]="getFormControlErrors(field)"
              [form]="form"
              [field]="field"
              [ngStyle]="field.styles"
              [classes]="field.classes"
              [enableSideSpacing]="sideSpacing"
              [$meta]="$meta"
            ></semmie-form-input>
          </ng-container>

          <ng-container *ngIf="field.layout !== 'toggle'">
            <ng-container *ngTemplateOutlet="defaultGroup; context: { sideSpacing: sideSpacing }"></ng-container>
          </ng-container>
          <ng-container *ngIf="field.layout === 'toggle'">
            <ng-container *ngIf="getFormControl(field)?.value">
              <ng-container *ngTemplateOutlet="defaultGroup; context: { sideSpacing: false }"></ng-container>
            </ng-container>
          </ng-container>
        </form>
      </ng-container>
    </ng-template>

    <ng-template #groupTpl let-sideSpacing="sideSpacing">
      <form [formGroup]="form">
        <ng-container *ngIf="!field.repeat">
          <ng-container *ngTemplateOutlet="defaultGroup; context: { sideSpacing: sideSpacing }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!!field.repeat">
          <ng-container [formArrayName]="field.name">
            <ng-container *ngFor="let groupArray of getFormArray(field).controls; let i = index">
              <ng-container *ngFor="let groupField of field.fields">
                <div [formGroupName]="i">
                  <semmie-form-input
                    [error]="getFormControlErrors(field)"
                    [control]="getFormControlByIndex(field, groupField, i)"
                    [index]="i"
                    [form]="form"
                    [field]="groupField"
                    [group]="field"
                    [ngStyle]="groupField.styles"
                    [classes]="groupField.classes"
                    [enableSideSpacing]="sideSpacing"
                    [$meta]="$meta"
                  ></semmie-form-input>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </form>
    </ng-template>

    <ng-template #defaultGroup let-sideSpacing="sideSpacing">
      <ng-container *ngFor="let groupField of field.fields">
        <semmie-form-input
          [error]="getFormControlErrors(field)"
          [form]="form"
          [field]="groupField"
          [group]="field"
          [ngStyle]="groupField.styles"
          [classes]="groupField.classes"
          [enableSideSpacing]="sideSpacing"
          [$meta]="$meta"
        ></semmie-form-input>
      </ng-container>
    </ng-template>
  `,
  styleUrls: [],
})
export class FormGroupComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @Input() field: DynamicField;
  @Input() form: UntypedFormGroup;
  @Input() enableSideSpacing = false;
  @Input() $meta: Record<string, any> = {};

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    @Optional() @Host() public formComponent: FormComponent,
    @Optional() @Self() ngControl: NgControl,
    private cdr: ChangeDetectorRef,
    private formService: FormService,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.field.type === DynamicFieldType.Group && this.field.layout === GroupLayout.Toggle) {
      this.bindGroupToggleEvents();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getFormArray(field: DynamicField) {
    return this.formService.getFormArray(field, this.form);
  }

  getFormControl(field: DynamicField): UntypedFormControl | null {
    return this.formService.getFormControl(field, this.form);
  }

  getFormControlErrors(field: DynamicField): ValidationErrors | null | undefined {
    return this.formService.getFormControl(field, this.form)?.errors;
  }

  getFormControlByIndex(groupField: DynamicField, field: DynamicField, index: number): UntypedFormControl {
    return this.formService.getFormControlByIndex(groupField, field, this.form, index);
  }

  showAsCard(field: DynamicField): boolean {
    return Utils.isNonNullOrUndefined(field.cardStyle);
  }

  /**
   * Translate and interpolate the field property.
   *
   * @param property property to interpolate
   * @returns translated or interpolated property
   */
  interpolateFieldProperty(property: string) {
    return this.formService.interpolateFieldProperty(property, this.field, {
      ...(this.formComponent?.data ?? {}),
      ...this.form.getRawValue(),
      $meta: this.$meta,
    });
  }

  /**
   * When the group toggle is disabled, reset all child fields
   *
   * !important: ONLY APPLICABLE TO TOGGLES GROUPS
   * !todo: refactor this behavior
   */
  private bindGroupToggleEvents() {
    this.getFormControl(this.field)
      ?.valueChanges.pipe(startWith(this.value), takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value === false) {
          if (this.field?.fields?.length) {
            this.field?.fields?.forEach((f) => {
              const ctrl = this.formService.getFormControl(f, this.form, this.field);

              if (!this.formService.isPersistentDataField(f) && Utils.isNonNullOrUndefined(ctrl)) {
                ctrl.setValue(null, { onlySelf: true, emitEvent: false });
              }
            });
          }
        }

        this.cdr.detectChanges();
      });
  }
}
