<div class="input-container group">
  <ng-container [ngSwitch]="inputMode">
    <ng-container *ngSwitchCase="'inline'">
      <ng-container *ngTemplateOutlet="datetimeTpl"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'picker'">
      <semmie-form-input-label (click)="modal.present()" [label]="label"></semmie-form-input-label>

      <div class="input-wrapper">
        <ion-datetime-button #datepickerButton [disabled]="disabled" [datetime]="id" class="date-button">
          <ng-container *ngIf="displayFormat && value; else placeholderTpl">
            <span class="text-lg" slot="date-target">{{ value | date: displayFormat | titlecase }}</span>
          </ng-container>
          <ng-template #placeholderTpl>
            <span class="placeholder" slot="date-target">{{ placeholder ?? label ?? '' | translate }}</span>
          </ng-template>
        </ion-datetime-button>

        <ng-container *ngIf="!isApp; else appModal">
          <ion-modal
            #modal
            class="auto-size-modal"
            [initialBreakpoint]="datePickerWebBreakpoints['initialBreakpoint']"
            [breakpoints]="datePickerWebBreakpoints['breakpoints']"
            [showBackdrop]="true"
            handle="false"
            [keepContentsMounted]="true"
          >
            <ng-template>
              <semmie-modal-header (onCloseClick)="modal.dismiss()"></semmie-modal-header>
              <ng-container *ngTemplateOutlet="datetimeTpl"></ng-container>
            </ng-template>
          </ion-modal>
        </ng-container>

        <ng-template #appModal>
          <ion-modal
            #modal
            class="auto-size-modal is-mobile"
            [initialBreakpoint]="datePickerWebBreakpoints['initialBreakpoint']"
            [breakpoints]="datePickerWebBreakpoints['breakpoints']"
            [showBackdrop]="true"
            [keepContentsMounted]="true"
            handle="false"
          >
            <ng-template>
              <semmie-modal-header (onCloseClick)="modal.dismiss()"></semmie-modal-header>
              <ng-container *ngTemplateOutlet="datetimeTpl"></ng-container>
            </ng-template>
          </ion-modal>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <semmie-form-input-label [formFieldId]="id" [label]="label"></semmie-form-input-label>
      <div class="input-wrapper">
        <input
          #inputRef
          [id]="id"
          [disabled]="disabled"
          [ngModel]="formattedValue"
          (keyup)="onValueChange($event)"
          [placeholder]="placeholder ?? label ?? '' | translate"
          type="text"
          inputmode="numeric"
          [maskito]="maskitoOptions"
        />
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #datetimeTpl>
  <ion-datetime
    #datetimeRef
    class="!w-full"
    [class.inline-picker]="!isApp"
    [presentation]="presentation"
    [id]="id"
    [max]="max"
    [min]="min"
    [value]="value"
    [locale]="locale"
    (ionChange)="isApp ? selectDate(datetimeRef.value) : null"
  ></ion-datetime>
  <ng-container *ngIf="!isApp">
    <div class="mx-4 flex justify-center pb-4 lg:pb-0">
      <semmie-button (onClick)="selectDate(datetimeRef.value)">{{ 'core.common.labels.select' | translate }}</semmie-button>
    </div>
  </ng-container>
</ng-template>
