import { ApplicationRef, Injectable, OnDestroy, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ToasterStyle } from '@semmie/schemas/components/toast';
import { ToastService } from '@semmie/services';
import { Subject, concat, filter, first, interval, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NgswUpdateService implements OnDestroy {
  private readonly appRef = inject(ApplicationRef);
  private readonly updates = inject(SwUpdate);
  private readonly toasterService = inject(ToastService);

  private readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkForUpdates() {
    if (!this.updates.isEnabled) return;

    this.updates.versionUpdates
      .pipe(
        takeUntil(this.destroy$),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(() => {
        this.showUpdateAvailableToast();
      });

    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      try {
        const updateFound = await this.updates.checkForUpdate();
        if (!updateFound) return;
        this.showUpdateAvailableToast();
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }

  showUpdateAvailableToast() {
    this.toasterService.show({
      header: $localize`:@@ngsw-update.toaster.header:Update available`,
      message: $localize`:@@ngsw-update.toaster.message: A new version of the application is available. Refreshing the page will update it.`,
      style: ToasterStyle.WARNING,
      duration: undefined,
      hideCloseIcon: true,
      clearToasters: true,
      buttons: [
        {
          icon: 'refresh-outline',
          role: 'cancel',
          handler: () => {
            document.location.reload();
          },
        },
      ],
    });
  }
}
