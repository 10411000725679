import { inject } from '@angular/core';

import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap } from 'rxjs/operators';

import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';

import { NotificationMessagesProvider } from '@onyxx/provider/notifications';

import { NotificationsAPIActions } from './notifications-api.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { NotificationsCommonActions } from './notifications-common.actions';

export class NotificationsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly notificationMessagesProvider = inject(NotificationMessagesProvider);
  private readonly authFacade = inject(AuthFacade);

  readonly load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsAPIActions.load),
      switchMap(() => this.authFacade.isAuthenticated$.pipe(filter(Boolean))),
      exhaustMap(() =>
        this.notificationMessagesProvider.list().pipe(
          map((notifications) => NotificationsAPIActions.loadSuccess({ notifications })),
          catchError((err) => of(NotificationsAPIActions.loadFailure({ errorMsg: err.message }))),
        ),
      ),
    );
  });

  readonly markAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsAPIActions.markAsRead),
      concatMap(({ notificationIds }) =>
        this.notificationMessagesProvider.markAsRead(notificationIds).pipe(
          map((response) => NotificationsAPIActions.markAsReadSuccess({ notificationIds, unread_count: response.unread_count })),
          catchError(({ message }: Error) => of(NotificationsAPIActions.markAsReadFailure({ errorMsg: message }))),
        ),
      ),
    );
  });

  readonly markAllAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsAPIActions.markAllAsRead),
      concatMap(() =>
        this.notificationMessagesProvider.markAllAsRead().pipe(
          map((response) => NotificationsAPIActions.markAllAsReadSuccess({ unread_count: response.unread_count })),
          catchError(({ message }: Error) => of(NotificationsAPIActions.markAllAsReadFailure({ errorMsg: message }))),
        ),
      ),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => NotificationsCommonActions.clear()));
  });

  ngrxOnInitEffects() {
    return NotificationsAPIActions.load();
  }
}
