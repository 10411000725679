import { InvitationState } from '@semmie/models/bi/invitation/invitation-state';
import { InvitationStatus } from '@semmie/models/bi/invitation/invitation-status';
import { BaseModel } from '@semmie/models/_abstract';
import { iInvitation, PersonRoleType } from '@semmie/schemas';
import { iInvitationAccount } from '@semmie/schemas/bi/invitation/invitation';

export class Invitation extends BaseModel<iInvitation> implements iInvitation {
  id: string;
  state: InvitationState;
  step: number;
  steps: number;
  child: string;
  parent: string;
  status: InvitationStatus;
  token: string;
  recipient_name: string;
  recipient_email: string;
  from: string;
  to: string;
  account: iInvitationAccount;
  role: PersonRoleType;

  constructor(props?: Partial<iInvitation>) {
    super(props);
  }

  get declined(): boolean {
    return [InvitationStatus.Declined, InvitationStatus.Revoked].includes(this.status);
  }

  get completed(): boolean {
    return [InvitationState.Finished, InvitationState.Active].includes(this.state);
  }

  get title(): string {
    return this.account?.title ?? '';
  }

  hasEmailInvitation(): boolean {
    return this.recipient_email !== '';
  }

  hasQrCodeInvitation(): boolean {
    return this.token !== '';
  }

  hasInvitationBeenAccepted(): boolean {
    if (this.hasOpenInvitation()) return false;
    return this.state !== 'unopened';
  }

  hasOpenInvitation(): boolean {
    if (!this.hasEmailInvitation() && this.hasQrCodeInvitation()) {
      return ['unopened'].includes(this.status);
    }

    return !this.declined && !this.completed;
  }

  canBeRevoked() {
    return this.state === InvitationState.Unopened;
  }

  hasBeenDeclined() {
    return this.status === InvitationStatus.Declined;
  }

  get party(): string {
    return this.to ?? this.recipient_name ?? this.child;
  }
}
