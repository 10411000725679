import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';

export function initSentry(
  dsn: string,
  name: string,
  version = 'development',
  environment = 'development',
  buildNumber = '0',
  dist: string,
) {
  Sentry.init(
    {
      dsn,
      release: `${name}@${version}+${buildNumber}`,
      environment,
      dist: dist,
    },
    SentryAngular.init,
  );
}
