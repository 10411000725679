import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { directDebitsApiActions } from './direct-debits-api.actions';
import { directDebitsCommonActions } from './direct-debits-common.actions';
import { Utils } from '@onyxx/utility/general';
import { iDirectDebit } from '@semmie/schemas/bi/payment';

export interface State {
  currentDirectDebits: {
    accountId: string;
    directDebits: iDirectDebit[];
  } | null;
}

const initialState: State = { currentDirectDebits: null };

const reducer = createReducer(
  initialState,
  on(directDebitsApiActions.load, (state): State => ({ ...state })),
  on(
    directDebitsApiActions.loadSuccess,
    (_, action): State => ({ currentDirectDebits: { directDebits: action.directDebits, accountId: action.accountId } }),
  ),
  on(directDebitsApiActions.loadFailure, (state): State => ({ ...state })),

  on(directDebitsCommonActions.updateDirectDebitsInStore, (state, action): State => {
    // only update if the current directDebits is that same
    if (state.currentDirectDebits?.accountId !== action.accountId) {
      return state;
    }
    return { ...state, currentDirectDebits: { ...state.currentDirectDebits, directDebits: action.directDebits } };
  }),

  on(directDebitsCommonActions.clear, (): State => initialState),
);

export const directDebitsFeature = createFeature({
  name: 'direct-debits',
  reducer,
  extraSelectors: ({ selectCurrentDirectDebits }) => ({
    selectDirectDebits: createSelector(selectCurrentDirectDebits, (directDebits) => {
      if (Utils.isNil(directDebits)) return false;
      return directDebits.directDebits;
    }),
  }),
});
