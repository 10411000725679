import { Injectable, Injector, inject } from '@angular/core';

import { combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Account, AccountKind, AccountHelpers } from '@onyxx/model/account';
import { AccountsService } from '@semmie/services';
import { BaseOnboardingService } from '@semmie/services/onboarding/__abstract/base-onboarding.service';
import { AnnuityOnboardingService } from '@semmie/services/onboarding/onboarding-annuity.service';
import { ChildOnboardingService } from '@semmie/services/onboarding/onboarding-child.service';
import { GrandparentOnboardingService } from '@semmie/services/onboarding/onboarding-grandparent.service';
import { OrganisationOnboardingService } from '@semmie/services/onboarding/onboarding-organisation.service';
import { PersonalOnboardingService } from '@semmie/services/onboarding/onboarding-personal.service';
import { SharedOnboardingService } from '@semmie/services/onboarding/onboarding-shared.service';
import { PersonService } from '@semmie/services/person/person.service';
import { UserStoreFacade } from '@semmie/store/user';
import { Utils } from '@onyxx/utility/general';
import { AccountAdditionalDataStoreFacade } from '@onyxx/store/account-additional-data';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  readonly accountAdditionalDataStoreFacade = inject(AccountAdditionalDataStoreFacade);

  injectedOnboarding: BaseOnboardingService | null = null;
  constructor(
    private injector: Injector,
    private accountsService: AccountsService,
    private personService: PersonService,
    private userFacade: UserStoreFacade,
  ) {}

  check(account: Account): void {
    combineLatest([this.userFacade.user$, this.userFacade.isAdvisor$])
      .pipe(
        switchMap(([user, isAdvisorUser]) =>
          combineLatest([
            of(account),
            isAdvisorUser ? this.personService.person.asObservable() : this.personService.getPersonById(user?.person?.id, false, false),
            of(user),
            this.accountsService.getAccountGoal(account?.id),
            of(isAdvisorUser),
            this.accountAdditionalDataStoreFacade.selectById(account.id),
          ]),
        ),
        switchMap(([account, person, user, goal, isAdvisorUser, additionalData]) => {
          /** Dev note: Refreshing while in organisation onboarding via Advisor portal the person get's undefined  */
          if (isAdvisorUser && AccountHelpers.isOrganisation(account) && Utils.isNil(person)) {
            const representative = AccountHelpers.representatives(account).find((p) => p.position === 1);
            return this.personService
              .getPersonById(representative?.id, false, false)
              .pipe(map((person) => [account, person, user, goal] as const));
          }
          return of([account, person, user, goal, additionalData] as const);
        }),
        take(1),
      )
      .subscribe(([account, person, user, goal, additionalData]) => {
        switch (AccountHelpers.kindByRole(account)) {
          case AccountKind.PERSONAL:
            this.injectedOnboarding = this.injector.get(PersonalOnboardingService, null);
            break;
          case AccountKind.SHARED:
            this.injectedOnboarding = this.injector.get(SharedOnboardingService, null);
            break;
          case AccountKind.CHILD:
            this.injectedOnboarding = this.injector.get(ChildOnboardingService, null);
            break;
          case AccountKind.GRANDPARENT:
            this.injectedOnboarding = this.injector.get(GrandparentOnboardingService, null);
            break;
          case AccountKind.ANNUITY:
          case AccountKind.ANNUITY_PAYOUT_LONG:
          case AccountKind.ANNUITY_PAYOUT_SHORT:
            this.injectedOnboarding = this.injector.get(AnnuityOnboardingService, null);
            break;
          case AccountKind.ORGANISATION:
            this.injectedOnboarding = this.injector.get(OrganisationOnboardingService, null);
            break;
        }

        if (this.injectedOnboarding) {
          this.injectedOnboarding.initialize(
            account,
            person ?? undefined,
            user ?? undefined,
            goal ?? undefined,
            additionalData ?? undefined,
          );
        }
      });
  }
}
