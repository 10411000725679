import { inject } from '@angular/core';

import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap } from 'rxjs/operators';

import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';

import { HighlightsProvider } from '@onyxx/provider/highlights';

import { HighlightsAPIActions } from './highlights-api.actions';
import { highlightsCommonActions } from './highlights-common.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { ConfigService } from '@semmie/services';

export class HighlightsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly highlightsProvider = inject(HighlightsProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly configService = inject(ConfigService);

  private readonly highlightsEnabled$ = combineLatest([this.authFacade.isAuthenticated$, this.configService.config$]).pipe(
    map(([isAuthenticated, c]) => isAuthenticated && c.config.features.highlights.enabled),
  );

  readonly load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HighlightsAPIActions.load),
      switchMap(() => this.highlightsEnabled$),
      filter(Boolean),
      exhaustMap(() =>
        this.highlightsProvider.list().pipe(
          map((highlights) => HighlightsAPIActions.loadSuccess({ highlights })),
          catchError((err) => of(HighlightsAPIActions.loadFailure({ errorMsg: err.message }))),
        ),
      ),
    );
  });

  readonly markAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HighlightsAPIActions.markAsRead),
      concatMap(({ highlightIds }) =>
        this.highlightsProvider.markAsRead(highlightIds).pipe(
          map(() => HighlightsAPIActions.markAsReadSuccess({ highlightIds })),
          catchError(({ message }: Error) => of(HighlightsAPIActions.markAsReadFailure({ errorMsg: message }))),
        ),
      ),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => highlightsCommonActions.clear()));
  });

  ngrxOnInitEffects() {
    return HighlightsAPIActions.load();
  }
}
