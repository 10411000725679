export enum ModalSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  Full = 'fs',
  Auto = 'au',
}

export interface ModalSizeConfiguration {
  breakpoints: number[];
  initialBreakpoint: number;
}

export const MODAL_SIZE: { [key in ModalSize]: ModalSizeConfiguration } = {
  [ModalSize.XSmall]: {
    breakpoints: [0, 0.25, 0.4],
    initialBreakpoint: 0.25,
  },
  [ModalSize.Small]: {
    breakpoints: [0, 0.35, 0.5, 0.75],
    initialBreakpoint: 0.35,
  },
  [ModalSize.Medium]: {
    breakpoints: [0, 0.55, 0.75],
    initialBreakpoint: 0.55,
  },
  [ModalSize.Large]: {
    breakpoints: [0, 0.5, 0.75],
    initialBreakpoint: 0.75,
  },
  [ModalSize.Full]: {
    breakpoints: [0, 1],
    initialBreakpoint: 1,
  },
  [ModalSize.Auto]: {
    breakpoints: [0, 1],
    initialBreakpoint: 1,
  },
};
