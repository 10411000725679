import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { AccountApiModel } from './api-model/account-api-model.interface';
import { Observable, map } from 'rxjs';
import { apiModelToModel } from './api-model/api-model-to-model';
import { PaginatedResponse } from '@onyxx/model/pagination';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account, AccountKind, AccountProfileAudit, AccountState } from '@onyxx/model/account';
import { modelToApiModel } from './api-model/model-to-api-model';
import { Utils } from '@onyxx/utility/general';

export type AccountListFilter = {
  page?: number;
  per_page?: number;
  state_from?: AccountState;
  state_till?: AccountState;
  kind?: ReadonlyArray<AccountKind>;
};

@Injectable({
  providedIn: 'root',
})
export class AccountProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  get(id: string) {
    return this.http
      .get<{ account: AccountApiModel }>(`${this.apiUrl}/accounts/${id}`)
      .pipe(map((response) => apiModelToModel(response.account)));
  }

  list(params?: AccountListFilter): Observable<PaginatedResponse<Account>> {
    const formattedKinds = params?.kind?.join(',');
    const formattedParams = Utils.isNotNil(formattedKinds)
      ? {
          ...params,
          kind: formattedKinds,
        }
      : { ...params };

    return this.http
      .get<{ accounts: Array<AccountApiModel>; meta: PaginatedResponse<Account>['meta'] }>(`${this.apiUrl}/accounts`, {
        params: formattedParams,
      })
      .pipe(
        map((response) => ({
          data: response.accounts.map(apiModelToModel),
          meta: response.meta,
        })),
      );
  }

  create(account: Partial<Account>): Observable<Account> {
    return this.http
      .post<{ account: AccountApiModel }>(`${this.apiUrl}/accounts`, { account: modelToApiModel(account) })
      .pipe(map((response) => apiModelToModel(response.account)));
  }

  update(id: string, params: Partial<Account & AccountProfileAudit>): Observable<Account> {
    return this.http
      .patch<{ account: AccountApiModel }>(`${this.apiUrl}/accounts/${id}`, { account: modelToApiModel(params) })
      .pipe(map((response) => apiModelToModel(response.account)));
  }

  delete(id: string) {
    return this.http.delete<void>(`${this.apiUrl}/accounts/${id}`);
  }
}
