import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkService } from '@semmie/services/network/network.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RequestErrorInterceptor implements HttpInterceptor {
  constructor(private networkService: NetworkService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (!navigator.onLine) {
          this.networkService.onOfflineApiError();
        }
        return throwError(() => error);
      }),
    );
  }
}
