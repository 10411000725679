import { ProfileFormStages } from '@semmie/views/user/profile/user-profile.component';

/** Profile display settings that you need to inject in order to be able to configure the lazy loaded profile module */

export class ProfileDisplaySettings {
  constructor(
    public formStages: ProfileFormStages[] = [],
    public showAvatar = true,
    public showFooterText = true,
    public unlockAllFields = false,
    public validateOnInit = false,
    public requiredChanges = true,
  ) {}
}
