import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserCommonActions = createActionGroup({
  source: 'User Common',
  events: {
    'Update Task Counts': props<{
      taskCounts: {
        normal: number;
        critical: number;
        high: number;
      };
    }>(),

    Clear: emptyProps(),
  },
});
