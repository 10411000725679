import { Route } from '@angular/router';
import { AppRouteNames } from '@onyxx/model/main';
import { AppRouteParameterNames } from 'apps/client-portal/src/app/app.common';

export const forceLogoutAreaRoutes: Route[] = [
  {
    path: AppRouteNames.Logout,
    loadComponent: () => import('@onyxx/feature/auth').then((m) => m.RedirectToRootComponent),
  },
  {
    /** Redirect to login if there is no token */
    path: AppRouteNames.SetPassword,
    redirectTo: AppRouteNames.Login,
  },
  {
    path: `${AppRouteNames.SetPassword}/:${AppRouteParameterNames.Token}`,
    loadComponent: () => import('@semmie/views/authentication/set-password/set-password.component').then((m) => m.SetPasswordComponent),
  },
  {
    /** Redirect for old v1 url's */
    path: `${AppRouteNames.StartupForgotPassword}/:${AppRouteParameterNames.Token}`,
    redirectTo: `${AppRouteNames.SetPassword}/:${AppRouteParameterNames.Token}`,
  },
  {
    path: `${AppRouteNames.LoginUnlock}/:${AppRouteParameterNames.UnlockToken}`,
    loadChildren: () => import('@semmie/views/authentication/login/login.module').then((m) => m.LoginModule),
    data: {
      unlockUser: true,
    },
  },
  {
    /** Redirect to login if there is no token */
    path: AppRouteNames.LoginUnlock,
    redirectTo: AppRouteNames.Login,
  },
  {
    /** Redirect for old v1 url's */
    path: `${AppRouteNames.StartupUnlock}/:${AppRouteParameterNames.UnlockToken}`,
    redirectTo: `${AppRouteNames.LoginUnlock}/:${AppRouteParameterNames.UnlockToken}`,
  },
  {
    // The path is used by backend when redirecting from the advisor portal, and should not be changed.
    path: `${AppRouteNames.AdvisorAutoLogin}/:${AppRouteParameterNames.Id}`,
    loadComponent: () => import('@semmie/views/advisor/advisor-login/advisor-login.component').then((c) => c.AdvisorLoginComponent),
  },
];
