<div
  [ngClass]="{
    'swiper-container': true,
    'with-pagination': pagination.enabled ?? true,
    'with-navigation': navigation,
    'heavy-fade': fade === 'heavy',
  }"
>
  <swiper
    #swiper
    [loop]="loop"
    [allowTouchMove]="true"
    [autoplay]="autoplay"
    [pagination]="pagination"
    [navigation]="navigation"
    [slidesPerView]="slidesPerView"
    [breakpoints]="breakpoints"
    [centeredSlides]="centeredSlides"
    [slideToClickedSlide]="loop ? false : true"
    [spaceBetween]="spaceBetween"
    [initialSlide]="initialSlide"
    [grabCursor]="true"
    [threshold]="15"
    [shortSwipes]="false"
    [longSwipesMs]="100"
    [edgeSwipeDetection]="true"
    [edgeSwipeThreshold]="0"
    [longSwipesRatio]="0.1"
    [loopedSlides]="loop ? loopedSlides : undefined"
    (activeIndexChange)="activeIndexChange($event)"
    (afterInit)="onSwiperAfterInitialized()"
    (click)="click($event[0], $event[1])"
  >
    <ng-template *ngFor="let slide of contents" swiperSlide class="h-full">
      <ng-template
        [ngTemplateOutlet]="slide.template"
        [ngTemplateOutletContext]="
          $any({
            $implicit: slide.template,
          })
        "
      ></ng-template>
    </ng-template>
    <ng-content></ng-content>
  </swiper>
</div>
