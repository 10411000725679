<ng-container *ngIf="meetsConditions(internalField.conditions)">
  <ng-container [ngSwitch]="getFieldType()">
    <div [hidden]="!meetsConditions(internalField.hidden)" [class]="meetsConditions(internalField.hidden) ? sideSpacingClasses : ''">
      <!-- Textbox Field -->
      <semmie-textbox
        *ngSwitchCase="DynamicFieldType.Text"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [labelHint]="getFieldLabelHint()"
        [autocomplete]="internalField.autocomplete"
        [inputMode]="internalField.inputMode"
        [enterKeyHint]="internalField.enterKeyHint"
        [placeholder]="interpolateFieldProperty('placeholder')"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
        [loading]="!!interpolateFieldProperty('loading')"
        [leadingIcon]="internalField.leadingIcon"
        [trailingIcon]="internalField.trailingIcon"
        [actions]="internalField.actions"
        [showButtons]="internalField.incrementBy"
        [step]="internalField.incrementBy"
        [min]="0"
        [infoModal]="infoModal"
        (onBlur)="onBlur()"
        [allowEmoticons]="internalField.allowEmoticons"
        [countryCode]="internalField.countryCode"
      >
      </semmie-textbox>

      <semmie-textarea
        *ngSwitchCase="DynamicFieldType.Textarea"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [labelHint]="getFieldLabelHint()"
        [autocomplete]="internalField.autocomplete"
        [enterKeyHint]="internalField.enterKeyHint"
        [placeholder]="interpolateFieldProperty('placeholder')"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
        [actions]="internalField.actions"
        [infoModal]="infoModal"
        (onBlur)="onBlur()"
      ></semmie-textarea>

      <!-- Radio Button Group Field -->
      <semmie-radio-button-group
        *ngSwitchCase="DynamicFieldType.Radio"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [options]="internalField.options"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
        [layout]="internalField.layout"
      >
      </semmie-radio-button-group>

      <!-- Checkbox Group Field -->
      <semmie-checkbox-group
        *ngSwitchCase="DynamicFieldType.Checkbox"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [options]="internalField.options"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
        [layout]="internalField.layout"
      >
      </semmie-checkbox-group>

      <!-- Date Field -->
      <semmie-date
        *ngSwitchCase="DynamicFieldType.Date"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [max]="internalField.max"
        [min]="internalField.min"
        [inputMode]="internalField.inputMode"
        [maxFromToday]="internalField.maxFromToday"
        [placeholder]="internalField.placeholder"
        [presentation]="internalField.presentation"
        [displayFormat]="internalField.displayFormat"
        [minFromToday]="internalField.minFromToday"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
      ></semmie-date>

      <!-- Dropdown Field -->
      <semmie-dropdown
        *ngSwitchCase="DynamicFieldType.Dropdown"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [placeholder]="interpolateFieldProperty('placeholder')"
        [label]="interpolateFieldProperty('label')"
        [selection]="internalField.selection"
        [preselect]="internalField.preselect"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [options]="internalField.options"
        [loading]="!!interpolateFieldProperty('loading')"
        [valuePath]="internalField.valuePath"
        [infoModal]="infoModal"
      ></semmie-dropdown>

      <!-- Icon Cards Field -->
      <semmie-icon-cards
        *ngSwitchCase="DynamicFieldType.IconCards"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [options]="internalField.options"
        [valuePath]="internalField.valuePath"
      ></semmie-icon-cards>

      <!-- Toggle Field -->
      <semmie-toggle
        *ngSwitchCase="DynamicFieldType.Toggle"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [description]="renderPropertyWithInfoModals('description')"
        [valuePath]="internalField.valuePath"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [cardStyle]="internalField.type !== 'group' ? internalField.cardStyle : ''"
      >
      </semmie-toggle>

      <!-- Slider Field -->
      <semmie-slider
        *ngSwitchCase="DynamicFieldType.Slider"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [layout]="internalField.layout"
        [options]="internalField.options"
        [min]="internalField.min"
        [max]="internalField.max"
        [valuePath]="internalField.valuePath"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
      >
      </semmie-slider>

      <!-- Upload Field -->
      <semmie-upload
        *ngSwitchCase="DynamicFieldType.Upload"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
        [url]="internalField.url"
        [label]="internalField.label"
        [subtitle]="internalField.subtitle"
        [fileTypes]="internalField.fileTypes"
        [params]="internalField.params"
        [method]="internalField.method"
        [layout]="internalField.layout"
        [cardStyle]="internalField.layout !== 'image' ? internalField.cardStyle : ''"
        [uploadAfterCreation]="internalField.uploadAfterCreation"
        [useParamsForDeletion]="internalField.useParamsForDeletion"
        [refresh]="internalField.refresh"
        class="mb-6"
      ></semmie-upload>

      <!-- Carousel Select -->
      <semmie-carousel-select
        *ngSwitchCase="DynamicFieldType.CarouselSelect"
        [options]="internalField.options"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [form]="form"
        [validations]="internalField.validations"
        [conditions]="internalField.conditions"
        [valuePath]="internalField.valuePath"
      ></semmie-carousel-select>

      <!-- Graph field -->
      <semmie-graph
        *ngSwitchCase="DynamicFieldType.Graph"
        [name]="internalField.name"
        [form]="form"
        [formControl]="getFormControl(internalField)"
        [valuePath]="internalField.valuePath"
        [mode]="internalField.mode"
      >
      </semmie-graph>

      <!-- Payout summary -->
      <semmie-payout-summary
        *ngSwitchCase="DynamicFieldType.PayoutSummary"
        [name]="internalField.name"
        [form]="form"
        [formControl]="getFormControl(internalField)"
        [valuePath]="internalField.valuePath"
      >
      </semmie-payout-summary>

      <!-- External Data Field -->
      <semmie-external-data
        *ngSwitchCase="DynamicFieldType.ExternalData"
        [form]="form"
        [formData]="form.valueChanges"
        [store]="internalField.store"
        [service]="internalField.service"
        [url]="internalField.url"
        [once]="internalField.once"
        [ignoreValues]="internalField.ignoreValues"
        (onExternalDataChange)="onExternalDataChange($event)"
        (onExternalDataError)="onExternalDataError($event)"
        (onExternalLoadingChange)="onExternalLoadingChange($event)"
      ></semmie-external-data>

      <!-- Label Field -->
      <semmie-label
        *ngSwitchCase="DynamicFieldType.Label"
        [class]="classes"
        [align]="internalField.align"
        [type]="internalField.labelType"
        [size]="internalField.size"
        [text]="internalField.description ? renderString(internalField.description) : null"
        [htmlContent]="internalField.htmlContent ? renderString(internalField.htmlContent) : null"
        [link]="!!internalField.relativePath"
        (onClick)="navigateTo(internalField.relativePath)"
      >
      </semmie-label>

      <semmie-image
        *ngSwitchCase="DynamicFieldType.Image"
        [width]="internalField.width"
        [height]="internalField.height"
        [src]="internalField.src"
      >
      </semmie-image>

      <ng-container *ngSwitchCase="DynamicFieldType.Modal">
        <semmie-label
          *ngIf="internalField.label"
          [align]="'center'"
          [text]="internalField.label"
          (onClick)="openInfoModal()"
          [link]="true"
          size="base_3"
          class="my-2"
        ></semmie-label>
      </ng-container>

      <semmie-autocomplete-input
        *ngSwitchCase="DynamicFieldType.AutocompleteInput"
        [name]="internalField.name"
        [form]="form"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [placeholder]="internalField.placeholder"
        [list]="internalField.list"
        [valuePath]="internalField.valuePath"
      >
      </semmie-autocomplete-input>

      <semmie-autocomplete-list
        *ngSwitchCase="DynamicFieldType.AutocompleteList"
        [name]="internalField.name"
        [form]="form"
        [formControl]="getFormControl(internalField)"
        [label]="interpolateFieldProperty('label')"
        [list]="internalField.list"
        [input]="internalField.input"
      >
      </semmie-autocomplete-list>

      <semmie-input-card
        *ngSwitchCase="DynamicFieldType.InputCard"
        class="my-4 block"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [title]="interpolateFieldProperty('label')"
        [subtitle]="interpolateFieldProperty('subtitle')"
        [deletable]="meetsConditions(internalField.deleteConditions)"
        [mode]="internalField.mode"
        [valueKey]="internalField.valueKey"
        [error]="hasErrors()"
        (onInteraction)="onFormFieldInteraction($event)"
      >
      </semmie-input-card>

      <semmie-cover-image-selector
        #coverImageSelector
        *ngSwitchCase="DynamicFieldType.CoverImage"
        [name]="internalField.name"
        [formControl]="getFormControl(internalField)"
        [url]="internalField.url"
        [formDataParamName]="internalField.formDataParamName"
        [uploadAfterCreation]="internalField.uploadAfterCreation"
      >
      </semmie-cover-image-selector>

      <div *ngIf="fieldCanShowErrors()" class="error-container">
        <ng-container *ngIf="hasErrors()">
          <semmie-label class="error-message py-0.5" type="small" size="tiny" [text]="getErrors()"></semmie-label>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
