export class Comparer {
  static compareVersions(currentVersion: string, liveVersion: string): boolean {
    const currentVersionParts = currentVersion.split('.');
    const liveVersionParts = liveVersion.split('.');

    for (let i = 0; i < currentVersionParts.length; i++) {
      const a = Number(liveVersionParts[i]);
      const b = Number(currentVersionParts[i]);
      if (a > b) return true;
      if (a < b) return false;
    }

    return false;
  }
}
