<ng-container *ngIf="options">
  <semmie-radio-button
    *ngFor="let option of options; let last = last"
    [label]="option.label"
    [description]="option.description"
    [cardLabel]="option.cardLabel"
    [disabled]="option.disabled || disabled"
    [value]="option.value"
    [name]="name"
    [layout]="layout"
    [last]="last"
    [padding]="itemPadding"
  >
  </semmie-radio-button>
</ng-container>
<ng-content></ng-content>
