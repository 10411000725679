import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { iQuestions } from '@onyxx/model/questions';
import { questionsFeature } from './questions.reducer';

export type QuestionsResponse = { questions: { people: iQuestions; organisation: iQuestions } };
@Injectable()
export class QuestionsStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  readonly questionsLoading$ = this.store.select(questionsFeature.selectQuestionsLoading);
  readonly questions$ = this.store.select(questionsFeature.selectQuestions);
}
