import { Account, AccountHelpers } from '@onyxx/model/account';
import { Formatter } from '@onyxx/utility/formatter';
import { Utils } from '@onyxx/utility/general';

/**
 * @deprecated This is to enable backwards compatibility between the account interface and the forms module. It should not be used unless needed to support the forms module.
 *
 * The functions returns an extended account with wrapped pure functions that can be called on the account using dot notation.
 */
export const accountAdapter = (account: Account | null) =>
  Utils.isNil(account)
    ? null
    : {
        ...account,
        finishedAccount: AccountHelpers.isFinished(account),
        owners: AccountHelpers.owners(account),
        formattedIban: Formatter.formatIban(account.iban),
        representatives: AccountHelpers.representatives(account),
        hasMultipleRepresentatives: AccountHelpers.hasMultipleRepresentatives(account),
        hasMultipleOwners: AccountHelpers.hasMultipleOwners(account),
        planCreatorIsKnown: AccountHelpers.planCreatorIsKnown(account),
      };
