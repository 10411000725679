import { Injectable, inject } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';

import { Goal } from '@semmie/models';
import { Performance } from '@semmie/models/bi/performance';
import { RiskProfile, RiskProfileApproach } from '@semmie/models/bi/risk-profile/';
import { Strategy } from '@semmie/models/bi/strategy';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { LinkedAccount } from '@onyxx/model/linked-account';
import { InstrumentKind } from '@semmie/schemas/bi/instrument/instrument-kind';
import { Account, AccountPosition, AccountHelpers } from '@onyxx/model/account';
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';
import { GoalFacade } from '@onyxx/store/goal';
import { Utils } from '@onyxx/utility/general';

@Injectable({
  providedIn: 'root',
})
export class AccountsStore {
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly goalFacade = inject(GoalFacade);
  private readonly account$ = this.accountStoreFacade.account$.pipe(filterNil());

  readonly accountProposals$ = new BehaviorSubject<Account[]>([]);

  private instrumentOrder = [InstrumentKind.STOCK, InstrumentKind.BOND, InstrumentKind.PRIVATE_MARKET, InstrumentKind.CASH];

  readonly funds$ = this.account$.pipe(
    map((account) =>
      [...account.positions]
        .sort(
          (a, b) => this.instrumentOrder.indexOf(a.instrument.instrumentKind) - this.instrumentOrder.indexOf(b.instrument.instrumentKind),
        )
        .filter((position) => this.instrumentOrder.includes(position.instrument.instrumentKind)),
    ),
    startWith([] as AccountPosition[]),
  );

  riskProfiles$ = new BehaviorSubject<RiskProfile[]>([]);

  /**
   * Used for calculating goal during onboarding or changing plan when account is active.
   * - When account is active: `selected`
   * - During onboarding with `fixed` approach: `selected`
   * - Otherwise: `advice`
   */
  riskProfile$ = combineLatest([this.account$, this.riskProfiles$]).pipe(
    map(([account, riskProfiles]) =>
      AccountHelpers.isActive(account) || account.risk_profile_approach === RiskProfileApproach.Fixed
        ? (riskProfiles.find((r) => r.selected) ?? riskProfiles.find((r) => r.advice))
        : (riskProfiles.find((r) => r.advice) ?? riskProfiles.find((r) => r.selected)),
    ),
  );
  riskProfileSelected$ = this.riskProfiles$.pipe(map((riskProfiles) => riskProfiles.find((riskProfile) => riskProfile.selected)));
  riskProfileInvested$ = this.riskProfiles$.pipe(map((riskProfiles) => riskProfiles.find((riskProfile) => riskProfile.invested)));
  riskProfileAdvice$ = this.riskProfiles$.pipe(map((riskProfiles) => riskProfiles.find((riskProfile) => riskProfile.advice)));

  strategies$ = new BehaviorSubject<Strategy[]>([]);
  strategySelected$ = this.strategies$.pipe(map((strategies) => strategies.find((strategy) => strategy.selected)));
  strategyInvested$ = this.strategies$.pipe(map((strategies) => strategies.find((strategy) => strategy.invested)));

  getCachedGoalForCurrentAccount$ = this.account$.pipe(
    map((account) => account.id),
    distinctUntilChanged(),
    switchMap((currentAccountId) => this._goals.asObservable().pipe(map((goals) => goals.get(currentAccountId)))),
  );

  getPerformancesForCurrentAccount$ = this.account$.pipe(
    map((account) => account.id),
    distinctUntilChanged(),
    switchMap((currentAccountId: string) =>
      this._performances.asObservable().pipe(map((performances) => performances.get(currentAccountId) ?? [])),
    ),
  );

  private _goals: BehaviorSubject<Map<string, Goal>> = new BehaviorSubject(new Map());
  private _rates: BehaviorSubject<Map<string, any>> = new BehaviorSubject(new Map());
  private _performances: BehaviorSubject<Map<string, Performance[]>> = new BehaviorSubject(new Map());
  private _linkedaccounts: BehaviorSubject<Map<string, Array<LinkedAccount>>> = new BehaviorSubject(new Map());

  get goals$() {
    return this._goals.asObservable();
  }

  get rates$() {
    return this._rates.asObservable();
  }

  get goals() {
    return this._goals;
  }

  get rates() {
    return this._rates;
  }

  get performances() {
    return this._performances;
  }

  get linkedAccounts() {
    return this._linkedaccounts;
  }

  getCachedGoals(): any {
    return this.goals.value;
  }

  getCachedRates(): any {
    return this.rates.value;
  }

  getCachedGoalById(id: string): Goal | undefined {
    return this.goals.value.get(id);
  }

  getCachedRatesById(id: string): any {
    return this.rates.value.get(id);
  }

  getPerformancesRatesById(id: string) {
    return this.performances.value.get(id);
  }

  getLinkedAccountsById(id: string): any {
    return this.linkedAccounts.value.get(id);
  }

  updateCachedGoalById(id: string, goal: Goal): void {
    this.goals.next(this.goals.value.set(id, goal));

    const newSuggestions = Utils.isNil(goal.suggestions)
      ? goal.suggestions
      : {
          deposit_monthly: goal.suggestions.deposit_monthly ?? 0,
          deposit_once: goal.suggestions.deposit_once ?? 0,
          ends_at: goal.suggestions.ends_at ?? '',
          period: goal.suggestions.period ?? 0,
        };

    /**
     * Need to convert some undefined values in order to make old model to work with new one:
     * - amount
     * - amount_start
     * - deposit
     * */
    this.goalFacade.dispatchUpdateGoalInStore(id, {
      ...goal,
      amount: goal.amount ?? 0,
      amount_start: goal.amount_start ?? 0,
      deposit: goal.deposit ?? 0,
      suggestions: newSuggestions,
    });
  }

  updateCachedRateById(id: string, rates: any): void {
    const cachedRates = this.rates.value;
    cachedRates.set(id, rates);
    this.rates.next(cachedRates);
  }

  updateCachePerformancesById(id: string, performances: any): void {
    const updatedCache = this.performances.value;
    updatedCache.set(id, performances);
    this.performances.next(updatedCache);
  }

  updateCacheLinkedAccountsById(id: string, accounts: any): void {
    const cachedLinkedAccounts = this.linkedAccounts.value;
    cachedLinkedAccounts.set(id, accounts);
    this.linkedAccounts.next(cachedLinkedAccounts);
  }

  updateRiskProfiles(riskProfiles: RiskProfile[]): void {
    this.riskProfiles$.next(riskProfiles);
  }

  updateStrategies(strategies: Strategy[]): void {
    this.strategies$.next(strategies);
  }

  clear(): void {
    this.rates.next(new Map());
    this.goals.next(new Map());
  }
}
