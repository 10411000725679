import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CardModule } from '@semmie/components/containers/card/card.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';

import { ToggleComponent } from '@semmie/components/presentational/core/toggle/toggle.component';
import { HapticOnTapDirective } from '@semmie/directives/haptic-on-tap.directive';
import { SharedModule } from '@semmie/shared/shared.module';

@NgModule({
  declarations: [ToggleComponent],
  imports: [SharedModule, FormsModule, ReactiveFormsModule, LabelModule, CardModule, IconComponent, HapticOnTapDirective],
  exports: [ToggleComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ToggleModule {}
