import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { UserEffects } from './user.effects';
import { UserStoreFacade } from './user.facade';
import { userFeature } from './user.reducer';

/**
 * The providers array is used to provide the user feature state, effects, and facade.
 * This can be used from standalone routing and modules, but not from standalone components.
 * In order to include it in standalone modules use the module grouping below.
 */
export const providers = [provideState(userFeature), provideEffects(UserEffects), UserStoreFacade];

/**
 * The UserStateModule class is an angular module that provides services for managing user state.
 */
@NgModule({ providers })
export class UserStateModule {}

export * from './user.facade';
