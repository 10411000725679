import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewChild,
  inject,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { asyncScheduler } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

import { Icon } from '@semmie/schemas';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { TEXTBOX_NUMERIC_INPUT_MODES, iTextBoxFormField } from '@semmie/schemas/components/dynamic-form';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { iDynamicFormFieldAction } from '@semmie/schemas/components/dynamic-form/form-fields';
import { ClipboardService } from '@semmie/services/clipboard/clipboard.service';
import { Parser } from '@semmie/shared/parser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MaskitoOptions, maskitoTransform } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { RegExpPatterns } from '@semmie/shared/regexp';
import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { ElementState } from '@maskito/core/src/lib/types';
import { Utils } from '@onyxx/utility/general';
import { filterNil } from '@onyxx/utility/observables';

@Component({
  selector: 'semmie-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent extends BaseFormComponent implements OnInit {
  @ViewChild('input', { static: true, read: NgControl }) input: NgControl;
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  /**
   * Controls the masking and keyboard
   */
  @Input() inputMode: iTextBoxFormField['inputMode'] = 'text';

  /**
   * hints the browser what to autocomplete here if possible
   */
  @Input() autocomplete?: HTMLInputElement['autocomplete'];

  /**
   * ISO 3 country code, sometimes used for masking the input
   */
  @Input() set countryCode(value: string | undefined) {
    this._countryCode = value;

    // re-evaluate maskito
    this.maskitoOptions = this.getMaskitoOptions();
  }
  private _countryCode: string | undefined;

  /**
   * Suggests a different action for the keyboard's enter key
   */
  @Input() enterKeyHint?: HTMLInputElement['enterKeyHint'];

  /**
   * Sets the label of the field.
   */
  @Input() label: iTextBoxFormField['label'];

  /**
   * Sets the label hint of the field.
   */
  @Input() labelHint: string | null;

  /**
   * Sets the placeholder of the field.
   */
  @Input() placeholder: iTextBoxFormField['placeholder'];

  /**
   * Sets a leading icon at the start of the input
   */
  @Input() leadingIcon: Icon | null = null;

  /**
   * Sets a leading icon at the end of the input
   */
  @Input() trailingIcon: Icon | null = null;

  @Input() actions: iDynamicFormFieldAction[];

  /** If true then we will show a loading bar underneath */
  @Input() loading = false;

  /**
   * Sets a custom error that does not
   * belong to the form control validators.
   *
   * @type {(string | null)}
   */
  @Input() error: string | null = null;

  @Input() showPasswordToggler = true;

  /** Show in info icon next to the label opening an info modal */
  @Input() infoModal?: iInfoModal;

  /**
   * Show in-/decrement buttons.
   * Can be enabled for numerical fields
   */
  @Input() showButtons = false;

  /**
   * Represents the in-/decrement steps
   * Used for numerical fields
   */
  @Input() step = 1;

  /**
   * Mininum boundary value.
   * Used for numerical fields
   */
  @Input() min: number;

  /**
   * Maximum boundary value.
   * Used for numerical fields
   */
  @Input() max: number;

  /**
   * Whether emoticons are allowed. If not the will
   * be discarded when entered.
   *
   * Does not apply to money and number input {@link type}
   */
  @Input() allowEmoticons = false;

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  readonly Icon = Icon;
  passwordIcon = Icon.VISIBLE;

  formattedValue: any;

  isFocused = false;

  maskitoOptions: MaskitoOptions;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private renderer: Renderer2,
    private clipboardService: ClipboardService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    super(ngControl);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (!event?.key) return;
    if (this.inputMode != 'money') return;

    // If entering a . at the decimal separator while the apps local has the decimal
    // separator a as a comma, move the cursor over the decimal separator.
    // this would allow typing a number with decimals on a native keyboard
    // without a , but with a .
    const decimalKey = event.key === '.';

    if (
      decimalKey &&
      this.inputRef.nativeElement.selectionStart != null &&
      this.inputRef.nativeElement.selectionStart === this.inputRef.nativeElement.selectionEnd &&
      this.formattedValue[this.inputRef.nativeElement.selectionStart] === getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal)
    ) {
      this.inputRef.nativeElement.selectionStart += 1;
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.maskitoOptions = this.getMaskitoOptions();

    if (this.value !== '' && Utils.isNotNil(this.value)) {
      this.formattedValue = this.formatValue(this.value);
      this.onValueChange(this.formattedValue);
    }

    /**
     * This is used in situations where the formcontrol gets updated by external factors
     */
    this.formControl?.valueChanges
      ?.pipe(
        filter(() => !this.isFocused),
        filterNil(),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.formattedValue = this.formatValue(value);
        this.onValueChange(this.formattedValue);
      });
  }

  get isTypePassword() {
    return this.inputMode === 'password';
  }

  get calculatedInputMode(): string {
    switch (this.inputMode) {
      case 'digits':
      case 'integer':
        return 'numeric';
      case 'money':
        return 'decimal';
      case 'tel':
        return 'tel';
      case 'email':
        return 'email';
      case 'password':
      case 'text':
      case 'zipcode':
      case 'iban':
      case 'uppercase':
        return 'text';
    }
  }

  get calculateInputType(): string {
    switch (this.inputMode) {
      case 'tel':
      case 'password':
        return this.inputMode;
      case 'email':
      case 'money':
      case 'integer':
      case 'digits':
      case 'text':
      case 'zipcode':
      case 'iban':
      case 'uppercase':
        return 'text';
    }
  }

  get autocapitalize(): string {
    switch (this.inputMode) {
      case 'iban':
      case 'uppercase':
        return 'characters';
      case 'zipcode':
        return this._countryCode === 'NLD' ? 'characters' : 'none';
      case 'tel':
      case 'password':
      case 'email':
      case 'money':
      case 'integer':
      case 'digits':
      case 'text':
        return 'none';
    }
  }

  focus(): void {
    asyncScheduler.schedule(() => this.inputRef.nativeElement.focus());
  }

  clear() {
    this.writeValue('');
    this.formattedValue = '';
  }

  blur() {
    this.isFocused = false;
    this.inputRef.nativeElement.blur();

    this.onBlur.emit();
  }

  onFocusHandler(event: any): void {
    this.isFocused = true;

    if (this.inputMode === 'money') {
      event?.target?.select();
    }
  }

  onBlurHandler(event: any): void {
    this.isFocused = false;
    this.onValueChange(this.formattedValue);

    this.onBlur.emit(event);
  }

  /**
   * When the input is of type password, modifies
   * the type of the input so the user can see the text.
   * It relies on the nativeElement to not change the
   * component @Input type internally
   */
  toggleMask(): void {
    const currentType = this.inputRef.nativeElement.type;
    const newType = currentType === 'password' ? 'text' : 'password';
    this.passwordIcon = newType === 'password' ? Icon.VISIBLE : Icon.HIDE;
    this.renderer.setAttribute(this.inputRef.nativeElement, 'type', newType);
  }

  iconAction(position: iDynamicFormFieldAction['position']): void {
    if (!this.actions) return;
    const action = this.actions.find((action) => action.position === position);
    if (action) {
      switch (action.type) {
        case 'copyToClipboard':
          this.clipboardService.copy(this.value || this.placeholder);
          break;
      }
    }
  }

  onValueChange(value = '') {
    if (TEXTBOX_NUMERIC_INPUT_MODES.some((c) => c === this.inputMode)) {
      this.writeValue(Parser.parseNumber(value));
      return;
    }

    if (this.inputMode === 'tel' || this.inputMode === 'iban' || this.inputMode === 'zipcode') {
      this.writeValue(value.replace(/\s/g, ''));
      return;
    }

    this.writeValue(value);
  }

  spin(event, dir) {
    const stepValue = this.step * dir;
    const result = Number(parseFloat(this.value + stepValue).toFixed(2));

    if (result == null) {
      this.value = null;
    } else if (this.min != null && result < this.min) {
      this.value = this.min;
    } else if (this.max != null && result > this.max) {
      this.value = this.max;
    } else {
      this.value = result;
    }
  }

  private getMaskitoOptions(): MaskitoOptions {
    const uppercasePostprocessor = (state: ElementState) => {
      return {
        ...state,
        value: state.value.toUpperCase(),
      };
    };
    switch (this.inputMode) {
      case 'money':
        return maskitoNumberOptionsGenerator({
          precision: 2,
          decimalSeparator: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal),
          min: this.disabled ? undefined : 0,
          thousandSeparator: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyGroup),
          decimalZeroPadding: true,

          prefix: '€ ',
        });
      case 'integer':
        return maskitoNumberOptionsGenerator({
          precision: 0,
          thousandSeparator: '',
        });
      case 'digits':
        return {
          mask: RegExpPatterns.NUMBERS_ONLY,
        };
      case 'uppercase':
        return {
          mask: /.*/,
          postprocessors: [uppercasePostprocessor],
        };
      case 'iban':
        return {
          /**
           * IBAN specs that we are checking for
           *  - total 34 characters
           *  - a space after every 4th character
           */
          mask: [
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
            ' ',
            /[A-Za-z0-9]/,
            /[A-Za-z0-9]/,
          ],
          postprocessors: [uppercasePostprocessor],
        };
      case 'tel':
        return {
          mask: ({ value }) => {
            const digitsCount = value.replace(/\D/g, '').length;
            const digitsAfterFirstTwo = Math.max(digitsCount - 2, 0);
            return [/\d/, /\d/, ' ', ...new Array(digitsAfterFirstTwo).fill(/\d/)];
          },
        };
      case 'zipcode': {
        const mask = this._countryCode === 'NLD' ? [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[A-Za-z]/, /[A-Za-z]/] : /.*/;

        return {
          mask,
          postprocessors: [uppercasePostprocessor],
        };
      }
    }

    if (!this.allowEmoticons) {
      return {
        mask: RegExpPatterns.WITHOUT_EMOTICONS,
      };
    }

    return {} as MaskitoOptions;
  }

  private formatValue(value: string | number) {
    const stringValue = typeof value === 'number' ? value.toFixed(2) : value;
    const cleanedValue = TEXTBOX_NUMERIC_INPUT_MODES.some((c) => c === this.inputMode)
      ? `${Parser.parseNumber(stringValue)}`.replace('.', getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal))
      : stringValue;

    return maskitoTransform(cleanedValue, this.getMaskitoOptions());
  }
}
