import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { userActivityFeature } from './user-activity.reducer';
import { UserActivityStoreActions } from './actions/user-activity-store.actions';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { filterNil } from '@onyxx/utility/observables';

@Injectable()
export class UserActivityStoreFacade {
  private readonly store = inject(Store);

  readonly ready$ = this.store.select(userActivityFeature.selectReady).pipe(filter((ready) => ready));

  readonly sessionExpired$ = this.store.select(userActivityFeature.selectSessionExpired).pipe(filterNil(), distinctUntilChanged());

  dispatchInitialize({ skipNavigationOnLogout }: { skipNavigationOnLogout: boolean }) {
    this.store.dispatch(UserActivityStoreActions.initialize({ skipNavigationOnLogout }));
  }
}
