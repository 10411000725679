import { AdvisorAuditApprovalMethods } from '@onyxx/model/advisor-audit-trail';

export const advisorAuditApprovalMethodsToString = (approvalMethod: AdvisorAuditApprovalMethods) => {
  switch (approvalMethod) {
    case AdvisorAuditApprovalMethods.Email:
      return 'E-mail';
    case AdvisorAuditApprovalMethods.InPerson:
      return 'In persoon';
    case AdvisorAuditApprovalMethods.Intercom:
      return 'Intercom';
    case AdvisorAuditApprovalMethods.Phone:
      return 'Telefonisch';
  }
};
