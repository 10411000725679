import { Route, UrlSegment, UrlSegmentGroup, defaultUrlMatcher } from '@angular/router';
import { Utils } from '@onyxx/utility/general';

export const areaMatcher = (routes: Route[]) => (segments: UrlSegment[], segmentGroup: UrlSegmentGroup) => {
  const matchedRoutes = routes.some((childRoute) => {
    const matchingFunction = childRoute.matcher;
    return Utils.isNotNil(matchingFunction)
      ? matchingFunction(segments, segmentGroup, childRoute)
      : defaultUrlMatcher(segments, segmentGroup, childRoute);
  });
  return matchedRoutes
    ? {
        consumed: [],
        posParams: {},
      }
    : null;
};
