import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';

import { SliderComponent } from './slider.component';

@NgModule({
  declarations: [SliderComponent],
  imports: [CommonModule, FormsModule, IonicModule, LabelModule],
  exports: [SliderComponent],
})
export class SliderModule {}
