import { Clipboard } from '@capacitor/clipboard';
import { Injectable } from '@angular/core';
import { ToastService } from '@semmie/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  copy(text: string, toastMessage = true, entity?: string): void {
    if (toastMessage) {
      this.toastService.show({
        message: this.translate.instant(entity ? 'clipboard.entity-notification' : 'clipboard.notification', { entity }),
      });
    }
    Clipboard.write({ string: text });
  }
}
