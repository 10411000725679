import { inject } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Goal, Person } from '@semmie/models';
import { Account, AccountState, AccountHelpers, AccountAdditionalData } from '@onyxx/model/account';
import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { QuestionnaireProvider } from '@semmie/providers/questionnaire/questionnaire.provider';
import { AccountsService, NavigationService } from '@semmie/services';
import { PersonService } from '@semmie/services/person/person.service';
import { AccountsStore } from '@semmie/store/accounts/accounts.store';
import { QuestionnaireService } from '@semmie/services/questionnaire/questionnaire.service';
import { QuestionnaireSubject } from '@onyxx/model/questionnaire';
import { Utils } from '@semmie/shared/utils';
import { PersonKind } from '@semmie/schemas/bi/person/person-kind.enum';
import { UserRole, iUser } from '@onyxx/model/user';
import { PayoutFacade } from '@onyxx/store/payout';
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';
import { CommonQueryParams } from '@semmie/views/shared/common-query-params.enum';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { MainRouteNames } from '@onyxx/model/main';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';
import { AccountAdditionalDataStoreFacade } from '@onyxx/store/account-additional-data';
import { AccountRouteNames } from '@semmie/views/account/account.common';

export abstract class BaseOnboardingService {
  currentStep = new BehaviorSubject<string | null>('');

  readonly accountsStore = inject(AccountsStore);
  readonly accountStoreFacade = inject(AccountStoreFacade);
  readonly accountAdditionalDataStoreFacade = inject(AccountAdditionalDataStoreFacade);
  readonly questionnaireProvider = inject(QuestionnaireProvider);
  readonly navigationService = inject(NavigationService);
  readonly accountsService = inject(AccountsService);
  readonly personService = inject(PersonService);
  readonly questionnaireService = inject(QuestionnaireService);
  readonly payoutFacade = inject(PayoutFacade);

  account: Account;
  person?: Person;
  accountPerson: AccountPerson;
  user?: iUser;
  goal?: Goal;
  isAdvisorOnboarding = false;
  accountAdditionalData: AccountAdditionalData | undefined;

  initialize(account: Account, person?: Person, user?: iUser, goal?: Goal, additionalData?: AccountAdditionalData): void {
    this.account = account;
    this.person = person;
    this.user = user;
    this.goal = goal;
    this.accountAdditionalData = additionalData;

    this.handleAdvisorOnboarding();

    const accountPerson = this.account.people.find((p) => p.id === this.user?.person?.id);
    this.accountPerson = new AccountPerson(accountPerson);

    this.handleOnboarding();
  }

  //#region Business flows

  handleOnboarding(): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(finished)),
        switchMap((finished) => this.handleFinishedPerson(finished)),
        switchMap((finished) => this.handleFinishedIdentification(finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(finished)),
        switchMap((finished) => this.handleFinishedStrategy(finished)),
        switchMap((finished) => this.handleFinishedPlan(finished)),
        switchMap((finished) => this.handleFinishedFirstPayment(finished)),
        switchMap((finished) => this.handleFinishedSummary(finished)),
        switchMap((finished) => this.handleFinishedSigning(finished)),
      )
      .subscribe(() => {
        this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  //#endregion

  //#region Business logic

  handleFinishedAccount(finished: boolean) {
    if (!AccountHelpers.isOnboarding(this.account)) {
      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      return EMPTY;
    }

    if (!finished) {
      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Account]);

      return EMPTY;
    }

    return this.hasFinishedPerson();
  }

  handleFinishedPerson(finished: boolean) {
    if (!finished) {
      if (this.currentStep?.value === 'identification') {
        this.navigationService.navigate(
          [
            ...this.onboardingPathPrefix(this.account.id),
            OnboardingRouteNames.Identification,
            {
              step: 'identification_type',
            },
          ],
          {
            queryParams: this.isAdvisorOnboarding
              ? {
                  [CommonQueryParams.PersonId]: this.person?.id,
                }
              : undefined,
          },
        );
      } else {
        this.navigationService.navigate([
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.Person,
          this.person?.id,
          {
            step: this.currentStep.value,
          },
        ]);
      }

      return EMPTY;
    }

    return this.hasFinishedIdentification();
  }

  handleFinishedIdentification(finished: boolean) {
    if (!finished) {
      this.navigationService.navigate(
        [
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.Identification,
          {
            step: 'identification_type',
          },
        ],
        {
          queryParams: this.isAdvisorOnboarding
            ? {
                [CommonQueryParams.PersonId]: this.person?.id,
              }
            : undefined,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedQuestionnaire();
  }

  handleFinishedQuestionnaire(finished: boolean) {
    if (!finished) {
      this.currentStep.next('questionnaire');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Questionnaire]);

      return EMPTY;
    }

    return this.hasFinishedStrategy();
  }

  handleFinishedStrategy(finished: boolean) {
    if (!finished) {
      this.currentStep.next('strategy');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Strategy]);

      return EMPTY;
    }

    return this.hasFinishedAccountPlan();
  }

  handleFinishedPlan(finished: boolean) {
    if (!finished) {
      this.currentStep.next('plan');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Plan]);

      return EMPTY;
    }

    return this.hasFinishedFirstPayment();
  }

  handleFinishedFirstPayment(finished: boolean) {
    if (!finished) {
      this.currentStep.next('payment');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'first' }]);

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  handleFinishedSummary(finished: boolean) {
    if (!finished) {
      this.currentStep.next('summary');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Summary]);

      return EMPTY;
    }

    return this.hasFinishedSigning('me');
  }

  handleFinishedSigning(finished: boolean) {
    if (!finished) {
      this.currentStep.next('sign');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Sign]);

      return EMPTY;
    }

    if (!AccountHelpers.hasCompletedSigning(this.account)) {
      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Sign, this.account.signing?.id]);

      return EMPTY;
    }

    return of(true);
  }

  handleFinishedSendingInvitation(finished: boolean) {
    if (!finished) {
      this.currentStep.next('invite');

      this.navigationService.navigate([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Invitation,
        { step: InvitationStep.INVITE },
      ]);

      return EMPTY;
    }

    return this.hasFinishedSendingInvitation();
  }

  //#endregion

  //#region Business checks

  hasFinishedAccount() {
    if (this.isAdvisorOnboarding) {
      return of(true);
    } else {
      return of(!!this.account.title);
    }
  }

  hasFinishedPerson() {
    if (Utils.isNullOrUndefined(this.person)) {
      return of(false);
    }

    if (this.person?.finished_at || this.person.onboarding_kind === PersonKind.CHILD) return of(true);

    if (!this.person.hasCompletedAddress()) {
      this.currentStep.next('address');
    } else if (!this.person.hasCompletedNationality()) {
      this.currentStep.next('nationality');
    } else if (!this.person.hasCompletedFinance()) {
      this.currentStep.next('financial');
    } else if (!this.person.hasCompletedEmployment()) {
      this.currentStep.next('employment');
    } else if ((!this.person.hasCompletedPhone() || !this.person.hasConfirmedPhone()) && !this.isAdvisorOnboarding) {
      this.currentStep.next('phone');
    } else {
      this.currentStep.next('identification');
    }

    return of(false);
  }

  hasFinishedIdentification() {
    return of((this.person?.hasIdentification() ?? false) || this.person?.onboarding_kind === PersonKind.CHILD);
  }

  hasFinishedQuestionnaire(customSteps?: QuestionnaireSubject[]) {
    return of(!this.questionnaireService.getUnfinishedSubject(this.account, this.user, customSteps));
  }

  hasFinishedStrategy(): Observable<boolean> {
    return of(!!this.account.strategy);
  }

  hasFinishedAccountPlan(): Observable<boolean> {
    return of(AccountHelpers.hasFinishedPlan(this.account));
  }

  hasFinishedFirstPayment(): Observable<boolean> {
    return of(AccountHelpers.hasValue(this.account) || this.account.meta.payment_optional == true);
  }

  hasFinishedSummary(): Observable<boolean> {
    return of(this.account.state >= AccountState.ONBOARDING && AccountHelpers.hasSigned(this.account, this.user?.id));
  }

  hasFinishedSigning(party: 'me' | 'other'): Observable<boolean> {
    return this.accountStoreFacade.account$.pipe(
      filterNil(),
      map((account) => {
        const hasSigning = account.signing;

        if (hasSigning) {
          const currentUserSigned = account.signing?.signing_users.find((signUser) =>
            party === 'me' ? signUser.user.id === this.user?.id : signUser.user.id !== this.user?.id,
          );
          return currentUserSigned?.state === 'signed';
        } else {
          if (AccountHelpers.isOnboarding(account)) return false;
          return true;
        }
      }),
      take(1),
    );
  }

  hasFinishedSendingInvitation(): Observable<boolean> {
    if (this.accountAdditionalData?.skipInvitation) return of(true);

    if (!this.account?.invitation) return of(false);

    if (this.account?.invitation?.hasInvitationBeenAccepted()) {
      return of(true);
    }

    return of(this.account?.invitation?.hasOpenInvitation());
  }

  hasAcceptedInvitation(): Observable<boolean> {
    if (this.accountAdditionalData?.skipInvitation) return of(true);
    return of(this.account?.invitation?.hasInvitationBeenAccepted() ?? false);
  }

  hasBeenWidValidated(): Observable<boolean> {
    return of(this.accountPerson.isWidValidated());
  }

  handleAdvisorOnboarding() {
    this.isAdvisorOnboarding = this.user?.role === UserRole.Advisor;
  }

  onboardingPathPrefix(accountId: string) {
    if (this.isAdvisorOnboarding) {
      return ['/', MainRouteNames.Advisor, MainRouteNames.Onboarding, accountId];
    }
    if (this.navigationService.backNavigationBeta$$()) {
      return [MainRouteNames.Accounts, accountId, AccountRouteNames.Onboarding, accountId];
    }
    return ['/', MainRouteNames.Onboarding, accountId];
  }

  //#endregion
}
