import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';
import { BaseSize } from '@semmie/schemas';
import { ButtonShape, ButtonTheme, ButtonThemeEnum, ButtonThemeShape } from '@semmie/schemas/common/button';

@Component({
  selector: 'semmie-button',
  template: `
    <button
      class="button"
      [ngClass]="getStyling()"
      [disabled]="disabled || loading"
      [type]="type"
      (click)="onClickHandler($event)"
      role="button"
    >
      <ng-content></ng-content>
      <div *ngIf="loading" class="loader" [ngClass]="getStyling()">
        <semmie-loading-ripple position="inline"></semmie-loading-ripple>
      </div>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseComponent implements OnInit {
  @Input() size?: BaseSize = 'md';
  @Input() theme?: ButtonTheme = ButtonThemeEnum.DEFAULT;
  @Input() shape: ButtonShape = ButtonThemeShape.CIRCLE;
  @Input() type: 'button' | 'submit' = 'submit';
  @Input() text: string;
  @Input() class: string;
  @Input() disabled?: boolean | null = false;
  @Input() loading?: boolean | null = false;
  @Input() fullWidth? = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.class) this.class += ' ';
  }

  onClickHandler($event: any): void {
    if (this.disabled) return;

    this.onClick.emit($event);
  }

  getStyling() {
    const classes = new Array<string>();

    classes.push(this.theme || ButtonThemeEnum.DEFAULT);
    classes.push(this.shape || ButtonThemeShape.CIRCLE);
    if (this.size) classes.push(this.size);
    if (this.fullWidth) classes.push('fullWidth');
    if (this.class) this.class.split(' ').forEach((c) => classes.push(c));
    if (this.disabled) classes.push('disabled');
    if (this.loading) classes.push('loading');

    return classes.join(' ');
  }
}
