import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselSlideComponent } from '@semmie/components/presentational/core/carousel-slide/carousel-slide.component';

@NgModule({
  declarations: [CarouselSlideComponent],
  imports: [CommonModule],
  exports: [CarouselSlideComponent],
})
export class CarouselSlideModule {}
