import { createFeature, createReducer, on } from '@ngrx/store';
import { appLoadingStatusCommonActions } from './app-loading-status-common.actions';

export interface State {
  loading: boolean;
}

const initialState: State = { loading: true };

const reducer = createReducer(
  initialState,
  on(appLoadingStatusCommonActions.updateLoading, (state, { loading }): State => {
    return { ...state, loading };
  }),
);

export const appLoadingStatusFeature = createFeature({
  name: 'app-loading-status',
  reducer,
});
