import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Account } from '@onyxx/model/account';

export const AdvisorAccountApiActions = createActionGroup({
  source: 'Advisor Account API',
  events: {
    loadAccount: props<{ accountId: string }>(),
    loadAccountSuccess: props<{ account: Account }>(),
    loadAccountFailure: emptyProps(),
    sendProposal: props<{ accountId: string }>(),
    sendProposalSuccess: props<{ accountId: string }>(),
    sendProposalFailure: emptyProps(),
  },
});
