import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { HighlightsEffects } from './highlights.effects';
import { highlightsFeature } from './highlights.reducer';
import { HighlightsFacade } from './highlights.facade';

export const providers = [provideState(highlightsFeature), provideEffects(HighlightsEffects), HighlightsFacade];

@NgModule({ providers })
export class HighlightsStateModule {}

export * from './highlights.facade';
