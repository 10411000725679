// eslint-disable-next-line @nx/enforce-module-boundaries
import { Goal } from '@onyxx/model/goal';
import { GoalApiModel } from './goal-api-model.interface';

export const apiModelToModel = (apiModel: GoalApiModel): Goal => {
  return {
    ...apiModel,

    amount: Number(apiModel.amount ?? 0),
    amount_start: Number(apiModel.amount_start ?? 0),
    deposit: Number(apiModel.deposit ?? 0),
  };
};
