import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';

import { asyncScheduler } from 'rxjs';

import { BaseFormComponent } from '@semmie/components/_abstract';
import { iTextAreaFormField } from '@semmie/schemas/components/dynamic-form';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { iDynamicFormFieldAction } from '@semmie/schemas/components/dynamic-form/form-fields';

@Component({
  selector: 'semmie-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends BaseFormComponent implements OnInit {
  @ViewChild('input', { static: true, read: NgControl }) input: NgControl;
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  /**
   * hints the browser what to autocomplete here if possible
   */
  @Input() autocomplete?: HTMLInputElement['autocomplete'];

  /**
   * Suggests a different action for the keyboard's enter key
   */
  @Input() enterKeyHint?: HTMLInputElement['enterKeyHint'];

  /**
   * Sets the label of the field.
   */
  @Input() label: iTextAreaFormField['label'];

  /**
   * Sets the label hint of the field.
   */
  @Input() labelHint: string | null;

  /**
   * Sets the placeholder of the field.
   */
  @Input() placeholder: iTextAreaFormField['placeholder'];

  @Input() actions: iDynamicFormFieldAction[];

  /** If true then we will show a loading bar underneath */
  @Input() loading = false;

  /**
   * Sets a custom error that does not
   * belong to the form control validators.
   *
   * @type {(string | null)}
   */
  @Input() error: string | null = null;

  /** Show in info icon next to the label opening an info modal */
  @Input() infoModal?: iInfoModal;

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  isFocused = false;

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  focus(): void {
    asyncScheduler.schedule(() => this.inputRef.nativeElement.focus());
  }

  clear() {
    this.writeValue('');
  }

  blur() {
    this.isFocused = false;
    this.inputRef.nativeElement.blur();

    this.onBlur.emit();
  }

  onFocusHandler(): void {
    this.isFocused = true;
  }

  onBlurHandler(event: any): void {
    this.isFocused = false;
    this.onBlur.emit(event);
  }

  onValueChange(value = '') {
    this.writeValue(value);
  }
}
