import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { BaseFormComponent } from '@semmie/components/_abstract';
import { CardStyle, Icon } from '@semmie/schemas';

@Component({
  selector: 'semmie-toggle',
  template: `
    <semmie-card *ngIf="cardStyle; else toggle" [cardStyle]="cardStyle">
      <ng-container *ngTemplateOutlet="toggle"></ng-container>
    </semmie-card>

    <ng-template #toggle>
      <div class="toggle-container" [ngClass]="getStyling()">
        <div class="toggle-header" hapticOnTap>
          <div *ngIf="icon" class="toggle-icon" (click)="triggerToggle()">
            <semmie-icon [icon]="icon"></semmie-icon>
          </div>
          <semmie-label *ngIf="label" (click)="triggerToggle()" type="text" size="base" [text]="label"></semmie-label>
          <ion-toggle class="primary" [name]="name" [value]="value" [disabled]="disabled" [label]="label" [(ngModel)]="value"></ion-toggle>
        </div>
        <semmie-label
          *ngIf="description"
          size="base_3"
          class="toggle-description pt-1"
          [htmlContent]="description"
          type="display"
        ></semmie-label>
      </div>
    </ng-template>
  `,
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent extends BaseFormComponent {
  @Input() icon: Icon;
  @Input() label?: string;
  @Input() description: string;
  @Input() cardStyle: CardStyle;
  @Input() classes: string;

  constructor(@Optional() @Self() ngControl: NgControl, cdr: ChangeDetectorRef) {
    super(ngControl, cdr);
  }

  getStyling() {
    const classes = new Array<string>();
    if (!this.cardStyle) {
      classes.push('pb-5');
    }
    if (this.classes) {
      classes.push(...this.classes.split(' '));
    }
    return classes.join(' ');
  }

  triggerToggle(): void {
    if (this.disabled) return;
    this.value = !this.value;
  }
}
