import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';

import { Formatter } from '../validator-formatter';
import { Denotation } from '@onyxx/model/validation';
import { OnyxxDenotationTranslatePipe } from '@onyxx/utility/translations';

@Injectable({ providedIn: 'root' })
export class MinValidator {
  constructor(private semmieCurrencyPipe: SemmieCurrencyPipe) {}

  /**
   * Check if the value is a number and is greater than or equal to the given minimum.
   * @param min The minimum value.
   * @param property The property of the value. This is used when the value is an object.
   * @param denotation The denotation of the value. This is used to translate the error message.
   */
  min(
    min: number | string,
    property?: string,
    denotation: Denotation = Denotation.Number,
    customMessage?: string,
    formatter?: Formatter,
  ): ValidatorFn {
    const onyxxDenotationTranslatePipe = new OnyxxDenotationTranslatePipe();

    return (control: AbstractControl): ValidationErrors | null => {
      const value = property ? control?.value?.[property] : control?.value;

      const numericValue = parseFloat(value);

      if (numericValue < Number(min)) {
        const isKnownDenotation = Object.values(Denotation).includes(denotation);
        const _denotation = isKnownDenotation ? onyxxDenotationTranslatePipe.transform(denotation) : denotation;

        switch (formatter) {
          case Formatter.CURRENCY:
            min = this.semmieCurrencyPipe.transform(min);
            break;
        }

        return new ValidatorMessage({
          code: 7,
          message: customMessage ?? $localize`:@@validation.min:This ${_denotation} must be at least ${min}.`,
          message_data: {
            min: min,
            denotation: _denotation,
          },
        });
      }

      return null;
    };
  }
}
