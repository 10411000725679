import { BaseModel } from '@semmie/models/_abstract';
import { iMessage, iMessageDocument } from '@semmie/schemas/bi/message/message';

export class Message extends BaseModel<iMessage> implements iMessage {
  created_at: string;
  description: string;
  id: string;
  read_at: string;
  title: string;
  body?: string;
  documents?: iMessageDocument[];
  path?: string;

  constructor(message: Partial<Message>) {
    super(message);
  }
}
