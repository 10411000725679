import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StickyCloseComponent } from './sticky-close.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

@NgModule({
  imports: [CommonModule, IconComponent],
  exports: [StickyCloseComponent],
  declarations: [StickyCloseComponent],
})
export class StickyCloseModule {}
