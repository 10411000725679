import { isSimpleRole } from './account-access-selectors';
import { AccountKind } from '../account-kind.enum';
import { Account } from '../account.interface';

export const isGrandparent = (account: Account): boolean => {
  return account.kind === AccountKind.CHILD && isSimpleRole(account);
};

export const isOrganisation = (account: Account): boolean => {
  return account.kind === AccountKind.ORGANISATION;
};

export const isAnnuity = (account: Account): boolean => {
  return account.kind === AccountKind.ANNUITY;
};

export const isAnnuityPayout = (account: Account): boolean => {
  return [AccountKind.ANNUITY_PAYOUT_LONG, AccountKind.ANNUITY_PAYOUT_SHORT].includes(account.kind);
};
