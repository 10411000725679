import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconSize, Icon, IconTheme } from '@semmie/schemas';
import { BaseComponent } from '@semmie/components/_abstract';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'semmie-icon',
  template: `
    <div (click)="onClickHandler($event)">
      <i class="icon semmie-icon flex" [ngClass]="[icon, theme, size]"></i>
    </div>
  `,
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent extends BaseComponent {
  @Input() size: IconSize | 'current' = 'xl';
  @Input() icon: Icon = Icon.ATOM;
  @Input() theme: IconTheme | '' = '';
  @Input() disabled = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  onClickHandler($event: any): void {
    if (!this.disabled) {
      this.onClick.emit($event);
    }
  }
}
