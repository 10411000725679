import { NgModule } from '@angular/core';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { CurrencyModule } from '@semmie/pipes/currency/currency-pipe.module';
import { PercentageModule } from '@semmie/pipes/percentage/percentage-pipe.module';
import { SharedModule } from '@semmie/shared/shared.module';

import { ProjectionCardComponent } from './projection-card.component';

@NgModule({
  imports: [SharedModule, IconComponent, LabelModule, PercentageModule, CurrencyModule],
  exports: [ProjectionCardComponent],
  declarations: [ProjectionCardComponent],
})
export class ProjectionCardModule {}
