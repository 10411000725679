import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Goal } from '@onyxx/model/goal';

export const goalCommonActions = createActionGroup({
  source: 'Goal Common',
  events: {
    Clear: emptyProps(),
    'Update goal in store': props<{ accountId: string; goal: Goal }>(),
  },
});
