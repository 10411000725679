import { Injectable, inject } from '@angular/core';
import { Observable, catchError, defer, map, of, tap } from 'rxjs';
import { FingerprintAIO, BIOMETRIC_ERRORS } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { AppStorageService, PlatformService } from '@semmie/services';
import { BiometricType } from '../models/biometric-type.type';
import { BiometricStatus } from '../models/biometric-status.enum';
import { BiometricStorageKey } from '../models/biometric-storage-key.enum';
import { BiometricAvailability } from '../models/biometric-availability.type';
import { Utils } from '@onyxx/utility/general';

export const enum PermissionCheckResultReason {
  NoPermission = 'no_permission',
  VerificationFailed = 'verification_failed',
}
export type PermissionCheckResult =
  | {
      success: true;
    }
  | {
      success: false;
      reason: PermissionCheckResultReason;
    };

@Injectable({
  providedIn: 'root',
})
export class NativeBiometricsService {
  private faio = inject(FingerprintAIO);
  private readonly appStorageService = inject(AppStorageService);
  private readonly platformService = inject(PlatformService);

  private readonly biometricTypeStorage = this.appStorageService.createStorageReader<BiometricType>(BiometricStorageKey.BiometricType);

  getAvailability = (): Observable<BiometricAvailability> => {
    if (!this.platformService.isApp) {
      const type: BiometricType = 'unknown';
      const status = BiometricStatus.Enrolled;
      return of({ status, type });
    }

    return defer(() => this.faio.isAvailable()).pipe(
      tap(async (bioMetricType) => {
        // cache the type in app storage so we have it when this
        // calls fail in the future
        await this.biometricTypeStorage.set(bioMetricType);
      }),
      map((bioMetricType) => {
        return { status: BiometricStatus.Enrolled, type: bioMetricType };
      }),
      catchError(async (error: { code: BIOMETRIC_ERRORS }) => {
        const codeStatusMap = new Map([
          [BIOMETRIC_ERRORS.BIOMETRIC_UNAVAILABLE, BiometricStatus.Disabled],
          [BIOMETRIC_ERRORS.BIOMETRIC_NOT_ENROLLED, BiometricStatus.NonEnrolled],
        ]);

        return {
          status: codeStatusMap.get(error.code) ?? BiometricStatus.Disabled,
          type: (await this.biometricTypeStorage.get()) ?? 'unknown',
        };
      }),
    );
  };

  authenticate = (): Observable<boolean> => {
    return defer(async () => {
      try {
        await this.faio.show({
          disableBackup: true,
        });
        return true;
      } catch (error) {
        return false;
      }
    });
  };

  requestPermission() {
    return defer(async (): Promise<PermissionCheckResult> => {
      try {
        await this.faio.show({
          disableBackup: true,
        });
      } catch (error) {
        if (
          Utils.isNotNil(error) &&
          Utils.asKeyOf<{ code: string }>('code') in error &&
          error.code === BIOMETRIC_ERRORS.BIOMETRIC_UNAVAILABLE
        ) {
          return { success: false, reason: PermissionCheckResultReason.NoPermission };
        }
        return { success: false, reason: PermissionCheckResultReason.VerificationFailed };
      }

      return { success: true };
    });
  }
}
