/**
 * Strip an unkown amount of of characters from the end of a string
 * @param strip content of what should be stripped from the value
 * @param value
 * @returns string
 */
export const recursivelyStripSuffix = (strip: string, value: string): string => {
  if (!value.endsWith(strip)) return value;
  return recursivelyStripSuffix(strip, value.replace(new RegExp(strip + '$'), ''));
};
