<div class="info-modal-content">
  <ng-container *ngIf="!customTemplate; else custom">
    <semmie-modal-header (onCloseClick)="closeModal()"></semmie-modal-header>
    <semmie-intro-content
      [title]="title | translate"
      [label]="subtitle | translate"
      [description]="description | translate"
      [image]="image"
      [button]="button | translate"
      [link_label]="conversionEnabled$$() ? (linkLabel | translate) : null"
      (onLinkLabelClick)="convertAccount()"
      (onButtonClick)="closeModal()"
    ></semmie-intro-content>
  </ng-container>
</div>

<ng-template #custom>
  <ng-content *ngTemplateOutlet="customTemplate"></ng-content>
</ng-template>
