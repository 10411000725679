import { Component, Input } from '@angular/core';
import { InfoModalComponent } from '@semmie/components/containers/modals/info-modal/info-modal.component';
import { BaseComponent } from '@semmie/components/_abstract';
import { ModalSize } from '@semmie/schemas/components/modal';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { ModalService } from '@semmie/services';
import { Utils } from '@semmie/shared/utils';
import { SharedModule } from '@semmie/shared/shared.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { InfoModalIconComponent } from '@onyxx/ui/info-modal-icon';

@Component({
  standalone: true,
  selector: 'semmie-form-input-label',
  templateUrl: './form-input-label.component.html',
  styleUrls: ['./form-input-label.component.scss'],
  imports: [SharedModule, IconComponent, InfoModalIconComponent],
})
export class FormInputLabelComponent extends BaseComponent {
  /** Id refering to the input component */
  @Input() formFieldId: string | number;
  @Input() infoModal?: iInfoModal;
  @Input() label: any;
  @Input() labelHint?: string | null;

  constructor(private modalService: ModalService) {
    super();
  }

  isDefined(property: any): boolean {
    return Utils.isNonNullOrUndefined(property);
  }

  async openInfoModal(): Promise<void> {
    if (!this.infoModal) return;

    await this.modalService.open(
      InfoModalComponent,
      {
        componentProps: {
          ...this.infoModal,
        },
      },
      { size: ModalSize.Auto },
    );
  }
}
