import { Formatter } from '@onyxx/utility/formatter';
import { BaseModel } from '@semmie/models/_abstract';
import { BankCode, bankNames } from '@onyxx/model/bank';
import { iPayment, PaymentProviderEnum, PaymentStateEnum } from '@semmie/schemas/bi/payment';

export class Payment extends BaseModel<iPayment> implements iPayment {
  id: string;
  provider: PaymentProviderEnum;
  amount: string;
  bank: string;
  state: PaymentStateEnum;
  customer_name: string;
  customer_iban: string;
  description: string;
  completed_at: string;
  redirect_url: string;

  constructor(props?: Partial<iPayment>) {
    super(props);
  }

  get new() {
    return this.state === PaymentStateEnum.NEW;
  }

  get busy() {
    return this.state === PaymentStateEnum.BUSY;
  }

  get succeeded() {
    return this.state === PaymentStateEnum.SUCCESS;
  }

  get failed() {
    return this.state === PaymentStateEnum.FAILED;
  }

  get cancelled() {
    return this.state === PaymentStateEnum.CANCELLED;
  }

  get timedOut() {
    return this.state === PaymentStateEnum.TIMEOUT;
  }

  /**
   * Get the bank name from BIC/SWIFT code
   * Used in `direct-debit-edit.json`
   */
  get bankName(): string | null {
    if (!this.bank) return null;
    const bankCode = BankCode[this.bank.substring(0, 4)];
    return bankNames[bankCode] ?? bankNames[BankCode.DEFAULT];
  }

  /**
   * Used in `direct-debit-edit.json`
   */
  get formattedIban(): string {
    return Formatter.formatIban(this.customer_iban);
  }
}
