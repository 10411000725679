import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Utils } from '@onyxx/utility/general';
import { PhoneNumberStoreFacade } from '@onyxx/store/phone-number';

export function phoneNumberVerificationReadyGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const phoneNumberStoreFacade = inject(PhoneNumberStoreFacade);

    return phoneNumberStoreFacade.phoneNumberPendingVerification$.pipe(
      map((number) => {
        if (Utils.isNil(number)) {
          return createUrlTreeFromSnapshot(route, ['..']);
        }

        return true;
      }),
    );
  };
}
