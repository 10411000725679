import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { userActivityFeature } from './user-activity.reducer';
import { UserActivityEffects } from './user-activity.effects';
import { UserActivityStoreFacade } from './user-activity.facade';

/**
 * The providers array is used to provide the user activity feature state, effects, and facade.
 * This can be used from standalone routing and modules, but not from standalone components.
 * In order to include it in standalone modules use the module grouping below.
 */
export const providers = [provideState(userActivityFeature), provideEffects(UserActivityEffects), UserActivityStoreFacade];

/**
 * The UserActivityStoreModule class is an angular module that provides services for managing user state.
 */
@NgModule({ providers })
export class UserActivityStoreModule {}

export * from './user-activity.facade';
