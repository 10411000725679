import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { DisableSwipeOnTouchDirective } from '@semmie/directives/disable-swipe-ontouch.directive';
import { NoVerticalScrollOnTouchDirective } from '@semmie/directives/no-vertical-scroll-ontouch.directive';
import { ShowOnPlatformDirective } from '@semmie/directives/show-on-platform.directive';

@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    DisableSwipeOnTouchDirective,
    NoVerticalScrollOnTouchDirective,
    ShowOnPlatformDirective,
  ],
  providers: [CurrencyPipe, DecimalPipe, PercentPipe],
  declarations: [DisableSwipeOnTouchDirective, NoVerticalScrollOnTouchDirective, ShowOnPlatformDirective],
})
export class SharedModule {}
