export enum BankCode {
  ABNA,
  ASNB,
  BUNQ,
  INGB,
  KNAB,
  MOYO,
  RABO,
  RBRB,
  SNSB,
  TRIO,
  FVLB,
  HAND,
  REVO,
  NNBA,
  // Belgium banks
  BELF,
  BNPB,
  BPOST,
  CENT,
  CITI,
  FINT,
  KBCB,
  // German banks
  N26,
  DEFAULT,
}
