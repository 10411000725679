import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const phoneNumberApiActions = createActionGroup({
  source: 'phone number API',
  events: {
    Verify: props<{ code: string }>(),
    'Verify Success': emptyProps(),
    'Verify Failure': props<{ error: unknown }>(),

    'Resend Code': emptyProps(),
    'Resend Code Success': emptyProps(),
    'Resend Code Failure': props<{ error: unknown }>(),
  },
});
