import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { IonicModule, Platform } from '@ionic/angular';
import { PinInputComponent } from '@onyxx/ui/pin-input';
import { Utils } from '@onyxx/utility/general';
import { ContentContainerModule, HeaderModule, LabelModule, PageHeaderModule } from '@semmie/components';
import { SemmiePhonePipe } from '@semmie/pipes/phone/phone.pipe';
import { asyncScheduler, distinctUntilChanged, firstValueFrom, map, of, startWith, switchMap } from 'rxjs';
import { countDownSecondsUtil, filterNil } from '@onyxx/utility/observables';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberStoreFacade, PhoneNumberStateModule } from '@onyxx/store/phone-number';
import { PlatformService } from '@semmie/services';

@Component({
  standalone: true,
  template: `
    <semmie-content-container size="lg" padding="px-10" (click)="focusInput()">
      <semmie-page-header
        [leftAction]="leftAction"
        [rightAction]="rightAction"
        (onLeftIconClick)="onBack()"
        (onRightIconClick)="onClose()"
      ></semmie-page-header>
      <semmie-content-container-top>
        <semmie-header
          [label]="label"
          i18n-title="@@edit-phone-number.confirm.title"
          title="Validate SMS code"
          i18n-description="@@edit-phone-number.confirm.description"
          description="Enter the code sent via SMS to {{ obfuscatedPhoneNumber$$() }}."
        ></semmie-header>
      </semmie-content-container-top>
      <div class="pt-28">
        <onyxx-pin-input [attr.autofocus]="true" [formControl]="otpFormControl" />
      </div>
      <div class="p-6">
        <semmie-label
          [class]="verificationResendTimerText$$()?.disabled ? 'link-disabled' : 'link'"
          type="text"
          size="base_2"
          align="center"
          [text]="verificationResendTimerText$$()?.text"
          (onClick)="sendSms()"
        ></semmie-label>
      </div>
    </semmie-content-container>
  `,
  selector: 'onyxx-verify-phone-number',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    ContentContainerModule,
    PageHeaderModule,
    HeaderModule,
    PhoneNumberStateModule,
    PinInputComponent,
    LabelModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class VerifyPhoneNumberComponent implements AfterViewInit {
  private readonly phoneNumberStoreFacade = inject(PhoneNumberStoreFacade);
  private readonly platformService = inject(PlatformService);
  private readonly platform = inject(Platform);

  private readonly phonePipe = inject(SemmiePhonePipe);

  @ViewChild(PinInputComponent) pinInput: PinInputComponent | undefined;

  @Input() label: string = $localize`:@@edit-phone-number.confirm.label:Verify your mobile number`;
  @Input() leftAction: boolean = true;
  @Input() rightAction: boolean = true;
  @Output() readonly back = new EventEmitter<void>();
  @Output() readonly exit = new EventEmitter<void>();
  @Output() readonly verificationSuccess = new EventEmitter<void>();

  readonly obfuscatedPhoneNumber$$ = toSignal(
    this.phoneNumberStoreFacade.phoneNumberPendingVerification$.pipe(
      filterNil(),
      map((phoneNumberPendingVerification) => {
        return this.phonePipe.transform(
          phoneNumberPendingVerification.phone,
          phoneNumberPendingVerification.country_phone.iso3,
          'international',
          true,
        );
      }),
      startWith('**********'),
      map((phoneNumber) => `<strong>${phoneNumber}</strong>`),
    ),
    {
      requireSync: true,
    },
  );

  readonly verificationResendTimerText$$ = toSignal(
    this.phoneNumberStoreFacade.resendAllowedAt$.pipe(
      switchMap((value) => {
        if (Utils.isNil(value)) {
          return of(null);
        }
        return countDownSecondsUtil(value, { humanized: true });
      }),
      map((countdownText) => {
        if (Utils.isNil(countdownText)) {
          return {
            disabled: false,
            text: $localize`:@@verify-phone-number.confirm.resend:Resend code`,
          };
        }
        return {
          disabled: true,
          text: $localize`:@@verify-phone-number.confirm.resend_timer:Haven't received a code? Resend ${countdownText}`,
        };
      }),
    ),
  );

  otpFormControl = new FormControl('');

  constructor() {
    this.phoneNumberStoreFacade.verificationFailure$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.otpFormControl.setValue('');
      this.focusInput();
    });

    this.phoneNumberStoreFacade.verificationBusy$.pipe(takeUntilDestroyed()).subscribe((busy) => {
      if (busy) {
        this.otpFormControl.disable({ emitEvent: false });
      } else {
        this.otpFormControl.enable({ emitEvent: false });
      }
    });

    this.otpFormControl.valueChanges.pipe(takeUntilDestroyed(), filterNil(), distinctUntilChanged()).subscribe((value) => {
      if (value.length === 6) {
        this.phoneNumberStoreFacade.dispatchVerify(value);
      }
    });

    this.phoneNumberStoreFacade.verificationSuccess$.pipe(takeUntilDestroyed()).subscribe(async () => {
      this.pinInput?.blur();

      if (this.platformService.isApp) {
        await firstValueFrom(this.platform.keyboardDidHide);
      }

      this.verificationSuccess.emit();
    });
  }

  ngAfterViewInit(): void {
    asyncScheduler.schedule(() => {
      this.focusInput();
    }, 350);
  }

  onBack() {
    this.back.emit();
  }

  onClose() {
    this.exit.emit();
  }

  sendSms() {
    this.phoneNumberStoreFacade.dispatchResendCode();
  }

  focusInput() {
    this.pinInput?.focus();
  }
}
