import { Component, Input } from '@angular/core';
import { IconComponent, LabelModule } from '@semmie/components';
import { Icon } from '@semmie/schemas';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, IconComponent, LabelModule],
  selector: 'semmie-disclaimer',
  templateUrl: 'disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  @Input() prefix = $localize`:@@disclaimer.prefix:Note:&nbsp;`;
  @Input() withPrefix = true;
  @Input() style: 'warning' | 'danger' = 'danger';

  Icon = Icon;
}
