import { Injectable, inject } from '@angular/core';
import { RateApp } from 'capacitor-rate-app';
import { AppStateStorage, AppStateStorageKeys } from '@semmie/schemas';
import { PlatformService, AppStorageService, MarketService, ModalService, ConfigService } from '@semmie/services';
import { firstValueFrom, map } from 'rxjs';
import { Utils } from '@onyxx/utility/general';
import { UserStoreFacade } from '@semmie/store/user';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppRateService {
  private readonly platformService = inject(PlatformService);
  private readonly marketService = inject(MarketService);
  private readonly appStorageService = inject(AppStorageService);
  private readonly modalService = inject(ModalService);
  private readonly userFacade = inject(UserStoreFacade);
  private readonly configService = inject(ConfigService);

  private readonly appStateStorage = this.appStorageService.createStorageReader<AppStateStorage>(AppStateStorageKeys.AppState);

  protected readonly appRateModalConfig$ = this.configService.config$.pipe(
    map((config) => {
      return {
        enabled:
          (this.platformService.isApp && this.platformService.isAndroid && config.config.features?.appRateModal?.android) ||
          (this.platformService.isApp && config.config.features?.appRateModal?.iOS),
        threshold: config.config.features?.appRateModal?.threshold,
      };
    }),
  );

  protected readonly externalUrlsConfig$ = this.configService.config$.pipe(map((config) => config.config.external_urls));

  /**
   * Show the store review prompt to the user
   * - App only
   * - Only on specific timed app launches
   */
  async requestStoreReview() {
    // On development in iOS the prompt will popup every time
    // So it's only enabled in production
    if (!this.platformService.isApp || !environment.production) return;
    const appRateModalConfig = await firstValueFrom(this.appRateModalConfig$);
    const appStateStorage = await this.appStateStorage.get();
    // There is a limit of 3 times per year for the prompt set by Apple, Android has a similar quota
    // To prevent the prompt from showing right after a new install, we only show it after a certain amount of launches
    // The threshold is set in the configuration
    if (appRateModalConfig.enabled && appStateStorage?.nrOfLaunches && appStateStorage?.nrOfLaunches >= appRateModalConfig.threshold) {
      RateApp.requestReview();
    }
  }

  /**
   * Ask the user to rate the app in the store via an confirmation modal
   */
  async manualRequestStoreReview() {
    const storeName = await this.marketService.getStoreName();
    const modal = await this.modalService.openConfirmationModal({
      title: $localize`:@@app-rate.review-modal.title:Write a review`,
      description: $localize`:@@app-rate.review-modal.description:We would love to hear your feedback! Would you like to leave a review in the ${storeName}?`,
      okButtonLabel: $localize`:@@app-rate.review-modal.ok-button-label:Write a review`,
      cancelButtonLabel: $localize`:@@app-rate.review-modal.cancel:No, I'll do it later`,
      canDismiss: true,
    });

    const { data } = await modal.onDidDismiss();

    if (data) {
      this.platformService.isApp ? this.marketService.openStore() : this.marketService.openStoreUrl();
    }
  }

  /**
   * Open the Kiyoh review page
   * - App: In app browser
   * - Web: New tab
   */
  async openKiyohReviewPage() {
    const externalUrlsConfig = await firstValueFrom(this.externalUrlsConfig$);

    const user = await firstValueFrom(this.userFacade.user$);
    if (Utils.isNil(user)) return;

    const kiyohUrl = `${externalUrlsConfig?.kiyoh}&reference_code=${user.id}&email=${encodeURIComponent(user.email ?? '')}`;
    if (this.platformService.isApp) {
      await Utils.openInAppBrowser(kiyohUrl);
    } else {
      await Utils.openUrl(kiyohUrl, { newTab: true });
    }
  }
}
