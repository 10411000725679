import { Injectable, inject } from '@angular/core';
import { Filesystem } from '@capacitor/filesystem';
import { CameraService } from '@semmie/services/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';

export const enum FilePickerSources {
  Camera = 'camera',
  Library = 'library',
  Browse = 'browse',
}
export type FilePickerOptions = {
  fileTypes?: ('document' | 'image')[];
  multiple?: boolean;
};

@Injectable({ providedIn: 'root' })
export class FilePickerService {
  private cameraService = inject(CameraService);

  /** Get an file, and depending on the platform, allow using the camera, selecting images from the photo library or browsing for files
   *
   * @returns a file or false if it was not successful
   */
  async pickFile(source: FilePickerSources, options: FilePickerOptions = {}) {
    if (source === FilePickerSources.Browse) {
      return await this.browseForFile(options);
    }

    if (source === FilePickerSources.Camera) {
      const photo = await this.cameraService.getPhotoFromCamera();
      if (!photo) return false;

      return await this.fileFromPhoto(photo);
    }

    if (source === FilePickerSources.Library) {
      const photos = await this.cameraService.getPhotoFromLibrary({ multiple: options.multiple });
      if (photos === false) return false;

      // TODO: Loading the file into memory takes long and make the file selection process feel slow and unresponsive
      const loadedFiles = await Promise.allSettled(photos.map((photo) => this.fileFromPhoto(photo)));
      const fileList = loadedFiles
        .filter((result): result is PromiseFulfilledResult<File> => result.status === 'fulfilled')
        .map((result) => result.value);

      return fileList;
    }

    return false;
  }

  private async browseForFile(options: FilePickerOptions) {
    const result = await FilePicker.pickFiles({
      limit: options.multiple ? 0 : 1,
      types: options.fileTypes?.flatMap(
        (inputFileType) =>
          ({
            document: 'application/pdf',
            image: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
          })[inputFileType],
      ),
    });

    const fileResults = await Promise.allSettled(
      result.files.map(async (file) => {
        let blob = file.blob;
        const fileName = file.name;

        if (!blob) {
          // on mobile blob is undefined, fall back to reading file from path
          if (file.path == null) {
            return false;
          }

          // TODO: Loading the file into memory takes long and make the file selection process feel slow and unresponsive
          const localFile = await Filesystem.readFile({
            path: file.path,
          });

          blob = this.b64toBlob(localFile.data as string, file.mimeType);
        }

        const metadata = {
          type: file.mimeType,
        };

        return new File([blob], fileName, metadata);
      }),
    );

    const fileList = fileResults
      .filter((result): result is PromiseFulfilledResult<File> => result.status === 'fulfilled')
      .map((result) => result.value);

    return fileList;
  }

  private async fileFromPhoto(fileUri: string) {
    const localFile = await Filesystem.readFile({
      path: fileUri,
    });

    const fileName = this.fileNameFromPath(fileUri);

    const blob = this.b64toBlob(localFile.data as string, 'image/jpeg');
    const metadata = {
      type: 'image/jpeg',
    };

    return new File([blob], fileName, metadata);
  }

  private b64toBlob = (base64Data: string, contentType: string) => {
    const decodedData = atob(base64Data);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: contentType });
  };

  private fileNameFromPath(path: string) {
    return path.split('\\').reverse()[0].split('/').reverse()[0];
  }
}
