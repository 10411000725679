import { NgModule, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgswUpdateService } from '@semmie/services/ngsw-update/ngsw-update.service';
import { userAreaRoutes } from './user-area.routes';
@NgModule({
  imports: [RouterModule.forChild(userAreaRoutes)],
})
export class UserAreaModule {
  private readonly ngswUpdateService = inject(NgswUpdateService);

  constructor() {
    this.ngswUpdateService.checkForUpdates();
  }
}
