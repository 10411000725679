import { AccountUserRole } from '../account-user-role.enum';
import { AccountKind } from '../account-kind.enum';
import { Account } from '../account.interface';
import { isGrandparent } from './account-type-selectors';
import { PersonRoleEnum } from '@semmie/schemas/bi/person/person-role';
import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { iOrganisationPerson } from '@semmie/schemas';
import { iUserPerson } from '@onyxx/model/user';

export const isOwner = (account: Account): boolean => {
  return account.user_role === AccountUserRole.OWNER;
};

export const isSimpleRole = (account: Account): boolean => {
  return account.user_role === AccountUserRole.SIMPLE;
};

export const isReadOnlyRole = (account: Account): boolean => {
  return account.user_role === AccountUserRole.READ_ONLY;
};

export const isPowerOfAttorneyRole = (account: Account): boolean => {
  return account.user_role === AccountUserRole.POWER_OF_ATTORNEY;
};

export const isFinancialAdministratorRole = (account: Account): boolean => {
  return account.user_role === AccountUserRole.FINANCIAL_ADMINISTRATOR;
};

export const hasFullRights = (account: Account): boolean => {
  return isOwner(account) || isFinancialAdministratorRole(account);
};

export const hasBasicRights = (account: Account): boolean => {
  return isPowerOfAttorneyRole(account);
};

export const hasSimpleRights = (account: Account): boolean => {
  return isSimpleRole(account);
};

export const hasReadOnlyRights = (account: Account): boolean => {
  return isReadOnlyRole(account);
};

export const kindByRole = (account: Account): AccountKind => {
  return isGrandparent(account) ? AccountKind.GRANDPARENT : account.kind;
};

export const canEditAccountHolder = (
  account: Account,
  personEdited: Partial<AccountPerson | iOrganisationPerson>,
  currentPerson: iUserPerson,
) => {
  if (currentPerson.id === personEdited.id) return true;

  if (account.kind === AccountKind.CHILD && personEdited.role === PersonRoleEnum.OWNER && isOwner(account)) return true;

  return false;
};
