import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { Message } from '@semmie/models/bi/message';
import { MessageDetail } from '@semmie/models/bi/message/message-detail.model';
import { AccountsProvider } from '@semmie/providers/accounts/accounts.provider';
import { iBaseListableService } from '@semmie/schemas/services/list/base-service';
import { AccountMessagesStore } from '@semmie/store/accounts/account-messages.store';
import { DEFAULT_PER_PAGE, PaginationParams, PaginatedResponse } from '@onyxx/model/pagination';
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';

@Injectable({
  providedIn: 'root',
})
export class AccountMessagesService implements iBaseListableService<Message> {
  private readonly account$ = this.accountStoreFacade.account$.pipe(filterNil());
  constructor(
    private accountStoreFacade: AccountStoreFacade,
    private accountsProvider: AccountsProvider,
    private accountMessagesStore: AccountMessagesStore,
  ) {}

  list(params?: PaginationParams, refresh?: boolean): Observable<PaginatedResponse<Message>> {
    const page = params?.page ?? 1;
    const pageCache = this.accountMessagesStore.getCachedPage(page);

    if (refresh || !pageCache?.data || !pageCache?.data?.length) {
      if (refresh) {
        this.accountMessagesStore.clearList();
      }

      return this.account$.pipe(take(1)).pipe(
        switchMap((account) => this.accountsProvider.listMessages(account.id, params?.page ?? page, params?.per_page ?? DEFAULT_PER_PAGE)),
        tap((response) => this.accountMessagesStore.updateCachedPageByPage(page, response)),
      );
    }

    return this.accountMessagesStore.messages$;
  }

  getMessage(id: string, refresh?: boolean) {
    const cache = this.accountMessagesStore.details$.value;

    if (!cache || cache?.id !== id || refresh) {
      return this.account$.pipe(take(1)).pipe(
        switchMap((account) => this.accountsProvider.getAccountMessage(account.id, id)),
        tap((message) => {
          this.accountMessagesStore.updateMessageDetail(message);
        }),
      );
    }

    return this.accountMessagesStore.details$;
  }

  updateMessage(data: MessageDetail) {
    this.accountMessagesStore.flatList$
      .pipe(
        take(1),
        tap((list) => {
          const cachedMessage = list.data.find((msg) => msg.id === data.id);

          if (cachedMessage && !cachedMessage.read_at) {
            this.accountStoreFacade.dispatchDecrementCurrentAccountUnreadInboxCountInStore();
          }
        }),
      )
      .subscribe(() => {
        this.accountMessagesStore.update(data);
        this.accountMessagesStore.updateCachedPage(data);
      });
  }

  clear() {
    this.accountMessagesStore.clear();
  }
}
