import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PersonProvider } from '@onyxx/provider/person';
import { phoneNumberCommonActions } from './phone-number-common.actions';
import { Utils } from '@onyxx/utility/general';
import { phoneNumberApiActions } from './phone-number-api.actions';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ToastService } from '@semmie/services';
import { GenericErrorToast, ToasterDuration, ToasterStyle } from '@semmie/schemas/components/toast';
import { PhoneNumberStoreFacade } from './phone-number.facade';
import { Store } from '@ngrx/store';
import { phoneNumberFeature } from './phone-number.reducer';

export class PhoneNumberEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly personProvider = inject(PersonProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly toastService = inject(ToastService);
  private readonly phoneNumberStoreFacade = inject(PhoneNumberStoreFacade);

  readonly verifyPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(phoneNumberApiActions.verify),
      concatLatestFrom(() => this.store.select(phoneNumberFeature.selectPhoneNumberPendingVerificationPersonId)),
      switchMap(([{ code }, personId]) => {
        if (Utils.isNil(personId)) return of(phoneNumberApiActions.verifyFailure({ error: new Error('phone number setup up correctly') }));

        return this.personProvider.verifyPhone(personId, code).pipe(
          map(() => phoneNumberApiActions.verifySuccess()),
          catchError((error: Error) => {
            return of(phoneNumberApiActions.verifyFailure({ error }));
          }),
        );
      }),
    );
  });

  readonly showVerifyErrorMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(phoneNumberApiActions.verifyFailure),
        tap(({ error }) => {
          const errorStatus = error instanceof HttpErrorResponse ? error.status : '';
          if (errorStatus === HttpStatusCode.UnprocessableEntity) {
            this.toastService.show({
              message: $localize`:@@edit-phone-number.error.incorrect-code:The entered code is incorrect`,
              style: ToasterStyle.DANGER,
            });
            return;
          }
          return this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );

  readonly resendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(phoneNumberApiActions.resendCode),
      concatLatestFrom(() => [
        this.phoneNumberStoreFacade.resendAllowedAt$,
        this.store.select(phoneNumberFeature.selectPhoneNumberPendingVerificationPersonId),
      ]),
      filter(
        ([, phoneVerificationResendAllowedAt]) =>
          Utils.isNil(phoneVerificationResendAllowedAt) || phoneVerificationResendAllowedAt < Date.now(),
      ),
      switchMap(([, , personId]) => {
        if (Utils.isNil(personId)) return of(phoneNumberApiActions.resendCodeFailure({ error: new Error('person no available') }));

        return this.personProvider.resendPhoneVerification(personId).pipe(
          map(() => phoneNumberApiActions.resendCodeSuccess()),
          catchError((error: Error) => {
            return of(phoneNumberApiActions.resendCodeFailure({ error }));
          }),
        );
      }),
    );
  });

  readonly showResendErrorMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(phoneNumberApiActions.resendCodeFailure),
        tap(({ error }) => {
          const errorStatus = error instanceof HttpErrorResponse ? error.status : '';
          if (errorStatus === HttpStatusCode.UnprocessableEntity) {
            this.toastService.show({
              message: $localize`:@@edit-phone-number.resend.error.too-many-request:The maximum number of attempts has been reached. Do you still not receive a code? Then contact our customer service.`,
              style: ToasterStyle.WARNING,
              duration: ToasterDuration.MEDIUM,
            });
            return;
          }
          return this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => phoneNumberCommonActions.clear()));
  });
}
