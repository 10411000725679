import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authApiActions } from '../auth-api.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AuthProvider } from '@onyxx/provider/auth';
import { ToastService } from '@semmie/services';
import { ToasterDuration, ToasterStyle } from '@semmie/schemas/components/toast';

export class PasswordSetEffects {
  private readonly actions$ = inject(Actions);
  private readonly authProvider = inject(AuthProvider);
  private readonly toastService = inject(ToastService);

  readonly $setPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.setPassword),
      switchMap(({ email, password, token }) => {
        return this.authProvider
          .setPassword({
            user: { password },
            token,
          })
          .pipe(
            tap(() => {
              this.toastService.show({
                header: $localize`:@@auth.set-password.success-toast.header:New password`,
                message: $localize`:@@auth.set-password.success-toast.message:Your new password has been configured. Use it when logging in in the future.`,
                style: ToasterStyle.SUCCESS,
                duration: ToasterDuration.MEDIUM,
              });
            }),
            map(() => authApiActions.setPasswordSuccess({ password, email })),
            catchError((error) => of(authApiActions.setPasswordFailure({ error }))),
          );
      }),
    );
  });

  readonly loginAfterPasswordSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.setPasswordSuccess),
      map(({ email, password }) => authApiActions.login({ username: email, password: password })),
    );
  });

  readonly setPasswordFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.setPasswordFailure),
        tap(() => {
          this.toastService.show({
            header: $localize`:@@auth.set-password.error-toast.header:Something went wrong`,
            message: $localize`:@@auth.set-password.error-toast.error:Go back to the login page and request a new password reset link.`,
            id: 'set-password-toast',
            style: ToasterStyle.DANGER,
          });
        }),
      );
    },
    { dispatch: false },
  );
}
