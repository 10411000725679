import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IbanFormatterPipe } from './iban-formatter.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [IbanFormatterPipe],
  declarations: [IbanFormatterPipe],
})
export class IbanFormatterModule {}
