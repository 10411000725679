import { createActionGroup, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account, AccountMetadata, AccountProfileAudit } from '@onyxx/model/account';

export const accountApiActions = createActionGroup({
  source: 'Account API',
  events: {
    'Load account': props<{ id: string }>(),
    'Load account success': props<{ account: Account }>(),
    'Load account failure': props<{ errorMsg: string }>(),

    'Create account': props<{ account: Partial<Account> }>(),
    'Create account success': props<{ account: Account }>(),
    'Create account failure': props<{ errorMsg: string }>(),

    'Update account': props<{ id: Account['id']; account: Partial<Account & AccountProfileAudit> }>(),
    'Update account success': props<{ account: Account }>(),
    'Update account failure': props<{ errorMsg: string }>(),

    'Delete account': props<{ id: Account['id'] }>(),
    'Delete account success': props<{ id: Account['id'] }>(),
    'Delete account failure': props<{ errorMsg: string }>(),

    'Update metadata partial': props<{ id: Account['id']; metadata: Partial<AccountMetadata> }>(),
    'Update metadata': props<{ id: Account['id']; metadata: AccountMetadata }>(),
    'Update metadata success': props<{ account: Account }>(),
    'Update metadata failure': props<{ errorMsg: string }>(),
  },
});
