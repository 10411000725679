<ng-container
  *ngIf="{
    imageSrc: imageSrc$ | async,
    imageError: imageError$ | async,
  } as ctx"
>
  <div class="image-wrapper" [ngStyle]="{ width: width + 'px' || 'auto', height: height + 'px' || 'auto' }" (click)="onClickHandler()">
    <div *rxIf="ctx.imageSrc" [class]="'image-container ' + styling" [class.shadow]="shadow" [class.clickable]="clickable">
      <object
        *ngIf="ctx.imageSrc.isSvg && useObject; else imageTpl"
        type="image/svg+xml"
        preserveAspectRatio="xMidYMid meet"
        [width]="width + 'px'"
        [height]="height + 'px'"
        [data]="(!ctx.imageError ? ctx.imageSrc.themed || ctx.imageSrc.default : ctx.imageSrc.default) | safe: 'url'"
      >
        <ng-container *ngTemplateOutlet="imageTpl"></ng-container>
      </object>

      <ng-template #imageTpl>
        <img
          [class]="'image ' + styling + ' ' + objectFit"
          [style.objectPosition]="objectPosition"
          [width]="width"
          [height]="height"
          [src]="!ctx.imageError ? ctx.imageSrc.themed || ctx.imageSrc.default : ctx.imageSrc.default"
          [alt]="alt"
          (error)="onImageError()"
        />
      </ng-template>

      <div *rxIf="!ctx.imageSrc" class="image-placeholder" style="background-image: url('{{ placeholderImage }}');"></div>
    </div>
  </div>
</ng-container>
