<div class="input-container group">
  <semmie-form-input-label [infoModal]="infoModal" [label]="label"></semmie-form-input-label>
  <ng-container [ngSwitch]="loading">
    <ng-container *ngSwitchCase="true">
      <div class="progressBar-wrapper">
        <p-progressBar class="progressBar" mode="indeterminate" [style]="{ height: '1px' }"></p-progressBar>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <ng-container *ngIf="isApp; else webDropdownTpl">
        <div class="mobile-dropdown" [class.disabled]="disabled" (click)="open()">
          <div class="value" [class.selected]="selectedOption">
            <ng-container *ngTemplateOutlet="imageTpl"></ng-container>

            {{ selectedOption?.label ?? placeholder }}
          </div>
          <div *ngIf="!disabled" class="icon">
            <semmie-icon size="xs" [icon]="Icon.CHEVRONDOWN"></semmie-icon>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #webDropdownTpl>
      <div class="web-dropdown">
        <div *ngIf="selectedOption?.image" class="web-dropdown__image">
          <ng-container *ngTemplateOutlet="imageTpl"></ng-container>
        </div>
        <select
          [id]="componentId"
          [ngModel]="value | json"
          [ngClass]="{ hasImage: selectedOption?.image, placeholder: value === null }"
          (change)="onSelectChange($event.target?.['value'])"
          [disabled]="disabled"
        >
          <option *ngIf="placeholder" [value]="null" disabled selected hidden>{{ placeholder }}</option>
          <option *ngFor="let option of selectItems" [value]="option.value | json">{{ option.label }}</option>
        </select>
        <div *ngIf="!disabled" class="web-dropdown__select_icon">
          <semmie-icon size="xs" [icon]="Icon.CHEVRONDOWN"></semmie-icon>
        </div>
      </div>
    </ng-template>

    <ng-template #imageTpl>
      <semmie-image
        *ngIf="selectedOption?.image"
        [styling]="selectedOption?.imageStyle === iDropdownImageStyle.Rounded ? 'rounded-sm' : ''"
        [width]="24"
        [src]="selectedOption?.image"
      ></semmie-image>
    </ng-template>
  </ng-container>
</div>
