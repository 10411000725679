import { Injectable } from '@angular/core';
import { Message, MessageDetail } from '@semmie/models/bi/message';
import { ListableStore } from '@semmie/models/services/listable-store';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountMessagesStore extends ListableStore<Message> {
  messages$ = this.flatList$;
  details$: BehaviorSubject<MessageDetail | null> = new BehaviorSubject(null);

  constructor() {
    super(undefined, Message);
  }

  updateMessageDetail(details: MessageDetail) {
    this.details$.next(details);
  }

  clear() {
    this.update(null);
    this.details$.next(null);
    this.list.next(new Map());
  }
}
