import { Injectable, inject } from '@angular/core';
import { AppIcon } from '@capacitor-community/app-icon';
import { AppIconStorageKeys, AppIconTheme } from '@semmie/schemas';
import { PlatformService, AppStorageService } from '@semmie/services';
import { Subject, catchError, distinctUntilChanged, filter, from, map, merge, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppIconService {
  private updatedAppIcon$ = new Subject<string>();

  private readonly platformService = inject(PlatformService);
  private readonly appStorageService = inject(AppStorageService);

  private readonly appIcons = [AppIconTheme.IconBlack, AppIconTheme.IconWhite, AppIconTheme.IconWord];

  private readonly appIconStorage = this.appStorageService.createStorageReader<string>(AppIconStorageKeys.AppIcon);

  readonly iconSwitchIsSupported$ = of(this.platformService.isApp).pipe(
    filter(Boolean),
    switchMap(() => from(AppIcon.isSupported())),
    map((isSupported) => isSupported.value),
    catchError(() => of(false)),
  );

  readonly currentAppIcon$ = merge(
    of(this.platformService.isApp).pipe(
      filter(Boolean),
      switchMap(() =>
        from(AppIcon.getName()).pipe(
          switchMap((alternateIcon) => {
            if (alternateIcon.value) {
              return of(alternateIcon.value);
            } else {
              return from(this.appIconStorage.get()).pipe(map((icon) => icon ?? AppIconTheme.IconBlack));
            }
          }),
        ),
      ),
    ),
    this.updatedAppIcon$,
  ).pipe(distinctUntilChanged());

  readonly currentAppIconUrl$ = this.currentAppIcon$.pipe(map((icon) => this.getIconUrlPath(icon)));

  getIconUrlPath(icon: string): string {
    return `assets/img/app_icons/${icon}.png`;
  }

  async updateIcon(name: string) {
    await this.appIconStorage.set(name);
    AppIcon.change({ name, suppressNotification: true, disable: this.appIcons.filter((icon) => icon !== name) });
    this.updatedAppIcon$.next(name);
  }

  async resetIcon() {
    await this.appIconStorage.remove();
    AppIcon.reset({ suppressNotification: true });
    this.updatedAppIcon$.next(AppIconTheme.IconBlack);
  }
}
