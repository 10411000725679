import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { Utils } from '@onyxx/utility/general';

const MILLISECONDS_PER_DAY = 86400000;

export enum DateFormat {
  /** Full date and time e.g. 5 september 2023 om 09:22 */
  DateTimeLong = 'datetime-long',
  /** Full date with day, month and year e.g. 5 september 2023 */
  DateLong = 'date-long',
  /** Short date with date and month e.g. 5 sep 2024 */
  DateShort = 'date-short',
  /** Shorted month with year e.g. sep'24 */
  MonthYearShort = 'month-year-short',
  /**  month with year e.g. september 2024 */
  MonthYearLong = 'month-year-long',
  /** Month long e.g. december */
  MonthFull = 'month-full',
  /** The year e.g. 2024 */
  YearLong = 'year',
  /** Depending on the current date it would be
   * * Time if it's the same day (e.g. 15:22)
   * * Short day of the week (e.g. mon) if it's the same week
   * * Short date otherwise (e.g. 5 sep 2023)
   */
  TimeAgo = 'since-now',
  /** Time of day e.g. 15:43 */
  Time = 'time',
}

@Pipe({
  name: 'onyxxDate',
  standalone: true,
  pure: true,
})
export class OnyxxDatePipe implements PipeTransform {
  private readonly locale = inject(LOCALE_ID);

  transform(value: string | number | Date, format: DateFormat): string {
    const date = Utils.parseDate(value);

    switch (format) {
      case DateFormat.DateLong:
        return date.toLocaleDateString(this.locale, {
          dateStyle: 'long',
        });
      case DateFormat.DateShort:
        return new Intl.DateTimeFormat(this.locale, {
          day: 'numeric',
          month: 'long',
        }).format(date);
      case DateFormat.MonthYearLong:
        return new Intl.DateTimeFormat(this.locale, {
          month: 'long',
          year: 'numeric',
        }).format(date);
      case DateFormat.MonthYearShort:
        return new Intl.DateTimeFormat(this.locale, {
          month: 'short',
          year: '2-digit',
        })
          .format(date)
          .replace(' ', '\u2019');
      case DateFormat.MonthFull:
        return date.toLocaleString(this.locale, { month: 'long' });
      case DateFormat.YearLong:
        return date.getFullYear().toString();
      case DateFormat.TimeAgo:
        return this.formatTimeAgo(date);
      case DateFormat.DateTimeLong:
        return this.formatDateTime(date);
      case DateFormat.Time:
        return new Intl.DateTimeFormat(this.locale, {
          hour: '2-digit',
          minute: '2-digit',
        }).format(date);
    }
  }

  private formatTimeAgo(date: Date): string {
    const currentDateTimestamp = Date.now();
    const daysDiff = (currentDateTimestamp - date.getTime()) / MILLISECONDS_PER_DAY;

    if (daysDiff <= 1) {
      return new Intl.DateTimeFormat(this.locale, {
        hour: '2-digit',
        minute: '2-digit',
      }).format(date);
    }

    if (daysDiff <= 7) {
      return new Intl.DateTimeFormat(this.locale, {
        weekday: 'short',
      }).format(date);
    }

    return new Intl.DateTimeFormat(this.locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(date);
  }

  private formatDateTime(value: Date): string {
    const date = this.transform(value, DateFormat.DateLong);
    const time = this.transform(value, DateFormat.Time);

    return $localize`:@@date-format.date-time:${date} at ${time}`;
  }
}
