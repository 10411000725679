import { Component, Input } from '@angular/core';
import { iTableContent } from '@semmie/schemas/components/table/table-content.interface';

@Component({
  selector: 'semmie-table',
  template: `
    <div *ngIf="tableContent" class="w-full">
      <div class="mb-4 flex w-full justify-between space-x-2" *ngFor="let row of tableContent">
        <div class="text-left"><semmie-label class="table-left" [text]="row.left" size="base_2"></semmie-label></div>
        <div class="shrink text-right">
          <semmie-label class="table-right" [text]="row.right" size="base_2" weight="medium"></semmie-label>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .table-left {
        color: var(--color-table-left);
      }
      .table-right {
        color: var(--color-table-right);
      }
    `,
  ],
})
export class TableComponent {
  @Input() tableContent: iTableContent[];
}
