import { PaginationMeta } from '@onyxx/model/pagination';
import { Utils } from '@onyxx/utility/general';

export const updateMetaData = (paginationMeta: PaginationMeta | null, action: 'increment' | 'decrement'): PaginationMeta | null => {
  if (Utils.isNil(paginationMeta)) {
    return paginationMeta;
  }

  const delta = action === 'increment' ? 1 : -1;
  const newTotalCount = paginationMeta.total_count + delta;

  let newTotalPages = paginationMeta.total_pages;
  if (newTotalCount === 0) {
    newTotalPages = 0;
  } else if (newTotalCount > 0 && newTotalPages === 0) {
    newTotalPages = 1;
  }

  const newPaginationMeta: PaginationMeta = {
    ...paginationMeta,
    total_count: newTotalCount,
    total_pages: newTotalPages,
  };

  return newPaginationMeta;
};
