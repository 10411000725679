import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BaseOnboardingService } from './__abstract/base-onboarding.service';
import { AccountHelpers } from '@onyxx/model/account';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';

/**
 * !Todo: Change logs to be logged only in debug mode
 */
@Injectable({
  providedIn: 'root',
})
export class SharedOnboardingService extends BaseOnboardingService {
  handleOnboarding(): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(finished)),
        switchMap((finished) => this.handleFinishedPerson(finished)),
        switchMap((finished) => this.handleFinishedIdentification(finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(finished)),
        switchMap((finished) => this.handleFinishedStrategy(finished)),
        switchMap((finished) => this.handleFinishedPlan(finished)),
        switchMap((finished) => this.handleFinishedFirstPayment(finished)),
        switchMap((finished) => {
          if (this.accountPerson.isInvitee()) {
            return this.inviteeOnboarding(finished);
          } else {
            return this.initiatorOnboarding(finished);
          }
        }),
      )
      .subscribe(() => {
        this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  handleFinishedFirstPayment(finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('payment');

      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'first' }]);

      return EMPTY;
    }

    if (this.accountPerson.isInvitee()) {
      return this.hasBeenWidValidated();
    }

    return this.hasFinishedSendingInvitation();
  }

  handleInvitationCompleted(finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('invite');

      this.navigationService.navigate([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Invitation,
        this.account.invitation?.id,
      ]);

      return EMPTY;
    }

    return this.hasFinishedSigning('other');
  }

  handleInviteeHasSigned(finished: boolean): Observable<any> {
    if (!finished) {
      return EMPTY;
    }

    this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Summary]);

    return this.hasFinishedSummary();
  }

  handleCompletedWidPayment(finished: boolean): Observable<any> {
    if (!finished) {
      this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'wid' }]);

      return EMPTY;
    }

    this.navigationService.navigate([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Summary]);

    return this.hasFinishedSummary();
  }

  hasFinishedSummary(): Observable<boolean> {
    return of(AccountHelpers.hasSigned(this.account, this.user?.id));
  }

  handleFinishedSendingInvitation(finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('invite');

      this.navigationService.navigate([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Invitation,
        { step: InvitationStep.INVITE },
      ]);

      return EMPTY;
    }

    return this.hasFinishedSigning('other');
  }

  private initiatorOnboarding(finished: boolean): Observable<any> {
    return this.handleFinishedSendingInvitation(finished).pipe(
      switchMap((finished) => this.handleInvitationCompleted(finished)),
      switchMap((finished) => this.handleInviteeHasSigned(finished)),
      switchMap((finished) => this.handleFinishedSummary(finished)),
      switchMap((finished) => this.handleFinishedSigning(finished)),
    );
  }

  private inviteeOnboarding(finished: boolean): Observable<any> {
    return this.handleCompletedWidPayment(finished).pipe(
      switchMap((finished) => this.handleFinishedSummary(finished)),
      switchMap((finished) => this.handleFinishedSigning(finished)),
    );
  }
}
