import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Task } from '@semmie/models/bi/task/task.model';
import { TaskProvider } from '@semmie/providers/task/task.provider';
import { TasksStore } from '@semmie/store/tasks';
import { iBaseListableService } from '@semmie/schemas/services/list/base-service';

@Injectable({
  providedIn: 'root',
})
export class TaskService implements iBaseListableService<Task> {
  tasks$ = this.tasksStore.tasks$;
  loading$ = this.tasksStore.loading$;

  constructor(
    private taskProvider: TaskProvider,
    private tasksStore: TasksStore,
  ) {}

  list(params?: any) {
    return this.taskProvider.list(params).pipe(
      tap((res) => {
        this.tasksStore.patchState({ tasks: res.data, loading: false });
      }),
    );
  }

  get(id: string) {
    return this.taskProvider.get(id).pipe(
      tap((task) => {
        if (task) this.tasksStore.updateTask(task.id, task);
      }),
    );
  }

  patch(id: string, task: Partial<Task>): Observable<Task> {
    return this.taskProvider.patch(id, task);
  }

  selectTask(id: Task['id']) {
    return this.tasksStore.selectTask(id);
  }

  deleteTask(id: Task['id']) {
    this.tasksStore.deleteTask(id);
  }

  clear() {
    this.tasksStore.clear();
  }
}
