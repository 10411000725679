<ng-container
  *ngIf="{
    booster: booster$ | async,
    titleKey: titleKey$ | async,
    description: description$ | async,
    image: image$ | async,
    cta: cta$ | async,
    rewardText: rewardText$ | async,
    buttonDisabled: buttonDisabled$ | async,
  } as ctx"
>
  <ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="'carousel'">
      <ng-container *ngTemplateOutlet="carouselLayout; context: { $implicit: ctx.booster }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'item'">
      <ng-container *ngTemplateOutlet="itemLayout; context: { $implicit: ctx.booster }"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #carouselLayout let-booster>
    <semmie-content-card
      [title]="ctx.titleKey ?? '' | translate"
      [description]="ctx.description"
      [image]="ctx.image"
      [cta]="ctx.cta"
      [ctaDisabled]="ctx.buttonDisabled ?? false"
      [ctaSecondary]="'boosters.types.' + booster.type + '.link' | translate"
      (ctaInteraction)="primaryCta(booster.type)"
    >
      <div header class="text-right">
        <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
      </div>
    </semmie-content-card>
  </ng-template>

  <ng-template #itemLayout let-booster>
    <semmie-card>
      <div class="flex items-stretch">
        <div class="grow">
          <semmie-label *ngIf="booster?.title" class="booster-card-title" type="heading" size="h3" [text]="booster?.title"></semmie-label>
          <semmie-label
            class="booster-card-subtitle"
            size="base_3"
            [text]="account?.title ?? ('core.common.account.title_unknown' | translate)"
          ></semmie-label>
        </div>
        <div class="self-center">
          <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
        </div>
      </div>
    </semmie-card>
  </ng-template>

  <ng-template #rewardValue let-booster>
    <ng-container [ngSwitch]="booster.rewardKind">
      <span *ngSwitchCase="RewardKind.THANKS" class="booster-card-reward-icon">
        <semmie-icon [icon]="Icon.HEART" size="sm"></semmie-icon>
      </span>
      <semmie-label *ngSwitchDefault class="booster-card-reward" size="base_3" [text]="ctx.rewardText"></semmie-label>
    </ng-container>
  </ng-template>
</ng-container>
