import { Injectable } from '@angular/core';

import mustache from 'mustache';

import { search } from '@metrichor/jmespath';

@Injectable({
  providedIn: 'root',
})
export class JmespathService {
  /**
   * Filter, transform or perform projections on JSON data.
   *
   * @param data JSON object
   * @param expression JMESPath Expression
   * @returns JSONValue - the result of the Jmespath Expression
   */
  interpolate<T>(data: any, expression: string | Array<string>): T | undefined {
    let parsedExpression = expression;

    if (Array.isArray(expression)) {
      parsedExpression = expression.join('.');
    }

    let interpolatedExpression = mustache.render(parsedExpression as string, data);

    if (interpolatedExpression.startsWith('$meta')) {
      interpolatedExpression = interpolatedExpression.replace('$meta', '"$meta"');
    }

    if (interpolatedExpression.startsWith('$')) {
      interpolatedExpression = interpolatedExpression.replace('$', '"$"');
    }

    if (!interpolatedExpression) return;

    try {
      return search(data, interpolatedExpression) as unknown as T;
    } catch (ex) {
      //ignore errors for now so that our code keeps evaluating
    }
  }
}
