import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { pushNotificationsFeature } from './push-notifications.reducer';
import { PushNotificationCommonActions } from './push-notifications-common.actions';
import { filterNil } from '@onyxx/utility/observables';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsFacade {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);

  readonly requestPermissionBusy$ = this.store.select(pushNotificationsFeature.selectRequestPermissionBusy);
  readonly hasPermission$ = this.store.select(pushNotificationsFeature.selectHasPermission);
  readonly enrolled$ = this.store.select(pushNotificationsFeature.selectEnrolled).pipe(filterNil());

  readonly notifications$ = this.actions$.pipe(
    ofType(PushNotificationCommonActions.pushNotificationReceived),
    map(({ notification }) => notification),
  );

  readonly notificationAction$ = this.actions$.pipe(
    ofType(PushNotificationCommonActions.pushNotificationAction),
    map(({ notification }) => notification),
  );

  readonly clearDone$ = this.actions$.pipe(ofType(PushNotificationCommonActions.clearDone));

  dispatchEnroll() {
    this.store.dispatch(PushNotificationCommonActions.enroll());
  }

  dispatchUnEnroll() {
    this.store.dispatch(PushNotificationCommonActions.unEnroll());
  }

  /**
   * Clear push notifications for this device. This includes remove the device token and cleaning the local storage.
   *
   * Use clearDone$ to wait for the process to finish.
   */
  dispatchClearPushNotification() {
    this.store.dispatch(PushNotificationCommonActions.clear());
  }
}
