import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';

import { Formatter } from '../validator-formatter';
import { Denotation } from '@onyxx/model/validation';
import { OnyxxDenotationTranslatePipe } from '@onyxx/utility/translations';

@Injectable({ providedIn: 'root' })
export class RangeValidator {
  constructor(private semmieCurrencyPipe: SemmieCurrencyPipe) {}

  range(
    min: string | number,
    max: string | number,
    property?: string,
    denotation: Denotation = Denotation.Number,
    formatter?: Formatter,
    customMessage?: 'age' | 'horizon',
  ): ValidatorFn {
    const onyxxDenotationTranslatePipe = new OnyxxDenotationTranslatePipe();

    return (control: AbstractControl): ValidationErrors | null => {
      const value = property ? control?.value[property] : control?.value;

      const numericValue = parseFloat(value);

      if (numericValue < Number(min) || numericValue > Number(max)) {
        const isKnownDenotation = Object.values(Denotation).includes(denotation);
        const _denotation = isKnownDenotation ? onyxxDenotationTranslatePipe.transform(denotation) : denotation;

        switch (formatter) {
          case Formatter.CURRENCY:
            min = this.semmieCurrencyPipe.transform(min);
            max = this.semmieCurrencyPipe.transform(max);
            break;
        }

        let validationMessage = $localize`:@@validation.range:Enter a ${_denotation} between ${min} and ${max}.`;
        switch (customMessage) {
          case 'age':
            validationMessage = $localize`:@@validation.range.age:Enter an age between ${min} and ${max} years.`;
            break;
          case 'horizon':
            validationMessage = $localize`:@@validation.range.horizon:Choose an investment horizon between ${min} and ${max} years.`;
            break;
        }

        return new ValidatorMessage({
          code: 7,
          message: validationMessage,
          message_data: {
            min,
            max,
            denotation: _denotation,
          },
        });
      }

      return null;
    };
  }
}
