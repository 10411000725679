import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { PersonStoreFacade } from './person.facade';
import { personFeature } from './person.reducer';
import { PersonEffects } from './person.effects';

export const providers = [provideState(personFeature), provideEffects(PersonEffects), PersonStoreFacade];

@NgModule({ providers })
export class PersonStateModule {}

export * from './person.facade';
