import { Injectable } from '@angular/core';
import { FileError } from '@semmie/schemas/bi/file';
import { UploadProvider } from '@semmie/providers/upload/upload.provider';
import { HttpMethod } from '@semmie/schemas/generics/http/http-method.enum';
import { HttpProgress } from '@semmie/schemas/generics/http/http-progress.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(private uploadProvider: UploadProvider) {}

  delete(url: string, params?: any): Observable<any> {
    return this.uploadProvider.delete(url, params);
  }

  upload(url: string, method: HttpMethod, params: { [key: string]: any }): Observable<HttpProgress> {
    const formData = new FormData();

    if (params) {
      for (const key in params) {
        const val = params[key];
        formData.append(key, val as any);
      }
    }

    return this.uploadProvider.upload(url, method, formData);
  }

  getFileErrorMessage(error: FileError) {
    switch (error) {
      case FileError.TooLarge:
        return $localize`:@@file-upload.error.too-large:Upload failed, file is larger than 15MB.`;
      case FileError.ContentTypeInvalid:
        return $localize`:@@file-upload.error.content-type-invalid:Upload failed, file type not recognized.`;
      case FileError.VirusDetected:
        return $localize`:@@file-upload.error.antivirus-virus-detected:Upload failed, file contains virus.`;
      case FileError.VirusClientError:
        return $localize`:@@file-upload.error.antivirus-client-error:Upload failed, antivirus scanner is not working.`;
      case FileError.CouldntPickFile:
        return $localize`:@@file-upload.error.couldnt-pick-file:Upload failed, could not select the file.`;
      case FileError.DocumentExpired:
        return $localize`:@@file-upload.error.document-expired:Upload failed, document has expired.`;
      default:
        return $localize`:@@file-upload.error.default:Upload failed.`;
    }
  }
}
