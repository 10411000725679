import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { BaseFormComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { DynamicFormFieldEvent } from '@semmie/schemas/components/dynamic-form/dynamic-form-event';
import { InputCardMode } from '@semmie/schemas/components/dynamic-form/form-fields';
import { Utils } from '@semmie/shared/utils';
@Component({
  selector: 'semmie-input-card',
  templateUrl: './input-card.component.html',
  styleUrls: ['./input-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCardComponent extends BaseFormComponent {
  @ViewChild('input') inputField: ElementRef<HTMLInputElement>;

  @HostBinding('attr.title') _title = '';
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() set mode(mode: InputCardMode) {
    if (mode === InputCardMode.PERCENTAGE) {
      this.inputSuffix = '%';
    }
    this._mode = mode;
  }
  /** Field has errors */
  @Input() error: boolean;
  @Input() deletable: boolean;

  @Output() onInteraction: EventEmitter<DynamicFormFieldEvent> = new EventEmitter();

  Icon = Icon;

  InputCardMode = InputCardMode;
  inputSuffix: string;

  private _mode: InputCardMode;

  get fieldType() {
    return this._mode === InputCardMode.PERCENTAGE ? 'tel' : 'text';
  }

  get formattedValue() {
    if (this._mode === InputCardMode.PERCENTAGE && !isNaN(this.inputValue)) {
      return this.decimalPipe.transform(this.inputValue, '1.0-0');
    }

    return this.inputValue;
  }

  get inputValue() {
    if (!Utils.isNullOrUndefined(this.valueKey) && this.value?.[this.valueKey]) {
      return this.value[this.valueKey];
    }

    return typeof this.value !== 'object' ? this.value : '';
  }

  get inputMode() {
    return this._mode;
  }

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private decimalPipe: DecimalPipe,
  ) {
    super(ngControl);
  }

  onInteractionHandler(event: string): void {
    this.onInteraction.emit({ name: event, data: this.value });
  }

  selectInput(): void {
    this.inputField?.nativeElement?.focus();
    this.inputField?.nativeElement?.select();
  }

  updateValue(value) {
    if (this.valueKey) {
      this.writeValue({ ...this.value, [this.valueKey]: value });
    } else {
      this.value = value;
    }
  }
}
