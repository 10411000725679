import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafeHtmlDirective } from './safe-html.directive';

@NgModule({
  imports: [CommonModule],
  exports: [SafeHtmlDirective],
  declarations: [SafeHtmlDirective],
})
export class SafeHtmlDirectiveModule {}
