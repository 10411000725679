import { inject } from '@angular/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { UserProvider } from '@onyxx/provider/user';
import { ReferralApiActions } from '@semmie/store/referral/actions/referral-api.actions';
import { ReferralStoreActions } from '@semmie/store/referral/actions/referral-common.actions';
import { ReferralProvider } from '@semmie/providers/referral/referral.provider';
import { Store } from '@ngrx/store';
import { referralFeature } from '@semmie/store/referral/referral.reducer';
import { BoostersStoreActions } from '@semmie/store/boosters/actions/boosters-common.actions';
import { PaymentStoreActions } from '@semmie/store/payment/actions/payment-common.actions';
import { AuthFacade } from '@onyxx/store/auth';

export class ReferralEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly userProvider = inject(UserProvider);
  private readonly referralProvider = inject(ReferralProvider);
  private readonly store = inject(Store);
  private readonly authFacade = inject(AuthFacade);

  readonly initializeStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReferralStoreActions.initialize),
      switchMap(() => [ReferralApiActions.loadUserReferral(), ReferralApiActions.loadReferrals()]),
    );
  });

  readonly loadUserReferral$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReferralApiActions.loadUserReferral),
      exhaustMap(() =>
        this.userProvider.getReferral().pipe(
          map((referral) => {
            if (referral === null) throw new Error('Referral not found');

            return ReferralApiActions.loadUserReferralSuccess({ referral });
          }),
          catchError((error) => of(ReferralApiActions.loadUserReferralFailure({ error }))),
        ),
      ),
    );
  });

  readonly loadReferrals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReferralApiActions.loadReferrals),
      exhaustMap(() =>
        this.referralProvider.list({ page: 1 }).pipe(
          map((pageResponse) => {
            return ReferralApiActions.loadReferralsSuccess({
              referrals: pageResponse.data,
              canLoadMore: pageResponse.meta.current_page < pageResponse.meta.total_pages,
            });
          }),
          catchError((error) => of(ReferralApiActions.loadReferralsFailure({ error }))),
        ),
      ),
    );
  });

  readonly loadReferralsNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReferralApiActions.loadReferralsNextPage),
      concatLatestFrom(() => this.store.select(referralFeature.selectReferralsLoadedPages)),
      exhaustMap(([, loadedPages]) =>
        this.referralProvider.list({ page: loadedPages + 1 }).pipe(
          map((pageResponse) => {
            return ReferralApiActions.loadReferralsPageSuccess({
              referrals: pageResponse.data,
              canLoadMore: pageResponse.meta.current_page < pageResponse.meta.total_pages,
            });
          }),
          catchError((error) => of(ReferralApiActions.loadReferralsPageFailure({ error }))),
        ),
      ),
    );
  });

  readonly triggerReferralRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoostersStoreActions.assignReward, PaymentStoreActions.paymentDone),
      map(() => ReferralApiActions.loadReferrals()),
    );
  });

  readonly reInitializeOnLogin$ = createEffect(() => {
    return this.authFacade.isAuthenticated$.pipe(
      filter(Boolean),
      map(() => ReferralStoreActions.initialize()),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => ReferralStoreActions.clear()));
  });

  ngrxOnInitEffects() {
    return ReferralStoreActions.initialize();
  }
}
