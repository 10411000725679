import { inject } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take } from 'rxjs/operators';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { QuestionsApiActions } from './questions-api.actions';
import { QuestionsStoreActions } from './questions-common.actions';
import { QuestionsProvider } from '@onyxx/provider/questions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';

export class QuestionsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly questionsProvider = inject(QuestionsProvider);
  private readonly authFacade = inject(AuthFacade);

  private readonly questionsPerson$ = this.questionsProvider.listQuestionPerson().pipe(
    take(1),
    map((questions) => questions || {}),
    catchError(() => of({})),
  );

  private readonly questionsOrganisation$ = this.questionsProvider.listQuestionOrganisation().pipe(
    take(1),
    map((questions) => questions || {}),
    catchError(() => of({})),
  );

  readonly initializeStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionsStoreActions.initialize),
      // TODO: remove this `switchMap` when we don't have to load the userState in the AppModule and can be put behind the init/auth guard
      switchMap(() => this.authFacade.isAuthenticated$.pipe(filter(Boolean))),
      switchMap(() => [QuestionsApiActions.loadQuestions()]),
    );
  });

  readonly loadQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionsApiActions.loadQuestions),
      exhaustMap(() =>
        combineLatest([this.questionsPerson$, this.questionsOrganisation$]).pipe(
          map(([questionsPerson, questionsOrganisation]) => {
            return QuestionsApiActions.loadQuestionsSuccess({
              questions: {
                person: questionsPerson,
                organisation: questionsOrganisation,
              },
            });
          }),
          catchError((error) => of(QuestionsApiActions.loadQuestionsFailure({ error }))),
        ),
      ),
    );
  });

  ngrxOnInitEffects() {
    return QuestionsStoreActions.initialize();
  }
}
