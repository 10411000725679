import { Injectable, inject } from '@angular/core';
import { BankCode, bankNames, bankLogoName, belgiumBanks } from '@onyxx/model/bank';
import { PaymentProvider } from '@semmie/providers/payment/payment.provider';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BankService {
  private readonly paymentProvider = inject(PaymentProvider);

  /**
   * Determine the bank logo assocciated with bank number number
   * @param accountNumber
   */
  determineBankLogo(accountNumber: string): string {
    const bankCode = this.determineBankCode(accountNumber);
    return bankLogoName[bankCode ?? BankCode.DEFAULT];
  }

  determineBankName(accountNumber: string): string {
    const bankCode = this.determineBankCode(accountNumber);
    return bankNames[bankCode ?? BankCode.DEFAULT];
  }

  /**
   * Retrieve a list of banks for payments
   * @returns banks
   */
  retrieveBanks() {
    return this.paymentProvider.listBanks().pipe(map((response) => response));
  }

  private determineBankCode(accountNumber: string) {
    let bankCode: BankCode | undefined;

    switch (accountNumber.slice(0, 2)) {
      case 'NL': {
        bankCode = this.getDutchBankCode(accountNumber);
        break;
      }
      case 'BE': {
        bankCode = this.getBelgiumBankCode(accountNumber) as unknown as BankCode;
        break;
      }

      default:
        bankCode = this.getBankCode(accountNumber);
    }

    return bankCode;
  }

  /**
   * Determine the dutch bank code based on number
   * @param accountNumber
   */
  private getDutchBankCode(accountNumber: string): BankCode {
    // Obtain chars from 4 to 8 which represents the bank
    return BankCode[accountNumber.slice(4, 8)];
  }

  /**
   * Determine the belgium bank code based on number
   * @param accountNumber
   */
  private getBelgiumBankCode(accountNumber: string) {
    const bankCode = Number(accountNumber.slice(4, 7));
    return Object.keys(belgiumBanks).find((key) => belgiumBanks[key].some(([min, max]) => min >= bankCode && bankCode <= max));
  }

  /**
   * Determine the bank code
   * @param accountNumber
   */
  private getBankCode(accountNumber: string): BankCode | undefined {
    return Object.keys(BankCode)
      .map((key) => BankCode[key]?.toString())
      .find((key: string) => accountNumber.toLowerCase().includes(key.toLowerCase()));
  }
}
