import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { PushNotificationToken, NewPushNotificationToken } from '@onyxx/model/notifications';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_NAMESPACE = 'notification_tokens';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  /**
   * Add a registered device for the current user
   */
  addDeviceToken(params: NewPushNotificationToken): Observable<PushNotificationToken> {
    return this.http
      .post<{ notification_token: PushNotificationToken }>(`${this.apiUrl}/${API_NAMESPACE}`, params)
      .pipe(map((response) => response.notification_token));
  }

  /**
   * Remove a device token from the list of registered devices
   */
  removeDeviceToken(deviceToken: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${API_NAMESPACE}/${deviceToken}`);
  }
}
