import { Injectable } from '@angular/core';
import { AlertOptions, ConfirmOptions, ConfirmResult, Dialog, PromptOptions, PromptResult } from '@capacitor/dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {
  alert(options: AlertOptions): Promise<void> {
    return Dialog.alert(options);
  }

  confirm(options: ConfirmOptions): Promise<ConfirmResult> {
    return Dialog.confirm(options);
  }

  prompt(options: PromptOptions): Promise<PromptResult> {
    return Dialog.prompt(options);
  }
}
