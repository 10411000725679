import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Person } from '@onyxx/model/person';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PersonProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  get(id: string) {
    return this.http.get<{ person: Person }>(`${this.apiUrl}/people/${id}`).pipe(map(({ person }) => person));
  }

  update(id: string, params: Partial<Person>) {
    return this.http.patch<{ person: Person }>(`${this.apiUrl}/people/${id}`, { person: params }).pipe(map(({ person }) => person));
  }

  verifyPhone(id: string, code: string) {
    return this.http.patch<void>(`${this.apiUrl}/people/${id}/verify_phone`, { code }).pipe();
  }

  resendPhoneVerification(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/people/${id}/resend_phone_confirmation`, {});
  }
}
