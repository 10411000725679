import { BaseModel } from '@semmie/models/_abstract';

import { Strategy as iStrategy, StrategyRiskProfile, StrategyDetail } from '@onyxx/model/strategy';
import { StrategyName } from '@onyxx/model/strategy';

export class Strategy extends BaseModel<iStrategy> implements iStrategy {
  active_at: string;
  /**
   * @deprecated
   *
   * TODO: Remove if riskProfilesInStrategiesEndpoint feature flag is removed
   */
  assets_spreads: { [key: string]: StrategyRiskProfile['asset_spread'] };
  bonds: number;
  content: string;
  description: string;
  details: StrategyDetail;
  disabled_text: string;
  enabled: boolean;
  icon: string;
  invested: boolean;
  name: StrategyName;
  selected: boolean;
  stocks: number;
  sustainability: number;
  title: string;
  title_short: string;
  video: string;
  profiles?: StrategyRiskProfile[];

  constructor(props?: Partial<Strategy>) {
    super(props);
  }

  clearSelection() {
    this.selected = false;
    this.invested = false;
    this.active_at = '';
    return this;
  }
}
