import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { authApiActions } from '../auth-api.actions';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Utils } from '@onyxx/utility/general';
import { GenericErrorToast, ToasterDuration, ToasterStyle } from '@semmie/schemas/components/toast';
import { inject } from '@angular/core';
import { ConfigService, EventsService, SentryService, ToastService } from '@semmie/services';
import { AuthProvider } from '@onyxx/provider/auth';
import { EventDataCategory } from '@semmie/schemas/bi/event/event.schema';
import { formatTimeDifferenceInMinutes } from '@onyxx/utility/formatter';

export class LoginEffects {
  private readonly actions$ = inject(Actions);
  private readonly toastService = inject(ToastService);
  private readonly sentryService = inject(SentryService);
  private readonly authProvider = inject(AuthProvider);
  private readonly configService = inject(ConfigService);
  private readonly eventService = inject(EventsService);

  readonly getToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.login),
      concatLatestFrom(() => this.configService.config$),
      exhaustMap(([{ username, password, code }, config]) =>
        this.authProvider
          .requestToken({
            grant_type: 'password',
            client_id: config.oauth?.client_id ?? '',
            client_secret: config.oauth?.client_secret ?? '',
            username,
            password,
            two_factor_code: code,
          })
          .pipe(
            map((session) => authApiActions.loginSuccess({ session: session })),
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.UnprocessableEntity) {
                return of(authApiActions.twoFactorRequiredForLogin({ username, password, twoFactorSmsNumber: error.error?.phone ?? '' }));
              }

              if (error instanceof HttpErrorResponse && error.error?.error === 'two_factor_verification_limit') {
                this.toastService.show({
                  message: $localize`:@@auth.login.error-toast.two-factor.sent-too-often:You resent the code too many times, please try again later.`,
                  style: ToasterStyle.DANGER,
                });

                return of(
                  authApiActions.twoFactorRequiredForLogin({
                    username,
                    password,
                    twoFactorSmsNumber: error.error?.phone ?? '',
                    due: Utils.parseDate(error.error.expires_at).getTime(),
                  }),
                );
              }

              return of(authApiActions.loginFailure({ error }));
            }),
          ),
      ),
    );
  });

  readonly showGetTokenFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.loginFailure),
        tap(({ error }) => {
          if (error instanceof HttpErrorResponse && error.error?.error === 'user_locked') {
            const formattedDifference = formatTimeDifferenceInMinutes(Date.now(), error.error.expires_at);
            this.toastService.show({
              header: $localize`:@@auth.login.error-toast.header:Login failed`,
              message: $localize`:@@auth.login.error-toast.locked:Your account is temporarily locked. We sent you an e-mail with instructions to unlock your account. Otherwise wait for: ${formattedDifference}.`,
              id: 'login-toast',
              style: ToasterStyle.DANGER,
              duration: ToasterDuration.MEDIUM,
            });
            return;
          }

          if (error instanceof HttpErrorResponse && error.error?.error === 'two_factor_invalid') {
            this.toastService.show({
              message: $localize`:@@auth.login.error-toast.two-factor:The entered code is incorrect`,
              id: 'login-toast',
              style: ToasterStyle.DANGER,
            });
            return;
          }

          if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest) {
            this.toastService.show({
              header: $localize`:@@auth.login.error-toast.header:Login failed`,
              message: $localize`:@@auth.login.error-toast.invalid:Check the entered e-mail address and password.`,
              id: 'login-toast',
              style: ToasterStyle.DANGER,
            });
            return;
          }

          this.toastService.show(GenericErrorToast());

          if (!(error instanceof HttpErrorResponse) || Utils.isNil(error.error?.error)) {
            let errorDataString: string | null = null;
            try {
              errorDataString = JSON.stringify(error);
            } catch {
              // ignore stringify exceptions
            }
            this.sentryService.captureMessage('login-error-email', errorDataString || 'Reason of failure unknown');
          }
        }),
      );
    },
    { dispatch: false },
  );

  readonly loginEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.loginSuccess),
        switchMap(() =>
          this.eventService.post({
            category: EventDataCategory.System,
            name: 'login_email',
          }),
        ),
      );
    },
    { dispatch: false },
  );
}
