import { iNavigationDetails } from '@semmie/schemas';
import { Utils } from '@semmie/shared/utils';

export abstract class BaseNavigationService {
  protected allRoutes: Array<iNavigationDetails> = [];

  get currentRoute(): iNavigationDetails {
    return this.allRoutes[this.allRoutes?.length - 1];
  }

  get previousRoute(): iNavigationDetails | undefined {
    return this.allRoutes[this.allRoutes.length - 2];
  }

  /**
   * !todo: to revisit; should support regular routing and in component 'stepping'
   */
  protected addRouteUrl(url: string): void {
    const urlPart = url.split(';', 1)[0];
    const queryMap = url
      .substr(urlPart.length + 1)
      .split(';')
      .map((p) => {
        const pSplit = p.split('=');

        return {
          [pSplit[0]]: pSplit[1],
        };
      })
      .reduce((prev, current) => {
        if (Object.keys(current)[0]) return Object.assign(prev, current);
        return prev;
      }, {});

    const combined: Array<any> = ([] as any[])
      .concat(urlPart.split('/'), Object.keys(queryMap).length ? queryMap : null)
      .filter((p) => Utils.isNonNullOrUndefined(p));

    if (combined?.length) {
      this.allRoutes.push({
        route: combined,
      });
    }
  }

  protected getCommand(commands: Array<any>): Array<any> {
    let nextCommand = ([] as any[]).concat(commands);

    if (typeof commands[0] === 'object') {
      const mergedCommand = this.currentRoute?.route.map((r) => {
        let previousRoute;

        if (typeof r === 'string') {
          previousRoute = `${r}`;
        } else if (typeof r === 'object') {
          previousRoute = Object.assign({}, r, ...commands);
        }

        return previousRoute;
      });

      if (mergedCommand) {
        nextCommand = mergedCommand;
      }
    }

    return nextCommand;
  }
}
