import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { SharedModule } from '@semmie/shared/shared.module';

import { InputCardComponent } from './input-card.component';

@NgModule({
  imports: [SharedModule, CardModule, LabelModule, IconComponent, FormsModule],
  exports: [InputCardComponent],
  declarations: [InputCardComponent],
})
export class InputCardModule {}
