import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { Utils } from '@onyxx/utility/general';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, FormsModule, IconComponent],
  selector: 'semmie-autocomplete-input',
  templateUrl: 'autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;
  @Input() form: UntypedFormGroup;
  @Input() label?: string;
  @Input() placeholder: string;
  @Input() infoModal: iInfoModal;
  /** Reference to the list field */
  @Input() list: string;
  Icon = Icon;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private cdr: ChangeDetectorRef,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return !Utils.isEqual(prev[this.valuePath], curr[this.valuePath]);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  clear(): void {
    this.value = '';
    this.inputRef?.nativeElement?.select();
    this.clearList();
    this.cdr.markForCheck();
  }

  clearList(): void {
    const list = this.form.get(this.list);
    if (list) {
      list.setValue(null);
    }
  }

  onValueChange(value: any) {
    this.value = value;
    this.clearList();
  }
}
