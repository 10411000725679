import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';

import { MagazineCardComponent } from '@semmie/components/containers/magazine-card/magazine-card.component';

@NgModule({
  declarations: [MagazineCardComponent],
  imports: [CommonModule, LabelModule, CardModule, ImageModule],
  exports: [MagazineCardComponent],
})
export class MagazineCardModule {}
