import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { OnyxxDenotationTranslatePipe } from '@onyxx/utility/translations';
import { Denotation } from '@onyxx/model/validation';

export function minMaxLength(min: number, max: number, denotation: Denotation = Denotation.Characters): ValidatorFn {
  const onyxxDenotationTranslatePipe = new OnyxxDenotationTranslatePipe();

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    if (value?.length < min || value?.length > max) {
      const isKnownDenotation = Object.values(Denotation).includes(denotation);
      const _denotation = isKnownDenotation ? onyxxDenotationTranslatePipe.transform(denotation) : denotation;

      return new ValidatorMessage({
        code: 11,
        message: $localize`:@@validation.min-max-length:This field must contain between ${min} and ${max} ${_denotation}`,
        message_data: {
          min: min,
          max: max,
          denotation: _denotation,
        },
      });
    }

    return null;
  };
}
