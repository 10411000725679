import { MessageDetail } from '@semmie/models/bi/message';
import { UploadDocument } from '@semmie/models/bi/upload-document/upload-document.model';
import { BaseModel } from '@semmie/models/_abstract';
import { QuestionnaireSubject } from '@onyxx/model/questionnaire';
import { TaskKind, TaskPriority, TaskState } from '@onyxx/model/task';
import { iTask, TaskSubject } from '@semmie/schemas/bi/task/task.interface';
import { TaskInitiator } from '@onyxx/model/task';

export class Task extends BaseModel<iTask> implements iTask {
  id: string;
  title: string;
  /** Task name: FE uses underscores, BE uses dashes  */
  name: string;
  state: TaskState;
  kind: TaskKind;
  priority: TaskPriority;
  initiator?: TaskInitiator;
  person?: TaskSubject;
  message?: Partial<MessageDetail>;
  documents?: UploadDocument[];
  action?: string;
  organisation?: TaskSubject;
  wid_payment?: boolean;
  questionnaires?: { subject: QuestionnaireSubject; organisation_id?: string }[];
  fields?: Array<string>;

  constructor(props?: Partial<iTask>) {
    super(props, [{ api: 'documents', local: 'documents', model: new UploadDocument() }]);
  }

  isMigrationTask() {
    return this.initiator === TaskInitiator.MIGRATION;
  }
}
