import { Injectable } from '@angular/core';
import { Booster } from '@semmie/models/bi/booster/booster.model';
import { BoosterProvider } from '@semmie/providers/booster/booster.provider';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Reward } from '@semmie/models/bi/reward/reward.model';
import { UserStoreFacade } from '@semmie/store/user';
import { BoostersStoreFacade } from '@semmie/store/boosters';
import { PaginatedResponse } from '@onyxx/model/pagination';

@Injectable({
  providedIn: 'root',
})
export class BoosterService {
  // TODO: remove after implementing a proper state management system with an action stream/event bus
  // Should be replaced with boostersStore.assignReward();
  readonly boostersChanged$$ = new Subject<Reward | undefined>();

  readonly boostersChanged$ = this.boostersChanged$$.asObservable();

  constructor(
    private boosterProvider: BoosterProvider,
    private userFacade: UserStoreFacade,
    private boostersStore: BoostersStoreFacade,
  ) {}

  list(params?: any): Observable<PaginatedResponse<Booster>> {
    return this.boosterProvider.list(params);
  }

  assignReward(reward: string, account: string): Observable<Reward> {
    this.boostersStore.assignReward();
    return this.boosterProvider.assignReward(reward, account).pipe(
      tap(() => this.userFacade.load()),
      switchMap((reward) => this.userFacade.readyNotification$.pipe(map(() => reward))),
      tap((reward: Reward) => this.boostersChanged$$.next(reward)),
    );
  }
}
