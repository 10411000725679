import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { QuestionsEffects } from './questions.effects';
import { questionsFeature } from './questions.reducer';
import { QuestionsStoreFacade } from './questions.facade';

export const providers = [provideState(questionsFeature), provideEffects(QuestionsEffects), QuestionsStoreFacade];

@NgModule({ providers })
export class QuestionsStoreModule {}

export * from './questions.facade';
