import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetPathPipe } from '@semmie/pipes/asset-path/asset-path.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [AssetPathPipe],
  declarations: [AssetPathPipe],
})
export class AssetPathPipeModule {}
