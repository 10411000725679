import { Injectable, RendererFactory2, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppNavVisibility, AppPreferences, AppState, iAppMeta } from '@semmie/schemas';
import { BaseStore } from '@semmie/models/_abstract';

const NAV_VISIBLE_PORTRAIT_CLASS = 'navVisible-portrait';
const NAV_VISIBLE_LANDSCAPE_CLASS = 'navVisible-landscape';

@Injectable({
  providedIn: 'root',
})
export class AppStore extends BaseStore<AppState> {
  private renderFactory = inject(RendererFactory2);

  appNavVisibility$ = this.select((store) => store.appNavVisibility);
  appIsActive$ = this.select((store) => store.appIsActive);

  /**
   * App preferences ((re)stored on the device)
   */
  appPreferences$$: BehaviorSubject<AppPreferences | null> = new BehaviorSubject(null);

  /**
   * App Meta information
   */
  appMeta$$: BehaviorSubject<iAppMeta | null> = new BehaviorSubject(null);

  constructor() {
    super({
      appIsActive: true,
      appNavVisibility: {
        portrait: true,
        landscape: true,
      },
    });
  }

  get appMeta$() {
    return this.appMeta$$.asObservable();
  }

  get appPreferences$() {
    return this.appPreferences$$.asObservable();
  }

  updateAppNavVisibility(navVisibility: AppNavVisibility): void {
    this.patchState({
      appNavVisibility: {
        portrait: navVisibility?.portrait === false ? false : true,
        landscape: navVisibility?.landscape === false ? false : true,
      },
    });

    const renderer = this.renderFactory.createRenderer(null, null);
    navVisibility?.portrait === false
      ? renderer.removeClass(document.body, NAV_VISIBLE_PORTRAIT_CLASS)
      : renderer.addClass(document.body, NAV_VISIBLE_PORTRAIT_CLASS);
    navVisibility?.landscape === false
      ? renderer.removeClass(document.body, NAV_VISIBLE_LANDSCAPE_CLASS)
      : renderer.addClass(document.body, NAV_VISIBLE_LANDSCAPE_CLASS);
  }

  updateAppIsActive(appIsActive: boolean): void {
    this.patchState({
      appIsActive,
    });
  }
}
