import { iBaseDynamicFormField } from './base-dynamic-field';

export enum RadioButtonGroupLayout {
  Item = 'item',
  Card = 'card',
}

export interface iRadioButtonGroupOption<T> {
  label: string;
  value: T;
  cardLabel?: string;
  description?: string;
  disabled?: boolean;
  source?: string;
}

export interface iRadioButtonGroupFormField<T> extends iBaseDynamicFormField {
  options: iRadioButtonGroupOption<T>[] | iRadioButtonGroupOption<T>;
  layout?: RadioButtonGroupLayout;
}
