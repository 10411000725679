import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Utils } from '@onyxx/utility/general';
import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { utc, DurationInputArg1, DurationInputArg2 } from 'moment';

export type dateCompareType = 'before' | 'after';
export type dateCompareModifier = 'add' | 'subtract';

export const enum DateBeforeAfterValidations {
  Default = 'default',
  TooYoung = 'tooYoung',
  TooYoungUbo = 'tooYoungUbo',
  TooOldChild = 'tooOldChild',
  TooOldGrandChild = 'tooOldGrandChild',
  Identification = 'identification',
}

/**
 * Check if the is before or after a given time \
 * For Example:
 * * 'before', 'now'
 * * 'after', 'now', 'DD-MM-YYYY', 'translation.string', 'subtract', `18`, 'year'
 * * 'before', '12/12/2023', 'DD-MM-YYYY'
 * * 'before', '12/12/2023', 'DD-MM-YYYY', 'add', `2`, 'month'
 * @param compareType
 * @param compareDateTo expected in the format MM/DD/YYYY
 * @param dateFormat for display purposes
 * @param customValidation
 * @param compareModifier
 * @param amount
 * @param unit
 */
export function dateBeforeOrAfter(
  compareType: dateCompareType,
  compareDateTo: 'now' | string,
  dateFormat = 'DD-MM-YYYY',
  customMessage?: DateBeforeAfterValidations,
  compareModifier?: dateCompareModifier,
  amount?: DurationInputArg1,
  unit?: DurationInputArg2,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const compareDate = compareDateTo === 'now' ? new Date() : new Date(compareDateTo);

    const datesAreValid = Utils.isValidDate(control.value) && Utils.isValidDate(compareDate);

    if (datesAreValid) {
      const value = utc(control?.value).format(dateFormat);
      const compareDate = compareDateTo === 'now' ? utc() : utc(compareDateTo);
      const formattedCompareDate = compareDate.format(dateFormat);
      const nowDate = utc();

      if (compareModifier === 'add') {
        compareDate.add(amount, unit);
      } else if (compareModifier === 'subtract') {
        compareDate.subtract(amount, unit);
      }

      const isBefore = utc(value, dateFormat).isBefore(compareDate);
      const isAfter = utc(value, dateFormat).isAfter(compareDate);

      if ((compareType === 'before' && !isBefore) || (compareType === 'after' && !isAfter)) {
        const isNow = formattedCompareDate === nowDate.format(dateFormat);

        let validationMessage = '';
        if (compareType === 'before' && !isBefore) {
          validationMessage = $localize`:@@validation.date.before:Date must be before ${formattedCompareDate}.`;
          if (isNow) {
            validationMessage = $localize`:@@validation.date.before.now:Date must be in the past.`;
          }
        } else if (compareType === 'after' && !isAfter) {
          validationMessage = $localize`:@@validation.date.after:Date must be after ${formattedCompareDate}.`;
          if (isNow) {
            validationMessage = $localize`:@@validation.date.after.now:Date must be in the future.`;
          }
        }

        if (Utils.isNotNil(customMessage)) {
          switch (customMessage) {
            case DateBeforeAfterValidations.TooYoung:
              validationMessage = $localize`:@@validation.date.too-young:Under ${amount}? Ask your parents to open an account.`;
              break;
            case DateBeforeAfterValidations.TooYoungUbo:
              validationMessage = $localize`:@@validation.date.too-young-ubo:A UBO/director must be ${amount} years or older.`;
              break;
            case DateBeforeAfterValidations.TooOldChild:
              validationMessage = $localize`:@@validation.date.too-old-child:Your child must be younger than ${amount}.`;
              break;
            case DateBeforeAfterValidations.TooOldGrandChild:
              validationMessage = $localize`:@@validation.date.too-old-grandchild:Your grandchild must be younger than ${amount}.`;
              break;
            case DateBeforeAfterValidations.Identification:
              validationMessage = $localize`:@@validation.date.identification:An identification document is valid for a maximum of ${amount} years.`;
              break;
          }
        }

        return new ValidatorMessage({
          code: 16,
          message: validationMessage,
          message_data: {
            date: formattedCompareDate,
          },
        });
      }
    }
    return null;
  };
}
