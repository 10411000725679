import { createFeature, createReducer, on } from '@ngrx/store';
import { biometricsCommonActions } from './biometrics-common.actions';
import { BiometricAvailability } from './models/biometric-availability.type';

export interface State {
  enabled: boolean | null;
  availability: BiometricAvailability | null;
  authenticated: boolean | null;
  authenticationBusy: boolean;
  permissionRequestBusy: boolean;
}
const initialState: State = {
  enabled: null,
  authenticated: null,
  authenticationBusy: false,
  permissionRequestBusy: false,
  availability: null,
};

const reducer = createReducer(
  initialState,
  on(
    biometricsCommonActions.initializeDone,
    (state, { availability, enabled }): State => ({
      ...state,
      availability,
      enabled,
    }),
  ),
  on(
    biometricsCommonActions.availabilityLoaded,
    (state, { availability }): State => ({
      ...state,
      availability,
    }),
  ),
  on(
    biometricsCommonActions.authenticate,
    (state): State => ({
      ...state,
      authenticationBusy: true,
      authenticated: null,
    }),
  ),
  on(
    biometricsCommonActions.authenticateSuccess,
    (state): State => ({
      ...state,
      authenticated: true,
      authenticationBusy: false,
    }),
  ),
  on(
    biometricsCommonActions.authenticateFailed,
    (state): State => ({
      ...state,
      authenticated: false,
      authenticationBusy: false,
    }),
  ),
  on(
    biometricsCommonActions.enable,
    (state): State => ({
      ...state,
      permissionRequestBusy: true,
    }),
  ),
  on(
    biometricsCommonActions.enableSuccess,
    (state): State => ({
      ...state,
      enabled: true,
      permissionRequestBusy: false,
    }),
  ),
  on(
    biometricsCommonActions.enablePermissionFailure,
    (state, { availability }): State => ({
      ...state,
      availability,
      permissionRequestBusy: false,
    }),
  ),
  on(
    biometricsCommonActions.enableFailure,
    (state): State => ({
      ...state,
      permissionRequestBusy: false,
    }),
  ),
  on(
    biometricsCommonActions.disableDone,
    (state): State => ({
      ...state,
      enabled: false,
    }),
  ),
  on(
    biometricsCommonActions.clear,
    (state): State => ({
      ...state,
      enabled: false,
    }),
  ),
);

export const biometricsFeature = createFeature({
  name: 'biometrics',
  reducer,
});
