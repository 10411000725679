import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ReferralApiActions } from './actions/referral-api.actions';
import { iUserReferral } from '@onyxx/model/user';
import { Referral } from '@semmie/models';
import { ReferralStoreActions } from '@semmie/store/referral/actions/referral-common.actions';

export interface State {
  userReferral: iUserReferral | null;
  userReferralLoading: boolean;
  referrals: Referral[];
  referralsLoading: boolean;
  referralsLoadedPages: number;
  referralsCanLoadMore: boolean;
}

const initialState: State = {
  userReferral: null,
  userReferralLoading: false,
  referrals: [],
  referralsLoading: false,
  referralsLoadedPages: 0,
  referralsCanLoadMore: false,
};

const reducer = createReducer(
  initialState,

  // initialization
  on(ReferralStoreActions.initialize, (state): State => ({ ...state, userReferralLoading: true, referralsLoading: true })),

  // user referral
  on(ReferralApiActions.loadUserReferral, (state): State => ({ ...state, userReferralLoading: true })),
  on(
    ReferralApiActions.loadUserReferralSuccess,
    (state, { referral }): State => ({ ...state, userReferralLoading: false, userReferral: referral }),
  ),
  on(ReferralApiActions.loadUserReferralFailure, (state): State => ({ ...state, userReferralLoading: false })),

  // referrals
  on(ReferralApiActions.loadReferrals, (state): State => ({ ...state, referralsLoading: true })),
  on(
    ReferralApiActions.loadReferralsSuccess,
    (state, { referrals, canLoadMore }): State => ({
      ...state,
      referralsLoading: false,
      referrals,
      referralsCanLoadMore: canLoadMore,
      referralsLoadedPages: 1,
    }),
  ),
  on(ReferralApiActions.loadReferralsFailure, (state): State => ({ ...state, referralsLoading: false })),
  on(ReferralApiActions.loadReferralsNextPage, (state): State => ({ ...state, referralsLoading: true })),
  on(
    ReferralApiActions.loadReferralsPageSuccess,
    (state, { referrals, canLoadMore }): State => ({
      ...state,
      referralsLoading: false,
      referrals: [...state.referrals, ...referrals],
      referralsCanLoadMore: canLoadMore,
      referralsLoadedPages: state.referralsLoadedPages + 1,
    }),
  ),
  on(ReferralApiActions.loadReferralsPageFailure, (state): State => ({ ...state, referralsLoading: false })),

  // general
  on(ReferralStoreActions.clear, (): State => initialState),
);

export const referralFeature = createFeature({
  name: 'referral',
  reducer,
  extraSelectors: ({ selectReferrals, selectReferralsCanLoadMore }) => ({
    selectReferralsWithLoadMore: createSelector(selectReferrals, selectReferralsCanLoadMore, (referrals, canLoadMore) => ({
      referrals,
      canLoadMore,
    })),
  }),
});
