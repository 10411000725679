import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { goalApiActions } from './goal-api.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Goal } from '@onyxx/model/goal';
import { goalCommonActions } from './goal-common.actions';
import { Utils } from '@onyxx/utility/general';

export interface State {
  currentGoal: {
    accountId: string;
    goal: Goal | null;
  } | null;
}

const initialState: State = { currentGoal: null };

const reducer = createReducer(
  initialState,
  on(goalApiActions.load, (state): State => ({ ...state })),
  on(goalApiActions.loadSuccess, (_, action): State => ({ currentGoal: { goal: action.goal, accountId: action.accountId } })),
  on(goalApiActions.loadFailure, (state): State => ({ ...state })),

  on(goalApiActions.update, (state): State => ({ ...state })),
  on(goalApiActions.updateSuccess, (_, action): State => ({ currentGoal: { goal: action.goal, accountId: action.accountId } })),
  on(goalApiActions.updateFailure, (state): State => ({ ...state })),

  on(goalCommonActions.updateGoalInStore, (state, action): State => {
    // only update if the current goal is that same
    if (state.currentGoal?.accountId !== action.accountId) {
      return state;
    }
    return { ...state, currentGoal: { ...state.currentGoal, goal: action.goal } };
  }),

  on(goalCommonActions.clear, (): State => initialState),
);

export const goalFeature = createFeature({
  name: 'goal',
  reducer,
  extraSelectors: ({ selectCurrentGoal }) => ({
    selectGoal: createSelector(selectCurrentGoal, (goal) => {
      if (Utils.isNil(goal)) return false;
      return goal.goal ?? null;
    }),
  }),
});
