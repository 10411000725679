import { timer } from 'rxjs';

export class Polling {
  static RECALCULATING_ACCOUNT_GOAL_INTERVAL = 5_000;

  /**
   * Poll every 4.8 minutes to refresh the URLs of signed resources stored on AWS.
   */
  static pollSignedDocuments() {
    return timer(0, 4.8 * 60 * 1000);
  }
}
