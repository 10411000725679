import { inject } from '@angular/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { CountriesApiActions } from './countries-api.actions';
import { CountriesStoreActions } from './countries-common.actions';
import { CountryProvider } from '@onyxx/provider/countries';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';

export class CountriesEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly countriesProvider = inject(CountryProvider);
  private readonly authFacade = inject(AuthFacade);

  readonly initializeStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CountriesStoreActions.initialize),
      // TODO: remove this `switchMap` when we don't have to load the userState in the AppModule and can be put behind the init/auth guard
      switchMap(() => this.authFacade.isAuthenticated$.pipe(filter(Boolean))),
      switchMap(() => [CountriesApiActions.loadCountries()]),
    );
  });

  readonly loadCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CountriesApiActions.loadCountries),
      exhaustMap(() =>
        this.countriesProvider.list().pipe(
          map((countries) => {
            return CountriesApiActions.loadCountriesSuccess({
              countries,
            });
          }),
          catchError((error) => of(CountriesApiActions.loadCountriesFailure({ error }))),
        ),
      ),
    );
  });

  ngrxOnInitEffects() {
    return CountriesStoreActions.initialize();
  }
}
