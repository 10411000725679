import { createFeature, createReducer, on } from '@ngrx/store';
import { QuestionsApiActions } from './questions-api.actions';
import { QuestionsStoreActions } from './questions-common.actions';
import { iQuestions } from '@onyxx/model/questions';

export interface State {
  questions: { person: iQuestions | null; organisation: iQuestions | null };
  questionsLoading: boolean;
}

const initialState: State = {
  questions: { person: null, organisation: null },
  questionsLoading: false,
};

const reducer = createReducer(
  initialState,

  // initialization
  on(QuestionsStoreActions.initialize, (state): State => ({ ...state, questionsLoading: false })),

  // questions
  on(QuestionsApiActions.loadQuestions, (state): State => ({ ...state, questionsLoading: true })),
  on(
    QuestionsApiActions.loadQuestionsSuccess,
    (state, { questions }): State => ({
      ...state,
      questionsLoading: false,
      questions,
    }),
  ),
);

export const questionsFeature = createFeature({
  name: 'questions',
  reducer,
});
