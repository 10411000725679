import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent, LabelModule } from '@semmie/components';

import { Icon } from '@semmie/schemas';
import { iMessage } from '@semmie/schemas/bi/message/message';
import { OnyxxDatePipe, DateFormat } from '@onyxx/utility/date-pipe';

@Component({
  standalone: true,
  imports: [CommonModule, LabelModule, IconComponent, OnyxxDatePipe],
  selector: 'semmie-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardComponent {
  @Input() condensed = false;
  @Input() icon: Icon;
  @Input() selected: boolean;
  @Input() active: boolean;
  @Input() message: iMessage;
  @Input() itemBeforeSelected: boolean;

  readonly DateFormat = DateFormat;
}
