import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpMethod } from '@semmie/schemas/generics/http/http-method.enum';
import { HttpProgressStatus } from '@semmie/schemas/generics/http/http-progress-status.enum';
import { HttpProgress } from '@semmie/schemas/generics/http/http-progress.interface';
import { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable()
export class UploadProvider {
  protected http = inject(HttpClient);

  protected readonly backendApiUrl = environment.apiUrl;

  /**
   * Delete a document
   * @param url
   */
  delete(url: string, params: any): Observable<any> {
    return this.http.delete(`${this.backendApiUrl}/${url}`, { params });
  }

  /**
   * Upload FormData to a specified url
   * @param body FormData
   * @param url upload path
   * @param method Default value is PATCH
   * @returns Observable
   */
  upload(url: string, method = HttpMethod.PATCH, body: FormData): Observable<HttpProgress> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const initialState: HttpProgress = { state: HttpProgressStatus.PENDING, progress: 0 };
    const reduceState = (upload: HttpProgress, event: any): HttpProgress => {
      if ([HttpEventType.DownloadProgress, HttpEventType.UploadProgress].includes(event.type)) {
        return {
          progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
          state: HttpProgressStatus.IN_PROGRESS,
        };
      }
      if (event.type === HttpEventType.Response) {
        return {
          progress: 100,
          state: HttpProgressStatus.DONE,
          response: event.body,
        };
      }
      return upload;
    };

    return this.http
      .request(method, `${this.backendApiUrl}/${url}`, {
        body,
        headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        scan(reduceState, initialState),
        distinctUntilChanged((a, b) => a.state === b.state && a.progress === b.progress),
      );
  }
}
