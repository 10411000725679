import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, LabelModule],
  selector: 'semmie-content-footer-link',
  template:
    '<semmie-label class="content-footer-link" type="text" size="base_2" align="center" [text]="text" (onClick)="onClick.emit()"></semmie-label>',
  styleUrls: ['./content-footer-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFooterLinkComponent extends BaseComponent {
  @Input() text?: string | null;

  @Output() onClick: EventEmitter<void> = new EventEmitter();
}
