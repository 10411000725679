export enum BoosterType {
  AUTO_INCASSO = 'auto-incasso',
  CAMPAIGN_ANNUITY = 'campaign-annuity',
  CAMPAIGN_TRANSAVIA = 'campaign-transavia',
  CAMPAIGN_TRYOUT = 'campaign-probeer',
  CAMPAIGN_CADEAU = 'campaign-cadeau',
  REFERRAL = 'referral',
  REFERRED = 'referred',
  REVIEW = 'review',
}
