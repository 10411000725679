import { createReducer, on, createFeature, createSelector } from '@ngrx/store';

import { HighlightsAPIActions } from './highlights-api.actions';
import { Highlights } from '@onyxx/model/highlights';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<Highlights> {}

const adapter = createEntityAdapter<Highlights>();

const initialState: State = adapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(HighlightsAPIActions.loadSuccess, (state, { highlights }): State => ({ ...adapter.setAll(highlights, state) })),
  on(HighlightsAPIActions.markAsReadSuccess, (state, { highlightIds }): State => ({ ...adapter.removeMany(highlightIds, state) })),
);

export const highlightsFeature = createFeature({
  name: 'highlights',
  reducer,
  extraSelectors: ({ selectHighlightsState, selectEntities }) => ({
    ...adapter.getSelectors(selectHighlightsState),
    selectIds: createSelector(selectEntities, (entities) => Object.keys(entities)),
  }),
});
