import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserActivityStoreActions = createActionGroup({
  source: 'User Activity Account Store',
  events: {
    initialize: props<{ skipNavigationOnLogout: boolean }>(),
    initializeDone: props<{ timestamp: number | null }>(),
    dueDateChanged: props<{ timestamp: number }>(),
    sessionExpired: emptyProps(),
    userActivity: emptyProps(),
  },
});
