import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { PhoneNumberStoreFacade } from './phone-number.facade';
import { phoneNumberFeature } from './phone-number.reducer';
import { PhoneNumberEffects } from './phone-number.effects';

export const providers = [provideState(phoneNumberFeature), provideEffects(PhoneNumberEffects), PhoneNumberStoreFacade];

@NgModule({ providers })
export class PhoneNumberStateModule {}

export * from './phone-number.facade';
