<ng-container *ngIf="layout === 'card'; else defaultCheckbox">
  <semmie-selection-card
    [title]="label"
    [description]="description"
    titleSize="h4"
    [enabled]="!isDisabled"
    [selected]="checked"
    [checkbox]="true"
    (onClick)="toggleButton()"
    [checkboxTpl]="checkbox"
  >
  </semmie-selection-card>
</ng-container>

<ng-template #defaultCheckbox>
  <div class="checkbox-default" (click)="toggleButton()">
    <semmie-label weight="medium" [text]="label"></semmie-label>
    <ng-container *ngTemplateOutlet="checkbox"></ng-container>
  </div>
</ng-template>

<ng-template #checkbox>
  <div class="checkbox" [class.disabled]="isDisabled" [class.checked]="checked">
    <ng-container *ngIf="checked">
      <semmie-icon size="xxs" [icon]="Icon.CHECKMARK"></semmie-icon>
    </ng-container>
  </div>
</ng-template>
