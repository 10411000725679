import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { NotificationMessage, NotificationMessagesResponse } from '@onyxx/model/notifications';
import { PaginationMeta, PaginationParams } from '@onyxx/model/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_NAMESPACE = 'notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessagesProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  list(params?: PaginationParams): Observable<NotificationMessagesResponse<NotificationMessage>> {
    return this.http
      .get<{ [API_NAMESPACE]: NotificationMessage[]; meta: PaginationMeta & { unread_count?: number } }>(
        `${this.apiUrl}/${API_NAMESPACE}`,
        {
          params: { ...params },
        },
      )
      .pipe(
        map((response) => {
          return {
            data: response[API_NAMESPACE],
            meta: response.meta,
          };
        }),
      );
  }

  markAsRead(notificationIds: string[]) {
    return this.http.patch<{ unread_count: number }>(`${this.apiUrl}/${API_NAMESPACE}/mark_as_read`, {
      ids: notificationIds,
    });
  }

  markAllAsRead() {
    return this.http.patch<{ unread_count: number }>(`${this.apiUrl}/${API_NAMESPACE}/mark_all_as_read`, {});
  }
}
