import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { PushNotificationsFacade } from './push-notifications.facade';
import { pushNotificationsFeature } from './push-notifications.reducer';
import { PushNotificationsEffects } from './push-notifications.effects';
import { EnvironmentProviders, Provider, ProviderToken } from '@angular/core';
import { PushNotificationConfig } from './config/push-notifications-config.interface';
import { PUSH_NOTIFICATION_CONFIG } from './config/push-notifications-config.token';

export const providePushNotificationsState = (config: ProviderToken<PushNotificationConfig>): (EnvironmentProviders | Provider)[] => {
  return [
    provideState(pushNotificationsFeature),
    provideEffects(PushNotificationsEffects),
    PushNotificationsFacade,
    {
      provide: PUSH_NOTIFICATION_CONFIG,
      useValue: config,
    },
  ];
};

export * from './push-notifications.facade';
export * from './config/push-notifications-config.interface';
export { initializePushNotificationListeners } from './capacitor-helpers/push-notification-event-handler';
