import { BaseModel } from '@semmie/models/_abstract';
import { iInstrumentRate } from '@semmie/schemas/bi/instrument/instrument-rate';

export class InstrumentRate extends BaseModel<iInstrumentRate> implements iInstrumentRate {
  rate_at: string;
  rate_index: string;

  constructor(data: iInstrumentRate) {
    super(data);
  }
}
