import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { AppLoadingStatusStoreFacade } from './app-loading-status.facade';
import { appLoadingStatusFeature } from './app-loading-status.reducer';
import { AppLoadingStatusEffects } from './app-loading-status.effects';

export const providers = [provideState(appLoadingStatusFeature), provideEffects(AppLoadingStatusEffects), AppLoadingStatusStoreFacade];

@NgModule({ providers })
export class AppLoadingStatusStateModule {}

export * from './app-loading-status.facade';
