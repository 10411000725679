import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/operators';

import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION, SKIP_AUTHENTICATION } from '@onyxx/model/http';
import { SetupTwoFactorRequest, iUserReferral } from '@onyxx/model/user';
import { LinkedAccount } from '@onyxx/model/linked-account';
import { User, iUser, iUserCredentialsPayload, iUserTokenDetails } from '@onyxx/model/user';
import { HttpContext, HttpStatusCode } from '@angular/common/http';

@Injectable()
export class UserProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  get() {
    return this.http.get<{ user: iUser }>(`${this.apiUrl}/user`).pipe(map((response) => new User(response['user'])));
  }

  patch(payload: Partial<iUser>) {
    return this.http
      .patch<{ user: iUser }>(`${this.apiUrl}/user`, {
        user: payload,
      })
      .pipe(map((response) => new User(response['user'])));
  }

  getReferral() {
    return this.http.get<{ user: iUserReferral | null }>(`${this.apiUrl}/user/referral`).pipe(map((response) => response.user));
  }

  updateCredentials(payload: iUserCredentialsPayload) {
    return this.http.patch<void>(`${this.apiUrl}/user/credentials`, { user: payload });
  }

  getUserTokenDetailsFromToken(token: string) {
    return this.http.get<iUserTokenDetails>(`${this.apiUrl}/user/password_request_token/${token}`, {
      context: new HttpContext().set(SKIP_AUTHENTICATION, true),
    });
  }

  /**
   * Returns user *Linked Accounts*
   */
  getLinkedAccounts() {
    return this.http
      .get<{ linked_accounts: LinkedAccount[] }>(`${this.apiUrl}/linked_accounts`)
      .pipe(map((response) => response.linked_accounts.map((linked_account: LinkedAccount) => new LinkedAccount(linked_account))));
  }

  /**
   * Enables or disables 2FA
   * @param request
   * @returns
   *
   * @throws {HttpErrorResponse} 403 - Forbidden - If the person is not created, 2FA cannot be enabled
   * @throws {HttpErrorResponse} 422 - Unprocessable Entity - If the verification code is invalid
   */
  setupTwoFactorAuthentication(request: SetupTwoFactorRequest) {
    return this.http.patch<{ user: iUser }>(`${this.apiUrl}/user/setup_two_factor`, { user: request }, { observe: 'response' }).pipe(
      map((response) => {
        if (response.status === HttpStatusCode.Created) {
          // the sms was sent
          return { two_factor_enabled: false };
        }
        return { two_factor_enabled: response.body?.user.two_factor_enabled ?? request.two_factor_enabled };
      }),
    );
  }
}
