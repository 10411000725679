import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { goalApiActions } from './goal-api.actions';
import { goalFeature } from './goal.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Goal } from '@onyxx/model/goal';
import { filter, map } from 'rxjs';
import { goalCommonActions } from './goal-common.actions';
import { Utils } from '@onyxx/utility/general';

@Injectable()
export class GoalFacade {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);

  readonly loadSuccess$ = this.actions$.pipe(ofType(goalApiActions.loadSuccess));
  readonly goal$ = this.store.select(goalFeature.selectGoal).pipe(filter((goal): goal is Goal | null => goal !== false));

  readonly updateDone$ = this.actions$.pipe(
    ofType(goalApiActions.updateSuccess, goalApiActions.updateFailure),
    map((action) => {
      return {
        success: action.type === goalApiActions.updateSuccess.type,
        goal: action.type === goalApiActions.updateSuccess.type ? action?.goal : null,
      } as
        | {
            success: true;
            goal: Goal;
          }
        | {
            success: false;
            goal: null;
          };
    }),
  );

  /**
   * Reload the goal if the account ID matches the loaded goal (current goal)
   * @param accountId  The account ID that should be reloaded, if not supplied the
   * current account goal will be reloaded
   */
  dispatchLoad(accountId?: string) {
    if (Utils.isNil(accountId)) {
      this.store.dispatch(goalApiActions.load());
    } else {
      this.store.dispatch(goalApiActions.loadForAccount({ accountId }));
    }
  }

  dispatchUpdate(accountId: string, goal: Partial<Goal>) {
    this.store.dispatch(goalApiActions.update({ accountId, goal }));
  }

  dispatchUpdateGoalInStore(accountId: string, goal: Goal) {
    this.store.dispatch(goalCommonActions.updateGoalInStore({ accountId, goal }));
  }
}
