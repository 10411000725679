import { NgModule, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppLoadingStatusStoreFacade } from '@onyxx/store/app-loading-status';
import { publicAreaRoutes } from './public-area.routes';

@NgModule({
  imports: [RouterModule.forChild(publicAreaRoutes)],
})
export class PublicAreaModule {
  private readonly appLoadingStatusStoreFacade = inject(AppLoadingStatusStoreFacade);

  constructor() {
    this.appLoadingStatusStoreFacade.dispatchDoneLoading();
    this.appLoadingStatusStoreFacade.dispatchHideSplashScreen();
  }
}
