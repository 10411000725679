import { Instrument } from '@semmie/models';
import { InstrumentRate } from '@semmie/models/bi/instrument/instrument-rate';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class InstrumentProvider extends BaseProvider<Instrument> {
  constructor() {
    super('instruments');
  }

  getInstrument(fundId: string): Observable<Instrument> {
    return super.__get('instruments', fundId).pipe(map((response) => new Instrument(response?.['instrument'])));
  }

  getInstrumentRates(fundId: string) {
    return super
      .__query(`instruments/${fundId}/rates`)
      .pipe(map((response) => response?.['instrument_rates']?.map((rate) => new InstrumentRate(rate))));
  }
}
