import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED, getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { AccountStoreFacade } from '@onyxx/store/account';
import { AuthFacade } from '@onyxx/store/auth';
import { filterNil } from '@onyxx/utility/observables';
import { AccountsProvider } from '@semmie/providers/accounts/accounts.provider';
import { AppStorageService } from '@semmie/services';
import { Utils } from '@semmie/shared/utils';
import { AdvisorAccountApiActions } from '@semmie/store/advisor-account/actions/advisor-api.actions';
import { AdvisorAccountStoreActions } from '@semmie/store/advisor-account/actions/advisor-store.actions';
import { advisorAccountFeature, iAdvisorAccount } from '@semmie/store/advisor-account/advisor-account.reducer';
import { EMPTY, catchError, exhaustMap, filter, from, map, of, shareReplay, switchMap, take } from 'rxjs';
import { AccountProvider } from '@onyxx/provider/account';
import { CommonRouteParams } from '@semmie/views/shared/common-route-params.enum';

export const ADVISOR_ACCOUNT_APP_STORAGE_KEY = 'advisor-account-store';

export class AdvisorAccountEffects implements OnInitEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly appStorageService = inject(AppStorageService);
  private readonly router = inject(Router);
  private readonly accountsProvider = inject(AccountsProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly accountProvider = inject(AccountProvider);

  private readonly advisorAppStorageReader = this.appStorageService.createSecuredStorageReader<{ advisorAccounts?: iAdvisorAccount[] }>(
    ADVISOR_ACCOUNT_APP_STORAGE_KEY,
  );

  private readonly accounts$ = this.accountProvider.list().pipe(shareReplay(1));

  readonly initializeFromStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdvisorAccountStoreActions.initialize),
      exhaustMap(() => from(this.advisorAppStorageReader.get())),
      map((advisorAccounts) => AdvisorAccountStoreActions.initializeLoadDone(advisorAccounts ?? undefined)),
    );
  });

  readonly updateStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdvisorAccountStoreActions.saveReturnUrl),
      concatLatestFrom(() => this.store.select(advisorAccountFeature.selectAll)),
      exhaustMap(([, updatedData]) => from(this.advisorAppStorageReader.set({ advisorAccounts: updatedData }))),
      map(() => AdvisorAccountStoreActions.saveDone()),
    );
  });

  readonly loadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdvisorAccountApiActions.loadAccount, AdvisorAccountApiActions.sendProposalSuccess),
      switchMap(({ type, accountId }) => {
        if (type === AdvisorAccountApiActions.sendProposalSuccess.type) {
          this.accountStoreFacade.dispatchBustAccountCache(accountId);
        }
        this.accountStoreFacade.dispatchEnsureAccountIsLoaded(accountId);
        return this.accountStoreFacade.account(accountId).pipe(filterNil());
      }),
      map((account) => {
        return AdvisorAccountApiActions.loadAccountSuccess({ account });
      }),
      catchError(() => of(AdvisorAccountApiActions.loadAccountFailure())),
    );
  });

  readonly baseRouteNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter(() =>
        this.router.isActive('advisor', {
          fragment: 'exact',
          matrixParams: 'ignored',
          paths: 'exact',
          queryParams: 'ignored',
        }),
      ),
      concatLatestFrom(() => this.store.select(advisorAccountFeature.selectAccount)),
      switchMap(([, account]) => {
        if (Utils.isNonNullOrUndefined(account)) {
          return EMPTY;
        }

        // As of now the advisor can only have one account linked
        return this.accounts$.pipe(
          map((accounts) => accounts.data[0]),
          take(1),
          map((account) => AdvisorAccountApiActions.loadAccount({ accountId: account.id })),
        );
      }),
    );
  });

  readonly advisorRouteNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter(() =>
        this.router.isActive('advisor', {
          fragment: 'exact',
          matrixParams: 'ignored',
          paths: 'subset',
          queryParams: 'ignored',
        }),
      ),
      concatLatestFrom(() => [
        this.store.select(getRouterSelectors().selectRouteParam(CommonRouteParams.AccountId)),
        this.store.select(advisorAccountFeature.selectAccount),
      ]),
      switchMap(([, accountId, account]) => {
        // scenarios where accountId in the route is null is handled by other effects
        if (Utils.isNullOrUndefined(accountId)) {
          return EMPTY;
        }
        // check if the account has already been loaded
        if (Utils.isNonNullOrUndefined(account) && accountId === account.id) {
          return EMPTY;
        }

        // trigger load
        return of(AdvisorAccountApiActions.loadAccount({ accountId }));
      }),
    );
  });

  readonly sendProposal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdvisorAccountApiActions.sendProposal),
      exhaustMap(({ accountId }) => this.accountsProvider.sendAdvisorProposal(accountId).pipe(map(() => ({ accountId })))),
      map(({ accountId }) => AdvisorAccountApiActions.sendProposalSuccess({ accountId })),
      // TODO: add error handling to show an error to the user
      catchError(() => of(AdvisorAccountApiActions.sendProposalFailure())),
    );
  });

  readonly clearStore$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(
      exhaustMap(() => from(this.advisorAppStorageReader.remove())),
      map(() => AdvisorAccountStoreActions.clearDone()),
    );
  });

  ngrxOnInitEffects() {
    return AdvisorAccountStoreActions.initialize();
  }
}
