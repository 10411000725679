import { Injectable } from '@angular/core';
import { Withdrawal } from '@semmie/models';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { PaginatedResponse } from '@onyxx/model/pagination';
import { iWithdrawal, WithdrawalCancelRequest, WithdrawalQuery, WithdrawalRequest } from '@semmie/schemas/bi/withdrawal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WithdrawalsProvider extends BaseProvider<Withdrawal> {
  constructor() {
    super('withdrawals');
  }

  /**
   * List paginated account withdrawals
   *
   * @param query: WithdrawalQuery
   * @returns Observable<PaginatedResponse<Withdrawal>>
   */
  list({ accountId, ...params }: WithdrawalQuery): Observable<PaginatedResponse<Withdrawal>> {
    return super.__list(`accounts/${accountId}/withdrawals`, params).pipe(
      map((response) => {
        return {
          data: response['withdrawals'].map((transaction: iWithdrawal) => new Withdrawal(transaction)),
          meta: response.meta,
        };
      }),
    );
  }

  /**
   * Create a withdrawal request
   *
   * @param query: WithdrawalQuery
   * @returns Observable<Withdrawal>
   */
  createWithdrawal({ accountId, ...withdrawal }: WithdrawalRequest): Observable<Withdrawal> {
    return super
      .__post(`accounts/${accountId}/withdrawals`, { withdrawal })
      .pipe(map((response) => new Withdrawal(response['withdrawal'])));
  }

  /**
   * Delete withdrawal request
   */
  deleteWithdrawal({ accountId, withdrawalId }: WithdrawalCancelRequest): Observable<void> {
    return super.__delete(`accounts/${accountId}/withdrawals/${withdrawalId}`).pipe(map(() => {}));
  }
}
