import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Icon } from '@semmie/schemas';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

@Component({
  selector: 'onyxx-info-modal-icon',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: '<semmie-icon [icon]="Icon.INFO" size="xxxs"></semmie-icon>',
  styleUrl: './info-modal-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalIconComponent {
  readonly Icon = Icon;
}
