import { Injectable } from '@angular/core';
import * as SentryAngular from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export class SentryService {
  captureException(exception) {
    SentryAngular.captureException(exception);
  }

  captureMessage(message: string, data?: any) {
    if (data) {
      SentryAngular.setExtra('data', data);
    }
    SentryAngular.captureMessage(message);
  }
}
