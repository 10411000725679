import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import * as Url from 'url-parse';
import { iAppMeta } from '@semmie/schemas/common/app.schema';
import { AppService } from '@semmie/services/app/app.service';

import { environment } from '../../../environments/environment';
import { AuthFacade } from '@onyxx/store/auth';
import { filterNil } from '@onyxx/utility/observables';
import { concatLatestFrom } from '@ngrx/operators';
import { Utils } from '@onyxx/utility/general';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { SKIP_AUTHENTICATION } from '@onyxx/model/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private backendApiUrl: Url = new Url(environment.apiUrl);
  private readonly appService = inject(AppService);
  private readonly authFacade = inject(AuthFacade);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  // Reduce unnecessary calls to retrieve version.json
  private appOsAndVersion$ = this.appService.getAppOsAndVersion().pipe(take(1), shareReplay({ bufferSize: 1, refCount: true }));

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isOurBackend(request)) {
      return next.handle(request);
    }

    if (request.context.get(SKIP_AUTHENTICATION)) {
      return this.appOsAndVersion$.pipe(
        map((appOsAndVersion) => this.cloneAndSetAppHeaders(request, null, appOsAndVersion)),
        switchMap((requestWithHeaders) => next.handle(requestWithHeaders)),
      );
    }

    // we want to respond to the token being change until the first successful response
    // from the server. Otherwise, to observable never completes and HTTP requests are
    // retried when the token refreshes or is revoked.
    const success$$ = new Subject<void>();

    return this.authFacade.token$.pipe(
      takeUntil(success$$),
      map((token) => {
        if (Utils.isNil(token)) {
          throw new HttpErrorResponse({
            status: HttpStatusCode.Unauthorized,
            statusText: 'Unauthorized',
            url: request.url,
            error: {
              message: 'The token is not available.',
            },
          });
        }

        return token;
      }),
      filterNil(),
      concatLatestFrom(() => this.authFacade.sessionExpired$),
      tap(([, isExpired]) => {
        if (isExpired) this.authFacade.dispatchRefreshToken();
      }),
      filter(([, expired]) => !expired),
      switchMap(([token]) => this.appOsAndVersion$.pipe(map((appOsAndVersion) => [token, appOsAndVersion] as const))),
      map(([token, appOsAndVersion]) => this.cloneAndSetAppHeaders(request, token, appOsAndVersion)),
      switchMap((requestWithHeaders) => {
        return next.handle(requestWithHeaders).pipe(
          tap((x) => {
            if (x instanceof HttpResponse) {
              // call was successful, now we can ignore future token emissions
              success$$.next();
            }
          }),
          catchError((error) => {
            if (error.status === HttpStatusCode.Unauthorized) {
              this.authFacade.dispatchRefreshToken();
              return EMPTY;
            }

            throw error;
          }),
        );
      }),
    );
  }

  private cloneAndSetAppHeaders<T>(request: HttpRequest<T>, token: string | null, appOsAndVersion: iAppMeta | null): HttpRequest<T> {
    const requestWithHeaders = request.headers
      .set('X-CLIENT-APP', appOsAndVersion?.os ?? '0.0.0')
      .set('X-CLIENT-APP-VERSION', appOsAndVersion?.version?.number ?? 'unknown');

    if (token) {
      return request.clone({
        headers: requestWithHeaders.set('Authorization', `Bearer ${token}`),
      });
    }

    return request.clone({
      headers: requestWithHeaders,
    });
  }

  private isOurBackend(request: HttpRequest<any>): boolean {
    return request.url.indexOf(this.backendApiUrl.hostname) > -1;
  }
}
