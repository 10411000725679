import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { EMPTY, catchError, exhaustMap, filter, map, of, tap } from 'rxjs';
import { inject } from '@angular/core';
import { AUTH_STORE_CONFIG } from '../auth-store-config.token';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { authFeature } from '../auth.reducer';
import { AuthProvider } from '@onyxx/provider/auth';
import { filterNil } from '@onyxx/utility/observables';
import { ConfigService, ToastService } from '@semmie/services';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { GenericErrorToast } from '@semmie/schemas/components/toast';
import { AuthFacade } from '../auth.facade';
import { Utils } from '@onyxx/utility/general';
import { authApiActions } from '../auth-api.actions';

export class TwoFactorEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly navController = inject(NavController);
  private readonly authProvider = inject(AuthProvider);
  private readonly configService = inject(ConfigService);
  private readonly toastService = inject(ToastService);
  private readonly authFacade = inject(AuthFacade);

  private readonly configToken = inject(AUTH_STORE_CONFIG);
  private readonly config = inject(this.configToken);

  readonly handleTwoFactor$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.twoFactorRequiredForLogin),
        tap(async () => {
          const twoFactorUrl = await this.config.twoFactorRedirectUrl();
          this.navController.navigateForward(twoFactorUrl);
        }),
      );
    },
    { dispatch: false },
  );

  readonly twoFactorLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.twoFactorLogin),
      concatLatestFrom(() => [this.store.select(authFeature.selectTwoFactorLoginDetailsCache)]),
      map(([{ code }, twoFactorDetails]) => {
        if (Utils.isNil(twoFactorDetails)) return null;
        return authApiActions.login({ username: twoFactorDetails.username, password: twoFactorDetails.password, code });
      }),
      filterNil(),
    );
  });

  readonly resendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.twoFactorSendCode),
      concatLatestFrom(() => [
        this.store.select(authFeature.selectTwoFactorLoginDetailsCache),
        this.configService.config$,
        this.authFacade.twoFactorResendAllowedAt$,
      ]),
      filter(([, , , twoFactorResendAllowedAt]) => Utils.isNil(twoFactorResendAllowedAt) || twoFactorResendAllowedAt < Date.now()),
      exhaustMap(([, twoFactorDetails, config]) => {
        if (Utils.isNil(twoFactorDetails)) return EMPTY;
        return this.authProvider
          .requestToken({
            grant_type: 'password',
            client_id: config.oauth?.client_id ?? '',
            client_secret: config.oauth?.client_secret ?? '',
            username: twoFactorDetails.username,
            password: twoFactorDetails.password,
          })
          .pipe(
            map(() => authApiActions.twoFactorSendCodeSuccess()),
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.UnprocessableEntity) {
                // expected error
                return of(authApiActions.twoFactorSendCodeSuccess());
              }

              if (
                error instanceof HttpErrorResponse &&
                error.status === HttpStatusCode.BadRequest &&
                error.error?.error === 'two_factor_verification_limit'
              ) {
                return of(authApiActions.twoFactorSendCodeLimit({ due: new Date(error.error.expires_at).getTime() }));
              }

              if (
                error instanceof HttpErrorResponse &&
                error.status === HttpStatusCode.BadRequest &&
                error.error?.error === 'user_locked'
              ) {
                return of(authApiActions.loginFailure({ error }));
              }

              return of(authApiActions.twoFactorSendCodeFailure({ errorMsg: error }));
            }),
          );
      }),
    );
  });

  readonly sendCodeError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.twoFactorSendCodeFailure),
        tap(() => {
          this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );
}
