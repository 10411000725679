import { Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Observable, switchMap, combineLatest } from 'rxjs';

export function mapSignalsFromObservable<TInput1, T>(
  signals: [Signal<TInput1>],
  map: (input1: TInput1) => Observable<T>,
  initialValue: T,
): Signal<T>;
export function mapSignalsFromObservable<TInput1, TInput2, T>(
  signals: [Signal<TInput1>, Signal<TInput2>],
  map: (input1: TInput1, input2: TInput2) => Observable<T>,
  initialValue: T,
): Signal<T>;
export function mapSignalsFromObservable<TInput1, TInput2, TInput3, T>(
  signals: [Signal<TInput1>, Signal<TInput2>, Signal<TInput3>],
  map: (input1: TInput1, input2: TInput2, input3: TInput3) => Observable<T>,
  initialValue: T,
): Signal<T>;
export function mapSignalsFromObservable<TInput1, TInput2, TInput3, TInput4, T>(
  signals: [Signal<TInput1>, Signal<TInput2>, Signal<TInput3>, Signal<TInput4>],
  map: (input1: TInput1, input2: TInput2, input3: TInput3, input4: TInput4) => Observable<T>,
  initialValue: T,
): Signal<T>;
export function mapSignalsFromObservable<TInput1, TInput2, TInput3, TInput4, TInput5, T>(
  signals: [Signal<TInput1>, Signal<TInput2>, Signal<TInput3>, Signal<TInput4>, Signal<TInput5>],
  map: (input1: TInput1, input2: TInput2, input3: TInput3, input4: TInput4, input5: TInput5) => Observable<T>,
  initialValue: T,
): Signal<T>;
export function mapSignalsFromObservable<TInput1, TInput2, TInput3, TInput4, TInput5, TInput6, T>(
  signals: [Signal<TInput1>, Signal<TInput2>, Signal<TInput3>, Signal<TInput4>, Signal<TInput5>, Signal<TInput6>],
  map: (input1: TInput1, input2: TInput2, input3: TInput3, input4: TInput4, input5: TInput5, input6: TInput6) => Observable<T>,
  initialValue: T,
): Signal<T>;
export function mapSignalsFromObservable<TInput1, TInput2, TInput3, TInput4, TInput5, TInput6, T>(
  signals: Signal<TInput1 | TInput2 | TInput3 | TInput4 | TInput5 | TInput6>[],
  map: (...variables: Array<TInput1 | TInput2 | TInput3 | TInput4 | TInput5 | TInput6>) => Observable<T>,
  initialValue: T,
): Signal<T> {
  return toSignal(combineLatest(signals.map((s) => toObservable(s))).pipe(switchMap((variables) => map(...variables))), {
    initialValue,
  });
}
