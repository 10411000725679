import { Injectable } from '@angular/core';
import { Referral } from '@semmie/models/bi/referral/referral.model';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { PaginatedResponse } from '@onyxx/model/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReferralProvider extends BaseProvider<Referral> {
  constructor() {
    super('referrals');
  }

  /**
   * List referrals, optionally with params to more specifically filter your request
   *
   * @param params
   * @returns
   */
  list(params?: { page?: number }): Observable<PaginatedResponse<Referral>> {
    return super.__list('referrals', params).pipe(
      map((response) => {
        return {
          data: response?.['referrals']?.map((b) => new Referral(b)),
          meta: response?.meta,
        };
      }),
    );
  }

  clear(): void {
    this.__clear();
  }
}
