import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CheckboxModule as PCheckboxModule } from 'primeng/checkbox';

import { CheckboxComponent } from '@semmie/components/presentational/core/checkbox/checkbox.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { SelectionCardComponent } from '@semmie/components/presentational/core/selection-card/selection-card.component';
import { LabelModule } from '@semmie/components/presentational/core/label';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, FormsModule, IconComponent, LabelModule, ReactiveFormsModule, PCheckboxModule, SelectionCardComponent],
  exports: [CheckboxComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CheckboxModule {}
