import { parsePhoneNumber } from 'libphonenumber-js';

export class Formatter {
  static formatIban(iban?: string): string {
    return iban?.match(/.{1,4}/g)?.join(' ') ?? '';
  }

  static formatPhone(number: string) {
    return parsePhoneNumber(number, 'NL').formatNational();
  }
}
