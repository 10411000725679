import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';
import { PaginationMeta } from '@onyxx/model/pagination';

export const visibleAccountsListApiActions = createActionGroup({
  source: 'Visible Accounts List API',
  events: {
    'Reload accounts': emptyProps(),
    'Reload accounts success': props<{ accounts: Account[]; paginationMeta: PaginationMeta }>(),
    'Reload accounts failure': props<{ errorMsg: string }>(),

    'Load next page': emptyProps(),
    'Load page success': props<{ accounts: Account[]; paginationMeta: PaginationMeta }>(),
    'Load page failure': props<{ errorMsg: string }>(),
  },
});
