export class Tin {
  static checkDutchNumber(number: string) {
    if (!number || number?.length < 9) return false;

    const calculated =
      parseInt(number[0], 10) * 9 +
      parseInt(number[1], 10) * 8 +
      parseInt(number[2], 10) * 7 +
      parseInt(number[3], 10) * 6 +
      parseInt(number[4], 10) * 5 +
      parseInt(number[5], 10) * 4 +
      parseInt(number[6], 10) * 3 +
      parseInt(number[7], 10) * 2 +
      parseInt(number[8], 10) * -1;

    return calculated % 11 !== 0;
  }

  static checkBelgiumNumber(number: string) {
    const checkDigit = number.slice(-2);
    const before2000sCheck = parseInt(number.slice(0, 9));
    const after2000sCheck = parseInt('2' + number.slice(0, 9));
    const modFn = (number) => 97 - (number % 97);

    return modFn(before2000sCheck) !== parseInt(checkDigit) && modFn(after2000sCheck) !== parseInt(checkDigit);
  }
}
