import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { iQuestions } from '@onyxx/model/questions';

const PROVIDER_KEY_PEOPLE = 'people/questions';
const PROVIDER_KEY_ORGANISATION = 'organisations/questions';

@Injectable()
export class QuestionsProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  listQuestionPerson() {
    return this.http.get<iQuestions>(`${this.apiUrl}/${PROVIDER_KEY_PEOPLE}`);
  }

  listQuestionOrganisation() {
    return this.http.get<iQuestions>(`${this.apiUrl}/${PROVIDER_KEY_ORGANISATION}`);
  }
}
