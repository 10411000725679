import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { AdvisorAccountStoreActions } from '@semmie/store/advisor-account/actions/advisor-store.actions';
import { AdvisorAccountApiActions } from '@semmie/store/advisor-account/actions/advisor-api.actions';
import { Account } from '@onyxx/model/account';

export interface iAdvisorAccount {
  id: string;
  returnUrl: string;
}

export interface State extends EntityState<iAdvisorAccount> {
  initialized: boolean;
  account: Account | null;
  sendingProposal: boolean;
}

export const adapter = createEntityAdapter<iAdvisorAccount>();
const initialState: State = adapter.getInitialState({ initialized: false, account: null, sendingProposal: false });

const reducer = createReducer(
  initialState,
  on(
    AdvisorAccountStoreActions.initializeLoadDone,
    (state, { advisorAccounts = [] }): State => ({ ...adapter.setAll(advisorAccounts, state), initialized: true }),
  ),
  on(
    AdvisorAccountStoreActions.saveReturnUrl,
    (state, { accountId, returnUrl }): State => ({ ...adapter.upsertOne({ id: accountId, returnUrl }, state) }),
  ),
  on(AdvisorAccountApiActions.loadAccountSuccess, (state, { account }): State => ({ ...state, account })),
  on(AdvisorAccountApiActions.loadAccountFailure, (state): State => ({ ...state, account: null })),

  on(AdvisorAccountApiActions.sendProposal, (state): State => ({ ...state, sendingProposal: true })),
  on(AdvisorAccountApiActions.sendProposalSuccess, (state): State => ({ ...state, sendingProposal: false })),
  on(AdvisorAccountApiActions.sendProposalFailure, (state): State => ({ ...state, sendingProposal: false })),

  on(AdvisorAccountStoreActions.clearDone, (): State => initialState),
);

export const advisorAccountFeature = createFeature({
  name: 'advisorAccount',
  reducer,
  extraSelectors: ({ selectAdvisorAccountState, selectEntities }) => ({
    ...adapter.getSelectors(selectAdvisorAccountState),
    selectReturnUrlByAccountId: (accountId: string) =>
      createSelector(selectEntities, (advisorAccountMap) => advisorAccountMap[accountId]?.returnUrl ?? ''),
  }),
});
