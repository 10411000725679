import { BaseModel } from '@semmie/models/_abstract';
import { iInstrument, iPortfolioPosition, iPortfolioRegion, iPortfolioSector, iPortfolioStatistic } from '@semmie/schemas';
import { instrumentTypeEnum } from '@semmie/schemas/bi/instrument/instrument-type';
import { InstrumentKind } from '@semmie/schemas/bi/instrument/instrument-kind';
import { TagTheme } from '@semmie/schemas/common/tag';

export class Instrument extends BaseModel<iInstrument> implements iInstrument {
  id: string;
  title: string;
  description: string;
  detail: boolean;
  content: string;
  symbol: string;
  tag: string;
  /** @deprecated */
  tag_theme: string;
  url: string;
  url_whitepaper: string;
  aum: string;
  costs: string;
  isin: string;
  kind: InstrumentKind;
  dividend: string;
  rating: number;
  region_image: string;
  regions: iPortfolioRegion[];
  fund_image: string;
  top_positions: iPortfolioPosition[];
  top_sectors: iPortfolioSector[];
  statistics: iPortfolioStatistic[];
  fund_description: string;
  incorporation: string;
  binck: number;
  currency?: string;

  /** @deprecated */
  get instrumentType(): instrumentTypeEnum {
    return this.tag_theme === 'green' ? instrumentTypeEnum.STOCK : instrumentTypeEnum.BOND;
  }

  get instrumentKind(): InstrumentKind {
    return this.kind ?? this.instrumentType;
  }

  get tagTheme(): TagTheme {
    if (this.kind) {
      switch (this.kind) {
        case InstrumentKind.STOCK:
          return 'variantC';
        case InstrumentKind.BOND:
          return 'variantD';
        case InstrumentKind.PRIVATE_MARKET:
          return 'variantE';
        case InstrumentKind.CASH:
          return 'variantF';
      }
    }
    return this.instrumentType === instrumentTypeEnum.STOCK ? 'variantC' : 'variantD';
  }
}
