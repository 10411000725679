import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { PaginatedResponse } from '@onyxx/model/pagination';
import { Task } from '@semmie/models/bi/task/task.model';
import { iTask } from '@semmie/schemas/bi/task/task.interface';

@Injectable()
export class TaskProvider extends BaseProvider<Task> {
  constructor() {
    super('tasks');
  }

  /**
   * Get a task
   *
   * ? Developer note: Explicitely remap data here for consistency
   *
   * @param id Account id
   * @returns Observable<Task>
   */
  get(id: string) {
    return super.__get('tasks', id).pipe(map((response) => (response && response['task'] ? new Task(response['task']) : null)));
  }

  /**
   * List tasks, optionally with params to more specifically filter your request
   *
   * ? Developer note: Explicitely remap data here for consistency
   *
   * @param params
   * @returns Observable<PaginatedResponse<Task>>
   */
  list(params?: any): Observable<PaginatedResponse<Task>> {
    return super.__list('tasks', params).pipe(
      map((response) => {
        return {
          data: response?.['tasks']?.map((t: iTask) => new Task(t)),
          meta: response.meta,
        };
      }),
    );
  }

  /**
   * Patch the task with specific values
   * @param id string
   * @param task Partial
   * @returns Observable<Task>
   */
  patch(id: string, task: Partial<Task>): Observable<Task> {
    return super.__patch(`tasks/${id}`, task);
  }

  clear(): void {
    this.__clear();
  }
}
