import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { firstValueFrom, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { VersionInfo } from '@semmie/schemas/common/app.schema';

export class Utils {
  static httpclient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

  /**
   * @deprecated in favour of isNil from @onyxx/utility/general
   *
   * TODO: replace all call sites and remove this function
   */
  static isNullOrUndefined<T>(element: T | null | undefined): element is null | undefined {
    return element === null || element === undefined;
  }

  static isNull<T>(element: T | null | undefined): element is null {
    return element === null;
  }

  /**
   * @deprecated in favour of isNotNil from @onyxx/utility/general
   *
   * TODO: replace all call sites and remove this function
   */
  static isNonNullOrUndefined<T>(element: T | null | undefined): element is NonNullable<T> {
    return !Utils.isNullOrUndefined(element);
  }

  /**
   * @deprecated is favour of openUrl from @onyxx/utility/general
   */
  static openUrl(url: string, sameTab?: boolean) {
    if (!sameTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  /**
   * @deprecated use parseBoolean instead
   */
  static isStringTruthy(str: string): boolean {
    return str === 'true';
  }

  /**
   * @deprecated use the function in the general utils library
   */
  static urlEncodeObject(object: object): string {
    return Object.entries(object)
      .reduce((acc, [key, value]) => {
        return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`];
      }, [])
      .join('&');
  }

  static baseUrl(): string {
    return window.location.origin;
  }

  static async getAppVersion() {
    return firstValueFrom(this.httpclient.get<VersionInfo>('assets/version.json'));
  }

  /**
   * @deprecated in favour of filterNil from @onyxx/utility/observables
   */
  static filterNil() {
    return pipe(filter(Utils.isNonNullOrUndefined));
  }
}
