import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

import { ImpactStyle } from '@capacitor/haptics';

import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

import * as moment from 'moment';
import { Subject, merge } from 'rxjs';
import { map, skipWhile, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { chartOptions, GoalChartHeaderData } from '@semmie/components/containers/account/goal/goal-chart';
import { BaseComponent } from '@semmie/components/_abstract';
import { Goal } from '@semmie/models';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { GoalFocus } from '@semmie/schemas/bi/goal/goal-focus.enum';
import { GoalState } from '@semmie/schemas/bi/goal/goal-state.enum';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Utils } from '@onyxx/utility/general';
import { Account, AccountKind } from '@onyxx/model/account';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'semmie-goal-chart',
  templateUrl: './goal-chart.component.html',
  styleUrls: ['./goal-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalChartComponent extends BaseComponent implements OnChanges, OnDestroy {
  @Input() account: Account;
  @Input() datesPension: {
    birth_at: string;
    retirement_at: string;
  };
  @Input() goal: Goal;
  @Output() onScrubHeaderData: EventEmitter<GoalChartHeaderData | null> = new EventEmitter();

  readonly dateNow = Number(moment().format('x'));
  readonly Highcharts: typeof Highcharts;

  readonly chartPointerMove$$ = new Subject<PointerEvent | TouchEvent>();
  readonly chartPointerEnter$$ = new Subject<PointerEvent | TouchEvent>();
  readonly chartPointerLeave$$ = new Subject<PointerEvent | TouchEvent>();

  readonly active$$ = toSignal(merge(this.chartPointerEnter$$.pipe(map(() => true)), this.chartPointerLeave$$.pipe(map(() => false))), {
    initialValue: false,
  });

  chartAvailable: boolean;
  chartData: any;
  chartOptions: any = chartOptions(this);
  chartOptionsCopy: any;
  headerData: { max: number; min: number; timestamp: number } | null = null;
  shouldUpdate: boolean;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private hapticFeedbackService: HapticFeedbackService,
    private semmieCurrencyPipe: SemmieCurrencyPipe,
    private platformService: PlatformService,
  ) {
    super();

    highchartsMore(Highcharts);
    this.Highcharts = Highcharts;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.goal && !Utils.isEqual(changes?.goal.previousValue, changes?.goal.currentValue)) {
      this.chartData = this.goal.formattedGraphData;
      this.plotChart();
    }
  }

  /** Prepare chart data */
  plotChart(): void {
    // ugly workaround because Highcharts is literally modifying the passed data
    this.chartOptionsCopy = JSON.parse(JSON.stringify(this.chartOptions));
    this.chartOptionsCopy.chart.events = this.chartOptions.chart.events;

    if (this.chartData?.length == 2 && this.goal.state !== GoalState.INVALID) {
      this.chartOptionsCopy.series[0].data = this.chartData[0];
      this.chartOptionsCopy.series[0].pointStart = this.dateNow;
      this.chartOptionsCopy.series[0].zones = [];
      this.chartOptionsCopy.series[1].data = this.chartData[1];
      this.chartOptionsCopy.series[1].pointStart = this.dateNow;
      this.chartOptionsCopy.series[1].zones = [];

      if (this.goal?.amount && this.goal?.focus === GoalFocus.AMOUNT) {
        this.chartOptionsCopy.yAxis.plotLines = [
          {
            id: 'goal',
            color: 'var(--color-goal-chart-goal-line)',
            width: 1,
            value: this.goal.amount,
            zIndex: 9,
            label: {
              style: { color: 'var(--color-goal-chart-goal-label)', fontSize: '10px', fontWeight: 'medium' },
              text: `Doelbedrag ${this.semmieCurrencyPipe.transform(this.goal.amount, false, 'decimalsIfAvailable')}`,
              rotation: 0,
              verticalAlign: 'top',
              y: -6,
              x: 0,
            },
          },
        ];
      } else {
        this.chartOptionsCopy.yAxis.plotLines = [];
      }

      const realistic = this.chartData[0];
      const lastRealisticItem = realistic[realistic.length - 1];
      const lastDate = moment(lastRealisticItem[0]).format('x');
      this.chartOptionsCopy.xAxis.plotLines[1].value = Number(lastDate);
      this.chartOptionsCopy.xAxis.plotLines[1].label.text =
        !!this.goal.annuity_ends_at_age || this.account.kind === AccountKind.ANNUITY
          ? String(this.goal.getPensionAge(this.datesPension) + ' ' + $localize`:@@goal-chart.x-axis.year:year`)
          : String(moment(lastRealisticItem[0]).format('YYYY'));
      this.chartOptionsCopy.yAxis.min = this.calculateLowestValue();
      this.chartOptionsCopy.yAxis.max = this.calculateHighestValue();

      this.shouldUpdate = true;
      this.chartAvailable = true;
    }
  }

  hideChartCrosshair(chart: Highcharts.Chart, event: PointerEvent | MouseEvent | TouchEvent) {
    if (!Utils.isNil(chart.pointer)) {
      const enrichedEvent = chart.pointer.normalize(event);
      const point = chart.pointer.findNearestKDPoint(chart.series, true, enrichedEvent);
      point?.setState();
      chart.pointer.reset(false, 0);
    }

    chart.xAxis[0].hideCrosshair();
  }

  renderChartCrosshair(chart: Highcharts.Chart, event: PointerEvent | MouseEvent | TouchEvent) {
    if (Utils.isNil(chart.pointer)) return;

    const enrichedEvent = chart.pointer.normalize(event);

    const point = chart.pointer.findNearestKDPoint(chart.series, true, enrichedEvent);

    point?.setState('hover');

    chart.xAxis[0].drawCrosshair(enrichedEvent, point);

    const headerData = this.chartData[0].find((item: number[]) => item[0] === point?.x);

    this.setGraphHeader(headerData);
  }

  /**
   * Highcharts callback to bind events
   */
  chartCallback(chart: Highcharts.Chart | null) {
    if (Utils.isNil(chart)) return;

    this.chartPointerEnter$$.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      // reflow is needed to fix a bug where the pointer cannot find the matching coordinates for the chart to be rendered
      chart.reflow();
      this.hapticFeedbackService.interact(ImpactStyle.Heavy);
      this.renderChartCrosshair(chart, e);
    });

    this.chartPointerMove$$
      .pipe(
        throttleTime(10),
        tap((event) => this.renderChartCrosshair(chart, event)),
        throttleTime(100),
        skipWhile(() => this.platformService.isAndroid),
        tap(() => this.hapticFeedbackService.interact(ImpactStyle.Light)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.chartPointerLeave$$.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      this.hideChartCrosshair(chart, e);
      this.setGraphHeader();
    });

    // android doesn't dispatch the pointer events properly, so we use touch event-variants of these instead
    if (this.platformService.isAndroid) {
      chart.container.addEventListener('touchmove', (e) => this.chartPointerMove$$.next(e), { passive: true });
      chart.container.addEventListener('touchend', (e) => this.chartPointerLeave$$.next(e), { passive: true });
    } else {
      chart.container.addEventListener('pointermove', (e) => this.chartPointerMove$$.next(e), { passive: true });
      chart.container.addEventListener('pointerleave', (e) => this.chartPointerLeave$$.next(e), { passive: true });
    }

    chart.container.addEventListener('pointerenter', (e) => this.chartPointerEnter$$.next(e), { passive: true });
    chart.container.addEventListener('click', (e) => this.hideChartCrosshair(chart, e), { passive: true });
  }

  /**
   * Emit the header data while scrubbing or untapping
   * @param result number[]
   */
  setGraphHeader(result?: number[]) {
    if (result) {
      this.headerData = {
        max: result[2],
        min: result[1],
        timestamp: result[0],
      };
    } else {
      this.headerData = null;
    }
    this.onScrubHeaderData.emit(this.headerData);
  }

  /**
   * Lowest possible value in the graph
   */
  private calculateLowestValue(): number {
    if (!this.goal || !this.chartData) return 0;
    const goal = typeof this.goal.amount !== 'undefined' ? Number(this.goal.amount) : undefined;
    const reducer = (previousValue: number, currentValue: string | number[]) => {
      return Number(currentValue[1]) < previousValue ? Number(currentValue[1]) : previousValue;
    };
    return this.chartData[1].reduce(reducer, goal);
  }

  /**
   * Highest possible value in the graph
   */
  private calculateHighestValue(): number | undefined {
    if (!this.goal || !this.chartData) return;
    const goal = typeof this.goal.amount !== 'undefined' ? Number(this.goal.amount) * 1.1 : undefined;
    const reducer = (previousValue: number, currentValue: string | number[]) => {
      return Number(currentValue[2]) > previousValue ? Number(currentValue[2]) : previousValue;
    };
    return this.chartData[1].reduce(reducer, goal);
  }
}
