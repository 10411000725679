import { AfterViewInit, ChangeDetectionStrategy, Component, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ContentContainerModule, ImageModule, LabelModule } from '@semmie/components';
import { AppStorageService, ModalService, PlatformService } from '@semmie/services';
import { PinCodeStorage, PinCodeStorageKeys } from '@semmie/schemas/bi/pincode';
import { DialogService } from '@semmie/services/dialog/dialog.service';
import { SharedModule } from '@semmie/shared/shared.module';
import { asyncScheduler, BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Keyboard } from '@capacitor/keyboard';
import { EventsService } from '@semmie/services/events/events.service';
import { EventDataCategory } from '@semmie/schemas/bi/event/event.schema';
import { AuthFacade } from '@onyxx/store/auth';
import { BiometricStatus, BiometricsStoreFacade } from '@onyxx/store/biometrics';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PinInputComponent } from '@onyxx/ui/pin-input';

const CLOSE_MODAL_DELAY = 400;

@Component({
  selector: 'semmie-security-modal',
  templateUrl: './security-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(PinInputComponent)
  pinInput: PinInputComponent | undefined;

  showPinCode$ = new BehaviorSubject<boolean>(false);
  codeSize = 5 as const;
  codeControl = new FormControl('', { nonNullable: true });
  ignoreBiometrics = false;

  private readonly pinCodeStorage = this.appStorageService.createSecuredStorageReader<PinCodeStorage>(PinCodeStorageKeys.PinCodeNumber);

  private destroy$ = new Subject<void>();

  constructor(
    private biometricsStoreFacade: BiometricsStoreFacade,
    private appStorageService: AppStorageService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private authFacade: AuthFacade,
    private eventsService: EventsService,
    private platformService: PlatformService,
  ) {
    // The security modal should never be shown if the user is not authenticated
    // If the user logs out while the modal is open, the modal should be dismissed
    // If the user decide to log out (if they forgot their code) the modal should be dismissed
    this.authFacade.isAuthenticated$
      .pipe(
        takeUntilDestroyed(),
        filter((authenticated) => !authenticated),
      )
      .subscribe(() => {
        this.dismissModal();
      });
  }

  async ngOnInit() {
    const { status } = await firstValueFrom(this.biometricsStoreFacade.availability$);
    const biometricsEnabled = await firstValueFrom(this.biometricsStoreFacade.enabled$);

    if (status === BiometricStatus.Enrolled && biometricsEnabled && !this.ignoreBiometrics) {
      this.biometricAuthenticate();
    } else {
      this.showPinCodeVerification();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    asyncScheduler.schedule(() => {
      this.focusInput();
      // force keyboard open on Android
      if (this.platformService.isAndroid) {
        Keyboard.show();
      }
    }, 350);
  }

  async clearPinCode() {
    const confirmResult = await this.dialogService.confirm({
      title: $localize`:@@security-modal.code.clear.title:Confirm`,
      message: $localize`:@@security-modal.code.clear.message:Are you sure? This will remove your PIN from the device.`,
      cancelButtonTitle: $localize`:@@security-modal.code.clear.cancel-button:Cancel`,
      okButtonTitle: $localize`:@@security-modal.code.clear.ok-button:Ok`,
    });
    if (!confirmResult.value) {
      this.focusInput();
      return;
    }

    this.authFacade.dispatchLogOut();
  }

  focusInput() {
    this.pinInput?.focus();
  }

  private async showBiometricAbortAlert() {
    await this.dialogService.alert({
      title: $localize`:@@security-modal.biometric.abort.title:You have canceled the scan. Log in another way.`,
      message: '',
      buttonTitle: $localize`:@@security-modal.biometric.abort.button:Ok`,
    });
    this.showPinCodeVerification();
  }

  private showPinCodeVerification() {
    this.showPinCode$.next(true);
    this.onCodeControlChanged();
    asyncScheduler.schedule(() => {
      this.focusInput();
    }, 350);
  }

  private onCodeControlChanged() {
    this.codeControl.valueChanges
      .pipe(
        filter((code) => code.length === this.codeSize),
        withLatestFrom(this.pinCodeStorage.get()),
        takeUntil(this.destroy$),
      )
      .subscribe(([inputCode, currentPinCodeInStorage]) => {
        if (inputCode !== currentPinCodeInStorage?.pinCode) {
          this.codeControl.setValue('', { emitEvent: false });
          this.codeControl.setErrors({ incorrect: true });
          return;
        }

        this.logEvent('login_pincode');

        this.dismissModal(true);
      });
  }

  private async biometricAuthenticate() {
    this.biometricsStoreFacade.dispatchAuthenticate();

    const result = await firstValueFrom(this.biometricsStoreFacade.authenticationDoneEvent$);

    if (!result.success) {
      await this.showBiometricAbortAlert();
      return;
    }

    const { type } = await firstValueFrom(this.biometricsStoreFacade.availability$);
    this.logEvent(`login_${type}`);
    this.dismissModal(true);
    return;
  }

  private dismissModal(success = false) {
    Keyboard.hide();

    asyncScheduler.schedule(() => {
      if (this.modalService.modal) {
        this.modalService.modal.canDismiss = true;
      }
      this.modalService.close({ success });
    }, CLOSE_MODAL_DELAY);
  }

  private logEvent(name: string) {
    firstValueFrom(
      this.eventsService.post({
        name,
        category: EventDataCategory.System,
      }),
    );
  }
}

@NgModule({
  imports: [SharedModule, ImageModule, LabelModule, FormsModule, ReactiveFormsModule, ContentContainerModule, PinInputComponent],
  declarations: [SecurityModalComponent],
})
export class SecurityModalModule {}
