import { Injectable } from '@angular/core';
import { BaseStore } from '@semmie/models/_abstract';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { QuestionnaireSubject, iQuestionnaire } from '@onyxx/model/questionnaire';
import { UserStoreFacade } from '@semmie/store/user';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

const SCENARIO_TIERS = [5000, 25000, 50000];
@Injectable({
  providedIn: 'root',
})
export class QuestionnaireStore extends BaseStore<Map<QuestionnaireSubject, iQuestionnaire>> {
  questionnaireData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private deposit: number;

  constructor(
    private userFacade: UserStoreFacade,
    private semmieCurrencyPipe: SemmieCurrencyPipe,
  ) {
    super(new Map<QuestionnaireSubject, iQuestionnaire>());
    this.userFacade.user$.pipe(take(1)).subscribe((user) => {
      this.deposit = SCENARIO_TIERS.reduce((acc, tier) => {
        const deposit = Number(user?.total_deposit);
        if (deposit < tier) return acc;
        return tier;
      }, SCENARIO_TIERS[0]);
      this.resetQuestionnaireData();
    });
  }

  /**
   * Retrieve the questionnaire data
   */
  get questionnaire() {
    return this.questionnaireData$;
  }

  /**
   * Reset the questionnaire data to its initial value
   */
  resetQuestionnaireData(): void {
    this.questionnaireData$.next({
      values: {},
      deposits: [
        { question: 'question_214', deposit: this.deposit, depositDisplay: this.semmieCurrencyPipe.transform(this.deposit) },
        { question: 'question_215' },
        { question: 'question_216' },
        { question: 'question_217' },
      ],
    });
  }

  updateQuestionnaireData(data: any): void {
    this.questionnaire.next(data);
  }
}
