import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authApiActions } from '../auth-api.actions';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { AuthProvider } from '@onyxx/provider/auth';
import { EventsService, ToastService } from '@semmie/services';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericErrorToast, ToasterStyle } from '@semmie/schemas/components/toast';
import { EventDataCategory } from '@semmie/schemas/bi/event/event.schema';

export class RegisterEffects {
  private readonly actions$ = inject(Actions);
  private readonly authProvider = inject(AuthProvider);
  private readonly toastService = inject(ToastService);
  private readonly eventService = inject(EventsService);

  readonly register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.register),
      exhaustMap((userDetails) => {
        return this.authProvider
          .register({
            user: {
              name_casual: userDetails.name,
              email: userDetails.email,
              password: userDetails.password,
            },
          })
          .pipe(
            map(() => authApiActions.registerSuccess({ email: userDetails.email, password: userDetails.password })),
            catchError((error) => of(authApiActions.registerFailure({ error }))),
          );
      }),
    );
  });

  readonly loginAfterRegister$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.registerSuccess),
      map(({ email, password }) => authApiActions.login({ username: email, password: password })),
    );
  });

  readonly registerFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.registerFailure),
        tap(({ error }) => {
          const errorKey =
            error instanceof HttpErrorResponse && error.error?.details?.email?.[0] ? error.error.details.email[0].error : null;
          this.toastService.show({
            message: (() => {
              switch (true) {
                case errorKey === 'taken':
                  return $localize`:@@auth.register.error-toast.email.taken:The entered email is already registered.`;
                case errorKey === 'invalid':
                  return $localize`:@@auth.register.error-toast.email.invalid:Dis you forget a @ or .? The entered e-mail address is invalid.`;
                default:
                  return GenericErrorToast().message;
              }
            })(),
            style: ToasterStyle.DANGER,
          });
        }),
      );
    },
    { dispatch: false },
  );

  readonly registerEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.registerSuccess),
        switchMap(() =>
          this.eventService.post({
            category: EventDataCategory.System,
            name: 'sign_up',
          }),
        ),
      );
    },
    { dispatch: false },
  );
}
