import { NgModule } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { visibleAccountsListFeature } from './visible-accounts-list.reducer';
import { VisibleAccountsListStoreFacade } from './visible-accounts-list.facade';
import { VisibleAccountsListEffects } from './visible-accounts-list.effects';

export const providers = [
  provideState(visibleAccountsListFeature),
  provideEffects(VisibleAccountsListEffects),
  VisibleAccountsListStoreFacade,
];

@NgModule({ providers })
export class VisibleAccountsListStateModule {}

export * from './visible-accounts-list.facade';
