import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { PaginatedResponse, PaginationMeta, PaginationParams } from '@onyxx/model/pagination';
import { TaskDetail, UserTask } from '@onyxx/model/task';
import { map, Observable } from 'rxjs';

@Injectable()
export class UserTaskProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  get(id: string) {
    return this.http.get<{ task: TaskDetail }>(`${this.apiUrl}/tasks/${id}`).pipe(map((response) => response.task));
  }

  list(params?: Partial<PaginationParams>): Observable<PaginatedResponse<UserTask>> {
    return this.http
      .get<{ tasks: UserTask[]; meta: PaginationMeta }>(`${this.apiUrl}/tasks`, {
        params: { ...params },
      })
      .pipe(
        map((res) => ({
          data: res.tasks,
          meta: res.meta,
        })),
      );
  }
}
