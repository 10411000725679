/**
 * Strip an unkown amount of of characters from the beginning of a string
 * @param strip content of what should be stripped from the value
 * @param value
 * @returns string
 */
export const recursivelyStripPrefix = (strip: string, value: string): string => {
  if (!value.startsWith(strip)) return value;
  return recursivelyStripPrefix(strip, value.replace(strip, ''));
};
