import { BaseModel } from '@semmie/models/_abstract/base-model';
import { iAdvisor, iAdvisorSettings, iAdvisorUser, iManagementFee } from '@semmie/schemas/bi/advisor/advisor.schema';

export class Advisor extends BaseModel<iAdvisor> implements iAdvisor {
  id: string;
  title: string;
  image: string;
  phone: string;
  fee: iManagementFee;
  email: string;
  user?: iAdvisorUser;
  settings?: iAdvisorSettings;

  constructor(props?: Partial<iAdvisor>) {
    super(props);
  }

  getContactDetails(property: keyof iAdvisor | keyof iAdvisorUser, companyOnly?: boolean) {
    if (companyOnly) return this[property];
    return this.user?.[property] ?? this[property];
  }
}
