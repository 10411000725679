import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, computed, forwardRef, inject, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { PlatformService } from '@semmie/services';

/**
 * This has to match the css classes that is generated in the scss file.
 */
type AllowedLength = 5 | 6;

@Component({
  selector: 'onyxx-pin-input',
  standalone: true,
  imports: [CommonModule, FormsModule, MaskitoDirective],
  template: `<div class="onyxx-pin-input" [ngClass]="'character-count-' + length$$()">
    <input
      #inputRef
      [maskito]="maskitoOptions$$()"
      [ngClass]="{ 'opacity-0': secure }"
      [autocomplete]="isDesktop ? '' : 'one-time-code'"
      inputmode="numeric"
      [ngModel]="value$$()"
      (ngModelChange)="onValueChange($event)"
      type="text"
    />

    <div class="dots">
      @for (item of indexArray$$(); track $index) {
        <div class="dot" [ngClass]="{ invisible: value$$().length > $index, focussed: $index === value$$().length }"></div>
      }
    </div>
    <div class="dots" *ngIf="secure">
      @for (item of indexArray$$(); track $index) {
        <div class="value-dot" [ngClass]="{ invisible: value$$().length <= $index }"></div>
      }
    </div>
  </div>`,
  styleUrl: 'pin-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinInputComponent),
      multi: true,
    },
  ],
})
export class PinInputComponent implements ControlValueAccessor {
  private readonly platformService = inject(PlatformService);

  value$$ = signal('');
  disabled$$ = signal(false);
  length$$ = signal<AllowedLength>(6);

  maskitoOptions$$ = computed<MaskitoOptions>(() => {
    const length = this.length$$();
    return {
      mask: [...Array(length).keys()].map(() => /\d/),
    };
  });

  indexArray$$ = computed(() => [...Array(this.length$$()).keys()]);

  isDesktop = this.platformService.isDesktop;

  @ViewChild('inputRef') protected inputRef: ElementRef<HTMLInputElement> | undefined;

  @Input() set length(value: AllowedLength) {
    this.length$$.set(value);
  }

  /**
   * Whether the entered values should be hidden on the UI
   */
  @Input() secure = false;

  focus() {
    this.inputRef?.nativeElement.focus();
  }

  blur() {
    this.inputRef?.nativeElement.blur();
  }

  onChange: (args: unknown) => unknown = () => {};
  onTouch: () => unknown = () => {};

  writeValue(obj: string): void {
    this.value$$.set(obj);
  }

  registerOnChange(fn: (args: unknown) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled$$.set(isDisabled);
  }

  protected onValueChange(value: string) {
    this.value$$.set(value);
    this.onChange(value);
    this.onTouch();
  }
}
