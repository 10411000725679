import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { Message } from '@semmie/models/bi/message';
import { MessageDetail } from '@semmie/models/bi/message/message-detail.model';
import { iBaseListableService } from '@semmie/schemas/services/list/base-service';
import { TasksProvider } from '@semmie/providers/tasks/tasks.provider';
import { TaskMessagesStore } from '@semmie/store/tasks/task-messages.store';
import { Utils } from '@semmie/shared/utils';
import { UserStoreFacade } from '@semmie/store/user';
import { PaginationParams, PaginatedResponse } from '@onyxx/model/pagination';

@Injectable({
  providedIn: 'root',
})
export class TaskMessagesService implements iBaseListableService<Message> {
  constructor(
    private tasksProvider: TasksProvider,
    private taskMessagesStore: TaskMessagesStore,
    private userFacade: UserStoreFacade,
  ) {}

  list(params?: PaginationParams, refresh?: boolean): Observable<PaginatedResponse<Message>> {
    const page = params?.page ?? 1;
    const pageCache = this.taskMessagesStore.getCachedPage(page);

    if (refresh || !pageCache?.data || !pageCache?.data?.length) {
      if (refresh) {
        this.taskMessagesStore.clearList();
      }

      return this.tasksProvider.list(params).pipe(
        map((tasks) => {
          return {
            meta: tasks.meta,
            data: tasks.data.map((t) => new Message({ id: t.id, title: t.title })),
          };
        }),
        tap((response) => this.taskMessagesStore.updateCachedPageByPage(page, response)),
      );
    }

    return this.taskMessagesStore.messages$;
  }

  getMessage(id: string, refresh?: boolean) {
    const cache = this.taskMessagesStore.details$.value;

    if (!cache || cache?.id !== id || refresh) {
      return this.tasksProvider.get(id).pipe(
        filter(Utils.isNonNullOrUndefined),
        map((task) => new MessageDetail({ ...task.message, id: task.id })),
        tap((message) => {
          this.taskMessagesStore.updateMessageDetail(message);
        }),
      );
    }

    return this.taskMessagesStore.details$;
  }

  updateMessage(data: MessageDetail) {
    this.taskMessagesStore.update(data);
  }

  refreshTasksAndUser() {
    return this.list(undefined, true).pipe(
      switchMap(() => {
        this.userFacade.load();
        return this.userFacade.readyNotification$;
      }),
    );
  }

  clear() {
    this.taskMessagesStore.clear();
  }
}
