import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule as PrimeSwitch } from 'primeng/inputswitch';

import { SwitchComponent } from './switch.component';

@NgModule({
  declarations: [SwitchComponent],
  imports: [CommonModule, FormsModule, PrimeSwitch],
  exports: [SwitchComponent],
})
export class SwitchModule {}
