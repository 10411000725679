import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { iAdvisorAccount } from '@semmie/store/advisor-account/advisor-account.reducer';

export const AdvisorAccountStoreActions = createActionGroup({
  source: 'Advisor Account Store',
  events: {
    initialize: emptyProps(),
    initializeLoadDone: props<{ advisorAccounts?: iAdvisorAccount[] } | undefined>(),
    saveReturnUrl: props<{ accountId: string; returnUrl: string }>(),
    saveDone: emptyProps(),
    clearDone: emptyProps(),
  },
});
