import { Injectable } from '@angular/core';
import { Organisation } from '@semmie/models/bi/organisation/organisation.model';
import { BaseStore } from '@semmie/models/_abstract';

@Injectable({
  providedIn: 'root',
})
export class OrganisationStore extends BaseStore<Map<string, Organisation>> {
  constructor() {
    super(new Map<string, Organisation>());
  }
}
