import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'onyxx-redirect-to-root',
  standalone: true,
  template: '',
})
export class RedirectToRootComponent implements OnInit {
  private readonly router = inject(Router);

  ngOnInit() {
    this.router.navigate(['']);
  }
}
