<ng-container [ngSwitch]="layout">
  <ng-container *ngSwitchCase="RadioButtonGroupLayout.Card">
    <semmie-selection-card
      [title]="label"
      [description]="description"
      [titleSize]="description ? 'h3' : 'h4'"
      [enabled]="!disabled"
      [selected]="isSelected"
      [label]="cardLabel"
      (onClick)="selectButton()"
    >
    </semmie-selection-card>
  </ng-container>
  <ng-container *ngSwitchCase="RadioButtonGroupLayout.Item">
    <div
      class="radio-button-item"
      [class.radio-button-item--enabled]="!disabled"
      [class.radio-button-item--selected]="isSelected"
      (click)="selectButton()"
      hapticOnTap
    >
      <div class="radio-button-item__container" [class.last]="last" [ngClass]="[padding ? padding : 'px-8 py-6']">
        <div class="radio-button-item__content">
          <div *ngIf="label" class="radio-button-item__title">
            <semmie-label type="heading" size="h3" weight="medium" [text]="label | translate"></semmie-label>
          </div>
          <div *ngIf="description" class="radio-button-item__description">
            <semmie-label type="text" size="base_3" [text]="description | translate"></semmie-label>
          </div>
        </div>
        <div class="radio-button-item__right radio-button">
          <p-radioButton [name]="name" [value]="value" [disabled]="disabled" [(ngModel)]="radioGroup.value" hapticOnTap></p-radioButton>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="radio-button" [class.disabled]="disabled">
      <p-radioButton
        [name]="name"
        [value]="value"
        [disabled]="disabled"
        [label]="label"
        [(ngModel)]="radioGroup.value"
        hapticOnTap
      ></p-radioButton>
    </div>
  </ng-container>
</ng-container>
