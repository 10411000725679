/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  signal,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InfoModalComponent } from '@semmie/components/containers/modals/info-modal/info-modal.component';
import { BaseComponent } from '@semmie/components/_abstract';
import { Goal, Person } from '@semmie/models';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { Icon } from '@semmie/schemas';
import { GoalFocus } from '@semmie/schemas/bi/goal/goal-focus.enum';
import { GoalState } from '@semmie/schemas/bi/goal/goal-state.enum';
import { PaymentProviderEnum } from '@semmie/schemas/bi/payment';
import { IPollingEvent } from '@semmie/schemas/bi/polling/polling-event.interface';
import { PollingStatus } from '@semmie/schemas/bi/polling/polling-status.enum';
import { iGraphFieldMode, iRadioButtonGroupOption } from '@semmie/schemas/components/dynamic-form';
import { ModalSize } from '@semmie/schemas/components/modal';
import { AccountsService, ModalService, NavigationService } from '@semmie/services';
import { PersonService } from '@semmie/services/person/person.service';
import { PollingService } from '@semmie/services/polling/polling.service';
import moment from 'moment';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, interval, Observable, of, Subject } from 'rxjs';
import { take, switchMap, tap, takeUntil, filter, map, shareReplay, finalize, startWith, takeWhile, exhaustMap } from 'rxjs/operators';
import { Polling } from '@semmie/shared/polling';
import { UserStoreFacade } from '@semmie/store/user';
import { concatLatestFrom } from '@ngrx/operators';
import { Account, AccountKind, AccountPermissions, AccountHelpers } from '@onyxx/model/account';
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';
import { CommonQueryParams } from '@semmie/views/shared/common-query-params.enum';
import { GoalFacade } from '@onyxx/store/goal';
import { Utils } from '@onyxx/utility/general';
import { GoalChartHeaderData } from '@semmie/components/containers/account/goal/goal-chart';

@Component({
  selector: 'semmie-account-goal',
  templateUrl: 'account-goal.component.html',
  styleUrls: ['./account-goal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountGoalComponent extends BaseComponent implements OnInit, OnDestroy {
  private unusedSuggestions: string[];
  private refreshGoal$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private customGoal$$ = new BehaviorSubject<Goal | undefined>(undefined);
  private headerData$$ = new BehaviorSubject<GoalChartHeaderData | null>(null);

  @ViewChild('depositModal') depositModal: TemplateRef<any>;
  /** Show the goal chart */
  @Input() showChart = true;
  /** Show the graph when offtrack */
  @Input() chartWhenOfftrack = true;
  /** Display the status button */
  @Input() showFooterButton = true;
  /** Path for the footer button */
  @Input() footerButtonPath: string[];
  /** Show off track suggestions when available */
  @Input() showSuggestions = true;
  /** Display mode, used within a form or default */
  @Input() mode: iGraphFieldMode = 'default';

  @Input() withPolling = true;

  /** Use a specified goal to display */
  @Input() set customGoal(value: Goal | undefined) {
    this.customGoal$$.next(value);
  }

  @Output() setSuggestion: EventEmitter<{ key: string; value: any }> = new EventEmitter();

  /** Show the goal current status */
  @Input() showStatus = true;

  // Enums
  readonly GoalState = GoalState;
  readonly Icon = Icon;

  readonly AccountHelpers = AccountHelpers;
  readonly AccountPermissions = AccountPermissions;

  isLoaded = signal(false);

  // Properties (public)
  account: Account;
  depositModalGoal: Goal;
  destroy$: Subject<boolean> = new Subject();
  retryLoading$$: Subject<boolean> = new Subject();
  selectedSuggestion: UntypedFormControl = new UntypedFormControl();
  showRetry = false;
  noData = false;
  suggestions$ = new BehaviorSubject<iRadioButtonGroupOption<string>[]>([]);

  goal$ = combineLatest([
    this.goalFacade.goal$.pipe(
      map((goal) => {
        return Utils.isNil(goal) ? null : new Goal(goal);
      }),
    ),
    this.customGoal$$.asObservable(),
  ]).pipe(
    map(([accountGoal, customGoal]) => (customGoal ? customGoal : accountGoal)),
    tap((goal) => {
      // Legacy reasons, further refactors and untangle from the other components needed, then this can be extracted as well
      if (Utils.isNil(goal)) {
        this.noData = true;
      } else {
        this.noData = false;
        this.setLoaded(true);

        if (goal.suggestions) {
          const options = Object.entries(goal.suggestions).map((v) => {
            return { key: v[0], value: v[1] };
          });
          this.suggestions$.next(this.setSuggestions(options, goal));
          this.selectedSuggestion.setValue(this.sortSuggestions(options)?.[0]?.key);
        }
      }
    }),
    filterNil(),
    // TODO: with a proper state management, get this polling out of here.
    exhaustMap((goal: Goal) => {
      if (goal.recalculating && this.withPolling) {
        return interval(Polling.RECALCULATING_ACCOUNT_GOAL_INTERVAL).pipe(
          exhaustMap(() => this.accountsService.getAccountGoal(this.account?.id, true).pipe(take(1))),
          takeWhile((retrievedGoal) => retrievedGoal.recalculating, true),
          startWith(goal),
        );
      } else {
        return of(goal);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /**
   * When scrubbing show scrub data else:
   * Retrieve the last date in the graph data and display in the YYYY format
   */
  expectedYear$ = combineLatest([this.goal$, this.headerData$$.asObservable()]).pipe(
    map(([goal, headerData]): string => {
      /** While scrubbing */
      if (headerData?.timestamp) {
        if (this.account.kind === AccountKind.ANNUITY && this.datesPension) {
          const selectedYearFromNow = Number(moment.unix(headerData.timestamp / 1000).diff(moment(), 'years'));
          const currentAge = moment().diff(moment(this.datesPension.birth_at), 'years');
          const ageAtSelection = (currentAge + selectedYearFromNow).toString();

          return $localize`:@@goal-chart.expected-at-age:Expected result at age ${ageAtSelection}`;
        }
        const yearAtSelection = moment
          .unix(headerData.timestamp / 1000)
          .format('YYYY')
          .toString();
        return $localize`:@@goal-chart.expected-in:Expected result in ${yearAtSelection}`;
      }

      /** When account type "annuity" */
      if (goal.annuity_ends_at_age || this.account.kind === AccountKind.ANNUITY) {
        return $localize`:@@goal-chart.goal-at-age:Investment goal at age ${goal.getPensionAge(this.datesPension)}`;
      }

      let endDate;
      if (goal.state === GoalState.LEARNING) {
        /** When in learning mode */
        const lastGraphItemDate = Utils.isNotNil(goal.graph?.realistic)
          ? moment(goal.graph.realistic[goal.graph.realistic.length - 1].date)
          : moment().add(30, 'years');
        endDate = goal.ends_at ? moment(goal.ends_at).format('YYYY').toString() : lastGraphItemDate.format('YYYY').toString();
      } else if (goal.focus === GoalFocus.AMOUNT && goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        /** When goal has focus type "amount" */
        endDate = moment(goal.graph.realistic[goal.graph.realistic.length - 1].date)
          .format('YYYY')
          .toString();
      } else if (goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        /** Else show goal in YYYY */
        endDate = moment(goal.graph.realistic[goal.graph.realistic.length - 1].date)
          .format('YYYY')
          .toString();
      }

      return endDate ? $localize`:@@goal-chart.goal-in:Investment goal in ${endDate}` : '';
    }),
  );

  /**
   * When scrubbing show scrub data else:
   * Retrieve the lowest realistic amount that could be reached
   */
  lowestResult$ = combineLatest([this.goal$, this.headerData$$.asObservable()]).pipe(
    map(([goal, headerData]) => {
      if (headerData?.min) {
        return parseInt(headerData.min.toString(), 10);
      }

      if (goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        return parseInt(goal.graph.realistic[goal.graph.realistic.length - 1].from.toString(), 10);
      }

      return 0;
    }),
  );

  /**
   * When scrubbing show scrub data else:
   * Retrieve the highest realistic amount that could be reached
   */
  highestResult$ = combineLatest([this.goal$, this.headerData$$.asObservable()]).pipe(
    map(([goal, headerData]) => {
      if (headerData?.max) {
        return parseInt(headerData.max.toString(), 10);
      }

      if (goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        return parseInt(goal.graph.realistic[goal.graph.realistic.length - 1].to.toString(), 10);
      }
      return 0;
    }),
  );

  // temp
  datesPension: any;

  constructor(
    private accountsService: AccountsService,
    private accountStoreFacade: AccountStoreFacade,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private semmieCurrencyPipe: SemmieCurrencyPipe,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private personService: PersonService,
    private pollingService: PollingService,
    private translate: TranslateService,
    private userFacade: UserStoreFacade,
    private goalFacade: GoalFacade,
  ) {
    super();
  }

  ngOnInit() {
    if (this.showSuggestions) {
      this.watchPaymentPolling();
      this.watchRefreshGoal();
    }
    this.initialize();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /** Close any modal currently opened */
  closeModal(): void {
    this.modalService.close();
  }

  /**
   * Return the corresponding state Icon
   * @param state GoalState
   */
  getIconForState(state: GoalState): Icon {
    switch (state) {
      case GoalState.ONTRACK:
        return Icon.CHECKMARK;
      case GoalState.OFFTRACK:
        return Icon.EXCLAMATION;
      case GoalState.LEARNING:
        return Icon.ATOM;
      default:
        return Icon.QUESTIONMARK;
    }
  }

  /**
   * Retrieve the value for a suggestion either in a displayable format or the actual value
   * @param suggestion key value object
   * @param asDisplayValue boolean (default true)
   * @param customGoal (optional) use a specified goal instead of the most recent
   * @returns any
   */
  getSuggestionValue(suggestion: { key: string; value?: any }, asDisplayValue = true, goal: Goal | null): any {
    if (!goal) return '';

    let newValue;
    switch (suggestion.key) {
      case 'deposit_once':
        newValue = Math.ceil(
          parseInt(AccountHelpers.isFinished(this.account) ? suggestion.value : Number(goal.amount_start) + suggestion.value),
        );
        return asDisplayValue ? this.semmieCurrencyPipe.transform(newValue, false, '1.0-0') : newValue;
      case 'deposit_monthly':
        // use goal.deposit since the name of the key is different
        newValue = Math.ceil(parseInt(goal.deposit?.toString() ?? '0') + suggestion.value);
        return asDisplayValue ? this.semmieCurrencyPipe.transform(newValue, false, '1.0-0') : newValue;
      case 'period':
        return suggestion.value;
      case 'ends_at': {
        const date = moment(suggestion.value);
        return asDisplayValue ? date.format('MMMM YYYY') : date.format('YYYY-MM-DD');
      }
    }
  }

  openCalculateModal(): void {
    this.modalService.open(
      InfoModalComponent,
      {
        componentProps: {
          title: $localize`:@@goal-chart.calculate-modal.title:Goal Calculation`,
          description: $localize`:@@goal-chart.calculate-modal.description:<p>This chart shows an estimate of the wealth you may accumulate in the future. The expected result in the chart is based on several factors:</p><ul><li>Expected performance of the model portfolio, considering various economic scenarios</li><li>How important it is to achieve your goal</li><li>Time until your goal</li><li>Starting amount</li><li>Monthly amount</li><li>Your investor profile</li><li>The costs</li></ul>`,
        },
      },
      { size: ModalSize.Auto },
    );
  }

  /**
   * Retrieve the latest (non recalculating) goal
   * @param loader show a loader covering the entire goal
   * @param refresh refresh cached goal
   * @param loadGoal quickfix to prevent retrieving goal in a plan flow for advisor
   */
  retrieveGoal(loader = true, refresh?: boolean, loadGoal = true): Observable<Goal> {
    this.showRetry = false;
    if (!loadGoal) {
      this.setLoaded(true);
      return EMPTY;
    } else if (loader) {
      this.setLoaded(false);
    }

    return this.accountStoreFacade.account$.pipe(
      filterNil(),
      switchMap((account) => this.accountsService.getAccountGoal(account.id, refresh)),
      finalize(() => {
        if (loader) {
          this.setLoaded(true);
        }
      }),
      take(1),
    );
  }

  /**
   * Return the user person or defined person by id
   */
  retrieveUserPerson(): Observable<Person> {
    let observable: Observable<Person | undefined | null>;

    if (this.account.kind == AccountKind.ANNUITY) {
      observable = this.personService.getPersonById(AccountHelpers.owners(this.account).find((p) => p.position === 1)?.id).pipe(take(1));
    } else {
      observable = this.userFacade.user$.pipe(
        switchMap((user) => {
          return this.personService.getPersonById(user?.person?.id).pipe(take(1));
        }),
        take(1),
      );
    }

    return observable.pipe(
      tap((person: Person) => {
        if (person) this.datesPension = person.datesPension;
        this.markForCheck();
      }),
    );
  }

  retryLoading(): void {
    this.showRetry = false;
    this.setLoaded(false);

    this.retryLoading$$.next(true);
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  /**
   * Navigate to specified path
   * @param path string[]
   * @param closeModal close modals when navigating
   */
  navigateTo(path: string[], closeModal = false): void {
    if (!path) return;
    this.navigationService.navigate(path, { relativeTo: this.activatedRoute });
    if (closeModal) {
      this.closeModal();
    }
  }

  /**
   * Set the header data when scrubbing
   * @param data { max: number; min: number; timestamp: number }
   */
  setHeaderData(data: GoalChartHeaderData | null): void {
    this.headerData$$.next(data);
  }

  setSuggestions(suggestions: any, goal: Goal): iRadioButtonGroupOption<string>[] {
    const options: iRadioButtonGroupOption<string>[] = [];
    this.sortSuggestions(suggestions).forEach((suggestion) => {
      options.push({
        label: this.translate.instant(
          'account-goal.suggestions.' + (AccountHelpers.isFinished(this.account) ? 'options_finished_plan.' : 'options.') + suggestion.key,
          { amount: this.getSuggestionValue(suggestion, true, goal) },
        ),
        value: suggestion.key,
      });
    });
    return options;
  }

  /**
   * Sort the suggestions into a displayable order (based on design)
   * @param suggestions by key / value array
   * @returns sorted array
   */
  sortSuggestions(suggestions: any): { key: string; value: string }[] {
    const sorting = ['deposit_once', 'deposit_monthly', 'period', 'ends_at'];
    return suggestions
      .slice()
      .sort((a, b) => sorting.indexOf(a.key) - sorting.indexOf(b.key))
      .filter((s) => {
        if (this.unusedSuggestions.includes(s.key)) return false;

        // goal period or ends_at date may not be more than 60 (years). In case it's suggested, filter out the suggestion
        if (s.key === 'period' && s.value > 60) {
          return false;
        }
        if (s.key === 'ends_at' && (moment(s.value).diff(moment(), 'months', true) > 719 || this.account.kind === AccountKind.ANNUITY)) {
          return false;
        }

        return true;
      });
  }

  /**
   * The offtrack suggestion button text based on the selected suggestion
   */
  suggestionButtonText(): string {
    if (this.mode === 'form') return this.translate.instant('account-goal.suggestions.button.change');
    switch (this.selectedSuggestion?.value) {
      case 'deposit_once':
        return this.translate.instant('account-goal.suggestions.button.deposit');
      default:
        return this.translate.instant('account-goal.suggestions.button.save');
    }
  }

  /**
   * Confirm the selected suggestion
   */
  selectSuggestion(goal: Goal | null): void {
    const key = this.selectedSuggestion?.value;

    if (this.mode === 'form') {
      let suggestionKey: string;
      switch (key) {
        case 'deposit_once':
          suggestionKey = 'amount_start';
          break;
        case 'deposit_monthly':
          suggestionKey = 'deposit';
          break;
        default:
          suggestionKey = key;
          break;
      }
      this.setSuggestion.emit({
        key: suggestionKey,
        value: this.getSuggestionValue({ key, value: goal?.suggestions?.[key] }, false, goal),
      });
      this.setLoaded(false);
      return;
    }

    switch (key) {
      case 'deposit_once':
        this.modalService.openPaymentOptionsModal(
          [PaymentProviderEnum.bancontact, PaymentProviderEnum.ideal, PaymentProviderEnum.manual],
          {
            amount: this.getSuggestionValue({ key, value: goal?.suggestions?.[key] }, false, goal),
          },
          this.account,
        );
        break;
      case 'deposit_monthly':
        // update the account plan and open a modal with more information
        this.depositModalGoal = goal?.clone();
        this.patchGoal({ deposit: this.getSuggestionValue({ key, value: goal?.suggestions?.[key] }, false, goal) as unknown as number });
        this.modalService.open(InfoModalComponent, { componentProps: { customTemplate: this.depositModal } }, { size: ModalSize.Full });
        break;
      case 'ends_at':
        // update the account plan and refresh the graph + status;
        this.patchGoal({ ends_at: this.getSuggestionValue({ key, value: goal?.suggestions?.[key] }, false, goal) });
        break;
    }
  }

  setLoaded(value: boolean): void {
    this.isLoaded.set(value);
    this.markForCheck();
  }

  private initialize(): void {
    this.accountStoreFacade.account$
      .pipe(
        filterNil(),
        take(1),
        tap((account) => {
          this.account = account;
          this.showFooterButton = this.showFooterButton && AccountPermissions.canChangeWealthPlan(account);
          if (AccountHelpers.isFinished(this.account)) {
            if (this.mode === 'default') {
              this.unusedSuggestions = ['amount', 'period'];
            } else {
              this.unusedSuggestions = ['amount', 'deposit_once', 'period'];
            }
          } else {
            this.unusedSuggestions = ['amount', 'ends_at'];
          }
        }),
        concatLatestFrom(() => this.userFacade.isAdvisor$),
        switchMap(([account, isAdvisor]) =>
          forkJoin([
            this.retrieveUserPerson().pipe(take(1)),
            this.retrieveGoal(true, false, !isAdvisor && AccountHelpers.hasFinishedPlan(account)).pipe(take(1)),
          ]),
        ),
      )
      .pipe(take(1))
      .subscribe();
  }

  private watchPaymentPolling(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams) => {
          const paymentId = queryParams?.[CommonQueryParams.PaymentId];
          if (paymentId) {
            this.modalService.openPaymentDialog(PaymentProviderEnum.ideal);
          }
        }),
        switchMap((queryParams) => {
          const paymentId = queryParams?.[CommonQueryParams.PaymentId];
          if (paymentId) {
            return this.pollingService.pollingEvent$.pipe(takeUntil(this.destroy$));
          }
          return of(false);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((event: IPollingEvent | boolean) => {
        if (typeof event !== 'boolean' && event?.status !== PollingStatus.POLLING) {
          this.refreshGoal$.next(true);
        }
      });
  }

  private watchRefreshGoal(): void {
    this.refreshGoal$
      .pipe(
        takeUntil(this.destroy$),
        filter((refresh) => !!refresh),
        switchMap(() => this.retrieveGoal(true, true)),
      )
      .subscribe();
  }

  /**
   * Update the account goal
   * @param goalPartial partial of a goal
   */
  private patchGoal(goalPartial: Partial<Goal>): void {
    this.accountsService.updateAccountGoal(this.account.id, goalPartial).pipe(take(1)).subscribe();
  }
}
