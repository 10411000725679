import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { NotificationsEffects } from './notifications.effects';
import { notificationsFeature } from './notifications.reducer';
import { NotificationsFacade } from './notifications.facade';

export const providers = [provideState(notificationsFeature), provideEffects(NotificationsEffects), NotificationsFacade];

@NgModule({ providers })
export class NotificationsStateModule {}

export * from './notifications.facade';
