import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, timeout } from 'rxjs/operators';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { UserStoreFacade } from '@semmie/store/user';
import { SentryService } from '@semmie/services/sentry/sentry.service';
import { AuthFacade } from '@onyxx/store/auth';

@Injectable()
export class InitializerGuard {
  readonly startRoute = this.platformService.isDesktop || this.platformService.isTablet ? 'login' : 'landing';
  readonly navigateToStart$: Observable<UrlTree> = of<UrlTree>(this.router.parseUrl(this.startRoute));

  constructor(
    private authFacade: AuthFacade,
    private userFacade: UserStoreFacade,
    private router: Router,
    private platformService: PlatformService,
    private sentryService: SentryService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authFacade.isAuthenticated$.pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          this.sentryService.captureMessage('Not Authenticated', { startRoute: this.startRoute });
          return this.navigateToStart$;
        }

        return this.userFacade.user$.pipe(
          take(1),
          switchMap((user) => {
            if (!user) {
              return this.userFacade.readyNotification$.pipe(
                timeout(5000),
                catchError(() => {
                  this.sentryService.captureMessage('User not loaded after 5 seconds - logging out');
                  this.authFacade.dispatchLogOut();
                  return this.navigateToStart$;
                }),
              );
            } else {
              return of(true);
            }
          }),
        );
      }),
    );
  }
}
