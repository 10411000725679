/* eslint-disable no-empty */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isValidIBAN } from 'ibantools';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function iban(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = String(control?.value).split(' ').join('').toUpperCase();
    if (!value) return null;

    let validIBAN = true;

    try {
      validIBAN = isValidIBAN(value);
    } catch (ex) {}

    if (!validIBAN) {
      return new ValidatorMessage({
        code: 13,
        message: $localize`:@@validation.iban.invalid:Typo? This IBAN does not exist.`,
      });
    }

    return null;
  };
}
