import { NgModule } from '@angular/core';

import { HighchartsChartModule } from 'highcharts-angular';

import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { SharedModule } from '@semmie/shared/shared.module';
import { LoadingRippleModule } from '@semmie/components/presentational/core/loading-ripple/loading-ripple.module';

import { GoalChartComponent } from './goal-chart.component';

@NgModule({
  imports: [SharedModule, HighchartsChartModule, IconComponent, LoadingRippleModule],
  exports: [GoalChartComponent],
  declarations: [GoalChartComponent],
})
export class GoalChartModule {}
