import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { map, take } from 'rxjs';

export function twoFactorAvailableGuard(): CanActivateFn {
  return () => {
    const authFacade = inject(AuthFacade);
    const router = inject(Router);

    return authFacade.twoFactorDetailsAvailable$.pipe(
      take(1),
      map((available) => (available ? true : router.createUrlTree(['']))),
    );
  };
}
