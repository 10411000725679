import { inject } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { defer, exhaustMap, map, of, switchMap, tap } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { biometricsCommonActions } from './biometrics-common.actions';
import { AppStorageService } from '@semmie/services';
import { BiometricStorageKey } from './models/biometric-storage-key.enum';
import { Action } from '@ngrx/store';
import { NativeBiometricsService, PermissionCheckResultReason } from './helpers/native-biometrics.service';

export class BiometricsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly appStorageService = inject(AppStorageService);
  private readonly authFacade = inject(AuthFacade);
  private readonly nativeBiometricsService = inject(NativeBiometricsService);
  private readonly biometricsEnabledStorage = this.appStorageService.createStorageReader<boolean>(BiometricStorageKey.BiometricEnabled);

  readonly initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(biometricsCommonActions.initialize),
      switchMap(() => this.nativeBiometricsService.getAvailability()),
      switchMap((availability) =>
        defer(() => this.biometricsEnabledStorage.get()).pipe(map((enabled) => [availability, enabled ?? false] as const)),
      ),
      map(([availability, enabled]) => biometricsCommonActions.initializeDone({ availability, enabled })),
    );
  });

  readonly loadAvailability$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(biometricsCommonActions.loadAvailability),
      switchMap(() => this.nativeBiometricsService.getAvailability()),
      map((availability) => biometricsCommonActions.availabilityLoaded({ availability })),
    );
  });

  readonly authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(biometricsCommonActions.authenticate),

      exhaustMap(() => this.nativeBiometricsService.authenticate()),
      map((success) => (success ? biometricsCommonActions.authenticateSuccess() : biometricsCommonActions.authenticateFailed())),
    );
  });

  readonly enable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(biometricsCommonActions.enable),

      switchMap(() => {
        return this.nativeBiometricsService.requestPermission().pipe(
          switchMap((result) => {
            if (result.success) {
              return defer(() => this.biometricsEnabledStorage.set(true)).pipe(map(() => biometricsCommonActions.enableSuccess()));
            }

            if (result.reason === PermissionCheckResultReason.NoPermission) {
              // if permission was not given, the availability of biometrics could have changed
              return this.nativeBiometricsService
                .getAvailability()
                .pipe(map((availability) => biometricsCommonActions.enablePermissionFailure({ availability })));
            }

            return of(biometricsCommonActions.enableFailure());
          }),
        );
      }),
    );
  });

  readonly disable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(biometricsCommonActions.disable),
      switchMap(() => defer(() => this.biometricsEnabledStorage.set(false))),
      map(() => biometricsCommonActions.disableDone()),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(
      tap(() => this.biometricsEnabledStorage.remove()),
      map(() => biometricsCommonActions.clear()),
    );
  });

  ngrxOnInitEffects(): Action {
    return biometricsCommonActions.initialize();
  }
}
