import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';

import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { iCountry } from '@onyxx/model/countries';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';

const PROVIDER_KEY = 'countries';

@Injectable()
export class CountryProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  list() {
    return this.http.get<{ [PROVIDER_KEY]: iCountry[] }>(`${this.apiUrl}/${PROVIDER_KEY}`).pipe(map((response) => response[PROVIDER_KEY]));
  }
}
