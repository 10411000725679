import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { iCountry } from '@onyxx/model/countries';

export const CountriesApiActions = createActionGroup({
  source: 'Countries API',
  events: {
    'Load Countries': emptyProps(),
    'Load Countries Success': props<{ countries: iCountry[] }>(),
    'Load Countries Failure': props<{ error: Error }>(),
  },
});
