import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { PageHeaderRightComponent } from '@semmie/components/containers/page-header/page-header-right/page-header-right.component';

import { BaseComponent } from '@semmie/components/_abstract';
import { Icon, LabelSize, IconSize } from '@semmie/schemas';

export interface headerIcon {
  icon: Icon;
  size: IconSize;
}

@Component({
  selector: 'semmie-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent extends BaseComponent {
  @ContentChild(PageHeaderRightComponent) pageHeaderRightComponent: TemplateRef<PageHeaderRightComponent>;

  @HostBinding('attr.title') _title = '';
  @Input() title?: string;
  @Input() titleSize: LabelSize = 'h2';
  @Input() titleAlign: 'center' | 'left' = 'center';
  @Input() backgroundColor: string;

  @Input() leftAction = true;
  @Input() leftIcon: headerIcon = {
    icon: Icon.CHEVRONLEFT,
    size: 'md',
  };

  @Input() rightAction?: boolean;
  @Input() rightIcon: headerIcon = {
    icon: Icon.CLOSE,
    size: 'md',
  };

  @HostBinding('class.in-modal')
  @Input()
  inModal = false;

  @Output() onLeftIconClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() onRightIconClick: EventEmitter<MouseEvent> = new EventEmitter();

  @HostBinding('class.sticky-header')
  @Input()
  sticky = false;

  readonly Icon = Icon;

  onLeftIconClickHandler($event: MouseEvent): void {
    // Prevent the click events from parent elements to be triggered
    $event.stopPropagation();
    this.onLeftIconClick.emit($event);
  }

  onRightIconClickHandler($event: MouseEvent): void {
    // Prevent the click events from parent elements to be triggered
    $event.stopPropagation();
    this.onRightIconClick.emit($event);
  }
}
