<semmie-content-container (click)="focusInput()">
  <ng-container *ngIf="showPinCode$ | async">
    <div class="mt-6 flex flex-col items-center justify-center gap-y-4">
      <div class="mb-8">
        <semmie-label
          type="heading"
          size="h1"
          weight="light"
          i18n-text="@@security-modal.code.title"
          text="Unlock with your code"
        ></semmie-label>
      </div>
      <onyxx-pin-input [length]="codeSize" [secure]="true" [formControl]="codeControl" />
      <ng-container *ngIf="codeControl.hasError('incorrect')">
        <semmie-label
          type="small"
          size="tiny"
          class="text-danger-300"
          i18n-text="@@security-modal.code.error.incorrect"
          text="Incorrect code, please try again"
        ></semmie-label>
      </ng-container>
      <semmie-label
        (onClick)="clearPinCode(); $event.stopPropagation()"
        size="base_3"
        class="link mt-4"
        i18n-text="@@security-modal.code.clear-code"
        text="Forgot code?"
      ></semmie-label>
    </div>
  </ng-container>

  <semmie-content-container-bottom>
    <div class="flex justify-center pb-12">
      <semmie-image src="img/logos/logo_dark.svg" width="75"></semmie-image>
    </div>
  </semmie-content-container-bottom>
</semmie-content-container>
