import { Injectable, NgZone, inject } from '@angular/core';

import { defer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { ModalService } from '@semmie/services/modal/modal.service';
import { SecurityModalComponent } from '@semmie/components/containers/modals/security-modal/security-modal.component';

export const enum SecurityModalResult {
  Opened = 'Opened',
  Opening = 'Opening',
}

@Injectable({ providedIn: 'root' })
export class SecurityModalService {
  private readonly ngZone = inject(NgZone);

  constructor(private modalService: ModalService) {}

  /**
   * Opens the SecurityModal.
   *
   * !todo: refactor and ensure the reference is appropriately available in the ModalService
   * @param ignoreBiometrics
   * @returns
   */
  show(options: { ignoreBiometrics: boolean } = { ignoreBiometrics: false }) {
    const modalPromise = this.modalService.open(
      SecurityModalComponent,
      {
        cssClass: 'security-modal',
        canDismiss: false,
        componentProps: {
          ignoreBiometrics: options.ignoreBiometrics,
          ignoreDuplicate: true,
        },
        animated: false,
      },
      undefined,
      false,
    );

    return defer(() => modalPromise).pipe(
      switchMap((modal) => defer(() => this.ngZone.run(() => modal.onWillDismiss())).pipe(startWith(SecurityModalResult.Opened))),
      startWith(SecurityModalResult.Opening),
    );
  }
}
