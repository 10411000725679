import { createFeature, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';
import { visibleAccountsListApiActions } from './visible-accounts-list-api.actions';
import { visibleAccountsListCommonActions } from './visible-accounts-list-common.actions';
import { createPaginatedEntityAdapter, PaginatedEntityState } from '@onyxx/utility/paginated-entity-state';

export interface State extends PaginatedEntityState<Account> {
  initialized: boolean;
}

const adapter = createPaginatedEntityAdapter<Account>();

const initialState: State = adapter.getInitialState({
  initialized: false,
});

const reducer = createReducer(
  initialState,

  on(
    visibleAccountsListApiActions.reloadAccountsSuccess,
    (state, { accounts, paginationMeta }): State => ({
      ...adapter.setAll(accounts, paginationMeta, state),
      initialized: true,
    }),
  ),

  on(
    visibleAccountsListApiActions.loadPageSuccess,
    (state, { accounts, paginationMeta }): State => ({
      ...adapter.addNextPage(accounts, paginationMeta, state),
      initialized: true,
    }),
  ),

  on(
    visibleAccountsListCommonActions.addToAccountCache,
    (state, { account }): State => ({
      ...adapter.addOne(account, state),
    }),
  ),

  on(
    visibleAccountsListCommonActions.updateAccountCache,
    (state, { account }): State =>
      adapter.updateOne(
        {
          id: account.id,
          changes: account,
        },
        state,
      ),
  ),

  on(
    visibleAccountsListCommonActions.removeFromAccountCache,
    (state, { id }): State => ({
      ...adapter.removeOne(id, state),
    }),
  ),

  on(visibleAccountsListCommonActions.clear, (): State => initialState),
);

export const visibleAccountsListFeature = createFeature({
  name: 'visibleAccountsList',
  reducer,
  extraSelectors: ({ selectVisibleAccountsListState, selectEntities, selectPaginationMeta }) => ({
    ...adapter.getSelectors(selectVisibleAccountsListState, selectEntities, selectPaginationMeta),
  }),
});
