import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlDirectiveModule } from '@semmie/directives/safe-html/safe-html.module';
import { SafePipeModule } from '@semmie/pipes/safe-html';

import { HtmlRenderComponent } from './html-render.component';

@NgModule({
  imports: [CommonModule, SafeHtmlDirectiveModule, SafePipeModule],
  exports: [HtmlRenderComponent],
  declarations: [HtmlRenderComponent],
})
export class HtmlRenderModule {}
