import * as DOMPurify from 'dompurify';

export class Sanitizer {
  static sanitize(input: string | Node, options: { allowIframe: boolean } = { allowIframe: false }) {
    /**
     * add noopener to target _blank anchors to prevent the new page
     * from being able to access the window.opener property and ensures it runs in a separate process.
     */
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });
    const domPurifyOptions = options.allowIframe ? { ADD_TAGS: ['iframe'] } : {};
    return DOMPurify.sanitize(input, domPurifyOptions);
  }
}
