import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { Denotation } from '@onyxx/model/validation';
import { OnyxxDenotationTranslatePipe } from '@onyxx/utility/translations';

export function minLength(length: number, denotation: Denotation = Denotation.Characters): ValidatorFn {
  const onyxxDenotationTranslatePipe = new OnyxxDenotationTranslatePipe();

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    if (value && value.length < length) {
      const isKnownDenotation = Object.values(Denotation).includes(denotation);
      const _denotation = isKnownDenotation ? onyxxDenotationTranslatePipe.transform(denotation) : denotation;

      return new ValidatorMessage({
        code: 2,
        message: $localize`:@@validation.min-length:This field must contain at least ${length} ${_denotation}`,
        message_data: {
          length: length,
          denotation: _denotation,
        },
      });
    }

    return null;
  };
}
