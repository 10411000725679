import { Injectable, NgZone, OnDestroy, inject } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { PushNotificationEvent } from './push-notification-event.interface';
import { Observable, share } from 'rxjs';
import { addListener, clearListeners } from './push-notification-event-handler';

/**
 * Wrapper service for capacitor push notifications functionality
 */
@Injectable({ providedIn: 'root' })
export class PushNotificationsService implements OnDestroy {
  private readonly ngZone = inject(NgZone);

  nativeEvents$ = new Observable<PushNotificationEvent>((subscriber) => {
    const unsubscribe = addListener((event) => {
      this.ngZone.run(() => {
        subscriber.next(event);
      });
    });

    return () => {
      unsubscribe();
    };
  }).pipe(share());

  ngOnDestroy(): void {
    clearListeners();
  }

  checkPermissions() {
    return PushNotifications.checkPermissions();
  }
  register() {
    return PushNotifications.register();
  }
  requestPermissions() {
    return PushNotifications.requestPermissions();
  }
}
