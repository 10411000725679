import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';

export function authenticatedGuard(): CanActivateFn {
  return () => {
    const authFacade = inject(AuthFacade);
    const router = inject(Router);

    return authFacade.isAuthenticated$.pipe(map((isAuthenticated) => (isAuthenticated ? true : router.createUrlTree(['']))));
  };
}
