import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { Utils } from '@semmie/shared/utils';

import { initSentry } from './sentry';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { setNonce } from '@ionic/core/loader';
import { initializePushNotificationListeners } from '@onyxx/store/push-notifications';

setNonce(import.meta.env.NG_APP_NONCE);

if (environment.production) {
  enableProdMode();
}

/**
 * In iOS, when the app is suspended in the background, if there is enough memory pressure, the browser service
 * is killed. Then, when the user clicks on a push notification, the action event is emitted sooner than expected.
 * Event listeners should be added as soon as possible.
 */
initializePushNotificationListeners();

Utils.getAppVersion()
  .then((appVersion) => {
    if (environment.production) {
      // we have different sourcemaps for each environment and platform. We use the dist property to differentiate them
      const distName = `${environment.name}${appVersion.platform ? `-${appVersion.platform}` : ''}`;
      initSentry(environment.sentryDsn, 'semmie-ionic', appVersion.number, environment.name, appVersion.buildNumber, distName);
    }
  })
  .finally(() => {
    platformBrowser()
      .bootstrapModule(AppModule, { ngZoneEventCoalescing: false, ngZoneRunCoalescing: false })
      .catch((err) => console.error(err));
  });
