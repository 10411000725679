import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { fromEvent, of, Subject } from 'rxjs';
import { delay, filter, mergeMap, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[noVerticalScrollOnTouch]' })
export class NoVerticalScrollOnTouchDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private readonly treshold = 350;
  private isLongPress = false;

  constructor(private ref: ElementRef) {}

  ngOnInit(): void {
    this.bindEvents();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private bindEvents() {
    const touchStart$ = fromEvent(this.ref.nativeElement, 'touchstart');
    const touchEnd$ = fromEvent(this.ref.nativeElement, 'touchend');
    const touchMove$ = fromEvent(this.ref.nativeElement, 'touchmove');

    const stopPress$ = touchEnd$.pipe(
      mergeMap((e) => {
        return of(e).pipe(takeUntil(touchEnd$));
      }),
    );

    const longPress$ = touchStart$.pipe(
      mergeMap((e) => {
        return of(e).pipe(delay(this.treshold), takeUntil(touchEnd$));
      }),
    );

    stopPress$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isLongPress = false;
    });

    longPress$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isLongPress = true;
    });

    touchMove$
      .pipe(
        filter(() => this.isLongPress),
        takeUntil(this.destroy$),
      )
      .subscribe((e: any) => {
        e.preventDefault();
      });
  }
}
