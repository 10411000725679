import { BaseModel } from '@semmie/models/_abstract';
import { PersonRoleEnum } from '@semmie/schemas/bi/person/person-role';
import { iAccountPerson } from '@semmie/schemas/bi/person/account-person';
import { iPerson } from '@semmie/schemas/bi/person/person';

export class AccountPerson extends BaseModel<iAccountPerson> implements iAccountPerson {
  id: string;
  title: string;
  name_casual: string;
  position: 1 | 2;
  role: PersonRoleEnum;
  finished: boolean;
  wid_payment: boolean;
  wid_validated: boolean;

  static isAccountPerson(value: iAccountPerson | iPerson): value is iAccountPerson {
    return 'title' in value;
  }

  get personName() {
    return this.title || this.name_casual;
  }

  isInvitee(): boolean {
    return this.position === 2;
  }

  isOwner(): boolean {
    return this.role === PersonRoleEnum.OWNER;
  }

  isWidValidated(): boolean {
    return this.wid_payment || this.wid_validated;
  }
}
