import { NgModule } from '@angular/core';

import { ContentContainerModule } from '@semmie/components/containers/content-container/content-container.module';
import { IntroContentModule } from '@semmie/components/containers/intro-content/intro-content.module';
import { ModalHeaderModule } from '@semmie/components/containers/modals/modal-header/modal-header.component';
import { PageHeaderModule } from '@semmie/components/containers/page-header/page-header.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

import { SharedModule } from '@semmie/shared/shared.module';

import { AccountConvertModalComponent } from './account-convert-modal.component';

@NgModule({
  imports: [SharedModule, IconComponent, PageHeaderModule, ContentContainerModule, IntroContentModule, ModalHeaderModule],
  exports: [AccountConvertModalComponent],
  declarations: [AccountConvertModalComponent],
})
export class AccountConvertModalModule {}
