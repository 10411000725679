import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingRippleComponent } from '@semmie/components/presentational/core/loading-ripple/loading-ripple.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoadingRippleComponent],
  exports: [LoadingRippleComponent],
})
export class LoadingRippleModule {}
