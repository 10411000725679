import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardComponent } from './card.component';
import { LabelModule } from '@semmie/components/presentational/core/label';

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, LabelModule],
  exports: [CardComponent],
})
export class CardModule {}
