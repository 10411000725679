<div class="input-container group">
  <semmie-form-input-label [formFieldId]="id" [infoModal]="infoModal" [label]="label" [labelHint]="labelHint"></semmie-form-input-label>

  <div class="input-wrapper">
    <div *ngIf="loading" class="progressBar-wrapper">
      <p-progressBar class="progressBar" mode="indeterminate" [style]="{ height: '1px' }"></p-progressBar>
    </div>
    <ng-container *ngIf="leadingIcon">
      <ng-container
        *ngTemplateOutlet="
          iconTpl;
          context: { $implicit: leadingIcon, class: 'input-icon input-icon-leading', action: iconAction.bind(this, 'leading') }
        "
      ></ng-container>
    </ng-container>

    <input
      #inputRef
      [class.with-leading-icon]="leadingIcon"
      [class.with-trailing-icon]="trailingIcon || isTypePassword"
      [class.has-errors]="error"
      [class.has-plus-minus]="showButtons && step && !disabled"
      [id]="id"
      [disabled]="disabled"
      [(ngModel)]="formattedValue"
      (ngModelChange)="onValueChange($event)"
      [attr.inputmode]="calculatedInputMode"
      [attr.autocomplete]="autocomplete"
      [attr.enterkeyhint]="enterKeyHint"
      [attr.min]="min"
      [attr.max]="max"
      [type]="calculateInputType"
      [placeholder]="placeholder ?? label"
      [maskito]="maskitoOptions"
      [attr.autocapitalize]="autocapitalize"
      (blur)="onBlurHandler($event)"
      (focus)="onFocusHandler($event)"
    />

    <ng-container *ngIf="trailingIcon">
      <ng-container
        *ngTemplateOutlet="
          iconTpl;
          context: { $implicit: trailingIcon, class: 'input-icon input-icon-trailing', action: iconAction.bind(this, 'trailing') }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="!trailingIcon && isTypePassword && showPasswordToggler">
      <ng-container
        *ngTemplateOutlet="
          iconTpl;
          context: {
            $implicit: passwordIcon,
            class: 'input-icon input-icon-trailing',
            action: toggleMask.bind(this),
          }
        "
      ></ng-container>
    </ng-container>

    <div class="plus-minus-buttons" *ngIf="showButtons && step && !disabled">
      <div class="button-wrapper">
        <div class="button button-minus" (click)="spin($event, -1)"><semmie-icon [icon]="Icon.MINUS" size="xxxs"></semmie-icon></div>
      </div>
      <div class="button-wrapper">
        <div class="button button-plus" (click)="spin($event, 1)"><semmie-icon [icon]="Icon.PLUS" size="xxxs"></semmie-icon></div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="error">
    <p class="error-message">{{ error }}</p>
  </ng-container>
</div>

<ng-template #iconTpl let-icon let-class="class" let-action="action">
  <div (click)="action && action(this)" [ngClass]="class">
    <semmie-icon [icon]="icon"></semmie-icon>
  </div>
</ng-template>
