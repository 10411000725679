<div class="page-header" [style.backgroundColor]="backgroundColor">
  <div *ngIf="leftAction" class="left-container">
    <div class="page-header-icon" (click)="onLeftIconClickHandler($event)">
      <semmie-icon [size]="leftIcon.size" [icon]="leftIcon.icon"></semmie-icon>
    </div>
  </div>

  <div class="main-container">
    <div class="content">
      <ng-content></ng-content>
      <semmie-label *ngIf="title" class="truncate" type="heading" [align]="titleAlign" [size]="titleSize" [text]="title"></semmie-label>
    </div>
  </div>

  <div *ngIf="rightAction || pageHeaderRightComponent" class="right-container">
    <ng-content select="semmie-page-header-right"></ng-content>
    <div *ngIf="rightAction" class="page-header-icon" (click)="onRightIconClickHandler($event)">
      <semmie-icon [size]="rightIcon.size" [icon]="rightIcon.icon"></semmie-icon>
    </div>
  </div>
</div>
