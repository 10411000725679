import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { ModalService } from '@semmie/services';

@Component({
  selector: 'semmie-info-modal',
  templateUrl: 'info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent extends BaseComponent {
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() description: string;
  @Input() subtitle = $localize`:@@info-modal.default.subtitle:Information`;
  @Input() image: string;
  @Input() footer: { image?: string; text?: string };
  @Input() button = $localize`:@@info-modal.default.button:Close`;
  @Input() linkButton: string;
  @Input() buttonShouldClose = true;
  @Input() hideCloseButton = false;
  @Input() customTemplate: TemplateRef<any>;

  @Output() onButtonClick: EventEmitter<void> = new EventEmitter();
  @Output() onLinkButton: EventEmitter<void> = new EventEmitter();

  Icon = Icon;

  constructor(private modalService: ModalService) {
    super();
  }

  onButtonClickHandler() {
    this.onButtonClick.emit();
    if (this.buttonShouldClose) this.closeModal(true);
  }

  onLinkLabelClickHandler() {
    this.onLinkButton.emit();
    if (this.buttonShouldClose) this.closeModal(true);
  }

  closeModal(confirmed = false): void {
    this.modalService.close(confirmed);
  }
}
