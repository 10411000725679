import { Injectable } from '@angular/core';

import mustache from 'mustache';

import { TranslateService } from '@ngx-translate/core';

import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { noop } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterpolateService {
  constructor(
    private translate: TranslateService,
    private semmieCurrency: SemmieCurrencyPipe,
  ) {}

  /**
   * Recursively interpolate an object.
   *
   * The following logic applies:
   *  - if the value is a string, render it with Mustache
   *  - if the result of the rendered string is defined; translate the result
   *  - if the value is an object, recursively call this function
   *  - if none of the above matches, just set the value
   *
   * @param templateObj data object to render
   * @param dataSource data source to use for interpolation
   * @returns interpolated object
   */
  recursivelyInterpolate(templateObj: any, dataSource: any): any {
    const result = {};

    for (const key in templateObj) {
      const val = templateObj[key];

      if (typeof val === 'string') {
        const interpolated = mustache.render(val, dataSource);
        if (interpolated) result[key] = this.translate.instant(interpolated, dataSource);
      } else if (val instanceof Object) {
        result[key] = this.recursivelyInterpolate(val, dataSource);
      } else {
        result[key] = val;
      }
    }

    return result;
  }

  renderWithInfoModals(templateObj: string, dataSource: any, infoModals: iInfoModal[]): string {
    if (!templateObj) return '';
    if (infoModals) {
      const modals = [...infoModals];
      modals.map((m: iInfoModal) => {
        if (m.trigger) {
          m.trigger = this.translate.instant(m.trigger);
        }
      });
      dataSource['info-modal'] = function () {
        return function (text, render) {
          const infoModal = modals?.find((modal) => modal.id === text);
          if (!infoModal) return;
          return `<span class="text-primary-400 cursor-pointer hover:text-primary-500" data-info-modal='${infoModal.id}'>${render(
            infoModal.trigger,
          )}</span>`;
        };
      };
    }
    return mustache.render(templateObj, dataSource);
  }

  /**
   * Render a string with Mustache and [Mustache Functions](https://github.com/janl/mustache.js/#functions).
   *
   * Supported functions:
   *  - **link** - render string as a link, with a pending state if the url is async
   *    - `{{#link}}{{ documents.semmie-contracts.url }}|Semmie overeenkomst{{/link}}`
   *  - **semmieCurrency** - format string as currency
   *    - `{{#semmieCurrency}}{{ auto_incasso.amount }}{{/semmieCurrency}}`
   *  - **translate** - translate a string translation key
   *    - `{{#translate}}account.strategy.{{ account.strategy }}.name{{/translate}}`
   *
   * @param str the templated string to render
   * @param dataSource data source to use for rendering
   * @returns interpolated string
   */
  renderString(str: string, dataSource: any) {
    const pipes = {
      link: (function () {
        return function () {
          return function (text, render) {
            const params = text.split('|');
            return `<a class="${!render(params[0]) ? 'disabled pending' : ''}" target='_blank' href='${
              render(params[0]) ?? 'link'
            }'>${render(params[1] ?? 'document')}</a>`;
          };
        };
      })(),
      semmieCurrency: (function (component) {
        return function () {
          return function (text, render) {
            const digitsInfoSetting = text.split('|');
            let digitsInfo;
            if (digitsInfoSetting) {
              digitsInfo = digitsInfoSetting[1];
            }
            return component.semmieCurrency.transform(render(text), false, digitsInfo);
          };
        };
      })(this),
      translate: (function (component) {
        return function () {
          return function (text, render) {
            try {
              return component.translate.instant(render(text), dataSource);
            } catch (ex) {
              // TODO: Assess why this block was empty before.
              noop();
            }
          };
        };
      })(this),
    };

    // move this function to the pipes as this is mutating the dataSource
    dataSource['openChat'] = function () {
      return function (text) {
        return `<span class="text-primary-400 cursor-pointer hover:text-primary-500" data-openChat>${text}</span>`;
      };
    };

    return mustache.render(str, { ...dataSource, ...pipes });
  }
}
