import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { highlightsFeature } from './highlights.reducer';
import { HighlightsAPIActions } from './highlights-api.actions';

@Injectable({
  providedIn: 'root',
})
export class HighlightsFacade {
  readonly store = inject(Store);

  readonly highlights$ = this.store.select(highlightsFeature.selectAll);
  readonly highlightIds$ = this.store.select(highlightsFeature.selectIds);

  dispatchLoad() {
    this.store.dispatch(HighlightsAPIActions.load());
  }

  dispatchMarkAsRead(highlightIds: Array<string>) {
    this.store.dispatch(HighlightsAPIActions.markAsRead({ highlightIds }));
  }
}
