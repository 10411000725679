import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PhoneNumberVerificationDetails } from './phone-number-verification-details';

export const phoneNumberCommonActions = createActionGroup({
  source: 'phone number common',
  events: {
    Clear: emptyProps(),

    'Setup verification': props<PhoneNumberVerificationDetails>(),
    'Set resend allowed at': emptyProps(),
  },
});
