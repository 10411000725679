import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const userTaskCommonActions = createActionGroup({
  source: 'User Task common',
  events: {
    Initialize: emptyProps(),
    Clear: emptyProps(),
    'Clear task detail': emptyProps(),
    'Skip task': props<{ id: string }>(),

    'Ensure task detail is loaded': props<{ id: string; showToastOnError: boolean }>(),
    'Task detail is loaded': props<{ id: string }>(),

    'Remove task': props<{ id: string }>(),

    'Mark task as stale': props<{ id: string }>(),

    'Update task counts': props<{
      taskCounts: {
        normal: number;
        critical: number;
        high: number;
      };
    }>(),
  },
});
