import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { CountriesEffects } from './countries.effects';
import { countriesFeature } from './countries.reducer';
import { CountriesStoreFacade } from './countries.facade';

export const providers = [provideState(countriesFeature), provideEffects(CountriesEffects), CountriesStoreFacade];

@NgModule({ providers })
export class CountriesStoreModule {}

export * from './countries.facade';
