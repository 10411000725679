import { inject } from '@angular/core';
import { OnInitEffects, createEffect } from '@ngrx/effects';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { accountAdditionalDataCommonActions } from './account-additional-data-common.actions';
import { map } from 'rxjs';

export class AccountAdditionalDataEffects implements OnInitEffects {
  private readonly authFacade = inject(AuthFacade);

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => accountAdditionalDataCommonActions.clear()));
  });

  ngrxOnInitEffects() {
    return accountAdditionalDataCommonActions.initialize();
  }
}
