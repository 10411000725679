import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { Person } from '@semmie/models/bi/person';
import { iPerson } from '@semmie/schemas';

@Injectable()
export class PersonProvider extends BaseProvider<Person> {
  constructor() {
    super('person');
  }

  /**
   * Get a person
   *
   * ? Developer note: Explicitly remap data here for consistency
   *
   * @param id Person id
   * @returns
   *
   * @deprecated in favour of get method of the person provider in @onyxx/provider/person
   */
  get(id: string) {
    return super.__get('people', id).pipe(map(this.buildPersonFromResponse));
  }

  /**
   * Update a person
   *
   * @param id Person id
   * @param params Person data
   * @returns
   */
  update(id: string, params: Partial<Person>) {
    return super.__patch(`people/${id}`, { person: params }).pipe(map(this.buildPersonFromResponse));
  }

  finish(id: string) {
    return super.__patch(`people/${id}/finish`).pipe(map(this.buildPersonFromResponse));
  }

  private buildPersonFromResponse(response: iPerson) {
    return response?.['person'] ? new Person(response['person']) : null;
  }
}
