/* eslint-disable @typescript-eslint/no-empty-function */

import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Utils } from '@semmie/shared/utils';

let uniqueComponentId = 0;

@Directive()
export abstract class BaseComponent implements OnChanges {
  @Input() id = ++uniqueComponentId;

  protected componentId: number;

  constructor() {
    this.componentId = ++uniqueComponentId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { id } = changes;

    if (Utils.isNullOrUndefined(id?.currentValue)) {
      this.id = this.componentId;
    }
  }
}
