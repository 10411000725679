import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseProvider } from '@semmie/providers/_abstract/base-provider';

import {
  BaseCreateQuestionnairePayload,
  BaseSubjectPayload,
  iQuestionnaire,
  CreateOrganisationQuestionnairePayload,
  CreatePersonQuestionnairePayload,
  OrganisationQuestionnaireSubjectPayload,
  PersonQuestionnaireSubjectPayload,
} from '@onyxx/model/questionnaire';

@Injectable()
export class QuestionnaireProvider extends BaseProvider<iQuestionnaire> {
  constructor() {
    super();
  }

  list(params?: any): Observable<iQuestionnaire> {
    return super.__list('questionnaire', params).pipe(map((response) => response['questionnaire']));
  }

  getBySubject({ subject }: BaseSubjectPayload): Observable<iQuestionnaire> {
    // const url = organisationId && isOrganisationSubject;
    return super.__query(`questionnaire?subject=${subject}`).pipe(map((response) => response?.questionnaire));
  }

  getOrganisationQuestionnaire({ organisationId, subject }: OrganisationQuestionnaireSubjectPayload): Observable<iQuestionnaire> {
    return super
      .__query(`organisations/${organisationId}/questionnaire?subject=${subject}`)
      .pipe(map((response) => response?.questionnaire));
  }

  getPersonQuestionnaire({ personId, subject }: PersonQuestionnaireSubjectPayload): Observable<iQuestionnaire> {
    return super.__query(`people/${personId}/questionnaire?subject=${subject}`).pipe(map((response) => response?.questionnaire));
  }

  createOrganisationQuestionnaire({
    organisationId,
    subject,
    questionnaire,
  }: CreateOrganisationQuestionnairePayload): Observable<iQuestionnaire> {
    return super.__post(`organisations/${organisationId}/questionnaire?subject=${subject}`, questionnaire);
  }

  createPersonQuestionnaire({ personId, subject, questionnaire }: CreatePersonQuestionnairePayload): Observable<iQuestionnaire> {
    return super.__post(`people/${personId}/questionnaire?subject=${subject}`, questionnaire);
  }

  create({ questionnaire, subject }: BaseCreateQuestionnairePayload): Observable<iQuestionnaire> {
    return super.__post(`questionnaire?subject=${subject}`, questionnaire);
  }
}
