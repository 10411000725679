import { Account } from '@onyxx/model/account';
import { BaseModel } from '@semmie/models/_abstract';
import { ReferralSource, ReferralState, iReferral } from '@semmie/schemas/bi/referral';
import { RewardKind } from '@semmie/schemas/bi/reward';

export class Referral extends BaseModel<iReferral> implements iReferral {
  /** Contains the id and title */
  account?: Partial<Account>;
  /** The value based on the rewardKind */
  amount: number;
  /** Api reference: kind */
  rewardKind: RewardKind;
  /** Api reference: title */
  name: string;
  /** Deprecated referrals have a price */
  price: string;
  source: ReferralSource;
  state: ReferralState;

  constructor(props?: Partial<Referral>) {
    super(props, [
      { api: 'title', local: 'name' },
      { api: 'kind', local: 'rewardKind' },
    ]);
  }
}
