import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthSession } from '@onyxx/model/auth';
import { SecureApplicationOptions } from './auth-store-config.interface';
import { AuthRedirectUrl } from './auth-redirect-url.interface';

export const authCommonActions = createActionGroup({
  source: 'Auth Common',
  events: {
    Initialize: emptyProps(),
    'Initialize success': props<{ session: AuthSession | null }>(),

    'Log in navigation done': emptyProps(),

    /** Clear the session and optionally navigate back to the configured URL */
    'Log out': props<{ skipNavigation: boolean }>(),
    'Log out done': props<{ url: AuthRedirectUrl | null }>(),

    'Manual token refresh': props<{ token: string; redirectAfterLogin: string[] }>(),
    'Manual token refresh success': props<{ session: AuthSession; redirectAfterLogin: string[] }>(),
    'Manual token refresh failure': props<{ errorMsg: string }>(),

    'Secure application': props<SecureApplicationOptions>(),
    'Secure application modal shown': emptyProps(),
    'Secure application done': emptyProps(),
  },
});
