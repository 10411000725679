import { inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ConfigStore } from '@semmie/store';
import { PaginatedResponse } from '@onyxx/model/pagination';

import { environment } from 'environments/environment';

export abstract class BaseProvider<T> {
  protected readonly storageKey: string;
  protected readonly backendApiUrl = environment.apiUrl;

  protected http: HttpClient;
  protected configStore: ConfigStore;

  constructor(storageKey?: string) {
    this.storageKey = storageKey ?? 'unset';

    this.http = inject(HttpClient);
    this.configStore = inject(ConfigStore);
  }

  protected __get(resource: string, id: string): Observable<T> {
    return this.http.get<T>(`${this.backendApiUrl}/${resource}/${id}`);
  }

  protected __list(resource: string, params: any): Observable<PaginatedResponse<T>> {
    return this.http.get<PaginatedResponse<T>>(`${this.backendApiUrl}/${resource}`, { params });
  }

  protected __create(resource: string, params: Partial<T>): Observable<T> {
    return this.http.post<T>(`${this.backendApiUrl}/${resource}`, params);
  }

  protected __update(resource: string, id: string, params: Partial<T>): Observable<T> {
    return this.http.patch<T>(`${this.backendApiUrl}/${resource}/${id}`, params);
  }

  protected __delete(url: string) {
    return this.http.delete<T>(`${this.backendApiUrl}/${url}`);
  }

  protected __query<K = any>(url: string): Observable<K> {
    return this.http.get<K>(`${this.backendApiUrl}/${url}`);
  }

  protected __blob(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.backendApiUrl}/${url}`, { responseType: 'blob', observe: 'response' });
  }

  protected __post<K = any>(url: string, params?: any): Observable<K> {
    return this.http.post<K>(`${this.backendApiUrl}/${url}`, params ?? {});
  }

  protected __patch<K = any>(url: string, params?: any): Observable<K> {
    return this.http.patch<K>(`${this.backendApiUrl}/${url}`, params ?? {});
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected __clear(): void {}
}
