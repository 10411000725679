import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RadioButtonComponent } from '@semmie/components/presentational/core/radio-button/radio-button.component';
import { RadioButtonGroupComponent } from '@semmie/components/presentational/core/radio-button-group/radio-button-group.component';

@NgModule({
  declarations: [RadioButtonGroupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RadioButtonComponent],
  exports: [RadioButtonGroupComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class RadioButtonGroupModule {}
