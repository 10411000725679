import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { ModalScrollContentComponent } from '@semmie/components/containers/modals/modal-scroll/modal-scroll-content/modal-scroll-content.component';
import { BaseComponent } from '@semmie/components/_abstract';
import { SharedModule } from '@semmie/shared/shared.module';
import { ModalStopScrollGestureOverlapDirective } from '@semmie/directives/modal-stop-scroll-gesture-overlap.directive';

@Component({
  selector: 'semmie-modal-scroll',
  template: `
    <div class="scroll-container">
      <div class="scroll-content" #scrollContent semmieModalStopScrollGestureOverlapDirective>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./modal-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalScrollComponent extends BaseComponent {}

@NgModule({
  declarations: [ModalScrollComponent, ModalScrollContentComponent],
  imports: [SharedModule, ModalStopScrollGestureOverlapDirective],
  exports: [ModalScrollComponent, ModalScrollContentComponent],
})
export class ModalScrollModule {}
