import { PersonRoleEnum } from '@semmie/schemas/bi/person/person-role';
import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { AccountKind } from '../account-kind.enum';
import { Account } from '../account.interface';
import { Utils } from '@onyxx/utility/general';

export const owners = (account: Account): Array<AccountPerson> => {
  return account.people.filter((p) => p.role === PersonRoleEnum.OWNER);
};

export const representatives = (account: Account): Array<AccountPerson> => {
  return account.people.filter((p) => p.role === PersonRoleEnum.REPRESENTATIVE);
};

export const hasMultipleOwners = (account: Account): boolean => {
  return owners(account).length > 1;
};

export const hasMultipleRepresentatives = (account: Account): boolean => {
  return representatives(account).length > 1;
};

export const hasMultiplePersons = (account: Account): boolean => {
  return account.people.length > 1;
};

export const otherParty = (account: Account, personId: string): AccountPerson | null => {
  return account.people.find((p) => p.id !== personId && p.role === PersonRoleEnum.OWNER) ?? null;
};

export const child = (account: Account): AccountPerson | null => {
  if (![AccountKind.CHILD, AccountKind.GRANDPARENT].includes(account.kind)) return null;

  return account.people.find((p) => p.role === PersonRoleEnum.OWNER) ?? null;
};

export const hasAdvisor = (account: Account) => {
  return Utils.isNotNil(account.advisor);
};

export const planCreatorIsKnown = (account: Account): boolean => {
  return !hasAdvisor(account);
};

export const invitationRole = (account: Account): string | null => {
  switch (account.kind) {
    case AccountKind.SHARED:
      return PersonRoleEnum.OWNER;
    case AccountKind.CHILD:
    case AccountKind.GRANDPARENT:
    case AccountKind.ORGANISATION:
      return PersonRoleEnum.REPRESENTATIVE;
    default:
      return null;
  }
};

export const personById = (account: Account, personId: string): AccountPerson | null => {
  return account.people.find((p) => p.id === personId) ?? null;
};
