import { Injectable, inject } from '@angular/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Utils } from '@onyxx/utility/general';
import { ConfigService } from '@semmie/services/config/config.service';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  private readonly platformService = inject(PlatformService);
  private readonly configService = inject(ConfigService);

  protected readonly externalUrlsConfig$ = this.configService.config$.pipe(map((config) => config.config.external_urls));

  /**
   * Open apple Store or Google Play Store depending on the platform
   * - Native app only
   */
  async openStore(country?: 'NL') {
    await AppUpdate.openAppStore({ country });
  }

  /**
   * Open the store url
   */
  async openStoreUrl() {
    const externalUrlsConfig = await firstValueFrom(this.externalUrlsConfig$);
    if (Utils.isNil(externalUrlsConfig)) return;

    if (this.platformService.isiOS && Utils.isNotNil(externalUrlsConfig.ios.app_store)) {
      Utils.openUrl(externalUrlsConfig.ios.app_store, { newTab: true });
    } else if (
      this.platformService.isApp &&
      this.platformService.isAndroid &&
      Utils.isNotNil(externalUrlsConfig.android.play_store_native)
    ) {
      Utils.openUrl(externalUrlsConfig.android.play_store_native, { newTab: true });
    } else if (!this.platformService.isApp && this.platformService.isAndroid && Utils.isNotNil(externalUrlsConfig.android.play_store)) {
      Utils.openUrl(externalUrlsConfig.android.play_store, { newTab: true });
    } else if (Utils.isNotNil(externalUrlsConfig.app_page)) {
      Utils.openUrl(externalUrlsConfig.app_page, { newTab: true });
    }
  }

  async getStoreName() {
    if (this.platformService.isiOS) {
      return $localize`:@@app-market.app-store.title:App Store`;
    } else if (this.platformService.isApp && this.platformService.isAndroid) {
      return $localize`:@@app-market.google-play-store.title:Google Play Store`;
    } else {
      return $localize`:@@app-market.store.default:store`;
    }
  }
}
