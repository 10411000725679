import { Pipe, PipeTransform } from '@angular/core';
import { Denotation } from '@onyxx/model/validation';

@Pipe({
  name: 'onyxxDenotationTranslate',
  standalone: true,
  pure: true,
})
export class OnyxxDenotationTranslatePipe implements PipeTransform {
  transform(denotation: Denotation): string {
    switch (denotation) {
      case Denotation.Characters:
        return $localize`:@@denotation.characters:characters`;
      case Denotation.Character:
        return $localize`:@@denotation.character:character`;
      case Denotation.Letters:
        return $localize`:@@denotation.letters:letters`;
      case Denotation.Letter:
        return $localize`:@@denotation.letter:letter`;
      case Denotation.Capitals:
        return $localize`:@@denotation.capitals:capitals`;
      case Denotation.Capital:
        return $localize`:@@denotation.capital:capital`;
      case Denotation.Numbers:
        return $localize`:@@denotation.numbers:numbers`;
      case Denotation.Number:
        return $localize`:@@denotation.number:number`;
      case Denotation.Amount:
        return $localize`:@@denotation.amount:amount`;
    }
  }
}
