import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function numbersOnly(property?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = property ? control?.value[property] : control?.value;
    const hasNumbersOnly = !isNaN(Number(value));

    if (!hasNumbersOnly) {
      return new ValidatorMessage({
        code: 4,
        message: $localize`:@@validation.only-numbers:This field can contain only numbers.`,
      });
    }

    return null;
  };
}
