import { BankCode, bankLogoName, Bic } from '@onyxx/model/bank';

/**
 * Get the bank logo (PNG) path from BIC/SWIFT code
 */
export const getBankPNG = (bic?: string) => {
  const knownBank = Object.values(Bic).find((value) => value.toUpperCase() === bic?.toUpperCase());
  return `img/banks/${knownBank ? bic?.toLowerCase() : bankLogoName[BankCode.DEFAULT]}.png`;
};

/**
 * Get the bank logo (SVG) path from BIC/SWIFT code
 */
export const getBankSVG = (bic?: string, option?: { contrast: boolean }) => {
  const knownBank = Object.values(Bic).find((value) => value.toUpperCase() === bic?.toUpperCase());
  return `img/banks/svg/${option?.contrast ? 'contrast/' : ''}${knownBank ? bic?.toLowerCase() : bankLogoName[BankCode.DEFAULT]}.svg`;
};
