<div
  *ngIf="!hidden"
  class="menu-item-container"
  [class.last]="last"
  [class.clickable]="clickable"
  [class.disabled]="disabled"
  (click)="onClickHandler()"
>
  <div class="menu-item-content" [ngClass]="color">
    <div class="menu-item-section menu-item-disable-area">
      <div *ngIf="icon" class="menu-item-icon">
        <semmie-icon [icon]="icon"></semmie-icon>
      </div>
      <semmie-label class="menu-item-label" *ngIf="text" [size]="textSize" [text]="text"></semmie-label>
    </div>
    <!-- min-h-8 makes the menu item leave space for possible toggle controls (which has a height of 8 (32px)) -->
    <div
      [ngClass]="{
        ' menu-item-disable-area': disableRightContent,
      }"
      class="min-h-8 menu-item-section"
    >
      <ng-content></ng-content>
    </div>
  </div>
  <ng-container *ngIf="contentTpl" [ngTemplateOutlet]="contentTpl" [ngTemplateOutletContext]="{ $implicit: contentContext }"></ng-container>
</div>
