import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';
import { accountApiActions } from './account-api.actions';
import { accountCommonActions } from './account-common.actions';
import { Utils } from '@onyxx/utility/general';

export interface State extends EntityState<Account> {
  currentAccountId: string | null;
  accountCacheTimestamps: Map<string, number>;
}

const adapter = createEntityAdapter<Account>();

const initialState: State = adapter.getInitialState({
  currentAccountId: null,
  accountCacheTimestamps: new Map<string, number>(),
});

const setAccountCacheTimestamp = (currentAccountCacheTimestamps: Map<string, number>, id: string): Partial<State> => {
  const newMap = new Map(currentAccountCacheTimestamps);
  newMap.set(id, Date.now());
  return {
    accountCacheTimestamps: newMap,
  };
};

const removeAccountCacheTimestamp = (currentAccountCacheTimestamps: Map<string, number>, id: string): Partial<State> => {
  const newMap = new Map(currentAccountCacheTimestamps);
  newMap.delete(id);
  return {
    accountCacheTimestamps: newMap,
  };
};

const reducer = createReducer(
  initialState,
  on(
    accountApiActions.loadAccountSuccess,
    (state, { account }): State => ({
      ...adapter.upsertOne(account, state),
      ...setAccountCacheTimestamp(state.accountCacheTimestamps, account.id),
    }),
  ),
  on(accountCommonActions.setCurrentAccount, (state, { id }): State => ({ ...state, currentAccountId: id })),
  on(accountCommonActions.clearCurrentAccount, (state): State => ({ ...state, currentAccountId: null })),

  on(
    accountApiActions.createAccountSuccess,
    (state, { account }): State => ({
      ...adapter.upsertOne(account, state),
      ...setAccountCacheTimestamp(state.accountCacheTimestamps, account.id),
    }),
  ),
  on(
    accountApiActions.updateAccountSuccess,
    accountApiActions.updateMetadataSuccess,
    accountCommonActions.updateAccountInStore,
    (state, { account }): State => ({
      ...adapter.updateOne({ id: account.id, changes: account }, state),
      ...setAccountCacheTimestamp(state.accountCacheTimestamps, account.id),
    }),
  ),
  on(
    accountApiActions.deleteAccountSuccess,
    (state, { id }): State => ({ ...adapter.removeOne(id, state), ...removeAccountCacheTimestamp(state.accountCacheTimestamps, id) }),
  ),

  on(accountCommonActions.clearCurrentAccount, (state): State => ({ ...state, currentAccountId: null })),

  on(
    accountCommonActions.reloadCurrentAccount,
    (state): State => ({ ...state, ...removeAccountCacheTimestamp(state.accountCacheTimestamps, state.currentAccountId ?? '') }),
  ),
  on(
    accountCommonActions.reloadAccount,
    (state, { id }): State => ({ ...state, ...removeAccountCacheTimestamp(state.accountCacheTimestamps, id) }),
  ),
  on(
    accountCommonActions.bustAccountCache,
    (state, { id }): State => ({ ...state, ...removeAccountCacheTimestamp(state.accountCacheTimestamps, id) }),
  ),
  on(accountCommonActions.clear, (): State => initialState),
);

export const accountFeature = createFeature({
  name: 'account',
  reducer,
  extraSelectors: ({ selectAccountState, selectEntities, selectCurrentAccountId }) => ({
    ...adapter.getSelectors(selectAccountState),
    selectAccount: (id: string) => createSelector(selectEntities, (entities) => entities[id] ?? null),
    selectCurrentAccount: createSelector(selectEntities, selectCurrentAccountId, (entities, currentAccountId) => {
      if (Utils.isNil(currentAccountId)) return null;

      return entities[currentAccountId] ?? null;
    }),
  }),
});
