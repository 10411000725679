import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  signal,
} from '@angular/core';

import * as Highcharts from 'highcharts/highstock';

import { BaseComponent } from '@semmie/components/_abstract/base-component.component';
import { Icon } from '@semmie/schemas';
import { BehaviorSubject, of } from 'rxjs';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { ChartCompareType, ChartPlotPreference } from '@semmie/schemas/bi/chart';
import { ImpactStyle } from '@capacitor/haptics';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { delay } from 'rxjs/operators';
import { Utils } from '@onyxx/utility/general';
import { PlotOptionType } from '@onyxx/model/account';
import moment from 'moment';

export interface PlotOption {
  text: string;
  available: boolean;
  type: 'month' | 'ytd' | 'all';
  count?: number;
}

export type SelectionDetails = {
  profit?: number;
  value?: number;
  date?: number;
  plotPreference?: ChartPlotPreference;
};

export enum GridStyle {
  Default = 'default',
  Full = 'full',
}

@Component({
  selector: 'semmie-advanced-chart',
  templateUrl: './advanced-chart.component.html',
  styleUrls: ['./advanced-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedChartComponent extends BaseComponent implements OnChanges {
  @ViewChild('chart') chart: any;
  @Input() set backgroundColor(backgroundColor: string) {
    this.backgroundColor$.next(backgroundColor);
  }

  @Input() set type(type: string) {
    this.type$.next(type);
  }

  @Input() data: any;
  @Input() chartData: Array<any> = [];

  @Input({ required: false }) selectedPlotOption: PlotOptionType | undefined;
  @Input() hidePlotOptions = false;
  @Input() hideXAxisLabels = true;
  @Input() gridStyle: GridStyle = GridStyle.Default;

  @Input() selectedPlotPreference = ChartPlotPreference.Index;
  @Input() enablePlotPreferences = true;
  @Input() placeholder = $localize`:@@advanced-chart.no-data:There is no data to display.`;

  @Output() onChartLoaded = new EventEmitter<boolean>();
  @Output() onChartTouch = new EventEmitter<PointerEvent>();
  @Output() onChartSelectionChange = new EventEmitter<SelectionDetails>();
  @Output() onLastDeltaChange = new EventEmitter<number>();
  @Output() onChartPlotOptionChange = new EventEmitter<PlotOptionType>();
  @Output() plotPreferencesClicked: EventEmitter<any> = new EventEmitter<void>();

  readonly Highcharts = Highcharts;
  readonly Icon = Icon;
  readonly GridStyle = GridStyle;
  readonly lastDisplayedChangeValue$$ = signal<number | null>(null);

  source: any;

  readonly plotOptions: { [key in PlotOptionType]: PlotOption } = {
    '1m': {
      text: '1 M',
      available: false,
      type: 'month',
      count: 1,
    },
    '3m': {
      text: '3 M',
      available: false,
      type: 'month',
      count: 3,
    },
    '6m': {
      text: '6 M',
      available: false,
      type: 'month',
      count: 6,
    },
    ytd: {
      text: 'ytd',
      available: false,
      type: 'ytd',
    },
    std: {
      text: 'std',
      available: false,
      type: 'all',
    },
  };

  readonly chartOptions: Highcharts.Options & {
    rangeSelector: Highcharts.RangeSelectorOptions;
    series: NonNullable<Highcharts.Options['series'] & { data: unknown[] }[]>;
  } = {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: 'spline',
      backgroundColor: 'transparent',
      height: 210,
      // top right bottom left
      spacing: [0, 0, 0, 0],
      style: {
        fontFamily: 'VAG Rounded Next',
      },
      events: {
        render: (function (chartComponent) {
          return function () {
            const lastValue = this?.series[0]?.points[this?.series[0]?.points.length - 1] as Highcharts.Point & {
              change: string;
              custom: SelectionDetails;
            };

            const profit = parseFloat(lastValue?.change);
            const plotPreference = chartComponent.selectedPlotPreference;

            chartComponent.onChartSelectionChange.emit({ profit, plotPreference });
            chartComponent.onLastDeltaChange.emit(profit);
            chartComponent.lastDisplayedChangeValue$$.set(profit);
          };
        })(this),
      },
    },
    rangeSelector: {
      enabled: true,
      labelStyle: {
        display: 'none',
      },
      inputEnabled: false,
      buttonTheme: {
        style: {
          display: 'none',
        },
      },
      buttons: Object.keys(this.plotOptions).map((key) => {
        const { text, type, count } = this.plotOptions[key];
        return {
          type,
          count,
          text,
        };
      }),
    },
    xAxis: {
      crosshair: {
        color: 'var(--color-advanced-chart-crosshair)',
        dashStyle: 'Solid',
      },
      type: 'datetime',
      tickAmount: 0,
      tickColor: 'transparent',
      lineColor: 'transparent',
      visible: false,
      minPadding: 0,
      startOnTick: false,
      endOnTick: false,
      maxPadding: 0,
      labels: {
        style: {
          color: 'var(--color-advanced-chart-labels)',
          fontSize: '10px',
        },
      },
      events: {
        afterSetExtremes: (function (chartComponent) {
          return function () {
            const lastValue = this?.series[0]?.points[this?.series[0]?.points.length - 1] as Highcharts.Point & {
              change: string;
              custom: SelectionDetails;
            };

            const profit = parseFloat(lastValue?.change);
            const plotPreference = chartComponent.selectedPlotPreference;

            chartComponent.onChartSelectionChange.emit({ profit, plotPreference });
            chartComponent.onLastDeltaChange.emit(profit);
            chartComponent.lastDisplayedChangeValue$$.set(profit);
          };
        })(this),
      },
    },
    yAxis: {
      visible: true,
      className: 'advanced-chart-y-axis',
      minPadding: 0,
      maxPadding: 0,
      startOnTick: true,
      title: undefined,
      opposite: false,
      gridLineDashStyle: 'Dot',
      gridLineColor: 'var(--color-advanced-chart-grid-line)',
      labels: {
        align: 'right',
        x: -8,
        y: 0,
        reserveSpace: true,
        style: {
          color: 'var(--color-advanced-chart-labels)',
          fontSize: '10px',
        },
        formatter: (function (chartComponent) {
          return function () {
            const label = this.axis.defaultLabelFormatter.call(this);
            if (chartComponent.selectedPlotPreference === ChartPlotPreference.Index) {
              return `${label}%`;
            }

            const value = Number(label);

            if (value >= 1000000) return `€ ${value / 1000000}M`;
            if (value >= 1000) return `€ ${value / 1000}K`;
            return `€ ${label.replace('k', 'K')}`;
          };
        })(this),
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: 'transparent',
      hideDelay: 300,
      borderWidth: 0,
      shadow: false,
      padding: 0,
      headerFormat: '',
      pointFormat: '',
      formatter: (function (chartComponent) {
        return function () {
          const profit = parseFloat((this.point as Highcharts.Point & { change: string }).change);
          const value = this.point.options.custom?.value;
          const date = this.point.options.custom?.date;
          const plotPreference = chartComponent.selectedPlotPreference;

          chartComponent.onChartSelectionChange.emit({ profit, value, date, plotPreference });

          return '';
        };
      })(this),
    },
    plotOptions: {
      series: {
        compare: ChartCompareType.Percentage,
        compareStart: true,
        showInNavigator: true,
        turboThreshold: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        events: {
          mouseOver: (function (chartComponent) {
            return function ($event) {
              chartComponent.chartFocused = true;
              chartComponent.onChartTouch.emit($event);
            };
          })(this),
          mouseOut: (function (chartComponent) {
            return function ($event) {
              chartComponent.chartFocused = false;
              chartComponent.onChartTouch.emit($event);
            };
          })(this),
        },
      },
    },
    series: [
      {
        custom: [],
        type: 'spline',
        lineWidth: 2,
        color: 'var(--color-advanced-chart-series-line)',
        data: [],
        zones: [],
        pointStart: 0,
        pointIntervalUnit: 'day',
        zoneAxis: 'x',
        animation: {
          duration: 800,
          easing: 'easeOut',
        },
        marker: {
          enabled: false,
        },
      },
    ],
  };

  updateFlag = false;

  /**
   * Complete 'loading' after a predefined delay in order
   * to save some resources on the MainThread
   */
  isLoaded$ = of(true).pipe(delay(400));

  private chartFocused = false;

  private backgroundColor$ = new BehaviorSubject<string>('transparent');
  private type$ = new BehaviorSubject<string>('line');

  constructor(
    private cdr: ChangeDetectorRef,
    private platformService: PlatformService,
    private hapticFeedbackService: HapticFeedbackService,
  ) {
    super();
    // set translations and date label formatting
    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        shortMonths: moment.monthsShort(),
        weekdays: moment.weekdays(),
        shortWeekdays: moment.weekdaysShort(),
      },
      xAxis: {
        dateTimeLabelFormats: {
          day: '%e %b',
          week: '%e %b',
          month: '%b\u2019%y',
        },
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { chartData, selectedPlotOption, selectedPlotPreference, hideXAxisLabels, gridStyle } = changes;
    // selectedCompareType || hideXAxisLabels changed
    if (
      (selectedPlotPreference && !Utils.isEqual(selectedPlotPreference.previousValue, selectedPlotPreference.currentValue)) ||
      (hideXAxisLabels && !Utils.isEqual(hideXAxisLabels.previousValue, hideXAxisLabels.currentValue))
    ) {
      if (selectedPlotPreference && this.chartOptions.plotOptions?.series) {
        this.chartOptions.plotOptions.series.compare =
          this.selectedPlotPreference === ChartPlotPreference.Index ? ChartCompareType.Percentage : ChartCompareType.Value;
      }

      if (hideXAxisLabels) {
        const xAxisVisible = !hideXAxisLabels.currentValue;
        if (!Array.isArray(this.chartOptions.xAxis) && this.chartOptions.xAxis && this.chartOptions.xAxis.visible !== xAxisVisible) {
          this.chartOptions.xAxis.visible = xAxisVisible;
        }
      }
      this.plotChart();
    }

    if (gridStyle) {
      if (this.chartOptions.chart) {
        this.chartOptions.chart.spacing =
          gridStyle.currentValue === GridStyle.Full
            ? // top: space for the label
              // left and right 16px  and 25px respectively as required by the design
              // 2px bottom so that the grid line is visible even if the graphs starts at the value
              [8, 24, 2, 16]
            : [0, 0, 0, 0];
      }
      if (this.chartOptions.yAxis && !Array.isArray(this.chartOptions.yAxis) && this.chartOptions.yAxis.labels) {
        // move the label above the grid line
        this.chartOptions.yAxis.labels.y = gridStyle.currentValue === GridStyle.Full ? -8 : 0;
      }

      this.plotChart();
    }

    // chart date changed
    this.onChartSelectionChange.emit();
    if (chartData && !Utils.isEqual(chartData.previousValue, chartData.currentValue)) {
      this.plotChart();
      return;
    }

    // selectedPlotOption changed
    if (selectedPlotOption && !Utils.isEqual(selectedPlotOption.previousValue, selectedPlotOption.currentValue)) {
      this.plotChart();
    }
  }

  get focused(): boolean {
    return !!this.chartFocused;
  }

  get plotOptionKeys(): Array<PlotOptionType> {
    return Object.keys(this.plotOptions) as PlotOptionType[];
  }

  get chartDataAvailable() {
    return !!this.chartData?.length;
  }

  chartCallback(chartInput: Highcharts.Chart | null) {
    if (Utils.isNil(chartInput)) return;
    const chart = chartInput;

    const chartContainer = document.getElementsByClassName('highcharts-container')[0];

    function hideMarkers(e: Highcharts.ChartClickEventObject) {
      if (!this.chartDataAvailable && Utils.isNil(chart.pointer)) return;

      const event = chart.pointer.normalize(e);

      chart.series.forEach(function (s) {
        const point = s.searchPoint(event, true);

        if (point) {
          point.setState('');
        }
      });

      chart.pointer.reset(false, 0);
      chart.xAxis[0].hideCrosshair();

      chart.redraw();

      if (this.chartDataAvailable && this.focused) {
        this.hapticFeedbackService.interact(ImpactStyle.Heavy);
      }
    }

    function hapticTouchFeedback() {
      if (this.chartDataAvailable && this.focused) {
        this.hapticFeedbackService.interact(ImpactStyle.Heavy);
      }
    }

    function hapticScrubFeedback() {
      if (this.chartDataAvailable && this.focused) {
        this.hapticFeedbackService.interact(ImpactStyle.Light);
      }
    }

    if (this.platformService.isApp) {
      if (this.platformService.isRunningOn('ios')) {
        chartContainer.addEventListener('touchmove', hapticScrubFeedback.bind(this));
      }

      chartContainer.addEventListener('touchstart', hapticTouchFeedback.bind(this));
      chartContainer.addEventListener('touchend', hideMarkers.bind(this));
      chartContainer.addEventListener('touchcancel', hideMarkers.bind(this));
    } else {
      chartContainer.addEventListener('click', hideMarkers.bind(this));
    }

    this.plotChart();
    this.setPlotOptionAvailability();
  }

  onPlotOptionTypeChange(plotOption: PlotOptionType): void {
    this.hapticFeedbackService.interact(ImpactStyle.Light);
    this.setSelectedPlotOption(plotOption);
    this.onChartPlotOptionChange.emit(plotOption);
  }

  plotChart(): void {
    this.chartOptions.rangeSelector.selected = this.getSelectedPlotOptionIndex(this.selectedPlotOption ?? 'std');
    this.chartOptions.series[0].data = this.chartData.slice().map((data) => {
      // DEVNOTE: Ugly fix for highcharts:
      // We have to map the data to make zero values not-zero. As Highcharts uses the compare feature only with the first non-zero non-null value.
      // https://api.highcharts.com/highstock/series.line.compare

      return {
        ...data,
        y: data.y === 0 ? 0.000001 : data.y,
        custom: {
          ...data.custom,
          value: data.custom.value === 0 ? 0.000001 : data.custom.value,
        },
      };
    });

    this.updateFlag = true;
    this.cdr.detectChanges();
  }

  plotPreferences(): void {
    this.hapticFeedbackService.interact(ImpactStyle.Light);
    this.plotPreferencesClicked.emit();
  }

  getSelectedPlotOptionIndex(plotOption: PlotOptionType): number {
    return Object.keys(this.plotOptions).findIndex((key) => key === plotOption);
  }

  setSelectedPlotOption(plotOption: PlotOptionType): void {
    this.chart.chart.rangeSelector.setSelected(this.getSelectedPlotOptionIndex(plotOption));
    this.chart.chart.redraw();
  }

  private setPlotOptionAvailability(): void {
    const buttons = this.chart.chart.rangeSelector.buttons;
    Object.keys(this.plotOptions).forEach((key, index) => {
      this.plotOptions[key].available = !buttons[index].element.classList.contains('highcharts-button-disabled');
    });
  }
}
