import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { Highlights } from '@onyxx/model/highlights';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { Observable, map } from 'rxjs';

const API_NAMESPACE = 'highlights';

@Injectable({ providedIn: 'root' })
export class HighlightsProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  list() {
    return this.http.get<{ highlights: Array<Highlights> }>(`${this.apiUrl}/${API_NAMESPACE}`).pipe(map(({ highlights }) => highlights));
  }

  markAsRead(highlightIds: string[]): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/${API_NAMESPACE}/mark_as_read`, {
      ids: highlightIds,
    });
  }
}
