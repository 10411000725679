export enum KvkForm {
  bv = 'bv',
  cooperatie = 'cooperatie',
  cv = 'cv',
  eenmanszaak = 'eenmanszaak',
  maatschap = 'maatschap',
  nv = 'nv',
  stichting = 'stichting',
  vereniging = 'vereniging',
  vof = 'vof',
  kerkgenootschap = 'kerkgenootschap',
  onderlinge_waarborg_maatschappij = 'onderlinge_waarborg_maatschappij',
  vereniging_van_eigenaars = 'vereniging_van_eigenaars',
  kerspel = 'kerspel',
}
