import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountAdditionalData } from '@onyxx/model/account';

export const accountAdditionalDataCommonActions = createActionGroup({
  source: 'Account Additional Data common',
  events: {
    Initialize: emptyProps(),
    'Set additional data': props<{ additionalData: AccountAdditionalData }>(),
    Clear: emptyProps(),
  },
});
