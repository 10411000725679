import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollContainerComponent } from './scroll-container.component';

@NgModule({
  declarations: [ScrollContainerComponent],
  imports: [CommonModule],
  exports: [ScrollContainerComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class ScrollContainerModule {}
