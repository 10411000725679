export const scenarioDescriptions = {
  214: {
    description:
      'Stel je hebt net {{depositDisplay}} op je Semmie rekening gestort. De wereldeconomie is stabiel en de aandelenmarkt ook. Hoe zou jij beleggen in deze situatie?',
  },
  215: {
    description:
      'Sinds je eerste inleg is de markt flink gestegen. Je hebt een rendement van {{{percentageDisplay}}} en je saldo staat op {{depositDisplay}}. Zou je anders gaan beleggen in deze situatie?',
  },
  216: {
    description:
      'Er ligt een recessie op de loer en de aandelenmarkt is in elkaar aan het zakken. Door deze tegenslag is je saldo nu nog {{depositDisplay}}. Dit maakt je totale rendement {{{percentageDisplay}}}. Met welke insteek blijf je beleggen?',
  },
  217: {
    description:
      'De recessie bleek mee te vallen. De indexen zijn weer stabiel en de koersen zijn weer hersteld. Je rendement is nu {{{percentageDisplay}}} en je saldo {{depositDisplay}}. Met welke insteek blijf je beleggen?',
  },
};

export const scenarioData = {
  214: [
    {
      id: 550,
      header: 'Risico op verlies: Lager',
      description: 'Je wilt risico beperken en verkiest stabiliteit boven een hoger rendement.',
      title: 'Defensief',
      projection: {
        from: 0,
        to: 3,
      },
    },
    {
      id: 551,
      header: 'Risico op verlies: Gemiddeld',
      description: 'Je neemt minder risico, maar beperkt hiermee ook het potentieel rendement.',
      title: 'Neutraal',
      projection: {
        from: -1,
        to: 6,
      },
    },
    {
      id: 552,
      header: 'Risico op verlies: Hoger',
      description: 'Door het nemen van een groter risico op verlies, is een hoger rendement mogelijk.',
      title: 'Offensief',
      projection: {
        from: -3,
        to: 9,
      },
    },
  ],
  215: [
    {
      id: 553,
      header: 'Risico op verlies: Lager',
      description: 'Je wilt dit verdiende rendement niet verliezen. Stabiliteit boven alles.',
      title: 'Defensief',
      projection: {
        from: 0,
        to: 5,
      },
    },
    {
      id: 554,
      header: 'Risico op verlies: Gemiddeld',
      description: 'Je zoekt balans tussen saldobehoud en rendement. Mee profiteren, maar met beperkt risico.',
      title: 'Neutraal',
      projection: {
        from: -1,
        to: 9,
      },
    },
    {
      id: 555,
      header: 'Risico op verlies: Hoger',
      description: 'Je wilt volop profiteren van de stijgende markt. Hoog risico, hoger rendement.',
      title: 'Offensief',
      projection: {
        from: -5,
        to: 13,
      },
    },
  ],
  216: [
    {
      id: 556,
      header: 'Risico op verlies: Lager',
      description: 'Je wilt een negatief rendement bovenal vermijden, voor zover dat kan.',
      title: 'Defensief',
      projection: {
        from: -4,
        to: 0,
      },
    },
    {
      id: 557,
      header: 'Risico op verlies: Gemiddeld',
      description: 'Je wilt niet teveel verliezen door deze dip, maar hoopt ook weer wat te kunnen verdienen als de economie herstelt.',
      title: 'Neutraal',
      projection: {
        from: -12,
        to: 10,
      },
    },
    {
      id: 558,
      header: 'Risico op verlies: Hoger',
      description: 'Door het nemen van een groter risico op verlies, is een hoger rendement mogelijk.',
      title: 'Offensief',
      projection: {
        from: -28,
        to: 25,
      },
    },
  ],
  217: [
    {
      id: 559,
      layout: 'projection',
      icon: 'low',
      header: 'Risico op verlies: Lager',
      description: 'Potentieel rendement missen is minder erg dan saldo verliezen.',
      title: 'Defensief',
      projection: {
        from: -2,
        to: 3,
      },
    },
    {
      id: 560,
      header: 'Risico op verlies: Gemiddeld',
      description: 'Je neemt minder risico, maar beperkt hiermee ook het potentieel rendement.',
      title: 'Neutraal',
      projection: {
        from: -4,
        to: 6,
      },
    },
    {
      id: 561,
      header: 'Risico op verlies: Hoger',
      description: 'Als de markt verder stijgt wil je daarvan profiteren. Hoog risico is acceptabel.',
      title: 'Offensief',
      projection: {
        from: -8,
        to: 12,
      },
    },
  ],
};
