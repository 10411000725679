import { Injectable } from '@angular/core';
import { PaginationParams, PaginatedResponse } from '@onyxx/model/pagination';
import { Message } from '@semmie/models/bi/message';
import { MessageDetail } from '@semmie/models/bi/message/message-detail.model';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_NAMESPACE = 'messages';

@Injectable({
  providedIn: 'root',
})
export class InboxProvider extends BaseProvider<Message> {
  constructor() {
    super(API_NAMESPACE);
  }

  /**
   * List inbox message.
   * Paginations params are optional
   *
   * @param {PaginationParams} [params]
   * @return {*}  Observable<PaginatedResponse<InboxMessage>>
   * @memberof InboxProvider
   */
  list(params?: PaginationParams): Observable<PaginatedResponse<Message>> {
    return super.__list(API_NAMESPACE, params).pipe(
      map((response) => {
        return {
          data: response?.[API_NAMESPACE]?.map((b: Message) => new Message(b)),
          meta: response?.meta,
        };
      }),
    );
  }

  /**
   * Fetch a inbox message
   *
   * @param {string} id
   * @return {*}  Observable<InboxMessage>
   * @memberof InboxProvider
   */
  get(id: string): Observable<MessageDetail> {
    return super.__get(API_NAMESPACE, id).pipe(map((response) => response?.['message']));
  }

  clear(): void {
    this.__clear();
  }
}
