import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormGroupModule } from '@semmie/components/containers/form-group/form-group.module';
import { FormInputModule } from '@semmie/components/containers/form-input/form-input.module';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';

import { FormComponent } from './form.component';

@NgModule({
  declarations: [FormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormGroupModule, FormInputModule, LabelModule],
  exports: [FormComponent],
})
export class FormModule {}
