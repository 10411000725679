import { Account } from '@onyxx/model/account';

export const placeholderImages = (premiumUser: boolean) => {
  return [
    {
      id: 1,
      src: 'premium/img/account/placeholder.jpg',
      premium: true,
    },
    {
      id: 2,
      src: 'img/account/placeholder-0.jpg',
      premium: false,
    },
    {
      id: 3,
      src: 'img/account/placeholder-1.jpg',
      premium: false,
    },
    {
      id: 4,
      src: 'img/account/placeholder-2.jpg',
      premium: false,
    },
    {
      id: 5,
      src: 'img/account/placeholder-3.jpg',
      premium: false,
    },
    {
      id: 6,
      src: 'img/account/placeholder-4.jpg',
      premium: false,
    },
  ].filter((c) => !c.premium || premiumUser);
};

export const placeholderImageUrl = (id: number, premiumUser: boolean) => {
  const placeholderImage = placeholderImages(premiumUser);
  const image = placeholderImage.find((i) => i.id === id) ?? placeholderImage[0];

  return image.src;
};

export const placeholderImageForAccount = (cover: Account['cover'], premiumUser: boolean) => {
  if (cover.url != null && cover.index <= 0) {
    return cover.url;
  }

  return placeholderImageUrl(cover.index, premiumUser);
};
