import { EnvironmentProviders, Provider, ProviderToken } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { authFeature } from './auth.reducer';
import { AuthFacade } from './auth.facade';
import { AuthStoreConfig } from './auth-store-config.interface';
import { AUTH_STORE_CONFIG } from './auth-store-config.token';
import { GeneralEffects } from './effects/general.effects';
import { LoginEffects } from './effects/login.effects';
import { ManualTokenRefreshEffects } from './effects/manual-token-refresh.effects';
import { PasswordResetEffects } from './effects/password-reset.effects';
import { RegisterEffects } from './effects/register.effects';
import { SecureApplicationEffects } from './effects/secure-application.effects';
import { UnlockUserEffects } from './effects/unlock-user.effects';
import { LogoutEffects } from './effects/logout.effects';
import { PasswordSetEffects } from './effects/password-set.effects';
import { TwoFactorEffects } from './effects/two-factor.effects';
import { TokenManagementEffects } from './effects/token-management.effects';

export const provideAuthState = (config: ProviderToken<AuthStoreConfig>): (EnvironmentProviders | Provider)[] => {
  return [
    provideState(authFeature),
    provideEffects(
      GeneralEffects,
      LoginEffects,
      LogoutEffects,
      ManualTokenRefreshEffects,
      PasswordResetEffects,
      PasswordSetEffects,
      RegisterEffects,
      SecureApplicationEffects,
      UnlockUserEffects,
      TwoFactorEffects,
      TokenManagementEffects,
    ),
    AuthFacade,
    {
      provide: AUTH_STORE_CONFIG,
      useValue: config,
    },
  ];
};

export { AuthFacade } from './auth.facade';

export { SecureApplicationOptions, AuthStoreConfig, SecureApplicationStatus } from './auth-store-config.interface';
