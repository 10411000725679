import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { iCountry } from '@onyxx/model/countries';
import { countriesFeature } from './countries.reducer';

export type CountriesResponse = { countries: iCountry[] };
@Injectable()
export class CountriesStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  readonly countriesLoading$ = this.store.select(countriesFeature.selectCountriesLoading);
  readonly countries$ = this.store.select(countriesFeature.selectCountries);
}
