import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';

/**
 * Circle with percentage value
 * Value will be rounded up to a zero decimal number
 */
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'semmie-progress-circle',
  template: `
    <div
      role="progresscircle"
      [attr.aria-valuenow]="value"
      aria-valuemin="0"
      aria-valuemax="100"
      style="--value:{{ value | number: '1.0-0' }}"
    ></div>
  `,
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent extends BaseComponent {
  @Input() value = 0;
}
