import { BaseModel } from '@semmie/models';
import { iWithdrawal, WithdrawalState } from '@semmie/schemas/bi/withdrawal';

import { iLinkedAccount } from '@onyxx/model/linked-account';

export class Withdrawal extends BaseModel<iWithdrawal> {
  id: string;
  state: WithdrawalState;
  amount: number;
  amount_all: boolean;
  paid: boolean;
  stornation: boolean;
  cancelable: boolean;
  created_at: Date;
  paid_at: Date | null;
  payment_at: Date | null;
  cancelled_at: Date | null;
  linked_account: iLinkedAccount;

  constructor(withdrawal: iWithdrawal) {
    super(withdrawal);
  }
}
