import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';
import { MaskitoDirective } from '@maskito/angular';
import { FormInputLabelComponent } from '@semmie/components/containers/form-input-label/form-input-label.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { TextboxComponent } from './textbox.component';

@NgModule({
  declarations: [TextboxComponent],
  imports: [CommonModule, FormsModule, IconComponent, FormInputLabelComponent, ProgressBarModule, MaskitoDirective],
  exports: [TextboxComponent],
})
export class TextboxModule {}
