<div class="disclaimer">
  <span class="indicator">
    <span class="icon" [ngClass]="{ 'text-danger-300': style === 'danger', 'text-warning-300': style === 'warning' }">
      <semmie-icon [icon]="Icon.DOT" size="xxxxs"></semmie-icon>
    </span>
  </span>
  <span class="text">
    <semmie-label *ngIf="withPrefix" class="inline" weight="medium" [htmlContent]="prefix | translate"></semmie-label>
    <ng-content></ng-content>
  </span>
</div>
