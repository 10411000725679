import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CardModule, ImageModule, LabelModule, LoadingRippleModule, PageHeaderModule } from '@semmie/components';
import { AssetPathPipeModule } from '@semmie/pipes/asset-path';
import { SharedModule } from '@semmie/shared/shared.module';
import { GoalChartModule } from '../goal-chart';
import { AccountGoalComponent } from './account-goal.component';

/** Do NOT use barrel file for these imports */
import { IntroContentModule } from '@semmie/components/containers/intro-content/intro-content.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { RadioButtonComponent } from '@semmie/components/presentational/core/radio-button/radio-button.component';
import { RadioButtonGroupModule } from '@semmie/components/presentational/core/radio-button-group/radio-button-group.module';
import { ModalHeaderModule } from '@semmie/components/containers/modals/modal-header/modal-header.component';
import { CurrencyModule } from '@semmie/pipes/currency/currency-pipe.module';
import { InfoModalIconComponent } from '@onyxx/ui/info-modal-icon';
import { IbanFormatterModule } from '@semmie/pipes/format-iban/iban-formatter.module';

@NgModule({
  imports: [
    SharedModule,
    CardModule,
    GoalChartModule,
    IconComponent,
    ImageModule,
    LabelModule,
    ButtonModule,
    AssetPathPipeModule,
    RadioButtonComponent,
    RadioButtonGroupModule,
    LoadingRippleModule,
    IntroContentModule,
    PageHeaderModule,
    FormsModule,
    ReactiveFormsModule,
    ModalHeaderModule,
    CurrencyModule,
    InfoModalIconComponent,
    IbanFormatterModule,
  ],
  exports: [AccountGoalComponent],
  declarations: [AccountGoalComponent],
})
export class AccountGoalModule {}
