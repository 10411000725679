import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { App, AppState } from '@capacitor/app';
import { AppStorageService } from '@semmie/services/app/app-storage.service';
import { PinCodeService } from '@semmie/services/pincode/pincode.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Comparer } from '@semmie/shared/comparer';
import { Utils as LegacyUtils } from '@semmie/shared/utils';
import { Utils } from '@onyxx/utility/general';
import { AppStore } from '@semmie/store/app/app.store';
import { ConfigStore } from '@semmie/store/config/config.store';
import { AppPreferences, AppTheme, iAppMeta, PreferencesStorageKeys } from '@semmie/schemas';
import { BiometricsStoreFacade, BiometricStatus } from '@onyxx/store/biometrics';
import { filterNil } from '@onyxx/utility/observables';

@Injectable({
  providedIn: 'root',
})
export class AppService implements OnDestroy {
  appIsActive$ = this.appStore.appIsActive$;
  appNavVisibility$ = this.appStore.appNavVisibility$;

  pinCodeStatus$ = this.pinCodeService.enabledPinCode$.pipe(
    map((pinCodeData) => {
      return { pinCodeEnabled: !!pinCodeData };
    }),
  );

  private readonly preferencesStorage = this.appStorageService.createStorageReader<AppPreferences>(PreferencesStorageKeys.Preferences);

  private destroy$ = new Subject<void>();

  constructor(
    private appStore: AppStore,
    private appStorageService: AppStorageService,
    private configStore: ConfigStore,
    private platformService: PlatformService,
    private biometricsStoreFacade: BiometricsStoreFacade,
    private pinCodeService: PinCodeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {
    if (this.platformService.isApp) {
      this.appActivityListener();
    }
    this.updateAppNavVisibility();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  appActivityListener() {
    if (this.platformService.isAndroid) {
      window.addEventListener('focusChange', (state: Event & { isActive: boolean }) => {
        this.ngZone.run(() => {
          this.appStore.updateAppIsActive(state.isActive);
        });
      });
    } else {
      App.addListener('appStateChange', (state: AppState) => {
        this.ngZone.run(() => {
          this.appStore.updateAppIsActive(state.isActive);
        });
      });
    }
  }

  getUncompletedAppOnboarding() {
    return this.getAppPreferences().pipe(
      switchMap((preferences) =>
        this.biometricsStoreFacade.availability$.pipe(
          map((details) => ({ isBiometricsAvailable: details.status === BiometricStatus.Enrolled, preferences })),
        ),
      ),
      map(({ preferences, isBiometricsAvailable }) => {
        const defaultAppOnboarding = {
          pincode: false,
          biometrics: isBiometricsAvailable ? false : true,
          notifications: false,
        };

        const uncompletedParts = { ...defaultAppOnboarding, ...(preferences?.appOnboarding ?? {}) };
        return Object.keys(uncompletedParts).filter((key) => !uncompletedParts[key]);
      }),
    );
  }

  async updateAppPreferences(preferences: Partial<AppPreferences>) {
    const currentPreferences = this.appStore.appPreferences$$.value;
    const newValue = Utils.deepMergeObjects(currentPreferences, preferences);
    await this.preferencesStorage.set(newValue);
    this.appStore.appPreferences$$.next(newValue);
  }

  getAppPreferences() {
    return this.appStore.appPreferences$.pipe(
      switchMap((latestValue) => {
        if (latestValue) {
          return of(latestValue);
        }
        // initialize from app storage
        return from(this.preferencesStorage.get()).pipe(
          // set default values
          map((preferences) => ({ appTheme: AppTheme.DEFAULT, ...preferences }) as AppPreferences),
          // update subject
          tap((preferences) => {
            // TODO Refactor the service so that there is a single source of truth instead
            // of trying to keep appPreferences$$ in sync with the appStorage. i.e. always
            // read from appStorage
            this.appStore.appPreferences$$.next(preferences);
          }),
        );
      }),
      distinctUntilChanged(),
    );
  }

  async clearAppPreferences() {
    await this.preferencesStorage.remove();
    this.appStore.appPreferences$$.next(null);
  }

  getAppOsAndVersion() {
    const meta = this.appStore.appMeta$$.value;

    if (meta) {
      return this.appStore.appMeta$;
    }

    return from(LegacyUtils.getAppVersion()).pipe(
      take(1),
      map((versionInfo) => {
        return {
          version: versionInfo,
          os: this.platformService.getPlatform(),
        } as iAppMeta;
      }),
      tap((versionInfo) => this.appStore.appMeta$$.next(versionInfo)),
    );
  }

  checkAppVersionUpToDate(): Observable<boolean> {
    return combineLatest([of(this.configStore.app), this.getAppOsAndVersion().pipe(filterNil()), this.appStore.appPreferences$]).pipe(
      map(([app, meta, preferences]) => {
        const configAppData = app[this.platformService.getPlatform()];
        const version = meta.version.number ?? '';

        if (configAppData) {
          const hasOlderVersion = Comparer.compareVersions(version, configAppData.version);

          if (hasOlderVersion) {
            /**
             * if the current version is optional, and the user wants to skip it, let it through
             * version comparison is done by the literal version string, e.g. 2.0.1 === 2.0.1
             */
            if (!configAppData.mandatory && Utils.isEqual(preferences?.skipVersion, configAppData.version)) {
              return true;
            }

            // reset skipVersion preferences every time a mandatory update appears
            this.updateAppPreferences({ skipVersion: null });

            return false;
          }
        }

        return true;
      }),
    );
  }

  private updateAppNavVisibility() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        let currentRoute = this.activatedRoute.root;
        let data: Data = {};
        while (currentRoute?.firstChild) {
          currentRoute = currentRoute.firstChild;
          data = {
            ...data,
            ...currentRoute.snapshot.data,
          };
        }

        this.appStore.updateAppNavVisibility(data.navVisibility);
      });
  }
}
