import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { IonicModule } from '@ionic/angular';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PersonStateModule } from '@onyxx/store/person';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PinInputComponent } from '@onyxx/ui/pin-input';
import { Utils } from '@onyxx/utility/general';
import { ContentContainerModule, HeaderModule, LabelModule, PageHeaderModule } from '@semmie/components';
import { NavigationService } from '@semmie/services';
import { asyncScheduler, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { countDownSecondsUtil, filterNil } from '@onyxx/utility/observables';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  template: `
    <ion-content [forceOverscroll]="false">
      <semmie-content-container size="lg" padding="px-10" (click)="focusInput()">
        <semmie-page-header [leftAction]="true" (onLeftIconClick)="onBack()"></semmie-page-header>
        <semmie-content-container-top>
          <semmie-header
            i18n-label="@@auth.two-factor-authentication.validate.title"
            label="Security"
            i18n-title="@@auth.two-factor-authentication.validate.subtitle"
            title="Validate SMS code"
            i18n-description="@@auth.two-factor-authentication.validate.description"
            description="Enter the code sent via SMS to {{ obfuscatedPhoneNumber$$() }}."
          ></semmie-header>
        </semmie-content-container-top>
        <div class="pt-28">
          <onyxx-pin-input [attr.autofocus]="true" [formControl]="otpFormControl" />
        </div>
        <div class="p-6">
          <semmie-label
            [class]="twoFactorTimerText$$()?.disabled ? 'link-disabled' : 'link'"
            type="text"
            size="base_2"
            align="center"
            [text]="twoFactorTimerText$$()?.text"
            (onClick)="sendSms()"
          ></semmie-label>
        </div>
      </semmie-content-container>
    </ion-content>
  `,
  selector: 'onyxx-two-factor-validate',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    ContentContainerModule,
    PageHeaderModule,
    HeaderModule,
    PersonStateModule,
    LabelModule,
    ReactiveFormsModule,
    PinInputComponent,
  ],
})
export class TwoFactorValidateComponent implements AfterViewInit {
  private readonly navigationService = inject(NavigationService);
  private readonly authFacade = inject(AuthFacade);

  @ViewChild(PinInputComponent) pinInput: PinInputComponent | undefined;

  readonly checkingOTP$$ = signal(false);
  readonly obfuscatedPhoneNumber$$ = toSignal(
    this.authFacade.twoFactorSmsNumber$.pipe(
      filterNil(),
      map((number) => {
        return `******${number}`;
      }),
      startWith('**********'),
      map((phoneNumber) => `<strong>${phoneNumber}</strong>`),
    ),
    {
      requireSync: true,
    },
  );

  twoFactorTimerText$$ = toSignal(
    this.authFacade.twoFactorResendAllowedAt$.pipe(
      switchMap((value) => {
        if (value === null) {
          return of(null);
        }
        return countDownSecondsUtil(value, { humanized: true });
      }),
      map((countdownText) => {
        if (Utils.isNil(countdownText)) {
          return { disabled: false, text: $localize`:@@auth.two-factor-authentication.resend:Resend code` };
        }
        return {
          disabled: true,
          text: $localize`:@@auth.two-factor-authentication.resend_timer:Haven't received a code? Resend ${countdownText}`,
        };
      }),
    ),
  );

  otpFormControl = new FormControl('');

  constructor() {
    this.authFacade.loginFailure$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.otpFormControl.setValue('');
      this.focusInput();
    });

    this.authFacade.loginBusy$.pipe(takeUntilDestroyed()).subscribe((busy) => {
      if (busy) {
        this.otpFormControl.disable();
      } else {
        this.otpFormControl.enable();
      }
    });

    this.otpFormControl.valueChanges.pipe(takeUntilDestroyed(), filterNil(), distinctUntilChanged()).subscribe((value) => {
      if (value.length === 6) {
        this.authFacade.dispatchTwoFactorLogin(value);
      }
    });
  }

  ngAfterViewInit(): void {
    // wait a bit for the animations to finish
    asyncScheduler.schedule(() => {
      this.focusInput();
    }, 350);
  }

  onBack() {
    this.navigationService.back();
  }

  sendSms() {
    this.authFacade.dispatchSendTwoFactorSms();
  }

  focusInput() {
    this.pinInput?.focus();
  }
}
