import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { visibleAccountsListApiActions } from './visible-accounts-list-api.actions';
import { visibleAccountsListFeature } from './visible-accounts-list.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { visibleAccountsListCommonActions } from './visible-accounts-list-common.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';

@Injectable()
export class VisibleAccountsListStoreFacade {
  readonly store = inject(Store);
  readonly actions = inject(Actions);

  readonly visibleAccounts$ = this.store.select(visibleAccountsListFeature.selectEntitiesWithMeta);

  readonly loadDone$ = this.actions.pipe(
    ofType(
      visibleAccountsListApiActions.reloadAccountsSuccess,
      visibleAccountsListApiActions.reloadAccountsFailure,
      visibleAccountsListApiActions.loadPageSuccess,
      visibleAccountsListApiActions.loadPageFailure,
    ),
  );

  dispatchLoadNextPage() {
    this.store.dispatch(visibleAccountsListApiActions.loadNextPage());
  }

  dispatchReloadAccounts() {
    this.store.dispatch(visibleAccountsListApiActions.reloadAccounts());
  }

  /** Insert an account in the entity store (does not cause API calls) */
  dispatchAddAccountToStore(account: Account) {
    this.store.dispatch(visibleAccountsListCommonActions.addToAccountCache({ account }));
  }

  /** Update the entity store (does not cause API calls) */
  dispatchUpdateAccountInStore(account: Account) {
    this.store.dispatch(visibleAccountsListCommonActions.updateAccountCache({ account }));
  }

  /** Update the entity store (does not cause API calls) */
  dispatchRemoveAccountFromStore(accountId: string) {
    this.store.dispatch(visibleAccountsListCommonActions.removeFromAccountCache({ id: accountId }));
  }
}
