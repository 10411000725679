import { Injectable, inject } from '@angular/core';

import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';

import { map } from 'rxjs/operators';
import { PskParticipant } from '@onyxx/model/psk-participants';

@Injectable({ providedIn: 'root' })
export class PskParticipantsProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  getPskParticipants() {
    return this.http
      .get<{ psk_participants: PskParticipant[] }>(`${this.apiUrl}/psk_participants`)
      .pipe(map(({ psk_participants }) => psk_participants));
  }
}
