import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PushNotification } from '@onyxx/model/notifications';
import { PermissionStatus } from '@capacitor/push-notifications';

export const PushNotificationCommonActions = createActionGroup({
  source: 'push notifications common',
  events: {
    Initialize: emptyProps(),

    'Push Notification Action': props<{ notification: PushNotification }>(),
    'Push Notification Received': props<{ notification: PushNotification }>(),

    'Registration Success': props<{ token: string }>(),
    'Registration Error': props<{ error: string }>(),

    'Set Device Token': props<{ token: string }>(),
    'Clear Device Token': emptyProps(),

    'Permission Updated': props<{ permission: PermissionStatus }>(),

    Enroll: emptyProps(),
    'Un Enroll': emptyProps(),
    'Enrolled Updated': props<{ enrolled: boolean }>(),
    'Enrolled And Permission Updated': props<{ enrolled: boolean; permission: PermissionStatus }>(),

    Clear: emptyProps(),
    'Clear Done': emptyProps(),
  },
});
