import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { exhaustMap, map } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { of } from 'rxjs';

/**
 * Routing guard that will log out a user before allowing them to access a route
 */
export function forceLogoutGuard(): CanActivateFn {
  return () => {
    const authFacade = inject(AuthFacade);

    return authFacade.isAuthenticated$.pipe(
      exhaustMap((authenticated) => {
        if (authenticated) {
          // if the user is authenticated, log them out and wait for the auth state to change
          authFacade.dispatchClearToken();
          return authFacade.loggedOut$.pipe(map(() => true));
        }

        return of(true);
      }),
    );
  };
}
