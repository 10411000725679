// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountMetadata, PlotOptionTypes } from '@onyxx/model/account';
import { ChartPlotPreference } from '@semmie/schemas/bi/chart';

export const parseMetadata = (metadata: string | null): AccountMetadata => {
  const jsonParse = (value: string) => {
    try {
      return metadata ? JSON.parse(value) : {};
    } catch (error) {
      return {};
    }
  };

  const unsafeJsonObject = jsonParse(metadata ?? '{}');
  const safeChartPlotPreference = Object.values(ChartPlotPreference).find((c) => c === unsafeJsonObject.chartPlotPreference) ?? null;
  const safeChartSelectedPlotOption = PlotOptionTypes.find((c) => c === unsafeJsonObject.chartSelectedPlotOption) ?? null;

  return {
    performance_category_GROUP: unsafeJsonObject.performance_category_GROUP ?? null,
    performance_category: unsafeJsonObject.performance_category ?? null,
    seen_account_more_info: unsafeJsonObject.seen_account_more_info ?? null,
    chartSelectedPlotOption: safeChartSelectedPlotOption,
    chartPlotPreference: safeChartPlotPreference,
    transactionFilters: unsafeJsonObject.transactionFilters ?? null,
    wid: unsafeJsonObject.wid ?? null,
    goalUpdateModalDate: unsafeJsonObject.goalUpdateModalDate ?? null,
    payment_optional: unsafeJsonObject.payment_optional ?? null,
    representativesPristine: unsafeJsonObject.representativesPristine ?? null,
  };
};
