import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { PaginatedResponse } from '@onyxx/model/pagination';
import { Booster } from '@semmie/models/bi/booster/booster.model';
import { Reward } from '@semmie/models/bi/reward/reward.model';

@Injectable()
export class BoosterProvider extends BaseProvider<Booster> {
  constructor() {
    super('boosts');
  }

  /**
   * List boosters, optionally with params to more specifically filter your request
   *
   * ? Developer note: Explicitely remap data here for consistency
   *
   * @param params
   * @returns
   */
  list(params?: any): Observable<PaginatedResponse<Booster>> {
    return super.__list('boosts', params).pipe(
      map((response) => {
        return {
          data: response?.['boosts']?.map((b) => new Booster(b)),
          meta: response?.meta,
        };
      }),
    );
  }

  clear(): void {
    this.__clear();
  }

  assignReward(reward: string, account: string): Observable<Reward> {
    return super.__patch(`rewards/${reward}`, { reward: { account_id: account } }).pipe(
      map((response) => {
        return new Reward(response?.reward);
      }),
    );
  }
}
