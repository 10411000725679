import { isNil } from './is-nil';

export const isEqual = <T>(dataX: T, dataY: T): boolean => {
  if (dataX === dataY) {
    return true;
  }

  if (typeof dataX !== 'object' || dataX === null || dataY === null) {
    return false;
  }

  if (Array.isArray(dataX)) {
    if (!Array.isArray(dataY) || dataX.length !== dataY.length) {
      return false;
    }

    for (let i = 0; i < dataX.length; i++) {
      if (!isEqual(dataX[i], dataY[i])) {
        return false;
      }
    }

    return true;
  }

  if (dataX instanceof Date) {
    if (!(dataY instanceof Date) || dataX.valueOf() !== dataY.valueOf()) {
      return false;
    }

    return true;
  }

  if (dataX instanceof RegExp) {
    if (!(dataY instanceof RegExp) || dataX.toString() !== dataY.toString()) {
      return false;
    }

    return true;
  }

  if (typeof dataX !== 'object' || isNil(dataY)) {
    return false;
  }

  const keysX = Object.keys(dataX);
  const keysY = Object.keys(dataY);

  if (keysX.length !== keysY.length) {
    return false;
  }

  for (const key of keysX) {
    if (!keysY.includes(key) || !isEqual<unknown>(dataX[key as keyof typeof dataX], dataY[key as keyof typeof dataY])) {
      return false;
    }
  }

  return true;
};
