import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { FormInputComponent } from '@semmie/components/containers/form-input/form-input.component';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { DynamicFieldType } from '@semmie/schemas/components/dynamic-form';
import { iAutocompleteListSettings } from '@semmie/schemas/components/dynamic-form/form-fields';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Utils } from '@semmie/shared/utils';
import { ImageModule } from '@semmie/components/presentational/core/image';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { LoadingRippleModule } from '@semmie/components/presentational/core/loading-ripple/loading-ripple.module';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, LabelModule, ImageModule, LoadingRippleModule],
  selector: 'semmie-autocomplete-list',
  templateUrl: 'autocomplete-list.component.html',
  styleUrls: ['./autocomplete-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteListComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() label?: string;
  @Input() infoModal: iInfoModal;
  /** list of items to display */
  @Input() list: iAutocompleteListSettings;
  /** Reference to the input field */
  @Input() input: string;
  isLoading$$ = new BehaviorSubject(false);
  hasError$$ = new BehaviorSubject(false);
  hasSearchQuery$$ = new BehaviorSubject(false);
  data$$: BehaviorSubject<any> = new BehaviorSubject(null);
  lastSelected$$ = new BehaviorSubject('');
  private destroy$$: Subject<boolean> = new Subject();
  private endDataStreams$$: Subject<boolean> = new Subject();

  constructor(
    @Optional() @Host() private formInputComponent: FormInputComponent,
    @Optional() @Self() ngControl: NgControl,
    private cdr: ChangeDetectorRef,
  ) {
    super(ngControl);
  }

  ngOnInit() {
    // Check the form changes for existing kvk search requests
    const dataStreams = this.formInputComponent?.actualFormComponent?.getDataStreams(DynamicFieldType.ExternalData, this.list?.data);

    if (dataStreams) {
      dataStreams
        .pipe(
          tap(() => {
            // unsubscribe to external data stream form previous form changes
            this.endDataStreams$$.next(true);
          }),
          switchMap((streams) => {
            if (streams[0]?.stream) {
              return streams[0].stream.pipe(takeUntil(this.endDataStreams$$));
            }

            return EMPTY;
          }),
          filter(Utils.isNonNullOrUndefined),
          tap(() => {
            // Set loader for each (pending) request
            this.isLoading$$.next(true);
            this.cdr.detectChanges();
          }),
          filter((stream) => {
            // filter out the requests that come out as error to display the error message
            if (stream.status > 200) {
              this.data$$.next([]);
              this.hasError$$.next(true);
              this.isLoading$$.next(false);
              this.cdr.detectChanges();
              return false;
            }
            return true;
          }),
          tap((stream) => {
            // display the results as list items
            if (stream.status === 200) {
              this.data$$.next(stream.body);
              this.hasError$$.next(false);
              this.isLoading$$.next(false);
              this.cdr.detectChanges();
            }
          }),
          takeUntil(this.destroy$$),
        )
        .subscribe();

      // clear results when search input is cleared
      this.form.valueChanges
        .pipe(
          map((changes) => !!changes?.[this.input]),
          distinctUntilChanged(),
          tap((hasSearchQuery) => {
            if (hasSearchQuery) this.isLoading$$.next(true);
            this.hasSearchQuery$$.next(hasSearchQuery);
            if (!hasSearchQuery) this.data$$.next([]);
          }),
          takeUntil(this.destroy$$),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$$.next(true);
    this.destroy$$.complete();
    this.isLoading$$.complete();
  }

  selectResult(item: any): void {
    if (item) {
      this.value = item[this.list.value];
      const input = this.form?.get(this.input);

      if (input) {
        this.lastSelected$$.next(item[this.list.searchKey]);
        input.patchValue(item[this.list.searchKey], { emitEvent: false, onlySelf: true });
      }
      this.cdr.markForCheck();
    }
  }
}
