import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';
import { CommonModule } from '@angular/common';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { Icon } from '@semmie/schemas';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, CardModule, LabelModule],
  selector: 'semmie-card-button',
  template: `
    <semmie-card (onClick)="onClickHandler($event)" class="card-button" [ngClass]="getStyling()" [padding]="false">
      <div class="card-button__container">
        <semmie-label type="heading" size="h3" [text]="text"></semmie-label>
        <span *ngIf="icon" class="card-button__icon" [class.card-button__icon--circle]="iconCircle">
          <semmie-icon size="xs" [icon]="icon"></semmie-icon>
        </span>
      </div>
    </semmie-card>
  `,
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent extends BaseComponent implements OnInit {
  @Input() text: string;
  @Input() class: string;
  @Input() disabled = false;
  @Input() icon: Icon;
  @Input() iconCircle = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.class) this.class += ' ';
  }

  onClickHandler($event: any): void {
    if (this.disabled) return;
    this.onClick.emit($event);
  }

  getStyling() {
    const classes = new Array<string>();
    if (this.disabled) classes.push('disabled');
    if (this.class) this.class.split(' ').forEach((c) => classes.push(c));
    return classes.join(' ');
  }
}
