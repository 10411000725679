import { iPropertyMap } from '@semmie/schemas/generics/property-map/property-map.interface';

export abstract class BaseModel<T> {
  protected initialProps: any;

  private fieldMapping: iPropertyMap[];

  constructor(props?: Partial<T>, mapping?: iPropertyMap[]) {
    if (mapping) this.fieldMapping = mapping;
    this.initialize(props);
  }

  initialize(props?: Partial<T>): this {
    if (props) {
      this.initialProps = props;
      if (this.fieldMapping) {
        this.mapProperties(props, 'local');
      } else {
        Object.assign(this, props);
      }
    }
    return this;
  }

  /**
   * Updates the Model<T> with new values
   * @param props Properties to update this Model<T> with
   */
  update(props: Partial<T>): this | undefined {
    if (!props) return;
    Object.assign(this, props);
    return this;
  }

  /**
   * Clones the Model<T> with the current props, or its initial props
   */
  clone(): any {
    const _class = this.constructor as new (props: Partial<T>) => this;
    const currentProps: any = {};

    for (const i in this) {
      currentProps[i] = this[i];
    }

    if (currentProps && Object.keys(currentProps).length) {
      return new _class(currentProps ?? {});
    }

    return new _class(this.initialProps ?? {});
  }

  /**
   * Prints out the current Model<T> with trace details for debugging purposes
   */
  print(): void {
    // eslint-disable-next-line no-console
    console.trace(`[${this.constructor.name}]`, this);
  }

  /**
   * Maps the properties from/to the locally named properties, and maps them to the correct model
   * @param props properties to map the Model<t>
   */
  mapProperties(props: Partial<T>, direction: 'api' | 'local'): this | undefined {
    if (!this.fieldMapping) return;

    const result = {};
    const mapping = new Map(this.fieldMapping.map((map) => [direction === 'local' ? map.api : map.local, map]));

    Object.keys(props).forEach((prop) => {
      const original = props[prop];
      const map = mapping.get(prop);

      // when no result use original
      if (!map) {
        result[prop] = original;
        return;
      }

      const propertyName = direction === 'local' ? map.local : map.api;

      // set property with specified name, map to correct instance when provided
      if (map.model) {
        result[propertyName] =
          original instanceof Array ? original.map((o) => map.model.clone(o).initialize(o)) : map.model.initialize(original);
      } else {
        result[propertyName] = original;
      }
    });

    Object.assign(this, result);
    return this;
  }
}
