import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentContainerModule } from '@semmie/components/containers/content-container/content-container.module';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { ButtonModule } from '@semmie/components/presentational/core/button/button.module';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { ToggleModule } from '@semmie/components/presentational/core/toggle/toggle.module';

import { IntroContentComponent } from './intro-content.component';
import { HeaderModule } from '@semmie/components/presentational/core/header/header.module';
import { PageHeaderModule } from '@semmie/components/containers/page-header/page-header.module';

@NgModule({
  declarations: [IntroContentComponent],
  imports: [CommonModule, PageHeaderModule, HeaderModule, ContentContainerModule, LabelModule, ButtonModule, ImageModule, ToggleModule],
  exports: [IntroContentComponent],
})
export class IntroContentModule {}
