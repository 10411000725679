import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BiometricAvailability } from './models/biometric-availability.type';

export const biometricsCommonActions = createActionGroup({
  source: 'Biometrics Tasks Common',
  events: {
    Initialize: emptyProps(),
    'Initialize done': props<{ enabled: boolean; availability: BiometricAvailability }>(),
    'Load availability': emptyProps(),
    'Availability loaded': props<{ availability: BiometricAvailability }>(),

    Authenticate: emptyProps(),
    'Authenticate success': emptyProps(),
    'Authenticate failed': emptyProps(),

    Enable: emptyProps(),
    'Enable success': emptyProps(),
    'Enable failure': emptyProps(),
    'Enable permission failure': props<{ availability: BiometricAvailability }>(),

    Disable: emptyProps(),
    'Disable done': emptyProps(),

    Clear: emptyProps(),
  },
});
