import { Injectable } from '@angular/core';

import { iAppConfiguration, iConfiguration } from '@semmie/schemas/bi/configuration/configuration';
import { JmespathService } from '@semmie/services/jmespath/jmespath.service';
import { BaseStore } from '@semmie/models/_abstract/base-store';

@Injectable({
  providedIn: 'root',
})
export class ConfigStore extends BaseStore<iConfiguration> {
  payment$ = this.select((state) => state?.config?.payment ?? ({} as NonNullable<iAppConfiguration['payment']>));

  constructor(private jmespathService: JmespathService) {
    super();
  }

  get config() {
    return this.store ?? {};
  }

  get app() {
    return this.store.value?.builds ?? {};
  }

  get magazineFeed() {
    return this.store.value?.config?.magazineFeed ?? null;
  }

  get theme() {
    return this.store.value?.config?.theme ?? 'semmie';
  }

  get views() {
    return this.store.value?.config?.views ?? {};
  }

  /**
   * @deprecated Use configService.config$. It is not nullable and is strongly typed.
   */
  get features() {
    return this.store.value?.config?.features ?? ({} as Record<string, any>);
  }

  // Used for payment amounts (plans, first payment, booster payments, etc.)
  get payment() {
    return this.store.value?.config?.payment ?? ({} as NonNullable<iAppConfiguration['payment']>);
  }

  get navigationTabs() {
    return this.store.value?.config?.navigationTabs ?? {};
  }

  get versions() {
    return this.store.value?.builds ?? {};
  }

  get companyConfig() {
    return this.store.value?.config?.company;
  }

  get translations() {
    return this.store.value?.config?.translations ?? false;
  }

  get maintenance() {
    return this.store.value?.maintenance ?? null;
  }

  get oauth() {
    return this.store.value?.oauth ?? { client_id: '', client_secret: '' };
  }

  getViewConfig<T>(path: string): Record<string, T> | undefined {
    return this.jmespathService.interpolate(this.views, path);
  }
}
