import { Injectable } from '@angular/core';
import { Intercom } from '@capacitor-community/intercom';

@Injectable({
  providedIn: 'root',
})
export class ChatNativeService {
  /**
   * TODO: Support intercom push notifications (FET-4269)
   * * https://app.clickup.com/t/2559043/FET-4269
   */

  async initialize(intercomUserId: string | null): Promise<void> {
    await this.logout();

    try {
      if (intercomUserId) {
        await Intercom.registerIdentifiedUser({ userId: intercomUserId });
      } else {
        await Intercom.registerUnidentifiedUser();
      }
    } catch (err) {
      console.warn('Intercom login failed', err);
    }
  }

  async openChat(): Promise<void> {
    await Intercom.displayMessenger().catch((err) => console.warn('Intercom open chat failed', err));
  }

  async closeChat(): Promise<void> {
    await Intercom.hideMessenger().catch((err) => console.warn('Intercom close chat failed', err));
  }

  async logout(): Promise<void> {
    await Intercom.logout().catch((err) => console.warn('Intercom logout failed', err));
  }
}
