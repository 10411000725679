<div (click)="readonly ? onClick.emit() : fileInput.click()" class="user-avatar">
  <div class="user-avatar-inner-border">
    <ng-container *ngIf="avatarUrl; else placeholderUserTpl">
      <semmie-image [src]="avatarUrl" [objectFit]="'objectCover'" [width]="120" [height]="120"></semmie-image>
    </ng-container>

    <ng-template #placeholderUserTpl>
      <div class="icon-container">
        <semmie-icon [icon]="Icon.USER"></semmie-icon>
      </div>
    </ng-template>

    <ng-container *ngIf="!readonly">
      <div class="uploader-container">
        <div class="icon">
          <semmie-icon [icon]="Icon.PHOTO"></semmie-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="!readonly">
  <ng-container *ngIf="{ error: errorKey$ | async, progress: progress$ | async } as ctx">
    <div class="mt-2 flex flex-col gap-y-4">
      <ng-container *ngIf="ctx.progress; else uploaderTpl">
        <semmie-label
          (onClick)="fileInput.click()"
          type="text"
          size="base_3"
          weight="medium"
          align="center"
          class="link"
          i18n-text="@@user.avatar.upload-progress"
          text="Uploading {{ ctx.progress }}%"
        ></semmie-label>
      </ng-container>
      <ng-template #uploaderTpl>
        <semmie-label
          (onClick)="fileInput.click()"
          type="text"
          size="base_3"
          weight="medium"
          align="center"
          class="link"
          i18n-text="@@user.avatar.upload-link"
          text="Change photo"
        ></semmie-label>
      </ng-template>
      <ng-container *ngIf="ctx.error">
        <semmie-label type="small" align="center" size="tiny" class="text-danger-300" [text]="ctx.error"></semmie-label>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<input (change)="handleFiles(fileInput.files)" [accept]="'image/*'" class="hidden" #fileInput type="file" />
