import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { RegExpPatterns } from '@semmie/shared/regexp';

export function email(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    const validEmail = RegExpPatterns.EMAIL.test(value);

    if (!validEmail) {
      return new ValidatorMessage({
        code: 8,
        message: $localize`:@@validation.email.invalid:Missing an @ or .? The email address entered is not recognized.`,
      });
    }

    return null;
  };
}
