<ng-container *ngIf="options">
  <div class="flex flex-col" [class.gap-y-5]="layout !== 'card'">
    <semmie-checkbox
      *ngFor="let option of options"
      [label]="option.label"
      [description]="option.description ?? ''"
      [isDisabled]="option.disabled || isDisabled"
      [value]="option.value"
      [name]="name"
      [layout]="layout"
    >
    </semmie-checkbox>
  </div>
</ng-container>
<ng-content></ng-content>
