import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PayoutCalculation } from '@onyxx/model/payout';
import { PayoutProvider } from '@onyxx/provider/payout';
import { Goal } from '@semmie/models';
import { GoalProvider } from '@semmie/providers/goal/goal.provider';
import { GoalFocus } from '@semmie/schemas/bi/goal/goal-focus.enum';
import { iPersonPensionDates } from '@semmie/schemas/bi/person/person';
import { ModalSize } from '@semmie/schemas/components/modal/modal-size';
import { ModalService } from '@semmie/services/modal/modal.service';
import { NavigationService } from '@semmie/services/navigation/navigation.service';
import { PersonService } from '@semmie/services/person/person.service';
import { Utils } from '@semmie/shared/utils';
import moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GoalService {
  constructor(
    private goalProvider: GoalProvider,
    private payoutProvider: PayoutProvider,
    private personService: PersonService,
    private modalService: ModalService,
    private navService: NavigationService,
    private translate: TranslateService,
  ) {}

  calculate(params: any): Observable<Goal> {
    const finished_account = Utils.isStringTruthy(params.finished_account);
    let defaultParams: any = {
      focus: params.focus,
      risk_profile: params.risk_profile,
      strategy: params.strategy,
      importance: params.importance,
      risk_appetite: params.risk_appetite,
      max_loss: params.max_loss,
    };

    if (finished_account) {
      defaultParams = {
        ...defaultParams,
        fee_percentage: params.fee_percentage,
      };
    }

    if (Utils.isStringTruthy(params.amount_transfer_active) && params.amount_transfer) {
      defaultParams = {
        ...defaultParams,
        amount_transfer_active: true,
        amount_transfer: params.amount_transfer,
      };
    }
    let goalParams: any;
    switch (params.focus) {
      case GoalFocus.AMOUNT: {
        goalParams = {
          ...defaultParams,
          amount: params.amount,
          amount_start: params.amount_start ?? 0,
          deposit: params.deposit,
        };
        goalParams = finished_account ? { ...goalParams, ends_at: params.ends_at } : { ...goalParams, period: params.period };
        break;
      }
      case GoalFocus.TIME: {
        goalParams = {
          ...defaultParams,
          amount_start: params.amount_start ?? 0,
          deposit: params.deposit,
        };
        goalParams = finished_account ? { ...goalParams, ends_at: params.ends_at } : { ...goalParams, period: params.period };
        break;
      }
      case GoalFocus.CONTINUOUS: {
        goalParams = {
          ...defaultParams,
          amount_start: params.amount_start ?? 0,
          deposit: params.deposit,
        };
        break;
      }
    }
    return this.goalProvider.calculate({ ...goalParams }).pipe(take(1));
  }

  /**
   * Calculate the annuity payout
   */
  calculatePayout({ amount_transfer, frequency, period }: any): Observable<PayoutCalculation> {
    return this.payoutProvider.calculatePayout({ amount_transfer, frequency, period }).pipe(take(1));
  }

  getDatesPension(params): Observable<iPersonPensionDates> {
    const personID: string = params.accountOwnerPersonId ?? params;
    if (Utils.isNullOrUndefined(personID)) return EMPTY;
    return this.personService.getPersonById(personID).pipe(
      filter(Utils.isNonNullOrUndefined),
      take(1),
      map((person) => {
        return {
          birth_at: person.birth_at,
          retirement_at: person.retirement_at,
          pension_min: Math.ceil(moment().diff(moment(person.birth_at), 'days') / 365.25) + 1,
          pension_max: moment(person.retirement_at).add(5, 'years').diff(moment(person.birth_at), 'years'),
        };
      }),
    );
  }

  getPeriodForPensionAge(params): number | null {
    if (!params.dates_pension?.retirement_at_age) return null;
    const currentAge = moment().diff(moment(params.dates_pension.birth_at), 'years');
    return Number(params.dates_pension.retirement_at_age) - currentAge;
  }

  async showInvalidGoalInformation(accountId: string): Promise<void> {
    const { InvalidGoalComponent } = await import('@semmie/views/account/overview/modals/goal-invalid/invalid-goal.component');

    const modal = await this.modalService.open(InvalidGoalComponent, {}, { size: ModalSize.Full });

    const { data: confirmed } = await modal.onDidDismiss();

    if (confirmed) {
      this.navService.navigate(['/accounts', accountId, 'plan'], { queryParams: { previous_route: 'overview' } });
      return;
    }

    this.navService.toDashboard(true);
  }
}
