import { HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION, SKIP_AUTHENTICATION } from '@onyxx/model/http';
import { EmailNotificationsSettings } from '@onyxx/model/notifications';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_NAMESPACE = 'mailing_users';

type EmailNotificationsResponse = {
  mailing_user: {
    user: {
      mailings: EmailNotificationsSettings;
    };
  };
};

@Injectable({
  providedIn: 'root',
})
export class EmailNotificationsProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  getMailNotifications(token: string): Observable<EmailNotificationsSettings> {
    return this.http
      .get<EmailNotificationsResponse>(`${this.apiUrl}/${API_NAMESPACE}/${token}`, {
        context: new HttpContext().set(SKIP_AUTHENTICATION, true),
      })
      .pipe(map((response) => response?.mailing_user?.user?.mailings));
  }

  updateMailNotifications(token: string, settings: EmailNotificationsSettings) {
    return this.http.patch<EmailNotificationsResponse>(
      `${this.apiUrl}/${API_NAMESPACE}/${token}`,
      {
        mailing_user: { user: { mailings: settings } },
      },
      { context: new HttpContext().set(SKIP_AUTHENTICATION, true) },
    );
  }
}
