<div class="input-wrapper">
  <input
    #inputRef
    type="text"
    [name]="name"
    [id]="id"
    [ngModel]="value"
    [placeholder]="placeholder"
    (ngModelChange)="onValueChange($event)"
  />
  <div class="icon-wrapper" (click)="clear()">
    <semmie-icon class="icon" [icon]="value ? Icon.CLOSE : Icon.SEARCH" [size]="value ? 'xxs' : 'lg'"></semmie-icon>
  </div>
</div>
