import { Icon } from '@semmie/schemas/common';
import { iDynamicFormFieldAction } from './dynamic-form-field-action.interface';
import { iBaseDynamicFormField, iDynamicExpressionProperty } from './base-dynamic-field';

export const TEXTBOX_NUMERIC_INPUT_MODES = ['money', 'integer'] as const;
export const TEXTBOX_STRING_INPUT_MODES = ['text', 'digits', 'tel', 'password', 'email', 'zipcode', 'iban', 'uppercase'] as const;
export const TEXTBOX_INPUT_MODES = [...TEXTBOX_NUMERIC_INPUT_MODES, ...TEXTBOX_STRING_INPUT_MODES];

export interface iTextBoxFormField extends iBaseDynamicFormField {
  incrementBy?: number;
  autocomplete?: string;
  inputMode: (typeof TEXTBOX_INPUT_MODES)[number];
  countryCode?: string;
  enterKeyHint?: string;
  trailingIcon?: Icon;
  leadingIcon?: Icon;
  actions?: iDynamicFormFieldAction[];
  loading?: iDynamicExpressionProperty;
  allowEmoticons?: boolean;
}
