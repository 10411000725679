import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { ButtonModule } from '@semmie/components/presentational/core/button/button.module';
import { LoadingRippleModule } from '@semmie/components/presentational/core/loading-ripple/loading-ripple.module';
import { ScrollableListComponent } from './scrollable-list.component';

@NgModule({
  declarations: [ScrollableListComponent],
  imports: [CommonModule, ButtonModule, LoadingRippleModule],
  exports: [ScrollableListComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ScrollableListModule {}
