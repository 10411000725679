import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { UserTaskStoreFacade } from './user-task.facade';
import { userTaskFeature } from './user-task.reducer';
import { UserTaskEffects } from './user-task.effects';
import { userTaskDetailFeature } from './user-task-detail.reducer';

export const providers = [
  provideState(userTaskFeature),
  provideState(userTaskDetailFeature),
  provideEffects(UserTaskEffects),
  UserTaskStoreFacade,
];

@NgModule({ providers })
export class UserTaskStateModule {}

export * from './user-task.facade';
