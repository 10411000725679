<ng-container *ngIf="initialized; else loadingTpl">
  <form [formGroup]="formGroup">
    <ng-container *ngFor="let field of dynamicForm?.fields">
      <ng-container [ngSwitch]="field.type">
        <semmie-form-group
          *ngSwitchCase="'group'"
          [form]="formGroup"
          [field]="field"
          [ngStyle]="field.styles"
          [enableSideSpacing]="enableSideSpacing"
          [class.mx-4]="enableSideSpacing && field.cardStyle"
          [$meta]="$meta"
        >
        </semmie-form-group>
        <semmie-form-input
          *ngSwitchDefault
          #semmieField
          [form]="formGroup"
          [field]="field"
          [$meta]="$meta"
          [ngStyle]="field.styles"
          [error]="getErrorMessage(field.name)"
          [enableSideSpacing]="enableSideSpacing"
        ></semmie-form-input>
      </ng-container>
    </ng-container>
    <ng-content></ng-content>
    <ng-container *ngIf="enabledFormSubmit">
      <button class="invisible" type="submit"></button>
    </ng-container>
  </form>
</ng-container>

<ng-template #loadingTpl>
  <semmie-label text="Loading..." i18n-text="@@loading-with-dots"></semmie-label>
</ng-template>
