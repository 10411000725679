<ng-container *ngIf="!condensed; else condensedTpl">
  <div class="item-card" [class.itemBeforeSelected]="itemBeforeSelected" [class.selected]="selected" [class.unread]="!message.read_at">
    <div class="title-container" [class.unread-indicator]="!message.read_at">
      <semmie-label type="heading" size="h4" weight="medium" class="title truncate" [text]="message.title"></semmie-label>
      <div class="ml-4 flex-shrink-0" *ngIf="message.created_at">
        <semmie-label class="date" size="base_3" [text]="message.created_at | onyxxDate: DateFormat.TimeAgo"></semmie-label>
      </div>
    </div>
    <div class="mr-6" [class.unread]="!message.read_at">
      <semmie-label class="description" size="base_3" [text]="message.description"></semmie-label>
    </div>
  </div>
</ng-container>

<ng-template #condensedTpl>
  <div class="item-card-condensed" [class.active]="active" [class.selected]="selected">
    <div class="title-container">
      <semmie-icon *ngIf="icon" [icon]="icon"></semmie-icon>
      <semmie-label type="heading" size="h3" weight="medium" class="title truncate" [text]="message.title"></semmie-label>
    </div>
  </div>
</ng-template>
