import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { accountAdditionalDataFeature } from './account-additional-data.reducer';
import { accountAdditionalDataCommonActions } from './account-additional-data-common.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountAdditionalData } from '@onyxx/model/account';

@Injectable({
  providedIn: 'root',
})
export class AccountAdditionalDataStoreFacade {
  readonly store = inject(Store);

  dispatchSetAccountAdditionalData(additionalData: AccountAdditionalData) {
    this.store.dispatch(accountAdditionalDataCommonActions.setAdditionalData({ additionalData }));
  }

  selectById(id: string) {
    return this.store.select(accountAdditionalDataFeature.selectAdditionalDataById(id));
  }
}
