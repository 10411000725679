<semmie-card class="input-card" [padding]="false">
  <div class="flex">
    <div class="grow px-8" [ngClass]="{ 'py-6': subtitle, 'py-8': !subtitle }" (click)="selectInput()">
      <semmie-label *ngIf="title" class="title" [text]="title" size="base" weight="medium"></semmie-label>
      <semmie-label *ngIf="subtitle" class="subtitle" [text]="subtitle" size="base_3"></semmie-label>
    </div>
    <div class="flex flex-wrap content-center">
      <ng-container *ngIf="inputMode === InputCardMode.DELETE; else inputField">
        <div *ngIf="deletable" class="p-8" (click)="onInteractionHandler('delete')">
          <semmie-icon theme="contrast" size="lg" [icon]="Icon.DELETE"></semmie-icon>
        </div>
      </ng-container>
    </div>
  </div>
</semmie-card>

<ng-template #inputField>
  <div class="value flex" [ngClass]="{ 'has-error': error }" (click)="selectInput()">
    <input
      #input
      [ngClass]="{ 'percentage-input': inputMode === InputCardMode.PERCENTAGE }"
      [type]="fieldType"
      [ngModel]="formattedValue"
      (ngModelChange)="updateValue($event)"
      [name]="name"
    />
    <semmie-label *ngIf="inputSuffix" [text]="inputSuffix" size="base"></semmie-label>
  </div>
</ng-template>
