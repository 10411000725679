import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { IconComponent, LabelModule } from '@semmie/components';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { RegExpPatterns } from '@semmie/shared/regexp';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, IconComponent, LabelModule],
  selector: 'semmie-password-checklist',
  templateUrl: './password-checklist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChecklistComponent extends BaseComponent {
  @Input({ required: true }) set password(value: string) {
    this.passwordValidations$$.set([
      {
        description: $localize`:@@password-checklist.validation.minimal-characters:At least eight characters`,
        valid: RegExpPatterns.HAS_EIGHT_CHARACTERS.test(value),
      },
      {
        description: $localize`:@@password-checklist.validation.uppercase-characters:At least one capital letter`,
        valid: RegExpPatterns.HAS_UPPERCASE_LETTER.test(value),
      },
      {
        description: $localize`:@@password-checklist.validation.lowercase-characters:At least one lowercase letter`,
        valid: RegExpPatterns.HAS_LOWERCASE_LETTER.test(value),
      },
      {
        description: $localize`:@@password-checklist.validation.digits:At least one digit`,
        valid: RegExpPatterns.HAS_DIGIT.test(value),
      },
    ]);
  }

  readonly Icon = Icon;

  readonly passwordValidations$$ = signal<Array<{ description: string; valid: boolean }>>([]);
}
