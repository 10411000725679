import { createFeature, createReducer, on } from '@ngrx/store';
import { PskParticipantsAPIActions } from './psk-participants-api.actions';
import { PskParticipantStoreActions } from './psk-participants-common.actions';
import { PskParticipant } from '@onyxx/model/psk-participants';

export interface State {
  psk_participants: PskParticipant[];
  loading: boolean;
}

const initialState: State = {
  psk_participants: [],
  loading: false,
};

const reducer = createReducer(
  initialState,

  // initialization
  on(PskParticipantStoreActions.initialize, (state): State => ({ ...state, loading: false })),

  // PSK participants
  on(PskParticipantsAPIActions.load, (state): State => ({ ...state, loading: true })),
  on(
    PskParticipantsAPIActions.loadSuccess,
    (state, { psk_participants }): State => ({
      ...state,
      loading: false,
      psk_participants,
    }),
  ),
);

export const pskParticipantsFeature = createFeature({
  name: 'psk-participants',
  reducer,
});
