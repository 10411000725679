import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelModule } from '@semmie/components/presentational/core/label/label.module';

import { MenuItemComponent } from './menu-item.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

@NgModule({
  declarations: [MenuItemComponent],
  imports: [CommonModule, LabelModule, IconComponent],
  exports: [MenuItemComponent],
})
export class MenuItemModule {}
