import { formatTimeDifferenceInMinutes, formatTimeDifferenceInMinutesHumanized } from '@onyxx/utility/formatter';
import { distinctUntilChanged, interval, map, startWith, takeUntil, timer } from 'rxjs';

export const countDownSecondsUtil = (dueDate: number, options?: { humanized: boolean }) => {
  const oneSecond = 1000;
  // check the difference every 100ms. If you schedule on every second, then small aberrations
  // in the timer cause numbers to be skipped
  return interval(oneSecond / 10).pipe(
    startWith(0),
    takeUntil(timer(new Date(dueDate))),
    map(() => {
      // if there is less than a second left, consider the time as passed
      if (dueDate - Date.now() < oneSecond) {
        return null;
      }

      const formattedDifference = options?.humanized
        ? formatTimeDifferenceInMinutesHumanized(Date.now(), dueDate)
        : formatTimeDifferenceInMinutes(Date.now(), dueDate);
      return formattedDifference;
    }),
    distinctUntilChanged(),
  );
};
