import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';

import { DropdownComponent } from '@semmie/components/presentational/core/dropdown/dropdown.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { FormInputLabelComponent } from '@semmie/components/containers/form-input-label/form-input-label.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, FormsModule, IconComponent, ReactiveFormsModule, ProgressBarModule, ImageModule, FormInputLabelComponent],
  exports: [DropdownComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class DropdownModule {}
