import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountKind } from '@onyxx/model/account';

export enum AccountKindFormat {
  Title = 'title',
  Adjective = 'adjective',
  AdjectiveLowerCase = 'adjective-lowercase',
}

@Pipe({
  name: 'onyxxAccountKindTranslate',
  standalone: true,
  pure: true,
})
export class OnyxxAccountKindTranslatePipe implements PipeTransform {
  transform(accountKind: AccountKind, format: AccountKindFormat = AccountKindFormat.Title): string {
    switch (format) {
      case AccountKindFormat.Title:
        return this.formatTitle(accountKind);
      case AccountKindFormat.Adjective:
        return this.formatAdjective(accountKind);
      case AccountKindFormat.AdjectiveLowerCase:
        return this.formatAdjectiveLowerCase(accountKind);
      default:
        return accountKind;
    }
  }

  private formatTitle(accountKind: AccountKind): string {
    switch (accountKind) {
      case AccountKind.PERSONAL:
        return $localize`:@@common.account-kind.personal.title:Personal`;
      case AccountKind.SHARED:
        return $localize`:@@common.account-kind.joint.title:Joint`;
      case AccountKind.CHILD:
        return $localize`:@@common.account-kind.child_parent.title:Child`;
      case AccountKind.GRANDPARENT:
        return $localize`:@@common.account-kind.grandparent.title:Grandchild`;
      case AccountKind.ANNUITY:
        return $localize`:@@common.account-kind.annuity.title:Annuity`;
      case AccountKind.ANNUITY_PAYOUT_LONG:
        return $localize`:@@common.account-kind.annuity-payout-long.title:Annuity payout`;
      case AccountKind.ANNUITY_PAYOUT_SHORT:
        return $localize`:@@common.account-kind.annuity-payout-short.title:Annuity payout`;
      case AccountKind.ORGANISATION:
        return $localize`:@@common.account-kind.organisation.title:Organisation`;
    }
  }

  private formatAdjective(accountKind: AccountKind): string {
    switch (accountKind) {
      case AccountKind.PERSONAL:
        return $localize`:@@common.account-kind.personal.adjective:Personal account`;
      case AccountKind.SHARED:
        return $localize`:@@common.account-kind.joint.adjective:Joint account`;
      case AccountKind.CHILD:
        return $localize`:@@common.account-kind.child_parent.adjective:Child account`;
      case AccountKind.GRANDPARENT:
        return $localize`:@@common.account-kind.grandparent.adjective:Grandchild account`;
      case AccountKind.ANNUITY:
        return $localize`:@@common.account-kind.annuity.adjective:Annuity account`;
      case AccountKind.ANNUITY_PAYOUT_LONG:
        return $localize`:@@common.account-kind.annuity-payout-long.adjective:Annuity payout account`;
      case AccountKind.ANNUITY_PAYOUT_SHORT:
        return $localize`:@@common.account-kind.annuity-payout-short.adjective:Annuity payout account`;
      case AccountKind.ORGANISATION:
        return $localize`:@@common.account-kind.organisation.adjective:Organisation account`;
    }
  }

  private formatAdjectiveLowerCase(accountKind: AccountKind): string {
    switch (accountKind) {
      case AccountKind.PERSONAL:
        return $localize`:@@common.account-kind.personal.adjective-lowercase:personal account`;
      case AccountKind.SHARED:
        return $localize`:@@common.account-kind.joint.adjective-lowercase:joint account`;
      case AccountKind.CHILD:
        return $localize`:@@common.account-kind.child_parent.adjective-lowercase:child account`;
      case AccountKind.GRANDPARENT:
        return $localize`:@@common.account-kind.grandparent.adjective-lowercase:grandchild account`;
      case AccountKind.ANNUITY:
        return $localize`:@@common.account-kind.annuity.adjective-lowercase:annuity account`;
      case AccountKind.ANNUITY_PAYOUT_LONG:
        return $localize`:@@common.account-kind.annuity-payout-long.adjective-lowercase:annuity payout account`;
      case AccountKind.ANNUITY_PAYOUT_SHORT:
        return $localize`:@@common.account-kind.annuity-payout-short.adjective-lowercase:annuity payout account`;
      case AccountKind.ORGANISATION:
        return $localize`:@@common.account-kind.organisation.adjective-lowercase:organisation account`;
    }
  }
}
