import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, exhaustMap, map, tap } from 'rxjs';
import { authCommonActions } from '../auth-common.actions';
import { AppStorageService, SentryService } from '@semmie/services';
import { AuthSession } from '@onyxx/model/auth';
import { AUTH_STORE_CONFIG } from '../auth-store-config.token';
import { Utils } from '@onyxx/utility/general';
import { NavController } from '@ionic/angular';
import { authApiActions } from '../auth-api.actions';
import { AuthSessionStorageKey } from './general.effects';

export class LogoutEffects {
  private readonly actions$ = inject(Actions);

  private readonly appStorageService = inject(AppStorageService);
  private readonly tokenStorage = this.appStorageService.createSecuredStorageReader<AuthSession>(AuthSessionStorageKey.OnyxxSession);
  private readonly sentryService = inject(SentryService);
  private readonly configToken = inject(AUTH_STORE_CONFIG);
  private readonly config = inject(this.configToken);
  private readonly navCtrl = inject(NavController);

  readonly logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authCommonActions.logOut),
      exhaustMap(({ skipNavigation }) => {
        return defer(async () => {
          try {
            await this.config.onBeforeLogout();
          } catch (error) {
            // swallow error, and keep going
            this.sentryService.captureException(error);
          }

          return authApiActions.revokeToken({ skipNavigation });
        });
      }),
    );
  });

  readonly afterRevokeActions = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.revokeTokenSuccess, authApiActions.revokeTokenFailure),
      exhaustMap(({ skipNavigation }) => {
        return defer(async () => {
          const logoutUrl = skipNavigation ? null : await this.config.logoutRedirectUrl();

          await this.tokenStorage.remove();

          try {
            this.config.onAfterLogout();
          } catch (error) {
            // swallow error, and keep going
            this.sentryService.captureException(error);
          }

          return logoutUrl;
        }).pipe(map((url) => authCommonActions.logOutDone({ url })));
      }),
    );
  });

  readonly navigateAfterLogout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authCommonActions.logOutDone),
        tap(async ({ url }) => {
          if (Utils.isNil(url)) return;

          if (url.external) {
            Utils.openUrl(url.url, { newTab: false });
          } else {
            await this.navCtrl.navigateRoot(url.url, { replaceUrl: true, animated: false });
          }
        }),
      );
    },
    { dispatch: false },
  );
}
