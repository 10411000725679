import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { directDebitsCommonActions } from './direct-debits-common.actions';
import { directDebitsApiActions } from './direct-debits-api.actions';
import { catchError, distinctUntilChanged, exhaustMap, map, of, switchMap, take } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';
import { AccountsService } from '@semmie/services';
import { HttpErrorResponse } from '@angular/common/http';

export class DirectDebitsEffects {
  private readonly actions$ = inject(Actions);
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly accountService = inject(AccountsService);

  private readonly accountId$ = this.accountStoreFacade.account$.pipe(map((account) => account?.id));

  readonly loadDirectDebits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(directDebitsApiActions.load),
      // switchMap instead of concatLatestFrom because the load might be triggered before
      // the accountId is ready
      switchMap(() => this.accountId$.pipe(filterNil(), take(1))),
      exhaustMap((accountId) =>
        this.accountService.getAccountDirectDebitList(accountId).pipe(
          map(({ data }) => directDebitsApiActions.loadSuccess({ directDebits: data, accountId })),
          catchError((error: Error) => {
            if (error instanceof HttpErrorResponse && error.status === 404) {
              return of(directDebitsApiActions.loadSuccess({ accountId, directDebits: [] }));
            }

            return of(directDebitsApiActions.loadFailure({ errorMsg: error.message }));
          }),
        ),
      ),
    );
  });

  /**
   * Clear the loaded direct debits when account ID changes to avoid showing a direct debits corresponding to another account
   */
  readonly clearDirectDebits$ = createEffect(() => {
    return this.accountId$.pipe(
      distinctUntilChanged(),
      map(() => directDebitsCommonActions.clear()),
    );
  });
}
