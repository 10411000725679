import { inject } from '@angular/core';
import { catchError, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { accountApiActions } from './account-api.actions';
import { Store } from '@ngrx/store';
import { accountFeature } from './account.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountProvider } from '@onyxx/provider/account';
import { EMPTY, of } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { accountCommonActions } from './account-common.actions';
import { getRouterSelectors } from '@ngrx/router-store';
import { Utils } from '@onyxx/utility/general';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { VisibleAccountsListStoreFacade } from '@onyxx/store/visible-accounts-list';
import { AccountStoreFacade } from './account.facade';
import { filterNil } from '@onyxx/utility/observables';
import { EventsService } from '@semmie/services';
import { EventDataCategory } from '@semmie/schemas/bi/event/event.schema';
import { CommonRouteParams } from '@semmie/views/shared/common-route-params.enum';

export const ACCOUNT_CACHE_TIMEOUT = 1000 * 60 * 60;

export class AccountEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly accountProvider = inject(AccountProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly accountStoreFacade = inject(AccountStoreFacade);
  private readonly visibleAccountsListStoreFacade = inject(VisibleAccountsListStoreFacade);
  private readonly eventService = inject(EventsService);

  readonly setCurrentAccountFromRoute$ = createEffect(() => {
    return this.store.select(getRouterSelectors().selectCurrentRoute).pipe(
      concatLatestFrom(() => this.store.select(getRouterSelectors().selectRouteParam(CommonRouteParams.AccountId))),
      map(([, id]) => {
        return Utils.isNil(id) ? accountCommonActions.clearCurrentAccount() : accountCommonActions.setCurrentAccount({ id });
      }),
    );
  });

  readonly ensureAccountIsLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.ensureAccountIsLoaded, accountCommonActions.setCurrentAccount),
      concatLatestFrom(() => [this.store.select(accountFeature.selectIds), this.store.select(accountFeature.selectAccountCacheTimestamps)]),
      switchMap(([{ id }, availableIds, accountCacheTimestamps]) => {
        const isAvailable = availableIds.some((availableId) => availableId === id);
        const cacheTimestamp = accountCacheTimestamps.get(id);
        const cacheIsStale = Utils.isNil(cacheTimestamp) || Date.now() - cacheTimestamp > ACCOUNT_CACHE_TIMEOUT;

        if (isAvailable && !cacheIsStale) return EMPTY;

        return of(accountApiActions.loadAccount({ id }));
      }),
    );
  });

  readonly loadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountApiActions.loadAccount),
      exhaustMap(({ id }) =>
        this.accountProvider.get(id).pipe(
          take(1),
          map((account) => accountApiActions.loadAccountSuccess({ account })),
          catchError((error) => of(accountApiActions.loadAccountFailure({ errorMsg: error.message }))),
        ),
      ),
    );
  });

  readonly reloadCurrentAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.reloadCurrentAccount),
      switchMap(() => {
        // wait for the current account ID to be available
        return this.store.select(accountFeature.selectCurrentAccountId).pipe(
          filterNil(),
          take(1),
          map((id) => accountApiActions.loadAccount({ id })),
        );
      }),
    );
  });

  readonly reloadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.reloadAccount),
      map(({ id }) => accountApiActions.loadAccount({ id })),
    );
  });

  readonly updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountApiActions.updateAccount),
      exhaustMap(({ id, account }) =>
        this.accountProvider.update(id, account).pipe(
          take(1),
          map((account) => accountApiActions.updateAccountSuccess({ account })),
          catchError((error) => of(accountApiActions.updateAccountFailure({ errorMsg: error.message }))),
        ),
      ),
    );
  });

  readonly createAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountApiActions.createAccount),
      exhaustMap(({ account }) =>
        this.accountProvider.create(account).pipe(
          take(1),
          map((account) => accountApiActions.createAccountSuccess({ account })),
          catchError((error) => of(accountApiActions.createAccountFailure({ errorMsg: error.message }))),
        ),
      ),
    );
  });

  readonly deleteAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountApiActions.deleteAccount),
      exhaustMap(({ id }) =>
        this.accountProvider.delete(id).pipe(
          take(1),
          switchMap(() => {
            return this.eventService
              .post({
                name: 'account-delete',
                category: EventDataCategory.Navigation,
                data: `Deleted account ID: ${id}`,
              })
              .pipe(catchError(() => of(null)));
          }),
          map(() => accountApiActions.deleteAccountSuccess({ id })),
          catchError((error) => of(accountApiActions.deleteAccountFailure({ errorMsg: error.message }))),
        ),
      ),
    );
  });

  readonly updateMetadataPartial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountApiActions.updateMetadataPartial),
      concatLatestFrom(({ id }) => this.accountStoreFacade.account(id)),
      map(([{ id, metadata }, account]) => {
        if (Utils.isNil(account)) return null;

        return { id, metadata: { ...account.meta, ...metadata } } as const;
      }),
      filterNil(),
      map(({ id, metadata }) => accountApiActions.updateMetadata({ id, metadata })),
    );
  });

  readonly updateMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountApiActions.updateMetadata),
      exhaustMap(({ id, metadata }) =>
        this.accountProvider
          .update(id, {
            meta: metadata,
          })
          .pipe(
            take(1),
            map((account) => accountApiActions.updateMetadataSuccess({ account })),
            catchError((error) => of(accountApiActions.updateMetadataFailure({ errorMsg: error.message }))),
          ),
      ),
    );
  });

  readonly updateWithdrawalAmount = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.updateCurrentAccountWithdrawalAmount),
      concatLatestFrom(() => this.accountStoreFacade.account$),
      map(([{ amount }, account]) => {
        if (Utils.isNil(account)) return null;

        return [account, amount] as const;
      }),
      filterNil(),
      map(([account, amount]) => {
        return accountCommonActions.updateAccountInStore({
          account: { ...account, current_available_for_withdrawal_value: Number(account.current_available_for_withdrawal_value) + amount },
        });
      }),
    );
  });

  readonly decrementOpenTaskCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.decrementOpenTaskCount),
      concatLatestFrom(() => this.accountStoreFacade.account$),
      map(([, account]) => account),
      filterNil(),
      map((account) => {
        return accountCommonActions.updateAccountInStore({
          account: { ...account, open_task_count: account.open_task_count - 1 },
        });
      }),
    );
  });

  readonly decrementUnreadTaskCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.decrementCurrentAccountUnreadInboxCount),
      concatLatestFrom(() => this.accountStoreFacade.account$),
      map(([, account]) => account),
      filterNil(),
      map((account) => {
        return accountCommonActions.updateAccountInStore({
          account: { ...account, unread_inbox_count: account.unread_inbox_count - 1 },
        });
      }),
    );
  });

  readonly updateInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.updateInvitation),
      concatLatestFrom(({ id }) => this.accountStoreFacade.account(id)),
      map(([{ invitation }, account]) => {
        if (Utils.isNil(account)) return null;

        return [invitation, account] as const;
      }),
      filterNil(),
      map(([invitation, account]) => {
        return accountCommonActions.updateAccountInStore({
          account: {
            ...account,
            invitation,
          },
        });
      }),
    );
  });

  readonly deleteInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCommonActions.deleteInvitation),
      concatLatestFrom(({ id }) => this.accountStoreFacade.account(id)),
      map(([, account]) => {
        if (Utils.isNil(account)) return null;

        return account;
      }),
      filterNil(),
      map((account) => {
        return accountCommonActions.updateAccountInStore({
          account: {
            ...account,
            invitation: undefined,
          },
        });
      }),
    );
  });

  readonly updateAccountList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          accountApiActions.loadAccountSuccess,
          accountApiActions.updateAccountSuccess,
          accountCommonActions.updateAccountInStore,
          accountApiActions.updateMetadataSuccess,
        ),
        tap(({ account }) => this.visibleAccountsListStoreFacade.dispatchUpdateAccountInStore(account)),
      );
    },
    { dispatch: false },
  );

  readonly addToAccountList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(accountApiActions.createAccountSuccess),
        tap(({ account }) => this.visibleAccountsListStoreFacade.dispatchAddAccountToStore(account)),
      );
    },
    { dispatch: false },
  );

  readonly removeFromAccountList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(accountApiActions.deleteAccountSuccess),
        tap(({ id }) => this.visibleAccountsListStoreFacade.dispatchRemoveAccountFromStore(id)),
      );
    },
    { dispatch: false },
  );

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => accountCommonActions.clear()));
  });
}
