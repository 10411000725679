import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { iDirectDebit } from '@semmie/schemas/bi/payment';

export const directDebitsCommonActions = createActionGroup({
  source: 'Direct Debits common',
  events: {
    Clear: emptyProps(),
    'Update direct debits in store': props<{ accountId: string; directDebits: iDirectDebit[] }>(),
  },
});
