import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { PickerController, PickerOptions } from '@ionic/angular';

import { Icon } from '@semmie/schemas';
import { PlatformService } from '@semmie/services/platform/platform.service';

import { BaseFormComponent } from '@semmie/components/_abstract/base-form-component.component';
import { iDropdownImageStyle, iDropdownOption } from '@semmie/schemas/components/dropdown/dropdown-option.interface';
import { Utils } from '@onyxx/utility/general';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { ModalService } from '@semmie/services';
import { iDropdownOptions, iDropDownSelection, iDropdownOptionsConfiguration } from '@semmie/schemas/components/dynamic-form';
import { TranslateService } from '@ngx-translate/core';
import { ModalSize } from '@semmie/schemas/components/modal';
import { NumberExt } from '@semmie/shared/numberext';

@Component({
  selector: 'semmie-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent extends BaseFormComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() options: iDropdownOption[] | iDropdownOptions | iDropdownOptionsConfiguration;
  @Input() selection: iDropDownSelection;
  @Input() preselect: boolean;
  @Input() loading = false;
  @Input() infoModal?: iInfoModal;

  readonly Icon = Icon;
  readonly iDropdownImageStyle = iDropdownImageStyle;

  selectItems: iDropdownOption[];
  selectedOption: iDropdownOption | null = null;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private cdr: ChangeDetectorRef,
    private platformService: PlatformService,
    private pickerController: PickerController,
    private modalService: ModalService,
    private translate: TranslateService,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options && !Utils.isEqual(changes?.options.previousValue, changes?.options.currentValue)) {
      this.setOptions(changes?.options?.currentValue);
    }
  }

  get isApp(): boolean {
    return this.platformService.isRunningOn('app');
  }

  open() {
    if (this.disabled) return;
    if (this.selection?.type === 'modal') {
      this.showModal();
    } else {
      this.showPicker();
    }
  }

  async showModal() {
    if (Array.isArray(this.selectItems)) {
      const normalizedOptions = this.selectItems.map((o, idx) => {
        return { ...o, id: o?.value ?? idx };
      });

      const modal = await this.modalService.openSelectionModal({ title: this.placeholder }, ModalSize.Full, normalizedOptions, {
        mode: this.selection?.type === 'modal' ? 'scrollable' : 'fixed',
        search: this.selection?.search,
      });

      const { data: result } = await modal.onDidDismiss<any>();

      if (result) {
        this.selectedOption = result;
        this.value = result?.value;
      }

      this.cdr.markForCheck();
    }
  }

  async showPicker() {
    const options: PickerOptions = {
      buttons: [
        {
          text: this.translate.instant('core.common.labels.cancel'),
          role: 'cancel',
        },
        {
          text: this.translate.instant('core.common.labels.confirm'),
          handler: (value: any) => {
            this.updateValue(value.option.value);
          },
        },
      ],
      columns: [
        {
          name: 'option',
          options: (this.selectItems as any as iDropdownOption[]).map((item: iDropdownOption) => ({
            text: this.translate.instant(item.label),
            value: item.value,
            image: item.image,
            imageStyle: item.imageStyle,
          })),
          selectedIndex:
            (this.selectItems as any as iDropdownOption[]).findIndex((item: iDropdownOption) => item.value === this.value) ?? null,
        },
      ],
      cssClass: 'onyxx-picker',
    };

    // TODO: the picker controller is deprecated, use the inline picker instead.
    const picker = await this.pickerController.create(options);
    await picker.present();
  }

  onSelectChange(value: string): void {
    const parsedValue = JSON.parse(value);
    this.updateValue(parsedValue);
  }

  private updateValue(value: unknown): void {
    this.selectedOption = this.selectItems.find((s) => Utils.isEqual(s.value, value)) ?? null;
    this.value = value;

    // The change detection should run in the setTimeout to fix SD-3812. Otherwise the dependent
    // drop down changes (in occupation form) does not trigger form validity changes.
    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

  private setOptions(options: iDropdownOption[] | iDropdownOptions | iDropdownOptionsConfiguration): void {
    if (options && 'source' in options) {
      this.loading = !!options.source;
    }

    if (options && Array.isArray(options)) {
      this.loading = false;
      const selectItems: Array<iDropdownOption> = [];

      options
        .filter((option) => !option.deprecated)
        .forEach((option) => {
          selectItems.push({
            label: this.translate.instant(option.label),
            value: option.value,
            valueRange: option.valueRange,
            disabled: option.disabled,
            image: option?.image ? this.platformService?.resolveAssetPath(option?.image) : null,
            imageStyle: option?.imageStyle,
          });
        });

      if (JSON.stringify(selectItems) === JSON.stringify(this.selectItems)) return;

      this.selectItems = selectItems;

      const selectedItem =
        this.selectItems.find((item) => {
          return (
            Utils.isEqual(item.value, this.value) ||
            (Utils.isNotNil(this.value) && item.valueRange && NumberExt.inRange(this.value, item.valueRange))
          );
        }) ?? null;

      this.selectedOption = null;

      if (selectedItem) {
        this.selectedOption = selectedItem;
        this.value = this.selectedOption.value;
      } else if (this.preselect && selectItems.length === 1) {
        this.selectedOption = this.selectItems[0];
        this.value = this.selectedOption?.value;
      } else if (!selectedItem && Utils.isNotNil(this.value)) {
        /** When dropdown has a value but the option is not available */
        this.value = null;
      }
    }
  }
}
