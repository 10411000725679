import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { phoneNumberApiActions } from './phone-number-api.actions';
import { phoneNumberCommonActions } from './phone-number-common.actions';
import { Utils } from '@onyxx/utility/general';
import { PhoneNumberVerificationDetails } from './phone-number-verification-details';

export interface State {
  pendingPhoneVerification: PhoneNumberVerificationDetails | null;
  verificationBusy: boolean;
  resendAllowedAt: number | null;
}

const PHONE_VERIFY_RESEND_TIMEOUT = 30_000; // 30 seconds

const initialState: State = {
  pendingPhoneVerification: null,
  verificationBusy: false,
  resendAllowedAt: null,
};

const reducer = createReducer(
  initialState,
  on(phoneNumberCommonActions.clear, (): State => initialState),

  on(phoneNumberApiActions.verify, (state): State => ({ ...state, verificationBusy: true })),
  on(
    phoneNumberApiActions.verifySuccess,
    (state): State => ({
      ...state,
      verificationBusy: false,
      pendingPhoneVerification: null,
    }),
  ),
  on(phoneNumberApiActions.verifyFailure, (state): State => ({ ...state, verificationBusy: false })),

  on(phoneNumberApiActions.resendCodeSuccess, (state): State => ({ ...state, resendAllowedAt: Date.now() + PHONE_VERIFY_RESEND_TIMEOUT })),

  on(
    phoneNumberCommonActions.setResendAllowedAt,
    (state): State => ({ ...state, resendAllowedAt: Date.now() + PHONE_VERIFY_RESEND_TIMEOUT }),
  ),

  on(phoneNumberCommonActions.setupVerification, (state, details): State => {
    return {
      ...state,
      pendingPhoneVerification: details,
    };
  }),
);

export const phoneNumberFeature = createFeature({
  name: 'phone number',
  reducer,

  extraSelectors: ({ selectPendingPhoneVerification }) => ({
    selectPhoneNumberPendingVerification: createSelector(selectPendingPhoneVerification, (pendingVerification) =>
      Utils.isNil(pendingVerification)
        ? null
        : {
            country_phone: pendingVerification.country_phone,
            phone: pendingVerification.phone,
          },
    ),
    selectPhoneNumberPendingVerificationPersonId: createSelector(selectPendingPhoneVerification, (pendingVerification) =>
      Utils.isNil(pendingVerification) ? null : pendingVerification.personId,
    ),
  }),
});
