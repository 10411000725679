import { createFeature, createReducer, on } from '@ngrx/store';
import { Payout } from '@onyxx/model/payout';
import { payoutApiActions } from './payout-api.actions';
import { payoutCommonActions } from './payout-common.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  payout: Payout | null;
}

const initialState: State = { loading: false, loaded: false, payout: null };

const reducer = createReducer(
  initialState,
  on(payoutApiActions.load, (state): State => ({ ...state, loading: true })),
  on(payoutApiActions.loadSuccess, (_, action): State => ({ payout: action.payout, loaded: true, loading: false })),
  on(payoutApiActions.loadFailure, (state): State => ({ ...state, loading: false })),

  on(payoutApiActions.create, (state): State => ({ ...state, loading: true })),
  on(payoutApiActions.createSuccess, ({ payout }): State => ({ payout, loading: false, loaded: true })),
  on(payoutApiActions.createFailure, (state): State => ({ ...state, loading: false })),
  on(payoutCommonActions.clear, (): State => initialState),
);

export const payoutFeature = createFeature({
  name: 'payout',
  reducer,
});
