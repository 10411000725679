import { Injectable } from '@angular/core';
import { Goal } from '@semmie/models';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { Utils } from '@semmie/shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoalProvider extends BaseProvider<Goal> {
  constructor() {
    super('goal');
  }
  calculate(params: any): Observable<Goal> {
    return this.__query(`goal/calculate?${Utils.urlEncodeObject(params)}`);
  }
}
