<semmie-modal-scroll>
  <semmie-modal-scroll-content>
    <semmie-modal-header (onCloseClick)="closeModal(AccountStatusModalCallback.CLOSE)"></semmie-modal-header>
    <semmie-content-container size="lg" padding="px-10">
      <semmie-content-container-top>
        <semmie-header
          [title]="statusMessage$$()?.title ?? ''"
          [label]="statusMessage$$()?.label ?? ''"
          [description]="statusMessage$$()?.description ?? ''"
        ></semmie-header>
      </semmie-content-container-top>

      @if (statusMessage$$()?.illustration) {
        <div class="content-image">
          <semmie-image [width]="240" [src]="statusMessage$$()?.illustration"></semmie-image>
        </div>
      }
      @if (statusMessage$$()?.footer || statusMessage$$()?.button) {
        <semmie-content-container-bottom>
          @if (statusMessage$$()?.footer) {
            <semmie-content-footer-text [htmlContent]="statusMessage$$()?.footer"></semmie-content-footer-text>
          }
          @if (statusMessage$$()?.button) {
            <semmie-button (click)="closeModal(statusMessage$$()?.closeCallback)">{{ statusMessage$$()?.button }}</semmie-button>
          }
        </semmie-content-container-bottom>
      }
    </semmie-content-container>
  </semmie-modal-scroll-content>
</semmie-modal-scroll>
