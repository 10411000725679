import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';
import { LabelSize } from '@semmie/schemas';
import { ChatService } from '@semmie/services/chat/chat.service';
import { InterpolateService } from '@semmie/services/interpolate/interpolate.service';
import { Sanitizer } from '@semmie/shared/sanitizer';

@Component({
  selector: 'semmie-label',
  template: `
    @if (useContentProjection) {
      <div [class]="getStyling()">
        <ng-content></ng-content>
      </div>
    } @else {
      <div #labelRef [ngClass]="{ 'html-content': !!htmlContent }" (click)="onClickHandler($event)"></div>
    }
  `,
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('labelRef', { static: false }) labelRef: ElementRef<HTMLDivElement>;

  @Input() class: string;
  @Input() type: 'display' | 'heading' | 'subHeading' | 'text' | 'small' = 'text';
  @Input() weight: 'light' | 'regular' | 'medium' = 'regular';
  @Input() text?: string | null = '';
  @Input() htmlContent?: string | null;
  @Input() size: LabelSize;
  @Input() align: 'left' | 'center' | 'right' | null = null;
  @Input() link = false;
  @Input() useContentProjection = false;

  @Output() onClick: EventEmitter<Event> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private interpolateService: InterpolateService,
    private chatService: ChatService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.class) this.class += ' ';
  }

  ngAfterViewInit(): void {
    if (this.useContentProjection) return;

    const elem = this.renderer.createElement(this.getElementType(this.type));

    this.renderer.setAttribute(elem, 'class', this.getStyling());
    if (this.htmlContent) {
      this.renderer.setProperty(elem, 'innerHTML', Sanitizer.sanitize(this.content));
    } else {
      const text = this.renderer.createText(Sanitizer.sanitize(this.content));
      this.renderer.appendChild(elem, text);
    }
    this.renderer.appendChild(this.labelRef?.nativeElement, elem);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.useContentProjection) return;

    const elem = this.labelRef?.nativeElement?.firstChild;
    const keysInChanges = Object.keys(changes);

    if (elem && ['type', 'weight', 'size', 'align', 'class'].some((key) => keysInChanges.includes(key))) {
      this.renderer.setAttribute(elem, 'class', this.getStyling());
    }
    if ((changes?.text || changes?.htmlContent) && elem) {
      elem.textContent = '';
      if (this.htmlContent) {
        this.renderer.setProperty(elem, 'innerHTML', Sanitizer.sanitize(this.content));
      } else {
        const text = this.renderer.createText(Sanitizer.sanitize(this.content));
        this.renderer.appendChild(elem, text);
      }
    }
  }

  get content(): string {
    return this.interpolateService.renderString(this.htmlContent ?? this.text ?? '', {});
  }

  getStyling() {
    const classes = new Array<string>();

    if (this.size) classes.push(this.size);
    else classes.push(this.getDefaultSizeForType(this.type));

    if (this.weight) classes.push(this.weight);
    if (this.align) classes.push(this.align);
    if (this.link) classes.push('link');
    if (this.class) this.class.split(' ').forEach((c) => classes.push(c));

    classes.push(this.class);

    return classes.join(' ');
  }

  onClickHandler($event): void {
    if ($event.target.hasAttribute('data-openChat')) {
      this.chatService.openChat();
    }
    this.onClick.emit($event);
  }

  private getElementType(type: string): string {
    switch (type) {
      case 'heading':
        return this.size ?? this.getDefaultSizeForType(this.type);
      case 'subHeading':
      case 'display':
        return 'div';
      case 'text':
      case 'small':
      default:
        return 'p';
    }
  }

  private getDefaultSizeForType(type: string): string {
    switch (type) {
      case 'heading':
        return 'h1';
      case 'subHeading':
        return 'sub_heading';
      case 'display':
        return 'display';
      case 'small':
        return 'small';
      case 'text':
      default:
        return 'base_2';
    }
  }
}
