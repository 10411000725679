import { Injectable } from '@angular/core';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { SemmiePercentagePipe } from '@semmie/pipes/percentage/percentage.pipe';
import { scenarioData } from '@semmie/services/questionnaire/questionnaire-scenario.data';
import { QuestionnaireService } from '@semmie/services/questionnaire/questionnaire.service';

@Injectable({ providedIn: 'root' })
export class QuestionnaireScenarioService {
  projection: Map<number, { from: number; to: number }> = new Map();
  scenarios: Map<number, any> = new Map();

  constructor(
    private semmieCurrencyPipe: SemmieCurrencyPipe,
    private semmiePercentagePipe: SemmiePercentagePipe,
    private questionnaireService: QuestionnaireService,
  ) {
    // TODO(questionnaire): retrieve from endpoint instead
    Object.values(scenarioData).forEach((scenarios) =>
      scenarios.forEach((scenario) => {
        this.createProjection(scenario.id, scenario.projection.from, scenario.projection.to);
        this.scenarios.set(scenario.id, scenario);
      }),
    );
  }

  createProjection(id: number, from: number, to: number): void {
    this.projection.set(id, { from, to });
  }

  projectionByValue(value: number): any {
    return this.projection.get(value);
  }

  scenarioByValue(value: any): any {
    return this.scenarios.get(value);
  }

  /**
   * Calculate the projection and percentage for the scenarios and set them in the questionnaire data store
   * @param scenario
   * @param deposit
   */
  setScenarioDeposit(scenario: string, deposit: number): void {
    const questionnaireData = this.questionnaireService.questionnaireData;
    const depositData = questionnaireData.deposits;
    let currentScenarioIndex = 0;
    const currentScenario = depositData.find((item, index) => {
      if (item.question === scenario) {
        currentScenarioIndex = index;
        return true;
      }
      return false;
    });
    const projection = this.projectionByValue(deposit);
    const percentage = (projection.from + projection.to) / 2;
    const projectionMultiplier = percentage / 100;
    const result = currentScenario.deposit * (1 + projectionMultiplier);

    depositData[currentScenarioIndex + 1] = {
      ...depositData[currentScenarioIndex + 1],
      deposit: result,
      depositDisplay: this.semmieCurrencyPipe.transform(result),
      percentage: projectionMultiplier,
      percentageDisplay: this.semmiePercentagePipe.transform(percentage),
    };

    questionnaireData.deposits = depositData;
    this.questionnaireService.updateQuestionnaireData(questionnaireData);
  }
}
