export enum DynamicFieldType {
  CarouselSelect = 'carousel-select',
  Checkbox = 'checkbox',
  Date = 'date',
  Dropdown = 'dropdown',
  ExternalData = 'external_data',
  Static = 'static',
  Group = 'group',
  IconCards = 'icon-cards',
  Radio = 'radio',
  Slider = 'slider',
  Toggle = 'toggle',
  Upload = 'upload',
  Label = 'label',
  AutocompleteList = 'autocomplete-list',
  AutocompleteInput = 'autocomplete-input',
  Modal = 'modal',
  InputCard = 'input-card',
  Image = 'image',
  CoverImage = 'cover-image',
  Text = 'text',
  Textarea = 'textarea',
  Graph = 'graph',
  PayoutSummary = 'payout-summary',
}
