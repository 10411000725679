export enum PaymentStateEnum {
  NEW = 'new',
  BUSY = 'busy',
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  /** With direct debits. Handle the same at `BUSY` */
  OPEN = 'open',
}
