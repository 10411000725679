export enum AccountTaskName {
  CONFIGURE_STRATEGY = 'configure_strategy',
  CONFIGURE_GOAL = 'configure_goal',
}

export enum UserTaskName {
  // TODO: Extend this to all user task names
  CDD_PERSON = 'cdd_person_re_examination',
  CDD_ORGANISATION = 'cdd_organisation_re_examination',
  OPENING_PROFILE_RENEWAL = 'opening_profile_renewal',
  PHONE_VERIFICATION = 'phone_verification',
  UPLOAD = 'upload',
}
