import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';
import { Invitation } from '@semmie/models/bi/invitation';

export const accountCommonActions = createActionGroup({
  source: 'Account Common',
  events: {
    'Clear current account': emptyProps(),
    'Set current account': props<{ id: Account['id'] }>(),
    'Reload current account': emptyProps(),
    'Reload account': props<{ id: Account['id'] }>(),
    'Ensure account is loaded': props<{ id: Account['id'] }>(),
    'Update current account withdrawal amount': props<{ amount: number }>(),
    'Decrement open task count': props<{ id: Account['id'] }>(),
    'Decrement current account unread inbox count': emptyProps(),
    'Update invitation': props<{ id: Account['id']; invitation: Invitation }>(),
    'Delete invitation': props<{ id: Account['id'] }>(),
    'Update account in store': props<{ account: Account }>(),
    'Bust account cache': props<{ id: string }>(),

    Clear: emptyProps(),
  },
});
