import { NgModule } from '@angular/core';
import { PageHeaderRightComponent } from '@semmie/components/containers/page-header/page-header-right/page-header-right.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { SharedModule } from '@semmie/shared/shared.module';

import { PageHeaderComponent } from './page-header.component';

@NgModule({
  declarations: [PageHeaderComponent, PageHeaderRightComponent],
  imports: [SharedModule, IconComponent, LabelModule],
  exports: [PageHeaderComponent, PageHeaderRightComponent],
})
export class PageHeaderModule {}
