import { Route } from '@angular/router';
import { AppRouteNames } from '@onyxx/model/main';
import { IsMobileGuard } from '@semmie/guards';

export const unauthenticatedAreaRoutes: Route[] = [
  {
    path: AppRouteNames.Register,
    loadChildren: () => import('@semmie/views/authentication/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: AppRouteNames.Landing,
    loadChildren: () => import('@semmie/views/landing/landing.module').then((m) => m.LandingModule),
    canActivate: [IsMobileGuard],
  },
  {
    path: AppRouteNames.Login,
    loadChildren: () => import('@semmie/views/authentication/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: AppRouteNames.TwoFactor,
    loadChildren: () => import('@onyxx/feature/auth').then((m) => m.twoFactorValidateRoutes),
  },
];
