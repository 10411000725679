import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { accountAdditionalDataCommonActions } from './account-additional-data-common.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountAdditionalData } from '@onyxx/model/account';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<AccountAdditionalData> {}

const adapter = createEntityAdapter<AccountAdditionalData>();

const initialState: State = adapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(accountAdditionalDataCommonActions.setAdditionalData, (state, { additionalData }): State => adapter.addOne(additionalData, state)),
  on(accountAdditionalDataCommonActions.clear, (): State => initialState),
);

export const accountAdditionalDataFeature = createFeature({
  name: 'accountAdditionalData',
  reducer,
  extraSelectors: ({ selectAccountAdditionalDataState, selectEntities }) => ({
    ...adapter.getSelectors(selectAccountAdditionalDataState),
    selectAdditionalDataById: (id: string) => createSelector(selectEntities, (additionalData) => additionalData[id]),
  }),
});
