<semmie-content-container size="lg" [padding]="padding">
  <ng-template *ngIf="pageHeader" #pageHeaderIntro>
    <semmie-page-header *ngTemplateOutlet="pageHeader"></semmie-page-header>
  </ng-template>

  <semmie-content-container-top>
    <semmie-header [label]="label" [title]="title" [description]="description"></semmie-header>
  </semmie-content-container-top>

  <ng-content></ng-content>
  <div *ngIf="image" class="content-image">
    <semmie-image [width]="imageWidth" [ngClass]="{ imageElevation: imageElevation }" [src]="image"></semmie-image>
  </div>

  <semmie-content-container-bottom>
    <ng-content select="[slot=footer]"></ng-content>
    <footer *ngIf="footer?.image" class="px-6">
      <semmie-image class="h-1/4" [src]="footer.image"></semmie-image>
    </footer>
    <semmie-content-footer-text *ngIf="footer?.text" [htmlContent]="footer.text"></semmie-content-footer-text>
    <ng-container *ngIf="!confirm; else confirmation">
      <semmie-button *ngIf="button" [loading]="buttonLoading" [disabled]="buttonDisabled" (onClick)="onButtonClickHandler($event)">
        {{ button }}
      </semmie-button>
    </ng-container>
    <semmie-content-footer-link
      *ngIf="link_label"
      [text]="link_label"
      (onClick)="onLinkLabelClickHandler($event)"
    ></semmie-content-footer-link>
  </semmie-content-container-bottom>
</semmie-content-container>

<ng-template #confirmation>
  <div class="w-full">
    <semmie-toggle #toggle cardStyle="neutralElevated" [label]="confirm" class="w-full"></semmie-toggle>
    <div class="mt-10 text-center">
      <semmie-button
        *ngIf="button"
        (onClick)="onButtonClickHandler($event)"
        [disabled]="buttonDisabled || !!(confirm && !toggle?.value)"
        [loading]="buttonLoading"
        >{{ button }}</semmie-button
      >
    </div>
  </div>
</ng-template>
