import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Account, AccountHelpers } from '@onyxx/model/account';
import { QuestionnaireProvider } from '@semmie/providers/questionnaire/questionnaire.provider';
import { iUser } from '@onyxx/model/user';
import { QuestionnaireStore } from '@semmie/store/questionnaire/questionnaire.store';
import {
  CreateQuestionnaireRequestPayload,
  iQuestionnaire,
  GetQuestionnairePayload,
  QuestionnaireSubject,
} from '@onyxx/model/questionnaire';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(
    private questionnaireProvider: QuestionnaireProvider,
    private questionnaireStore: QuestionnaireStore,
  ) {}

  get questionnaireData(): any {
    return this.questionnaireStore.questionnaire.value;
  }

  createQuestionnaire({
    subject,
    answers,
    organisationId,
    isOrganisationSubject,
    personId,
    isAdvisorUser,
  }: CreateQuestionnaireRequestPayload): Observable<iQuestionnaire> {
    const questionnaire = { questionnaire: { answers } };

    if (organisationId && isOrganisationSubject) {
      return this.questionnaireProvider.createOrganisationQuestionnaire({
        subject,
        questionnaire,
        organisationId,
      });
    } else if (personId && isAdvisorUser) {
      return this.questionnaireProvider.createPersonQuestionnaire({
        subject,
        questionnaire,
        personId,
      });
    }

    return this.questionnaireProvider.create({
      subject,
      questionnaire,
    });
  }

  getBySubject({
    subject,
    refresh,
    organisationId,
    isOrganisationSubject,
    personId,
    isAdvisorUser,
  }: GetQuestionnairePayload): Observable<iQuestionnaire> {
    return this.questionnaireStore.store$.pipe(
      take(1),
      switchMap((store) => {
        const questionnaire = store?.get(subject);

        let questionnaireSource = this.questionnaireProvider.getBySubject({
          subject,
        });

        if (questionnaire && !refresh) {
          questionnaireSource = of(questionnaire);
        }

        if (organisationId && isOrganisationSubject) {
          questionnaireSource = this.questionnaireProvider.getOrganisationQuestionnaire({
            subject,
            organisationId,
          });
        } else if (personId && isAdvisorUser) {
          questionnaireSource = this.questionnaireProvider.getPersonQuestionnaire({
            subject,
            personId,
          });
        }

        return questionnaireSource.pipe(
          map((questionnaire) => ({
            ...questionnaire,
            disableTimer: subject === QuestionnaireSubject.SUSTAINABILITY_PREFERENCE,
          })),
        );
      }),
    );
  }

  /**
   * Provide the unfinished questionnaire subject
   * @param account {Account}
   * @param user {iUser}
   * @param customSteps {QuestionnaireSubject[]} only check for specified steps, otherwise use default
   * @returns {QuestionnaireSubject | null}
   */
  getUnfinishedSubject(account: Account, user?: iUser, customSteps?: QuestionnaireSubject[]): QuestionnaireSubject | null {
    const questionnaireSubjectOrder = customSteps || [
      QuestionnaireSubject.KNOWLEDGE_EXPERIENCE,
      QuestionnaireSubject.RISK_APPETITE,
      QuestionnaireSubject.SUSTAINABILITY_PREFERENCE,
    ];
    let result: QuestionnaireSubject | null = null;

    for (const subject of questionnaireSubjectOrder) {
      let complete = user?.questionnaires?.[subject];
      if (
        AccountHelpers.hasOrganisation(account) &&
        account.organisation?.questionnaires &&
        subject in account.organisation.questionnaires
      ) {
        complete = account.organisation.questionnaires?.[subject];
      }
      if (!complete) {
        result = subject;
        break;
      }
    }

    return result;
  }

  /**
   * Reset the questionnaire data in the store to its initial value
   */
  resetQuestionnaireData(): void {
    this.questionnaireStore.resetQuestionnaireData();
  }

  /**
   * Save the form values to the store
   * @param questions keys
   * @param values values
   */
  setQuestionValuesToStore(questions: string[], values: any[]): void {
    const answers = {};
    questions.forEach((question, index) => {
      answers[question] = values[index];
    });

    const questionnaireData = this.questionnaireData;
    questionnaireData.values = { ...questionnaireData.values, ...answers };
    this.updateQuestionnaireData(questionnaireData);
  }

  /**
   * Update the questionnaire data in the store
   * @param data
   */
  updateQuestionnaireData(data: any): void {
    this.questionnaireStore.updateQuestionnaireData(data);
  }
}
