import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Camera, CameraPermissionType, CameraResultType, CameraSource, PermissionStatus } from '@capacitor/camera';

import { Utils } from '@semmie/shared/utils';
import { DialogService } from '@semmie/services/dialog/dialog.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

export const enum ImageSources {
  Camera = 'camera',
  Library = 'library',
}

@Injectable({ providedIn: 'root' })
export class CameraService {
  private cameraPermissionsGranted$$ = new BehaviorSubject<boolean | null>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  cameraPermissionsGranted$ = this.cameraPermissionsGranted$$.asObservable().pipe(filter(Utils.isNonNullOrUndefined));

  constructor(
    private dialog: DialogService,
    private platformService: PlatformService,
    private translateService: TranslateService,
  ) {
    if (this.platformService.isApp) {
      this.checkPermissions();
    }
  }

  async checkPermissions() {
    const { camera } = await Camera.checkPermissions();
    this.cameraPermissionsGranted$$.next(camera === 'granted');
  }

  async requestPermissions() {
    const { camera } = await Camera.requestPermissions({
      permissions: ['camera'],
    });
    return camera === 'granted';
  }

  async openSettingsDialog(imageSource: ImageSources) {
    const translationKeys = new Map([
      [ImageSources.Camera, 'core.common.permissions.camera'],
      [ImageSources.Library, 'core.common.permissions.library'],
    ]);

    const { value } = await this.dialog.confirm({
      okButtonTitle: this.translateService.instant(translationKeys.get(imageSource) + '.denied.button'),
      message: this.translateService.instant(translationKeys.get(imageSource) + '.denied.message'),
      title: this.translateService.instant(translationKeys.get(imageSource) + '.denied.title'),
    });
    if (value) {
      await BarcodeScanner.openSettings();
    }
  }

  /** Check permissions and get a photo using the camera
   * @returns URI path to the file
   */
  async getPhotoFromCamera() {
    if (!(await this.checkSpecificPermissions(ImageSources.Camera))) {
      return false;
    }

    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      saveToGallery: false,
      allowEditing: false,
    });

    if (photo.path == null) {
      return false;
    }

    return photo.path;
  }

  /** Check permissions and get a photo from the library
   * @returns URI path to the file
   */
  async getPhotoFromLibrary(options: { multiple?: boolean } = {}) {
    if (!(await this.checkSpecificPermissions(ImageSources.Library))) {
      return false;
    }

    const images = await Camera.pickImages({
      limit: options?.multiple ? 0 : 1,
    });

    const photoPaths = images.photos.map((photo) => photo.path).filter((path): path is string => path != null);

    return photoPaths;
  }

  private async checkSpecificPermissions(imageSource: ImageSources) {
    if (!this.platformService.isApp) {
      return false;
    }

    const checkPermission = ({ camera, photos }: PermissionStatus) =>
      (imageSource === ImageSources.Camera && camera !== 'granted') ||
      (imageSource == ImageSources.Library && photos !== 'granted' && photos !== 'limited');

    const permissions = await Camera.checkPermissions();

    if (checkPermission(permissions)) {
      const permissionTypes = new Map<ImageSources, CameraPermissionType>([
        [ImageSources.Camera, 'camera'],
        [ImageSources.Library, 'photos'],
      ]);

      const permissions = await Camera.requestPermissions({
        permissions: [permissionTypes.get(imageSource) ?? 'camera'],
      });

      if (checkPermission(permissions)) {
        await this.openSettingsDialog(imageSource);
        return false;
      }
    }

    return true;
  }
}
