<semmie-card padding="px-6 py-6">
  <ng-container *rxLet="payout$$ as payout; suspense: loadingTpl">
    <ng-container *ngIf="!isLoading$$(); else loadingTpl">
      <ng-container *ngIf="payout?.frequency && payout?.amount; else noData">
        <div class="mb-1 flex flex-row">
          <semmie-label
            size="h5"
            weight="medium"
            i18n-text="@@payout.frequency.label"
            text="Expected gross payout per {{ frequencyDisplay(payout.frequency) }}"
          ></semmie-label>
          <onyxx-info-modal-icon (click)="openInfoModal()"></onyxx-info-modal-icon>
        </div>
        <semmie-label size="h2" weight="medium" [text]="payout.amount | semmieCurrency"></semmie-label>
        <hr class="my-6" />
        <div class="mb-1">
          <semmie-label size="h5" weight="medium" i18n-text="@@payout.terms.label" text="Number of terms"></semmie-label>
        </div>
        <semmie-label size="h2" weight="medium" [text]="payout.terms?.toString()"></semmie-label>
      </ng-container>
    </ng-container>
  </ng-container>
</semmie-card>

<ng-template #loadingTpl>
  <div class="flex-center h-40 flex-col">
    <div class="m-auto h-10 w-10">
      <semmie-loading-ripple position="inline"></semmie-loading-ripple>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <div class="flex flex-col p-8 text-center">
    <div class="m-auto">
      <semmie-image src="illustrations/small/plan.png" width="68" height="78"></semmie-image>
    </div>
    <semmie-label i18n-text="@@payout.no-data" text="No payout form data" type="text" align="center" class="error-message"></semmie-label>
  </div>
</ng-template>
