import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BoostersStoreActions } from '@semmie/store/boosters/actions/boosters-common.actions';

@Injectable()
export class BoostersStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  assignReward() {
    this.store.dispatch(BoostersStoreActions.assignReward());
  }
}
