import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Referral } from '@semmie/models';
import { Utils } from '@semmie/shared/utils';
import { ReferralApiActions } from '@semmie/store/referral/actions/referral-api.actions';
import { referralFeature } from '@semmie/store/referral/referral.reducer';
import { Observable, filter } from 'rxjs';

export type ReferralResponse = { canLoadMore: boolean; referrals: Referral[] };
@Injectable()
export class ReferralStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  readonly userReferralLoading$ = this.store.select(referralFeature.selectUserReferralLoading);
  readonly userReferral$ = this.store.select(referralFeature.selectUserReferral).pipe(filter(Utils.isNonNullOrUndefined));

  readonly referralsLoading$ = this.store.select(referralFeature.selectReferralsLoading);
  readonly referrals$: Observable<ReferralResponse> = this.store.select(referralFeature.selectReferralsWithLoadMore);

  dispatchLoadReferralsNextPage() {
    this.store.dispatch(ReferralApiActions.loadReferralsNextPage());
  }
}
