import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { iQuestions } from '@onyxx/model/questions';

export const QuestionsApiActions = createActionGroup({
  source: 'Questions API',
  events: {
    'Load Questions': emptyProps(),
    'Load Questions Success': props<{ questions: { person: iQuestions; organisation: iQuestions } }>(),
    'Load Questions Failure': props<{ error: Error }>(),
  },
});
