import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export abstract class BaseStore<T> {
  protected _store = new BehaviorSubject<T | null>(null);

  private _class_def: any;

  constructor(
    public initialState?: T,
    public _class?: any,
  ) {
    if (initialState) {
      this._store.next(initialState);
    }

    if (this._class) {
      this._class_def = this._class;
    }
  }

  get store() {
    return this._store;
  }

  get store$() {
    return this._store.asObservable();
  }

  select<R>(project: (store: T) => R): Observable<R> {
    return this._store.pipe(map(project), distinctUntilChanged());
  }

  patchState(partialStateOrUpdateFn: Partial<T | null> | ((state: T | null) => Partial<T>)): void {
    const prevState = this.value;

    const newState = typeof partialStateOrUpdateFn === 'function' ? partialStateOrUpdateFn(prevState) : partialStateOrUpdateFn;

    const combinedState = this._class_def ? new this._class_def({ ...prevState, ...newState }) : { ...prevState, ...newState };

    this._store.next(combinedState);
  }

  update(value: T | null) {
    this._store.next(value);
  }

  private get value() {
    return this._store.getValue();
  }
}
