import { ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function exprInvalidIf(compareValue: string | number | boolean | null, expressions: Array<boolean>): ValidatorFn {
  return (): ValidationErrors | null => {
    if (!expressions) return null;

    if (expressions.some((p) => p === compareValue)) {
      return new ValidatorMessage({
        code: 17,
        message: $localize`:@@validation.required:This is a required field.`,
      });
    }

    return null;
  };
}
