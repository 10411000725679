import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { PageHeaderModule } from '@semmie/components/containers/page-header/page-header.module';

import { ContentContainerComponent } from './content-container.component';
import { ContentContainerTopComponent } from '@semmie/components/containers/content-container/content-container-top/content-container-top.component';
import { ContentContainerBottomComponent } from '@semmie/components/containers/content-container/content-container-bottom/content-container-bottom.component';

import { ContentIntroTextComponent } from '@semmie/components/containers/page-content/content-intro-text/content-intro-text.component';
import { ContentFooterTextComponent } from '@semmie/components/containers/page-content/content-footer-text/content-footer-text.component';
import { ContentFooterLinkComponent } from '@semmie/components/containers/page-content/content-footer-link/content-footer-link.component';

@NgModule({
  declarations: [ContentContainerComponent, ContentContainerTopComponent, ContentContainerBottomComponent],
  imports: [CommonModule, IonicModule, PageHeaderModule, ContentIntroTextComponent, ContentFooterTextComponent, ContentFooterLinkComponent],
  exports: [
    ContentContainerComponent,
    ContentContainerTopComponent,
    ContentContainerBottomComponent,
    ContentIntroTextComponent,
    ContentFooterTextComponent,
    ContentFooterLinkComponent,
  ],
})
export class ContentContainerModule {}
