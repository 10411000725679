/**
 * An enum of all known AccountKinds
 * Currently the singular source of truth
 */
export enum AccountKind {
  PERSONAL = 'person_1',
  SHARED = 'person_2',
  CHILD = 'child_parent',
  GRANDPARENT = 'child_grandparents',
  ANNUITY = 'annuity',
  ANNUITY_PAYOUT_LONG = 'annuity_payout_long',
  ANNUITY_PAYOUT_SHORT = 'annuity_payout_short',
  ORGANISATION = 'organisation',
}

export const AccountKindWithAutoIncasso = [
  ...Object.values(AccountKind).filter((kind) => {
    return ![AccountKind.ANNUITY_PAYOUT_LONG, AccountKind.ANNUITY_PAYOUT_SHORT].includes(kind);
  }),
];
