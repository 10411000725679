<div class="input-container group">
  @if (label) {
    <semmie-form-input-label [formFieldId]="id" [infoModal]="infoModal" [label]="label"></semmie-form-input-label>
  }
  <div class="input-wrapper">
    <textarea
      #inputRef
      pInputTextarea
      [(ngModel)]="value"
      (ngModelChange)="onValueChange($event)"
      (blur)="onBlurHandler($event)"
      (focus)="onFocusHandler()"
      [id]="id"
      [disabled]="disabled"
      [class.has-errors]="error"
      [placeholder]="placeholder ?? label"
      [attr.autocomplete]="autocomplete"
      [attr.enterkeyhint]="enterKeyHint"
    ></textarea>
    <label class="label-hint">{{ labelHint }}</label>
  </div>
  <ng-container *ngIf="error">
    <p class="error-message">{{ error }}</p>
  </ng-container>
</div>
