import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { CarouselComponent } from '@semmie/components/presentational/core/carousel/carousel.component';

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, SwiperModule],
  exports: [CarouselComponent],
})
export class CarouselModule {}
