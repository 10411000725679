import { BehaviorSubject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { BaseStore } from '@semmie/models/_abstract';
import { MappedPagination, PaginatedResponse } from '@onyxx/model/pagination';

export class ListableStore<T> extends BaseStore<T> {
  list: BehaviorSubject<MappedPagination<T>> = new BehaviorSubject(new Map());

  get flatList$() {
    return this.list.pipe(
      mergeMap((items) => {
        const flattened: PaginatedResponse<T> = {
          data: [],
          meta: {
            current_page: 1,
            total_count: 0,
            total_pages: 1,
          },
        };

        if (items?.size) {
          items.forEach((a) => {
            flattened.meta = {
              current_page: a.meta.current_page,
              total_count: (flattened?.meta?.total_count ?? 0) + a.meta.total_count,
              total_pages: a.meta.total_pages || 1,
            };

            flattened.data = flattened.data.concat(a.data);
          });
        }

        return of(flattened);
      }),
    );
  }

  pushToCachedPage(item: T): void {
    const cachedPages = this.list.value;

    if (!cachedPages || !cachedPages.size) return;

    const total_pages = cachedPages.get(1)?.meta.total_pages || 1;

    if (total_pages === cachedPages.size) {
      cachedPages.get(cachedPages.size)?.data.push(item);
      this.list.next(cachedPages);
    }
  }

  getCachedPage(page: number) {
    const cachedPage = this.list.value.get(page);
    return cachedPage;
  }

  getFromCachedPageById(id: string): T | null {
    const cachedPages = this.list.value;
    for (const [, value] of cachedPages.entries()) {
      const item = value.data.find((a: any) => a.id === id);
      if (item) return item;
    }
    return null;
  }

  updateCachedPage(item: T): void {
    const cachedPages = this.list.value;

    if (!cachedPages.size) return;

    const cachedAccountMeta = this.getCachedListMeta(item['id'], cachedPages);
    cachedPages.get(cachedAccountMeta.page)?.data.splice(cachedAccountMeta.index, 1, item);
    this.list.next(cachedPages);
  }

  updateCachedPageByPage(page: number, accounts: PaginatedResponse<T>) {
    const cachedPages = this.list.value;
    cachedPages.set(page, accounts);
    this.list.next(cachedPages);
  }

  deleteFromCachedPagesById(id: string): void {
    const cachedPages = this.list.value;
    const cachedAccountMeta = this.getCachedListMeta(id, cachedPages);
    cachedPages.get(cachedAccountMeta.page)?.data.splice(cachedAccountMeta.index, 1);
    this.list.next(cachedPages);
  }

  clearList() {
    this.list.value.clear();
  }

  private getCachedListMeta(id: string, cachedList: MappedPagination<T>): { index: number; page: number } {
    let page = 1;
    let index = -1;

    cachedList.forEach((cachedAccount, key) => {
      const accIdx = cachedAccount.data?.findIndex((a: any) => a && a.id === id);

      if (accIdx > -1) {
        index = accIdx;
        page = key;
        return;
      }
    });

    return {
      index,
      page,
    };
  }
}
