import { PaymentConfigurationService } from '@semmie/services/payment-configuration/payment-configuration.service';
import { GoalService } from '@semmie/services/goal/goal.service';
import { QuestionsProvider } from '@onyxx/provider/questions';
import { CountryProvider } from '@onyxx/provider/countries';
import { AccountsService, OrganisationService } from '@semmie/services';
import { BankService } from '@semmie/services/bank/bank.service';

export const providers = [
  { provide: 'accountsService', useExisting: AccountsService },
  { provide: 'goalService', useExisting: GoalService },
  { provide: 'organisationService', useExisting: OrganisationService },
  { provide: 'paymentConfigurationService', useExisting: PaymentConfigurationService },
  { provide: 'bankService', useExisting: BankService },
  QuestionsProvider,
  CountryProvider,
];
