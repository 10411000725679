import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { authApiActions } from '../auth-api.actions';
import { defer, filter, map, switchMap, tap } from 'rxjs';
import { authCommonActions } from '../auth-common.actions';
import { AppStorageService } from '@semmie/services';
import { AuthSession } from '@onyxx/model/auth';
import { filterNil } from '@onyxx/utility/observables';
import { Utils } from '@onyxx/utility/general';
import { NavController } from '@ionic/angular';
import { AUTH_STORE_CONFIG } from '../auth-store-config.token';
import { Store } from '@ngrx/store';
import { authFeature } from '../auth.reducer';

export const enum AuthSessionStorageKey {
  OnyxxSession = '__onyxx_session__',
}

export class GeneralEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly appStorageService = inject(AppStorageService);
  private readonly navCtrl = inject(NavController);
  private readonly tokenStorage = this.appStorageService.createSecuredStorageReader<AuthSession>(AuthSessionStorageKey.OnyxxSession);
  private readonly configToken = inject(AUTH_STORE_CONFIG);
  private readonly config = inject(this.configToken);

  private readonly sessionStorage$ = defer(() => this.tokenStorage.get()).pipe(map((storedSession) => storedSession));

  readonly initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authCommonActions.initialize),
      concatLatestFrom(() => this.store.select(authFeature.selectSessionLoaded)),
      // prevent duplicate initialization
      filter(([, sessionLoaded]) => !sessionLoaded),
      switchMap(() => this.sessionStorage$),
      map((session) => authCommonActions.initializeSuccess({ session })),
    );
  });

  readonly storeSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          authCommonActions.initializeSuccess,
          authApiActions.loginSuccess,
          authApiActions.refreshTokenSuccess,
          authCommonActions.manualTokenRefreshSuccess,
        ),
        map(({ session }) => session),
        filterNil(),
        tap((session) => this.tokenStorage.set(session)),
      );
    },
    { dispatch: false },
  );

  readonly navigateOnAfterGettingNewSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.loginSuccess),
      switchMap(() =>
        defer(async () => {
          const url = await this.config.loginRedirectUrl();

          if (url.external) {
            Utils.openUrl(url.url, { newTab: false });
          } else {
            await this.navCtrl.navigateRoot(url.url, { replaceUrl: true, animated: false });
          }
        }),
      ),
      map(() => authCommonActions.logInNavigationDone()),
    );
  });
}
