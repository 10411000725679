import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  standalone: true,
  imports: [CommonModule, LabelModule, ImageModule, TranslateModule],
  selector: 'semmie-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankComponent extends BaseComponent {
  @Input() bankLogo: string;
  @Input() person: string;
  @Input() number: string;

  @HostBinding('class.active')
  @Input()
  active = true;
}
