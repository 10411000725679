import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { referralFeature } from '@semmie/store/referral/referral.reducer';
import { ReferralEffects } from '@semmie/store/referral/referral.effects';
import { ReferralStoreFacade } from '@semmie/store/referral/referral.facade';

/**
 * The providers array is used to provide the referral feature state, effects, and facade.
 * This can be used from standalone routing and modules, but not from standalone components.
 * In order to include it in standalone modules use the module grouping below.
 */
export const providers = [provideState(referralFeature), provideEffects(ReferralEffects), ReferralStoreFacade];

/**
 * The ReferralStoreModule class is an angular module that provides services for managing user state.
 */
@NgModule({ providers })
export class ReferralStoreModule {}

export * from './referral.facade';
