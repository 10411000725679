// TODO: Disabling for now until we asses how this impact the components is being used
/* eslint-disable no-useless-escape */

export const RegExpPatterns = {
  /**
   * Will match any number in the string
   */
  NUMBERS: /\d/,

  /**
   * Will match any character (upper- and lowercase) in the string
   */
  CHARACTERS: /[a-zA-Z]/,

  /**
   * Will match if the string only contains numbers in the string
   */
  NUMBERS_ONLY: /^\d+$/,

  /**
   * Will match if the string only contains characters in the string
   */
  CHARACTERS_ONLY: /^[a-zA-Z]+$/,

  /**
   * Will match if the string length is equal to 3
   */
  MINIMAL_THREE_NON_WHITESPACE_CHARACTERS: /^\S{3,}$/,

  /**
   * Will match if the string is a valid email address
   */
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  /**
   * Extract video ID's from youtube and vimeo urls
   */
  YOUTUBE_ID_EXTRACION: /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/,
  VIMEO_ID_EXTRACION: /^.*(?:vimeo.com\/|video\/)(\d+).*/,
  /**
   * Will match if the string has at least 8 characters
   */
  HAS_EIGHT_CHARACTERS: /^.{8,}$/,

  /**
   * Will match if the string has at least 1 uppercase letter
   */
  HAS_UPPERCASE_LETTER: /[A-Z]/,

  /**
   * Will match if the string has at least 1 lowercase letter
   */
  HAS_LOWERCASE_LETTER: /[a-z]/,

  /**
   * Will match if the string has at least 1 digit
   */
  HAS_DIGIT: /.*[0-9].*/,

  EXTRACT_UID: /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}?)/m,

  /**
   * Match a string that does **NOT** contain emoticons
   *
   * The regex uses Extended_Pictographic https://www.unicode.org/reports/tr51/#def_level1_emoji
   */
  WITHOUT_EMOTICONS: /^[^\p{Extended_Pictographic}]*$/u,
};
