import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { AuthSession, TWO_FACTOR_RESEND_TIMEOUT } from '@onyxx/model/auth';
import { authApiActions } from './auth-api.actions';
import { authCommonActions } from './auth-common.actions';
import { Observable, map } from 'rxjs';
import { Utils } from '@onyxx/utility/general';

export interface State {
  session: AuthSession | null;
  sessionLoaded: boolean;
  loginBusy: boolean;
  registerBusy: boolean;
  requestPasswordResetBusy: boolean;
  setPasswordBusy: boolean;
  appSecured: boolean;
  /** App is securing process is busy initializing e.g. a security modal is opening or navigation is in progress */
  appSecureInitializing: boolean;
  /** The app is being secured e.g. a modal is shown and waiting for user interaction */
  appSecureBusy: boolean;
  twoFactorSmsNumber: string | null;
  twoFactorResendAllowedAt: number | null;
  twoFactorLoginDetailsCache: { username: string; password: string } | null;
}

const initialState: State = {
  session: null,
  sessionLoaded: false,
  loginBusy: false,
  registerBusy: false,
  requestPasswordResetBusy: false,
  setPasswordBusy: false,
  appSecured: false,
  appSecureInitializing: false,
  appSecureBusy: false,
  twoFactorSmsNumber: null,
  twoFactorResendAllowedAt: null,
  twoFactorLoginDetailsCache: null,
};

const reducer = createReducer(
  initialState,
  on(
    authCommonActions.initializeSuccess,
    (state, { session }): State => ({
      ...state,
      session,
      sessionLoaded: true,
    }),
  ),
  on(
    authApiActions.login,
    (state): State => ({
      ...state,
      loginBusy: true,
    }),
  ),
  on(
    authApiActions.loginSuccess,
    (state, { session }): State => ({
      ...state,
      session,
      appSecured: true,
      twoFactorSmsNumber: null,
      twoFactorLoginDetailsCache: null,
    }),
  ),
  on(
    authApiActions.loginFailure,
    (state): State => ({
      ...state,
      session: null,
      loginBusy: false,
      appSecured: false,
    }),
  ),
  on(
    authApiActions.twoFactorRequiredForLogin,
    (state, { twoFactorSmsNumber, username, password, due }): State => ({
      ...state,
      twoFactorSmsNumber,
      twoFactorLoginDetailsCache: { username, password },
      twoFactorResendAllowedAt: due ?? Date.now() + TWO_FACTOR_RESEND_TIMEOUT,
      loginBusy: false,
    }),
  ),
  on(
    authApiActions.twoFactorSendCodeSuccess,
    (state): State => ({ ...state, twoFactorResendAllowedAt: Date.now() + TWO_FACTOR_RESEND_TIMEOUT }),
  ),
  on(authApiActions.twoFactorSendCodeLimit, (state, { due }): State => ({ ...state, twoFactorResendAllowedAt: due })),
  on(
    authCommonActions.logInNavigationDone,
    (state): State => ({
      ...state,
      loginBusy: false,
    }),
  ),
  on(
    authApiActions.refreshTokenSuccess,
    (state, { session }): State => ({
      ...state,
      session,
    }),
  ),

  on(
    authApiActions.refreshTokenFailure,
    (state): State => ({
      ...state,
      session: null,
    }),
  ),
  on(
    authCommonActions.logOutDone,
    (state): State => ({
      ...state,
      session: null,
      appSecureInitializing: false,
      appSecureBusy: false,
      appSecured: false,
    }),
  ),
  on(
    authApiActions.register,
    (state): State => ({
      ...state,
      registerBusy: true,
    }),
  ),
  on(
    authApiActions.registerFailure,
    authApiActions.registerSuccess,
    (state): State => ({
      ...state,
      registerBusy: false,
    }),
  ),
  on(
    authApiActions.requestPasswordReset,
    (state): State => ({
      ...state,
      requestPasswordResetBusy: true,
    }),
  ),
  on(
    authApiActions.requestPasswordResetFailure,
    authApiActions.requestPasswordResetSuccess,
    (state): State => ({
      ...state,
      requestPasswordResetBusy: false,
    }),
  ),
  on(
    authApiActions.setPassword,
    (state): State => ({
      ...state,
      setPasswordBusy: true,
    }),
  ),
  on(
    authApiActions.setPasswordFailure,
    authApiActions.setPasswordSuccess,
    (state): State => ({
      ...state,
      setPasswordBusy: false,
    }),
  ),
  on(
    authCommonActions.manualTokenRefreshSuccess,
    (state, { session }): State => ({
      ...state,
      session,
    }),
  ),
  on(
    authCommonActions.secureApplication,
    (state): State => ({
      ...state,
      // if the secure modal is already shown, then the secure handler is already loaded
      appSecureInitializing: state.appSecureBusy ? false : true,
      appSecured: false,
    }),
  ),
  on(
    authCommonActions.secureApplicationModalShown,
    (state): State => ({
      ...state,
      appSecureInitializing: false,
      appSecureBusy: true,
      appSecured: false,
    }),
  ),
  on(
    authCommonActions.secureApplicationDone,
    (state): State => ({
      ...state,
      appSecureInitializing: false,
      appSecureBusy: false,
      appSecured: true,
    }),
  ),
);

export const authFeature = createFeature({
  name: 'auth',
  reducer,
  extraSelectors: ({
    selectSession,
    selectSessionLoaded,
    selectTwoFactorLoginDetailsCache,
    selectRegisterBusy,
    selectLoginBusy,
    selectSetPasswordBusy,
  }) => ({
    selectToken: createSelector(selectSession, selectSessionLoaded, (session, loaded) => ({
      token: session?.access_token ?? null,
      loaded,
    })),
    selectTwoFactorDetailsAvailable: createSelector(selectTwoFactorLoginDetailsCache, (twoFactorLoginDetailsCache) =>
      Utils.isNotNil(twoFactorLoginDetailsCache),
    ),
    selectRegisterAndLoginBusy: createSelector(selectRegisterBusy, selectLoginBusy, (registerBusy, loginBusy) => registerBusy || loginBusy),
    selectSetPasswordAndLoginBusy: createSelector(
      selectSetPasswordBusy,
      selectLoginBusy,
      (setPasswordBusy, loginBusy) => setPasswordBusy || loginBusy,
    ),
  }),
});

// this selector cannot be part of the extra selectors, otherwise it will get memoized. And since
// it used the date, that would be problematic.
export const selectIsSessionExpired = (session$: Observable<AuthSession | null>) =>
  session$.pipe(
    map((session) => {
      if (!session) return false;

      // advisor access tokens expire every 10 minutes, while for normal sessions we want
      // refresh the token 10 minutes before the real expiry
      const secondsBeforeRealExpiryToTriggerRefresh = session.expires_in < 1000 ? session.expires_in / 10 : 600;

      const currentDate = Math.floor(new Date().getTime() / 1000);
      return currentDate >= session.created_at + session.expires_in - secondsBeforeRealExpiryToTriggerRefresh;
    }),
  );
