import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Invitation } from '@semmie/models/bi/invitation';
import { InvitationProvider } from '@semmie/providers/invitation/invitation.provider';
import { invitationUpdateParams } from '@semmie/schemas/bi/invitation/invitation-update-params';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  private invitations = new BehaviorSubject<Invitation[]>([]);

  constructor(
    private invitationProvider: InvitationProvider,
    private translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly invitations$ = this.invitations.asObservable();

  get(id: string): Observable<Invitation> {
    return this.invitations.pipe(
      switchMap((invitations) => {
        const inv = invitations.filter((i) => i.id === id)[0];

        if (!inv) {
          return throwError('no invitation found');
        }

        return of(inv);
      }),
    );
  }

  list(refresh?: boolean): Observable<Array<Invitation>> {
    const cached = this.invitations.value;

    if (!cached?.length || refresh) {
      return this.invitationProvider.list().pipe(tap((i) => this.invitations.next(i)));
    }

    return this.invitations;
  }

  /**
   * Accept or decline an invitation
   */
  update(params: invitationUpdateParams): Observable<Invitation> {
    return this.invitationProvider.patch(params);
  }

  delete(inviteId): Observable<void> {
    return this.invitationProvider.delete(inviteId);
  }

  clear() {
    this.invitations.next([]);
  }

  /**
   * Get the description for a incoming invite
   * @param invitation
   * @returns string
   */
  getInvitationDescription(invitation: Invitation | undefined): string {
    if (!invitation) return '';

    // TODO: Localize account labels (core.common.account.label)
    const interpolatedAccountKind = this.translateService.instant(
      'core.common.account.label.adjective.lowercase.' + invitation.account.kind,
    );

    return invitation.child
      ? $localize`:@@invitation.viewing.description.child:${invitation.from} has invited you to open an account for ${
          invitation.child ?? invitation.party
        }. Accept or delete the invitation.`
      : $localize`:@@invitation.viewing.description.default:${invitation.from} has invited you to a ${interpolatedAccountKind}. Accept or delete the invitation.`;
  }
}
