import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { payoutApiActions } from './payout-api.actions';
import { catchError, exhaustMap, map, of, shareReplay, switchMap, take } from 'rxjs';
import { PayoutProvider } from '@onyxx/provider/payout';
import { Store } from '@ngrx/store';
import { getRouterSelectors } from '@ngrx/router-store';
import { filterNil } from '@onyxx/utility/observables';
import { HttpErrorResponse } from '@angular/common/http';
import { payoutCommonActions } from './payout-common.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { CommonRouteParams } from '@semmie/views/shared/common-route-params.enum';

export class PayoutEffects {
  private readonly actions$ = inject(Actions);
  private readonly payoutProvider = inject(PayoutProvider);
  private readonly store = inject(Store);
  private readonly authFacade = inject(AuthFacade);

  private readonly accountId$ = this.store
    .select(getRouterSelectors().selectRouteParam(CommonRouteParams.AccountId))
    .pipe(filterNil(), shareReplay({ bufferSize: 1, refCount: true }));

  readonly loadPayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(payoutApiActions.load),
      // switchMap instead of concatLatestFrom because the load might be triggered before
      // the accountId is ready
      switchMap(() => this.accountId$.pipe(take(1))),
      exhaustMap((accountId) =>
        this.payoutProvider.getPayout(accountId).pipe(
          map((payout) => payoutApiActions.loadSuccess({ payout })),
          catchError((error: Error) => {
            if (error instanceof HttpErrorResponse && error.status === 404) {
              return of(payoutApiActions.loadSuccess({ payout: null }));
            }

            return of(payoutApiActions.loadFailure({ errorMsg: error.message }));
          }),
        ),
      ),
    );
  });

  readonly createPayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(payoutApiActions.create),
      switchMap(({ accountId, payout }) =>
        this.payoutProvider.createPayout(accountId, payout).pipe(
          map((payout) => payoutApiActions.createSuccess({ payout })),
          catchError(({ message }: Error) => of(payoutApiActions.createFailure({ errorMsg: message }))),
        ),
      ),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => payoutCommonActions.clear()));
  });
}
