<semmie-card [padding]="false">
  <div class="magazine-card" (click)="onClickHandler()">
    <div *ngIf="!!item?.video_id" class="video-play-button">
      <img [src]="playIcon" />
    </div>
    <div class="magazine-card-image">
      <semmie-image [placeholder]="true" [objectFit]="'objectCover'" [height]="216" [src]="item.image_url"></semmie-image>
    </div>
    <div class="magazine-card-details">
      <semmie-label
        type="small"
        size="tiny"
        align="center"
        class="pre-heading"
        [text]="item && item.category ? item.category + ' — ' + item.published : ''"
      ></semmie-label>
      <semmie-label type="heading" size="h3" align="center" weight="medium" class="title" [text]="item.title || ''"></semmie-label>
      <semmie-label *ngIf="!item" class="text-placeholder l"></semmie-label>
    </div>
  </div>
</semmie-card>
