import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExternalDataComponent } from './external-data.component';
import { providers } from './external-data.common';
import { CountriesStoreModule } from '@onyxx/store/countries';
import { QuestionsStoreModule } from '@onyxx/store/questions';
import { PskParticipantsStoreModule } from '@onyxx/store/psk-participants';

@NgModule({
  declarations: [ExternalDataComponent],
  imports: [CommonModule, QuestionsStoreModule, CountriesStoreModule, PskParticipantsStoreModule],
  exports: [ExternalDataComponent],
  providers,
})
export class ExternalDataModule {}
