import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { exhaustMap, filter, map } from 'rxjs';
import { authCommonActions } from '../auth-common.actions';
import { AuthFacade } from '../auth.facade';
import { AUTH_STORE_CONFIG } from '../auth-store-config.token';
import { SecureApplicationStatus } from '@onyxx/store/auth';
import { filterNil } from '@onyxx/utility/observables';

export class SecureApplicationEffects {
  private readonly actions$ = inject(Actions);
  private readonly authFacade = inject(AuthFacade);
  private readonly configToken = inject(AUTH_STORE_CONFIG);
  private readonly config = inject(this.configToken);

  readonly secureApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authCommonActions.secureApplication),
      concatLatestFrom(() => this.authFacade.isAuthenticated$),
      filter(([, authenticated]) => authenticated),
      exhaustMap(([options]) => {
        return this.config.onSecureApplication(options).pipe(map((status) => ({ status, skipNavigation: options.skipNavigation })));
      }),
      map(({ status, skipNavigation }) => {
        switch (status) {
          case SecureApplicationStatus.Securing:
            return null;
          case SecureApplicationStatus.Secured:
            return authCommonActions.secureApplicationDone();
          case SecureApplicationStatus.ModalShown:
            return authCommonActions.secureApplicationModalShown();
          case SecureApplicationStatus.Logout:
            return authCommonActions.logOut({ skipNavigation });
        }
      }),
      filterNil(),
    );
  });
}
