import { CanActivateFn } from '@angular/router';
import { UserAreaModule } from './user-area.module';
import { combineLatest, filter, map } from 'rxjs';
import { AuthFacade } from '@onyxx/store/auth';
import { UserActivityStoreFacade } from '@onyxx/store/user-activity';
import { inject } from '@angular/core';

export function initGuard(): CanActivateFn {
  return () => {
    const authFacade = inject(AuthFacade);
    const userActivityStoreFacade = inject(UserActivityStoreFacade);

    return combineLatest([userActivityStoreFacade.ready$, authFacade.ready$]).pipe(
      map(([userActivityReady, authReady]) => userActivityReady && authReady),
      filter((ready) => ready),
    );
  };
}

export const userArea = () => {
  return {
    path: '',
    loadChildren: () => UserAreaModule,

    // The init guard prevents the guards from the child routes from being executed until the auth store and user
    // activity store is ready. Otherwise, `unAuthenticatedGuard` could incorrectly navigate the user to the main
    // route while the user activity store is initializing and yet to determine whether the session is indeed valid
    canActivate: [initGuard()],
  };
};
