import { DestroyRef, Directive, ElementRef, Input, OnChanges, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { ThemeService } from '@semmie/services/theme/theme.service';
import { UrlService } from '@semmie/services/url/url.service';

@Directive({
  selector: '[semmieBackgroundImage]',
  standalone: true,
})
export class BackgroundImageDirective implements OnChanges, OnInit {
  @Input() themeAvailability: { dark?: boolean; premium?: boolean; premiumDark?: boolean } = {};
  @Input({ alias: 'semmieBackgroundImage', required: true }) backgroundImage: string;

  platformService = inject(PlatformService);
  urlService = inject(UrlService);
  themeService = inject(ThemeService);
  destroyRef = inject(DestroyRef);

  private currentTheme = { useDarkTheme: false, usePremiumTheme: false };
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.themeService.currentTheme$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((ct) => {
      this.currentTheme = ct;
      const url = this.getUrl();
      this.el.nativeElement.style.backgroundImage = url;
    });
  }

  ngOnChanges() {
    const url = this.getUrl();
    this.el.nativeElement.style.backgroundImage = url;
  }

  private getUrl() {
    const { useDarkTheme, usePremiumTheme } = this.currentTheme;

    if (this.urlService.isUrl(this.backgroundImage)) {
      return `url('${this.backgroundImage}')`;
    }

    const premiumImage = usePremiumTheme && !!this.themeAvailability.premium;
    const darkThemeImage =
      (useDarkTheme && !!this.themeAvailability.dark) || (useDarkTheme && premiumImage && !!this.themeAvailability.premiumDark);

    const resolvedAsset = this.platformService.resolveAssetPath(this.backgroundImage, {
      darkTheme: darkThemeImage,
      premium: premiumImage,
    });

    return `url(${resolvedAsset})`;
  }
}
