import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PskParticipant } from '@onyxx/model/psk-participants';

export const PskParticipantsAPIActions = createActionGroup({
  source: 'PSK participants API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ psk_participants: PskParticipant[] }>(),
    'Load Failure': props<{ errorMsg: string }>(),
  },
});
