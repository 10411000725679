// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';
import { AccountApiModel } from './account-api-model.interface';
import { parseMetadata } from './parse-metadata';
import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { Goal, Instrument, Organisation } from '@semmie/models';
import { Utils } from '@onyxx/utility/general';
import { Advisor } from '@semmie/models/bi/advisor/advisor.model';
import { Invitation } from '@semmie/models/bi/invitation';

export const apiModelToModel = (apiModel: AccountApiModel): Account => {
  return {
    ...apiModel,

    meta: parseMetadata(apiModel.meta.data),

    current_auto_incasso_value: Number(apiModel.current_auto_incasso_value ?? 0),
    current_boost_value: Number(apiModel.current_boost_value ?? 0),
    current_cash_value: Number(apiModel.current_cash_value ?? 0),
    current_payment_value: Number(apiModel.current_payment_value ?? 0),
    current_performance_amount: Number(apiModel.current_performance_amount ?? 0),
    current_position_cash_value: Number(apiModel.current_position_cash_value ?? 0),
    current_position_security_value: Number(apiModel.current_position_security_value ?? 0),
    current_stornation_value: Number(apiModel.current_stornation_value ?? 0),
    current_available_for_withdrawal_value: Number(apiModel.current_available_for_withdrawal_value ?? 0),
    value: Number(apiModel.value ?? 0),
    total_deposit: Utils.isNil(apiModel.total_deposit) ? undefined : Math.max(0, apiModel.total_deposit),

    // TODO: replace Instrument with an interface
    positions: Utils.isNil(apiModel.positions)
      ? []
      : apiModel.positions.map((p) => ({
          ...p,
          instrument: new Instrument(p.instrument),
        })),
    // TODO: replace AccountPerson model with an interface
    people: apiModel.people?.map((p) => new AccountPerson(p)) ?? [],
    // TODO: replace Organisation model with an interface
    organisation: Utils.isNil(apiModel.organisation) ? undefined : new Organisation(apiModel.organisation),
    // TODO: replace Invitation model with an interface
    invitation: Utils.isNil(apiModel.invitation) ? undefined : new Invitation(apiModel.invitation),
    // TODO: replace Advisor model with an interface
    advisor: Utils.isNil(apiModel.advisor) ? undefined : new Advisor(apiModel.advisor),
    // TODO: replace Goal model with an interface
    goal: Utils.isNil(apiModel.goal) ? undefined : new Goal(apiModel.goal),
  };
};
