import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import jmespath from '@metrichor/jmespath';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function total(amount: number, path?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;
    if (!value) return null;

    let total = 0;
    if (value.some((val) => val instanceof Object) && path) {
      total = value.reduce((acc, val) => acc + Number(jmespath.search(val, path)), 0);
    } else if (value.every((val) => ['string', 'number'].includes(typeof val))) {
      total = value.reduce((acc, val) => acc + Number(val), 0);
    }

    if (total > Number(amount)) {
      return new ValidatorMessage({
        code: 15,
        message: $localize`:@@validation.total.invalid:The total cannot be greater than ${total}.`,
        message_data: amount,
      });
    }

    return null;
  };
}
