import { createFeature, createReducer, on } from '@ngrx/store';
import { CountriesApiActions } from './countries-api.actions';
import { CountriesStoreActions } from './countries-common.actions';
import { iCountry } from '@onyxx/model/countries';

export interface State {
  countries: iCountry[];
  countriesLoading: boolean;
}

const initialState: State = {
  countries: [],
  countriesLoading: false,
};

const reducer = createReducer(
  initialState,

  // initialization
  on(CountriesStoreActions.initialize, (state): State => ({ ...state, countriesLoading: false })),

  // countries
  on(CountriesApiActions.loadCountries, (state): State => ({ ...state, countriesLoading: true })),
  on(
    CountriesApiActions.loadCountriesSuccess,
    (state, { countries }): State => ({
      ...state,
      countriesLoading: false,
      countries,
    }),
  ),
);

export const countriesFeature = createFeature({
  name: 'countries',
  reducer,
});
