export interface iDropdownOption {
  label: string;
  value: any;
  valueRange?: [number | null, number | null];
  image?: string | null;
  imageStyle?: iDropdownImageStyle;
  disabled?: boolean;
  conditions?: Array<string>;
  deprecated?: boolean;
}

export enum iDropdownImageStyle {
  Rounded = 'rounded',
}
