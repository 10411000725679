import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SemmiePercentagePipe } from '@semmie/pipes/percentage/percentage.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [SemmiePercentagePipe],
  declarations: [SemmiePercentagePipe],
})
export class PercentageModule {}
