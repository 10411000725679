import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'nl.semmie.semmieapp',
  appName: 'Semmie',
  server: {
    iosScheme: 'semmie',
    androidScheme: 'https',
  },
  webDir: '../../dist/apps/client-portal',
  android: { path: './android' },
  ios: { path: './ios' },
  plugins: {
    Intercom: {
      iosApiKey: 'ios_sdk-f6e1e4f79c33eb0c09ae47b00659010bfd899f0b',
      iosAppId: 'a1yza49a',
      androidApiKey: 'android_sdk-cb59cf0c2f8e75d3abbd5dda7513860febacf8b0',
      androidAppId: 'a1yza49a',
    },
    SplashScreen: {
      androidScaleType: 'CENTER_INSIDE',
      useDialog: true,
      launchAutoHide: false,
      backgroundColor: '#000000',
    },
    PushNotifications: {
      presentationOptions: ['badge', 'sound', 'alert'],
    },
  },
};

export default config;
