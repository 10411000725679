import { NgModule } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { DirectDebitsStoreFacade } from './direct-debits.facade';
import { directDebitsFeature } from './direct-debits.reducer';
import { DirectDebitsEffects } from './direct-debits.effects';

export const providers = [provideState(directDebitsFeature), provideEffects(DirectDebitsEffects), DirectDebitsStoreFacade];

@NgModule({ providers })
export class DirectDebitsStateModule {}

export * from './direct-debits.facade';
