import { Directive, HostListener } from '@angular/core';
import { ImpactStyle } from '@capacitor/haptics';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';

@Directive({ standalone: true, selector: '[hapticOnTap]' })
export class HapticOnTapDirective {
  constructor(private hapticFeedbackService: HapticFeedbackService) {}

  @HostListener('click')
  onClick() {
    this.hapticFeedbackService.interact(ImpactStyle.Light);
  }
}
