import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

import { StepsComponent } from './steps.component';

@NgModule({
  declarations: [StepsComponent],
  imports: [CommonModule, IconComponent, LabelModule],
  exports: [StepsComponent],
})
export class StepsModule {}
