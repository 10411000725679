import { NgModule } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { biometricsFeature } from './biometrics.reducer';
import { BiometricsStoreFacade } from './biometrics.facade';
import { BiometricsEffects } from './biometrics.effects';

export const providers = [provideState(biometricsFeature), provideEffects(BiometricsEffects), BiometricsStoreFacade];

@NgModule({ providers })
export class BiometricsStateModule {}

export { BiometricsStoreFacade } from './biometrics.facade';
export { BiometricStatus } from './models/biometric-status.enum';
export { BiometricAvailability } from './models/biometric-availability.type';
