import { ChangeDetectionStrategy, Component, Signal, computed, inject } from '@angular/core';
import { AccountStatusModalCallback, iAccountStatusMessage } from '@semmie/schemas/bi/account/account-status';
import { ModalService } from '@semmie/services';
import { AccountStatus } from '@onyxx/model/account';
import { SharedModule } from '@semmie/shared/shared.module';
import {
  ButtonModule,
  ContentContainerModule,
  HeaderModule,
  IconComponent,
  ImageModule,
  LabelModule,
  ModalHeaderModule,
  ModalScrollModule,
  PageHeaderModule,
} from '@semmie/components';
import { Illustration } from '@semmie/shared/globals';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    IconComponent,
    LabelModule,
    HeaderModule,
    ImageModule,
    ButtonModule,
    ContentContainerModule,
    PageHeaderModule,
    ModalHeaderModule,
    ModalScrollModule,
  ],
  selector: 'semmie-account-status-modal',
  templateUrl: './account-status-modal.component.html',
  styleUrls: ['./account-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountStatusModalComponent {
  private readonly modalService = inject(ModalService);

  /** Set with opening modal */
  status: AccountStatus | AccountStatusModalCallback;

  readonly AccountStatusModalCallback = AccountStatusModalCallback;

  readonly statusMessage$$: Signal<iAccountStatusMessage | undefined> = computed(() => {
    switch (this.status) {
      case AccountStatus.TASK:
        return {
          label: $localize`:@@account.status-modal.task.label:Open tasks`,
          title: $localize`:@@account.status-modal.task.title:More information needed`,
          description: $localize`:@@account.status-modal.task.description:We kindly ask for additional information from you. Check your open tasks for more details.`,
          illustration: Illustration.PAPER_PLANE,
          button: $localize`:@@account.status-modal.task.button:View now`,
          closeCallback: AccountStatusModalCallback.TASK,
        };
      case AccountStatus.CHECKING:
        return {
          label: $localize`:@@account.status-modal.checking.label:Account status 1 of 3`,
          title: $localize`:@@account.status-modal.checking.title:Semmie is checking your information`,
          description: $localize`:@@account.status-modal.checking.description:Your information is being carefully checked, and several legally required checks are being performed.`,
          illustration: Illustration.IDENTIFICATION,
          footer: $localize`:@@account.status-modal.checking.footer:<strong>Processing:</strong> Approximately one day. Excluding weekends and holidays.`,
          button: $localize`:@@account.status-modal.checking.button:What is being checked?`,
          closeCallback: AccountStatusModalCallback.CHECKING_EXPLANATION,
        };
      case AccountStatusModalCallback.CHECKING_EXPLANATION:
        return {
          title: $localize`:@@account.status-modal.checking_explanation.title:What is being checked?`,
          description: $localize`:@@account.status-modal.checking_explanation.description:<p><strong>The checks we perform are designed to protect you from potential identity fraud and to protect Semmie from people who might want to misuse our services. The checks are largely legally required. What is being checked?</strong></p><h3>1. Identification through Your Passport or ID Card</h3><p>We check your passport or ID card for validity and completeness. We use your identification document to identify you as the account holder.</p><h3>2. Passport Lost or Stolen</h3><p>We perform a VIS check (Verification Information System). VIS is the register for stolen and/or lost identification documents. This way, we ensure that your identification document is not registered as lost or stolen.</p><h3>3. Politically Exposed Person or Applicable Sanction</h3><p>We check if you appear on (international) lists that include all persons who have been sanctioned or are registered as politically exposed (PEP) persons.</p><h3>4. Verification of Your Information</h3><p>Finally, we check if you have provided all required information and if it is correct. This includes checking your address details but also if your email address is complete.</p>`,
          button: $localize`:@@account.status-modal.checking_explanation.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
      case AccountStatus.HOLD:
        return {
          label: $localize`:@@account.status-modal.hold.label:Activating account`,
          title: $localize`:@@account.status-modal.hold.title:Please be patient`,
          description: $localize`:@@account.status-modal.hold.description:Activating your account is taking longer than usual. We kindly ask for your patience.`,
          illustration: Illustration.HOURGLASS,
          footer: $localize`:@@account.status-modal.hold.footer:<strong>Questions?</strong> Contact us via chat.`,
          button: $localize`:@@account.status-modal.hold.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
      case AccountStatus.CREATED:
        return {
          label: $localize`:@@account.status-modal.created.label:Account status 2 of 3`,
          title: $localize`:@@account.status-modal.created.title:Account opened`,
          description: $localize`:@@account.status-modal.created.description:Your account can now receive funds. Your deposit is on its way. We will start investing soon.`,
          illustration: Illustration.WID,
          button: $localize`:@@account.status-modal.created.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
      case AccountStatus.INVESTING:
        return {
          label: $localize`:@@account.status-modal.investing.label:Account status 3 of 3`,
          title: $localize`:@@account.status-modal.investing.title:We are starting to invest`,
          description: $localize`:@@account.status-modal.investing.description:We will start investing at the next trading moment. Our trading moments are on weekdays, excluding holidays.`,
          illustration: Illustration.ROCKET,
          button: $localize`:@@account.status-modal.investing.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
      case AccountStatus.BLOCKED:
        return {
          label: $localize`:@@account.status-modal.blocked.label:Account status`,
          title: $localize`:@@account.status-modal.blocked.title:Account blocked`,
          description: $localize`:@@account.status-modal.blocked.description:The account is blocked. Please contact customer service.`,
          illustration: Illustration.CONE,
          button: $localize`:@@account.status-modal.blocked.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
      case AccountStatus.TERMINATED:
        return {
          label: $localize`:@@account.status-modal.terminated.label:Account status`,
          title: $localize`:@@account.status-modal.terminated.title:Account is being closed`,
          description: $localize`:@@account.status-modal.terminated.description:Any direct debits have been stopped, and your investments will be sold at the next trading moment. The amount will then be transferred to your designated counter-account.`,
          illustration: Illustration.HOURGLASS,
          button: $localize`:@@account.status-modal.terminated.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
      case AccountStatus.ARCHIVED:
        return {
          label: $localize`:@@account.status-modal.archived.label:Account status`,
          title: $localize`:@@account.status-modal.archived.title:Account archived`,
          description: $localize`:@@account.status-modal.archived.description:The account has been closed. The account information will be available for another year until we have provided the annual overview.`,
          illustration: Illustration.BOX,
          button: $localize`:@@account.status-modal.archived.button:Close`,
          closeCallback: AccountStatusModalCallback.CLOSE,
        };
    }
  });

  closeModal(closeCallback?: AccountStatusModalCallback) {
    this.modalService.close(closeCallback);
  }
}
