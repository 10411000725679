import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PaymentStoreActions } from '@semmie/store/payment/actions/payment-common.actions';

@Injectable()
export class PaymentStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);

  paymentDone() {
    this.store.dispatch(PaymentStoreActions.paymentDone());
  }
}
