import { publicAreaRoutes } from './public-area.routes';
import { areaMatcher } from '../area-matcher';
import { PublicAreaModule } from './public-area.module';

export const publicArea = () => {
  return {
    matcher: areaMatcher(publicAreaRoutes),
    loadChildren: () => PublicAreaModule,
  };
};
