<semmie-carousel
  #carousel
  [slidesPerView]="1.2"
  [spaceBetween]="16"
  [breakpoints]="{
    '1024': { slidesPerView: 1.2, centeredSlides: true, spaceBetween: 20 },
  }"
  [initialSlide]="initialIndex"
  [navigation]="true"
>
  <semmie-carousel-slide *ngFor="let item of options; let i = index">
    <ng-template #carouselSlideContent>
      <semmie-projection-card
        [value]="item.value"
        [title]="item.label"
        [form]="form"
        [scenario]="name"
        [selected]="isActive(i)"
        hapticOnTap
      ></semmie-projection-card>
    </ng-template>
  </semmie-carousel-slide>
</semmie-carousel>
