import { Reward } from '@semmie/models/bi/reward/reward.model';
import { BaseModel } from '@semmie/models/_abstract';
import { BoosterState, BoosterType, iBooster } from '@semmie/schemas/bi/booster';
import { RewardKind } from '@semmie/schemas/bi/reward';

export class Booster extends BaseModel<iBooster> implements iBooster {
  /**
   * Represents the value of 'rewardKind'
   */
  amount: string;

  /**
   * Represents the RewardKind
   *
   * @info remapped from _'kind'_
   */
  rewardKind: RewardKind;

  rewards: Reward[];
  state: BoosterState;
  title: string;

  /**
   * Represents the BoosterType
   *
   * @info remapped from _'name'_
   */
  type: BoosterType;

  constructor(props?: Partial<Booster>) {
    super(props, [
      { api: 'kind', local: 'rewardKind' },
      { api: 'name', local: 'type' },
      { api: 'rewards', local: 'rewards', model: new Reward() },
    ]);
  }
}
