import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { HttpContext } from '@angular/common/http';
import { SKIP_AUTHENTICATION } from '@onyxx/model/http';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceProvider extends BaseProvider<any> {
  /**
   * The root url of the api without the version path
   */
  private readonly rootApi = this.backendApiUrl.split('/').slice(0, -1).join('/');

  constructor() {
    super();
  }

  /**
   * Get the api up status where only the status code is important to know
   *
   * 200 - the api is up and running
   * 521 - the api is down
   */
  getApiUpStatus() {
    return this.http
      .get(`${this.rootApi}/up`, { responseType: 'text', observe: 'response', context: new HttpContext().set(SKIP_AUTHENTICATION, true) })
      .pipe(
        map((response) => response.status),
        catchError((err) => of(err.status)),
      );
  }
}
