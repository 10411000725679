import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentContainerModule } from '@semmie/components/containers/content-container/content-container.module';
import { ButtonModule } from '@semmie/components/presentational/core/button/button.module';
import { FormModule } from '@semmie/components/containers/form/form.module';
import { IntroContentModule } from '@semmie/components/containers/intro-content/intro-content.module';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { PageHeaderModule } from '@semmie/components/containers/page-header/page-header.module';
import { ScrollContainerModule } from '@semmie/components/containers/scroll-container/scroll-container.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';

import { FormPageComponent } from './form-page.component';
import { ProgressBarComponent } from '@semmie/components/presentational/core/progress-bar/progress-bar.component';
import { SharedModule } from '@semmie/shared/shared.module';
import { HeaderModule } from '@semmie/components/presentational/core/header/header.module';
import { ModalHeaderModule } from '@semmie/components/containers/modals/modal-header/modal-header.component';

@NgModule({
  declarations: [FormPageComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LabelModule,
    IconComponent,
    FormModule,
    ContentContainerModule,
    PageHeaderModule,
    IntroContentModule,
    ScrollContainerModule,
    ProgressBarComponent,
    HeaderModule,
    SharedModule,
    ModalHeaderModule,
  ],
  exports: [FormPageComponent],
})
export class FormPageModule {}
