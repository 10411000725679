/* eslint-disable no-empty */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { postcodeValidator } from 'postcode-validator';

import getCountryISO2 from 'country-iso-3-to-2';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { iCountry } from '@onyxx/model/countries';
import { Utils } from '@onyxx/utility/general';

export function zipcode(country: iCountry): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = String(control?.value ?? '').toUpperCase();
    if (Utils.isNotNil(value) && Utils.isNil(country)) {
      return new ValidatorMessage({
        code: 17,
        message: $localize`:@@validation.zip-code.no-country:You forgot to select a country.`,
      });
    } else if (Utils.isNil(value) || Utils.isNil(country)) {
      return null;
    }

    const iso2Country = getCountryISO2(country.iso3);
    let validZipCode = true;

    try {
      validZipCode = postcodeValidator(value, iso2Country);
    } catch (ex) {}

    if (!validZipCode) {
      return new ValidatorMessage({
        code: 10,
        message: $localize`:@@validation.zip-code.invalid:Typo? This is not a recognized postal code in ${country.title}.`,
      });
    }

    return null;
  };
}
