import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EventData } from '@semmie/schemas/bi/event/event.schema';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';

@Injectable({
  providedIn: 'root',
})
export class EventsProvider extends BaseProvider<EventData> {
  constructor() {
    super('event');
  }

  post(params: any): Observable<null> {
    return this.__post('events', { event: params });
  }
}
