import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Goal } from '@onyxx/model/goal';

export const goalApiActions = createActionGroup({
  source: 'Goal API',
  events: {
    Load: emptyProps(),
    'Load for account': props<{ accountId: string }>(),
    'Load Success': props<{ accountId: string; goal: Goal | null }>(),
    'Load Failure': props<{ errorMsg: string }>(),

    Update: props<{ accountId: string; goal: Partial<Goal> }>(),
    'Update Success': props<{ accountId: string; goal: Goal }>(),
    'Update Failure': props<{ errorMsg: string }>(),
  },
});
