import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';

import { HeaderComponent } from './header.component';

@NgModule({
  imports: [CommonModule, LabelModule],
  exports: [HeaderComponent],
  declarations: [HeaderComponent],
})
export class HeaderModule {}
