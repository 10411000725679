import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppStorageService } from '@semmie/services';
import { PinCodeStorage, PinCodeStorageKeys } from '@semmie/schemas/bi/pincode';

@Injectable({ providedIn: 'root' })
export class PinCodeService {
  private readonly pinCodeStorage = this.appStorageService.createSecuredStorageReader<PinCodeStorage>(PinCodeStorageKeys.PinCodeNumber);

  constructor(private appStorageService: AppStorageService) {}

  get enabledPinCode$(): Observable<boolean> {
    return from(this.pinCodeStorage.get()).pipe(
      map((value) => !!value),
      take(1),
    );
  }

  async clearStorageOnLogout() {
    await this.pinCodeStorage.remove();
  }
}
