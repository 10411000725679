export enum AccountStatusAction {
  MODAL = 'modal',
  CONTACT = 'contact',
  VALUE_TRANSFERS = 'value-transfers',
  PAYOUT_OVERVIEW = 'payout',
}

export interface iAccountStatusMessage {
  label?: string;
  title?: string;
  description?: string;
  illustration?: string;
  footer?: string;
  button?: string;
  closeCallback?: AccountStatusModalCallback;
}

export enum AccountStatusModalCallback {
  CLOSE = 'close',
  TASK = 'task',
  CHECKING_EXPLANATION = 'checking_explanation',
}
