import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { accountApiActions } from './account-api.actions';
import { accountFeature } from './account.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account, AccountMetadata, AccountProfileAudit } from '@onyxx/model/account';
import { Invitation } from '@semmie/models/bi/invitation';
import { accountCommonActions } from './account-common.actions';

@Injectable()
export class AccountStoreFacade {
  readonly store = inject(Store);
  readonly actions = inject(Actions);

  readonly account$ = this.store.select(accountFeature.selectCurrentAccount);

  readonly accountLoaded$ = this.actions.pipe(
    ofType(accountApiActions.loadAccountSuccess, accountApiActions.loadAccountFailure),
    map((action) => {
      return {
        success: action.type === accountApiActions.loadAccountSuccess.type,
        account: action.type === accountApiActions.loadAccountSuccess.type ? action.account : null,
      } as
        | {
            success: false;
            account: null;
          }
        | {
            success: true;
            account: Account;
          };
    }),
  );

  readonly accountCreated$ = this.actions.pipe(
    ofType(accountApiActions.createAccountSuccess, accountApiActions.createAccountFailure),
    map((action) => {
      return {
        success: action.type === accountApiActions.createAccountSuccess.type,
        account: action.type === accountApiActions.createAccountSuccess.type ? action.account : null,
      } as
        | {
            success: false;
            account: null;
          }
        | {
            success: true;
            account: Account;
          };
    }),
  );

  readonly accountUpdated$ = this.actions.pipe(
    ofType(accountApiActions.updateAccountSuccess, accountApiActions.updateAccountFailure),
    map((action) => {
      return {
        success: action.type === accountApiActions.updateAccountSuccess.type,
        account: action.type === accountApiActions.updateAccountSuccess.type ? action.account : null,
      } as
        | {
            success: false;
            account: null;
          }
        | {
            success: true;
            account: Account;
          };
    }),
  );

  readonly accountDeleted$ = this.actions.pipe(
    ofType(accountApiActions.deleteAccountSuccess, accountApiActions.deleteAccountFailure),
    map((action) => {
      return {
        success: action.type === accountApiActions.deleteAccountSuccess.type,
      };
    }),
  );

  account(id: string) {
    return this.store.select(accountFeature.selectAccount(id));
  }

  dispatchEnsureAccountIsLoaded(id: string) {
    this.store.dispatch(accountCommonActions.ensureAccountIsLoaded({ id }));
  }

  dispatchBustAccountCache(id: string) {
    this.store.dispatch(accountCommonActions.bustAccountCache({ id }));
  }

  dispatchUpdateWithProfileAudit(account: Partial<Account> & AccountProfileAudit & { id: Account['id'] }) {
    this.store.dispatch(accountApiActions.updateAccount({ id: account.id, account }));
  }

  dispatchUpdateAccount(account: Partial<Account> & { id: Account['id'] }) {
    this.store.dispatch(accountApiActions.updateAccount({ id: account.id, account }));
  }

  dispatchCreateAccount(account: Partial<Account>) {
    this.store.dispatch(accountApiActions.createAccount({ account }));
  }

  dispatchUpdateCurrentAvailableWithdrawalInStore(amount: number) {
    this.store.dispatch(accountCommonActions.updateCurrentAccountWithdrawalAmount({ amount }));
  }

  dispatchDecrementAccountOpenTaskCountInStore(accountId: string) {
    this.store.dispatch(accountCommonActions.decrementOpenTaskCount({ id: accountId }));
  }

  dispatchDecrementCurrentAccountUnreadInboxCountInStore() {
    this.store.dispatch(accountCommonActions.decrementCurrentAccountUnreadInboxCount());
  }

  dispatchReloadCurrentAccount() {
    this.store.dispatch(accountCommonActions.reloadCurrentAccount());
  }

  dispatchReloadAccount(accountId: string) {
    this.store.dispatch(accountCommonActions.reloadAccount({ id: accountId }));
  }

  dispatchDeleteInvitationInStore(accountId: string) {
    this.store.dispatch(accountCommonActions.deleteInvitation({ id: accountId }));
  }

  dispatchUpdateInvitationInStore(accountId: string, invitation: Invitation) {
    this.store.dispatch(accountCommonActions.updateInvitation({ id: accountId, invitation }));
  }

  dispatchUpdateAccountInStore(account: Account) {
    this.store.dispatch(accountCommonActions.updateAccountInStore({ account }));
  }

  dispatchDeleteAccount(accountId: string) {
    this.store.dispatch(accountApiActions.deleteAccount({ id: accountId }));
  }

  dispatchUpdateMetaData(accountId: string, data: AccountMetadata) {
    this.store.dispatch(accountApiActions.updateMetadata({ id: accountId, metadata: data }));
  }

  dispatchUpdateMetaDataByKey(accountId: string, data: Partial<AccountMetadata>) {
    this.store.dispatch(accountApiActions.updateMetadataPartial({ id: accountId, metadata: data }));
  }
}
