export enum EventDataCategory {
  Navigation = 'nav',
  Click = 'click',
  Error = 'error',
  System = 'system',
}

export interface EventData {
  name: string;
  category: EventDataCategory;
  data?: string;
  account_id?: string;
  person_id?: string;
}
