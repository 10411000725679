import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import getCountryISO2 from 'country-iso-3-to-2';

type PhoneFormat = 'national' | 'international';

@Pipe({
  name: 'semmiePhone',
  standalone: true,
})
export class SemmiePhonePipe implements PipeTransform {
  transform(number: string, countryIso3: string, format: PhoneFormat = 'international', obfuscate = false) {
    const formattedPhoneNumber = this.formatNumber(number, countryIso3, format);
    return obfuscate ? this.obfuscateNumber(formattedPhoneNumber) : formattedPhoneNumber;
  }

  private formatNumber(number: string, countryIso3: string, format: PhoneFormat) {
    try {
      const iso2Code = getCountryISO2(countryIso3);
      const parsedPhoneNumber = parsePhoneNumber(number, iso2Code);

      if (parsedPhoneNumber.isValid()) {
        return format === 'national' ? parsedPhoneNumber.formatNational() : parsedPhoneNumber.formatInternational();
      }

      return number;
    } catch (ex) {
      return number;
    }
  }

  private obfuscateNumber(number: string) {
    return `${number.slice(0, 3)} ${number.slice(3, -3).replace(/\d/g, '*')} ${number.slice(-3)}`;
  }
}
