import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NewPayout, Payout } from '@onyxx/model/payout';

export const payoutApiActions = createActionGroup({
  source: 'payout API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ payout: Payout | null }>(),
    'Load Failure': props<{ errorMsg: string }>(),

    Create: props<{ accountId: string; payout: NewPayout }>(),
    'Create Success': props<{ payout: Payout }>(),
    'Create Failure': props<{ errorMsg: string }>(),
  },
});
