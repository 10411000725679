import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Invitation } from '@semmie/models/bi/invitation';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { invitationUpdateParams } from '@semmie/schemas/bi/invitation/invitation-update-params';

const PROVIDER_KEY = 'invitations';

@Injectable()
export class InvitationProvider extends BaseProvider<Invitation> {
  constructor() {
    super(PROVIDER_KEY);
  }

  /**
   * List invitations
   *
   * @return {*}  Observable<Array<Invitation>>
   * @memberof InvitationProvider
   */
  list(): Observable<Array<Invitation>> {
    return super.__query(PROVIDER_KEY).pipe(map((response) => response?.[PROVIDER_KEY]?.map((i: Invitation) => new Invitation(i))));
  }

  /**
   *
   * Update a current invitation
   *
   * @param {string} code
   * @memberof InvitationProvider
   */
  patch(params: invitationUpdateParams): Observable<Invitation> {
    return super.__patch(`${PROVIDER_KEY}/${params.inviteId}`, {
      invitation: {
        status: params.status,
        message_declined: params.message_declined,
      },
    });
  }

  /**
   *
   * Delete an invitation
   *
   * @param {string} inviteId
   * @memberof InvitationProvider
   */
  delete(inviteId: string): Observable<any> {
    return super.__delete(`${PROVIDER_KEY}/${inviteId}`);
  }
}
