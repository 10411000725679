import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { User, UserRole } from '@onyxx/model/user';

import { UserAPIActions } from './actions/user-api.actions';
import { UserCommonActions } from './actions/user-common.actions';
import { TWO_FACTOR_RESEND_TIMEOUT } from '@onyxx/model/auth';

export interface State {
  user: User | null;
  loading: boolean;
  loaded: boolean;

  /** If an update is in progress */
  updating: boolean;

  credentialsUpdating: boolean;

  twoFactorUpdateBusy: boolean;
  twoFactorResendAllowedAt: number | null;
}

const initialState: State = {
  user: null,
  loading: false,
  loaded: false,
  updating: false,
  credentialsUpdating: false,
  twoFactorUpdateBusy: false,
  twoFactorResendAllowedAt: null,
};

const reducer = createReducer(
  initialState,
  on(UserAPIActions.load, (state): State => ({ ...state, loading: true })),
  on(UserAPIActions.loadSuccess, (state, { user }): State => ({ ...state, loading: false, loaded: true, user })),
  on(UserAPIActions.loadFailure, (state): State => ({ ...state, loading: false })),
  on(UserCommonActions.clear, (): State => initialState),

  on(UserAPIActions.update, (state): State => ({ ...state, updating: true })),
  on(UserAPIActions.updateSuccess, (state, { user }): State => ({ ...state, updating: false, user: new User({ ...state.user, ...user }) })),
  on(UserAPIActions.updateFailure, (state): State => ({ ...state, updating: false })),

  on(UserAPIActions.updateCredentials, (state): State => ({ ...state, credentialsUpdating: true })),
  on(
    UserAPIActions.updateCredentialsSuccess,
    (state, { payload }): State => ({ ...state, credentialsUpdating: false, user: new User({ ...state.user, email: payload.email }) }),
  ),
  on(UserAPIActions.updateCredentialsFailure, (state): State => ({ ...state, credentialsUpdating: false })),

  on(UserAPIActions.localUpdate, (state, { user }): State => ({ ...state, user: new User({ ...state.user, ...user }) })),

  on(
    UserAPIActions.sendTwoFactorAuthenticationSuccess,
    (state): State => ({
      ...state,
      twoFactorResendAllowedAt: Date.now() + TWO_FACTOR_RESEND_TIMEOUT,
    }),
  ),
  on(
    UserAPIActions.updateTwoFactorAuthentication,
    (state): State => ({
      ...state,
      twoFactorUpdateBusy: true,
    }),
  ),
  on(
    UserAPIActions.updateTwoFactorAuthenticationSuccess,
    (state, { enabled }): State => ({
      ...state,
      twoFactorUpdateBusy: false,
      user: new User({ ...state.user, two_factor_enabled: enabled }),
    }),
  ),
  on(
    UserAPIActions.updateTwoFactorAuthenticationFailure,
    (state): State => ({
      ...state,
      twoFactorUpdateBusy: false,
    }),
  ),

  on(
    UserCommonActions.updateTaskCounts,
    (state, { taskCounts }): State => ({
      ...state,
      user: new User({
        ...state.user,
        task_count: taskCounts,
      }),
    }),
  ),
);

export const userFeature = createFeature({
  name: 'user',
  reducer,
  extraSelectors: function ({ selectUser, selectLoaded, selectLoading }) {
    const selectSimple = {
      selectIsReady: createSelector(selectLoaded, selectLoading, (loaded, loading) => loaded && !loading),
      selectUnreadInboxCount: createSelector(selectUser, (user) => user?.unread_inbox_count),
      selectTaskCountCritical: createSelector(selectUser, (user) => user?.task_count?.critical),
      selectTaskCountHigh: createSelector(selectUser, (user) => user?.task_count?.high),
      selectTaskCountNormal: createSelector(selectUser, (user) => user?.task_count?.normal),
      selectOpenRewardCount: createSelector(selectUser, (user) => user?.open_reward_count),
      selectUserIsAdvisor: createSelector(selectUser, (user) => user?.role === UserRole.Advisor),
      selectUserTwoFactorEnabled: createSelector(selectUser, (user) => user?.two_factor_enabled ?? false),
    } as const;

    const selectComplex = {
      selectUserAccountCounter: createSelector(
        selectSimple.selectUnreadInboxCount,
        selectSimple.selectTaskCountCritical,
        selectSimple.selectTaskCountHigh,
        selectSimple.selectTaskCountNormal,
        (unreadInboxCount, taskCountCritical, taskCountHigh, taskCountNormal) =>
          (unreadInboxCount ?? 0) + (taskCountCritical ?? 0) + (taskCountHigh ?? 0) + (taskCountNormal ?? 0),
      ),
      selectTaskCountCombined: createSelector(
        selectSimple.selectTaskCountCritical,
        selectSimple.selectTaskCountHigh,
        selectSimple.selectTaskCountNormal,
        (taskCountCritical, taskCountHigh, taskCountNormal) => (taskCountCritical ?? 0) + (taskCountHigh ?? 0) + (taskCountNormal ?? 0),
      ),
    } as const;
    return { ...selectSimple, ...selectComplex } as const;
  },
});
