<div
  [ngClass]="{
    relative: true,
    active: active$$(),
  }"
  *ngIf="chartAvailable"
>
  <div class="graph-legend">
    <div class="graph-legend__item graph-legend__item--realistic" i18n="@@goal-chart.legend.realistic">Realistic</div>
    <div class="graph-legend__item graph-legend__item--unrealistic" i18n="@@goal-chart.legend.unrealistic">Unrealistic</div>
  </div>
  <div class="chart-container">
    <ng-container *ngIf="goal; else loadingTpl">
      <highcharts-chart
        disableSwipeOnTouch
        [Highcharts]="Highcharts"
        [options]="chartOptionsCopy"
        (chartInstance)="chartCallback($event)"
        [(update)]="shouldUpdate"
      >
      </highcharts-chart>
    </ng-container>
  </div>
</div>

<ng-template #loadingTpl>
  <div class="goal-chart-loader">
    <div class="loader">
      <semmie-loading-ripple position="inline"></semmie-loading-ripple>
    </div>
  </div>
</ng-template>
