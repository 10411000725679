import { forceLogoutGuard } from '@onyxx/feature/auth';
import { ForceLogoutAreaModule } from './force-logout-area.module';
import { forceLogoutAreaRoutes } from './force-logout-area.routes';
import { areaMatcher } from '../../area-matcher';

export const forceLogoutArea = () => {
  return {
    matcher: areaMatcher(forceLogoutAreaRoutes),
    loadChildren: () => ForceLogoutAreaModule,
    canActivate: [forceLogoutGuard()],
  };
};
