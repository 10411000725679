import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Utils } from '@onyxx/utility/general';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export const enum RequiredValidations {
  Default = 'default',
  ZipCode = 'zipCode',
  StreetNumber = 'streetNumber',
  NameCasual = 'nameCasual',
  NameChild = 'nameChild',
  NameGrandchild = 'nameGrandchild',
  BirthAt = 'birthAt',
}

export function required(requiredValidation: RequiredValidations = RequiredValidations.Default, property?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = property ? control?.value[property] : control?.value;

    let validationMessage = $localize`:@@validation.required:This is a required field.`;
    switch (requiredValidation) {
      case RequiredValidations.ZipCode:
        validationMessage = $localize`:@@validation.required.zip-code:You forgot to enter the postal code.`;
        break;
      case RequiredValidations.StreetNumber:
        validationMessage = $localize`:@@validation.required.street-number:You forgot to enter your house number.`;
        break;
      case RequiredValidations.NameCasual:
        validationMessage = $localize`:@@validation.required.name-casual:Don’t you have a first name? ;) You probably do. Please enter it here.`;
        break;
      case RequiredValidations.NameChild:
        validationMessage = $localize`:@@validation.required.name-child:Doesn’t your child have a first name? ;) Surely they do. Please enter it here.`;
        break;
      case RequiredValidations.NameGrandchild:
        validationMessage = $localize`:@@validation.required.name-grandchild:Doesn’t your grandchild have a first name? ;) Surely they do. Please enter it here.`;
        break;
      case RequiredValidations.BirthAt:
        validationMessage = $localize`:@@validation.required.birth-at:You forgot to enter the date of birth.`;
        break;
    }

    if (Utils.isNil(value) || value === '' || (Array.isArray(value) && value.length === 0)) {
      return new ValidatorMessage({
        code: 1,
        message: validationMessage,
      });
    }

    return null;
  };
}
