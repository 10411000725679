<div [class.active]="selected" class="projection-card">
  <div class="top">
    <semmie-label weight="medium" size="base" class="title" [text]="title"></semmie-label>
    @if (subtitle) {
      <semmie-label type="small" class="subtitle" [text]="subtitle"></semmie-label>
    } @else {
      <semmie-label
        type="small"
        class="subtitle"
        i18n-text="@@projection-card.subtitle.expected-return"
        text="Expected return"
      ></semmie-label>
    }

    <div class="data">
      <semmie-label class="data-value data-value-left" weight="medium" size="base" [text]="depositFrom | semmieCurrency"></semmie-label>
      <semmie-label
        class="data-center"
        weight="medium"
        size="base"
        i18n-text="@@projection-card.projection.until"
        text="until"
      ></semmie-label>
      <semmie-label class="data-value data-value-right" weight="medium" size="base" [text]="depositTo | semmieCurrency"></semmie-label>
    </div>
    @if (projection) {
      <div class="data">
        <semmie-label
          class="data-value data-value-left"
          weight="medium"
          size="base"
          [text]="projection.from | semmiePercentage"
        ></semmie-label>
        <semmie-label
          class="data-center"
          weight="medium"
          size="base"
          i18n-text="@@projection-card.projection.until"
          text="until"
        ></semmie-label>
        <semmie-label
          class="data-value data-value-right"
          weight="medium"
          size="base"
          [text]="projection.to | semmiePercentage"
        ></semmie-label>
      </div>
    }
  </div>
  <div class="bottom">
    <div class="icon"><semmie-icon [icon]="icon" size="xl3"></semmie-icon></div>
    <semmie-label class="title" weight="medium" size="base" [text]="detailsTitle"></semmie-label>
    <semmie-label class="subtitle" [text]="detailsSubTitle"></semmie-label>
  </div>
</div>
