import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Host, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { AccountGoalComponent } from '@semmie/components/containers/account/goal';
import { FormInputComponent } from '@semmie/components/containers/form-input/form-input.component';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { Goal } from '@semmie/models';
import { iGoal } from '@semmie/schemas';
import { DynamicFieldType, iGraphFieldMode } from '@semmie/schemas/components/dynamic-form';
import { HttpProgressStatus } from '@semmie/schemas/generics/http/http-progress-status.enum';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'semmie-graph',
  template: `
    <semmie-account-goal
      [showFooterButton]="false"
      [chartWhenOfftrack]="false"
      [mode]="mode"
      (setSuggestion)="setSuggestion($event)"
      [customGoal]="goal ?? undefined"
    ></semmie-account-goal>
  `,
})
export class GraphComponent extends BaseFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AccountGoalComponent) accountGoal: AccountGoalComponent;

  @Input() form: UntypedFormGroup;

  @Input() set mode(mode: iGraphFieldMode) {
    if (mode) {
      this._mode = mode;
    }
  }

  get mode(): iGraphFieldMode {
    return this._mode;
  }

  goal: Goal | null = null;

  private _mode: iGraphFieldMode = 'form';

  private destroy$: Subject<boolean> = new Subject();
  private endDataStreams$: Subject<boolean> = new Subject();

  constructor(
    @Optional() @Self() ngControl: NgControl,
    @Optional() @Host() private formInputComponent: FormInputComponent,
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();

    const stream = this.formInputComponent?.actualFormComponent?.getDataStreams(DynamicFieldType.ExternalData, this.valuePath);
    if (stream) {
      stream
        .pipe(
          filter((f) => !!f[0].stream),
          tap(() => this.endDataStreams$.next(true)),
          switchMap((streams: any) => streams[0].stream.pipe(takeUntil(this.endDataStreams$))),
          filter((s) => !!s),
          tap((s: any) => {
            if (s instanceof HttpResponse) {
              // show loader
              this.accountGoal.noData = false;
              this.accountGoal.showRetry = false;

              this.accountGoal.setLoaded(s.statusText.toLowerCase() === HttpProgressStatus.DONE);

              if (s.status > 200) {
                this.accountGoal.showRetry = true;
              }

              this.accountGoal.markForCheck();
            }
          }),
          filter((s) => !(s instanceof HttpResponse)),
          tap((response: { goal: iGoal }) => {
            if (this.form.valid || response.goal.suggestions) {
              this.goal = new Goal(response.goal);
            }

            this.accountGoal.markForCheck();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngAfterViewInit(): void {
    this.accountGoal.retryLoading$$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const externalDataField = this.formInputComponent.actualFormComponent.getFieldByName(this.valuePath);
      externalDataField?.externalDataComponent.reinit();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setSuggestion(suggestion: { key: string; value: any }): void {
    this.form.get(suggestion.key)?.setValue(suggestion.value);
  }
}
