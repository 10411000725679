import { Route } from '@angular/router';
import { AppRouteNames } from '@onyxx/model/main';
import { phoneNumberVerificationReadyGuard } from '@onyxx/feature/verify-phone-number';
import { IsAppGuard, CriticalTaskGuard } from '@semmie/guards';
import { InitializerGuard } from '@semmie/guards/authentication/initializer.guard';
import { CriticalTaskRouteNames } from 'apps/client-portal/src/app/app.common';

export const authenticatedAreaRoutes: Route[] = [
  {
    path: AppRouteNames.PinCode,
    loadComponent: () => import('@semmie/views/pincode/pincode.component'),
    canActivate: [IsAppGuard],
  },
  {
    path: AppRouteNames.Biometrics,
    loadChildren: () => import('@semmie/views/authentication/biometric/biometric.module').then((m) => m.BiometricModule),
    canActivate: [IsAppGuard],
  },
  {
    path: AppRouteNames.Notifications,
    loadComponent: () => import('@semmie/views/authentication/notifications/notifications.component').then((m) => m.NotificationsComponent),
    canActivate: [IsAppGuard],
  },
  {
    path: AppRouteNames.UrgentTasks,
    pathMatch: 'full',
    loadComponent: () => import('@onyxx/feature/user-tasks').then((m) => m.SelectFirstUrgentTaskComponent),
    data: { monitorInactivity: true },
    canActivate: [InitializerGuard],
  },
  {
    path: AppRouteNames.UrgentTasks,
    loadChildren: () => import('@onyxx/feature/user-tasks').then((m) => m.userTaskRoutes),
    data: { monitorInactivity: true },
    canActivate: [InitializerGuard],
  },
  {
    // TODO: move critical tasks to own feature
    path: AppRouteNames.CriticalTasks,
    children: [
      {
        path: '',
        loadComponent: () => import('@semmie/views/critical-tasks/critical-tasks.component').then((c) => c.CriticalTasksComponent),
      },
      {
        path: CriticalTaskRouteNames.PhoneVerification,
        loadComponent: () =>
          import('@semmie/views/critical-tasks/critical-task-confirm-phone-number.component').then(
            (c) => c.CriticalTaskConfirmPhoneNumberComponent,
          ),
        canActivate: [phoneNumberVerificationReadyGuard()],
      },
    ],
    canActivate: [InitializerGuard],
    data: { monitorInactivity: true },
  },
  {
    path: '',
    loadChildren: () => import('@semmie/views/main/main.module'),
    runGuardsAndResolvers: 'always',
    canActivate: [InitializerGuard, CriticalTaskGuard],
    data: { monitorInactivity: true },
  },
];
