import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { iDirectDebit } from '@semmie/schemas/bi/payment';

export const directDebitsApiActions = createActionGroup({
  source: 'Direct Debits API',
  events: {
    Load: emptyProps(),
    'Load success': props<{ directDebits: iDirectDebit[]; accountId: string }>(),
    'Load failure': props<{ errorMsg: string }>(),
  },
});
