import { Routes } from '@angular/router';
import { ProfileDisplaySettings } from '@semmie/tokens';
import { InvestorProfileRouteNames } from '@semmie/views/settings/investor-profile/investor-profile.common';

export enum SettingsRouteNames {
  Menu = 'menu',
  Profile = 'profile',
  Inbox = 'inbox',
  /**
   * @deprecated Use UserTasks instead
   */
  Tasks = 'tasks',
  UserTasks = 'user-tasks',
  Redeem = 'redeem',
  Security = 'security',
  Display = 'display',
  EmailNotifications = 'email-notifications',
  PushNotifications = 'push-notifications',
  Legal = 'legal',
  Support = 'support',
  Cdd = 'cdd',
}

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'menu',
  },
  {
    path: SettingsRouteNames.Menu,
    loadChildren: () => import('./menu/menu.module').then((m) => m.SettingsMenuModule),
  },
  {
    path: SettingsRouteNames.Profile,
    loadComponent: () => import('@semmie/views/settings/profile/profile.component').then((m) => m.SettingsProfileComponent),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
    providers: [
      {
        provide: ProfileDisplaySettings,
        useValue: new ProfileDisplaySettings([], true, true, false, true, true),
      },
    ],
  },
  {
    path: SettingsRouteNames.Inbox,
    loadChildren: () => import('../user/inbox/inbox.module').then((m) => m.InboxModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Tasks,
    loadChildren: () => import('../user/tasks/task-list.module').then((m) => m.UserTasksModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.UserTasks,
    loadChildren: () => import('@onyxx/feature/user-task-list').then((m) => m.userTaskListRoutes),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Redeem,
    loadComponent: () => import('./redeem/redeem-code.component'),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Security,
    loadChildren: () => import('./security/security.module').then((m) => m.SettingsSecurityModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Display,
    loadChildren: () => import('./display/display.module').then((m) => m.SettingsDisplayModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.EmailNotifications,
    loadComponent: () => import('./email-notifications/email-notifications.component'),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.PushNotifications,
    loadComponent: () => import('./push-notifications/push-notifications.component'),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Legal,
    loadChildren: () => import('./legal/legal.module').then((m) => m.SettingsLegalModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Support,
    loadChildren: () => import('./support/support.module').then((m) => m.SettingsSupportModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: InvestorProfileRouteNames.InvestorProfile,
    loadChildren: () => import('./investor-profile/investor-profile.module').then((m) => m.InvestorProfileModule),
    data: {
      navVisibility: {
        portrait: false,
      },
    },
  },
  {
    path: SettingsRouteNames.Cdd,
    loadChildren: () => import('@semmie/views/settings/cdd/cdd.module').then((m) => m.CddModule),
    data: {
      navVisibility: {
        portrait: false,
        landscape: false,
      },
    },
  },
];
