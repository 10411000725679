<div>
  <div class="flex justify-center">
    <div style="width: 200px">
      <semmie-carousel
        *rxLet="selectedIndex$; let selectedIndex"
        [slidesPerView]="1"
        [spaceBetween]="10"
        [loop]="true"
        [navigation]="true"
        [centeredSlides]="true"
        [initialSlide]="selectedIndex"
        [activeIndex]="selectedIndex"
        (activeSlideChanged)="selectionChanged($event)"
        [pagination]="{ enabled: false }"
        fade="heavy"
      >
        <semmie-carousel-slide *ngIf="hasCustomFile$$()">
          <ng-template #carouselSlideContent>
            <div class="pb-12">
              <div [@activeSlide]="selectedIndex === 0 ? 'active' : 'inactive'">
                <semmie-image [src]="uploadedFileUrl$$()" width="200" height="200" objectFit="objectCover" styling="rounded-[24px]" />
              </div>
              <!--
                Padding is added to all slides and button is showed within the padding to make sure
                the carousel is always the same height and to prevent layout shift if tge custom file card
                is removed
              -->
              <div class="absolute bottom-0 left-1/2 w-full -translate-x-1/2">
                <ng-template *ngTemplateOutlet="uploadButton" />
              </div>
              <ng-template [ngIf]="selectedIndex === 0">
                <ng-template *ngTemplateOutlet="checkMark" />
              </ng-template>
            </div>
          </ng-template>
        </semmie-carousel-slide>
        <semmie-carousel-slide *ngFor="let placeholder of placeholderImages$$(); let i = index">
          <ng-template #carouselSlideContent>
            <div class="pb-12">
              <div [@activeSlide]="placeholder.id === selectedImageId$$() ? 'active' : 'inactive'">
                <semmie-image [src]="placeholder.src" width="200" height="200" styling="rounded-[24px]" />
              </div>
              <ng-template [ngIf]="placeholder.id === selectedImageId$$()">
                <ng-template *ngTemplateOutlet="checkMark" />
              </ng-template>
            </div>
          </ng-template>
        </semmie-carousel-slide>
      </semmie-carousel>
    </div>
  </div>
  <!-- Show the button on top of where the button would rended in the custom file card -->
  <div [ngClass]="{ invisible: hasCustomFile$$() }" class="relative z-50 -translate-y-full">
    <ng-template *ngTemplateOutlet="uploadButton" />
  </div>

  <ng-template #uploadButton>
    <semmie-upload
      #upload
      [url]="url"
      [uploadedFile]="uploadedFileUrl$$()"
      [fileTypes]="['image']"
      [layout]="UploadLayout.Button"
      [params]="uploadParams$$()"
      [uploadAfterCreation]="uploadAfterCreation"
      (onFileSelected)="onCustomFileSelected($event)"
      (onDelete)="onCustomFileDeleted()"
      [label]="uploadImageLabel$$()"
      i18n-optionsModalTitle="@@cover-image-selector.options-modal.title"
      optionsModalTitle="Change image"
    />
  </ng-template>
  <ng-template #checkMark>
    <div @checkEnter class="pointer-events-none absolute top-0 z-10 flex h-full w-full items-center justify-center pb-12">
      <div class="flex h-10 w-10 items-center justify-center rounded-full bg-black bg-opacity-50 backdrop-blur-sm">
        <semmie-icon [icon]="Icon.CHECKMARK" size="sm" theme="white" />
      </div>
    </div>
  </ng-template>
</div>
