import { Injectable, RendererFactory2, inject } from '@angular/core';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AppTheme } from '@semmie/schemas/common/theme';
import { AppService } from '@semmie/services/app/app.service';
import { combineLatest, defer, fromEvent } from 'rxjs';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserStoreFacade } from '@semmie/store/user';
import { filterNil } from '@onyxx/utility/observables';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private appService = inject(AppService);
  private platformService = inject(PlatformService);
  private userFacade = inject(UserStoreFacade);
  private renderFactory = inject(RendererFactory2);

  private readonly prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  private platformThemePreference$ = defer(() =>
    // use defer so that the latest version of prefersDark is used when subscribing
    fromEvent(this.prefersDark, 'change').pipe(
      map(() => this.prefersDark.matches),
      startWith(this.prefersDark.matches),
    ),
  );

  // To prevent the theme from switching as advisor users logs out,
  // we "cache" the value of isAdvisor until the new user is available
  private readonly isAdvisor$ = this.userFacade.user$.pipe(
    filterNil(),
    concatLatestFrom(() => this.userFacade.isAdvisor$),
    map(([, isAdvisor]) => isAdvisor),
    startWith(false),
  );

  private useDarkTheme$ = combineLatest([this.isAdvisor$, this.appService.getAppPreferences(), this.platformThemePreference$]).pipe(
    map(([isAdvisor, preferences, userPrefersDark]) => {
      const useDarkTheme = (preferences.appTheme === AppTheme.DEFAULT && userPrefersDark) || preferences.appTheme === AppTheme.DARK;
      return useDarkTheme && !isAdvisor;
    }),
    distinctUntilChanged(),
  );

  premiumThemeAvailable$ = this.userFacade.user$.pipe(
    map((user) => user?.isPremium() ?? false),
    distinctUntilChanged(),
  );

  private usePremiumTheme$ = combineLatest([this.userFacade.user$, this.premiumThemeAvailable$]).pipe(
    map(([user, premiumThemeAvailable]) => {
      return premiumThemeAvailable && (user?.premiumThemeEnabled ?? true);
    }),
    distinctUntilChanged(),
  );

  currentTheme$ = combineLatest([this.useDarkTheme$, this.usePremiumTheme$]).pipe(
    map(([useDarkTheme, usePremiumTheme]) => ({ useDarkTheme, usePremiumTheme })),
  );

  initialize() {
    const renderer = this.renderFactory.createRenderer(null, null);
    this.currentTheme$.pipe(takeUntilDestroyed()).subscribe(({ useDarkTheme, usePremiumTheme }) => {
      if (useDarkTheme) {
        renderer.addClass(document.body, AppTheme.DARK);
        this.platformService.setAppStyles(AppTheme.DARK);
      } else {
        renderer.removeClass(document.body, AppTheme.DARK);
        this.platformService.setAppStyles(AppTheme.LIGHT);
      }

      if (usePremiumTheme) {
        renderer.addClass(document.body, AppTheme.PREMIUM);
      } else {
        renderer.removeClass(document.body, AppTheme.PREMIUM);
      }
    });
  }
}
