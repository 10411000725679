import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule as PrimeButton } from 'primeng/button';

import { ButtonComponent } from '@semmie/components/presentational/core/button/button.component';
import { LoadingRippleModule } from '@semmie/components/presentational/core/loading-ripple/loading-ripple.module';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, PrimeButton, LoadingRippleModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
