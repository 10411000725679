import { DOCUMENT } from '@angular/common';
import { CSP_NONCE, Injectable, RendererFactory2, inject } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatWebService {
  private document = inject(DOCUMENT);
  private cspNonce = inject(CSP_NONCE);
  private renderFactory = inject(RendererFactory2);

  async initialize(intercomUserId: string | null): Promise<void> {
    /**
     * Quick fix to update the user with their intercomUserId if it is supplied
     */
    if ((window as any)['Intercom'] && intercomUserId) {
      await (window as any)['Intercom']('update', { user_id: intercomUserId });
      return;
    }

    const renderer = this.renderFactory.createRenderer(null, null);
    const script = renderer.createElement('script');
    script.setAttribute('nonce', this.cspNonce);

    script.type = 'text/javascript';

    script.text = `window.intercomSettings = {api_base: "https://api-iam.intercom.io", app_id: "${environment.intercomApiKey}", ${
      intercomUserId ? 'user_id: "' + intercomUserId + '"' : ''
    }};(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${
      environment.intercomApiKey
    }';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    renderer.appendChild(this.document.body, script);
  }

  async openChat(): Promise<void> {
    if (!this.chatIsInitialized()) return;
    (window as any)['Intercom']('show');
  }

  async closeChat(): Promise<void> {
    if (!this.chatIsInitialized()) return;
    (window as any)['Intercom']('hide');
  }

  async logout(): Promise<void> {
    if (!this.chatIsInitialized()) return;
    (window as any)['Intercom']('shutdown');
  }

  /**
   * Check if the Intercom script from initialize() has been fully loaded to prevent unnecessary undefined errors.
   * Caused e.g. by (probably a race condition) when logging in from Advisor Portal to CP where logout is being triggered.
   * Intercom is still working after the error, so this check is to prevent it being logged by Sentry.
   */
  private chatIsInitialized(): boolean {
    return !!(window as any)['Intercom'];
  }
}
