import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { Goal } from '@semmie/models';
import { Account } from '@onyxx/model/account';
import { Invitation } from '@semmie/models/bi/invitation';
import { Message, MessageDetail } from '@semmie/models/bi/message';
import { DirectDebit } from '@semmie/models/bi/payment/direct-debit.model';
import { RiskProfile } from '@semmie/models/bi/risk-profile/';
import { Strategy } from '@semmie/models/bi/strategy/strategy.model';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { PaginatedResponse } from '@onyxx/model/pagination';
import { iDirectDebit } from '@semmie/schemas/bi/payment';
import { iTermination, iTerminationResponse } from '@semmie/schemas/bi/termination';
import { iDirectDebitPayload } from '@semmie/schemas/bi/payment/direct-debit-payload.schema';
import { iSigningDocument } from '@semmie/schemas/bi/signing/signing-document';
import { LinkedAccount } from '@onyxx/model/linked-account';
import { iAccountBalanceSheet } from '@semmie/schemas/bi/account';
import { AccountUser } from '@onyxx/model/account';
import { DestroyLinkedAccount } from '@semmie/schemas/bi/linked-accounts/linked-account-destroy';
import { GoalFocus } from '@onyxx/model/goal';
import { Utils } from '@onyxx/utility/general';

@Injectable()
export class AccountsProvider extends BaseProvider<Account> {
  constructor() {
    super('accounts');
  }

  /**
   * Update goal
   *
   * @param id Account id
   * @param params Goal data
   * @returns
   */
  updateGoal(id: string, params: Partial<Goal>): Observable<Goal> {
    return super.__patch(`accounts/${id}/goal`, { goal: params }).pipe(map((response) => new Goal(response?.goal)));
  }

  /**
   * Change account order
   *
   * @param params Accounts: { sort: [{id: string}]} }
   * @returns
   */
  updateSorting(params: any) {
    return super.__post('accounts/sort', params);
  }

  /**
   * Update the account image
   *
   * @param id Account id
   * @param image Image (file)upload data
   * @returns
   */
  updateImage(id: string, image: any) {
    return super.__post(`accounts/${id}/image`, image);
  }

  /**
   * Retrieve the account balance sheet
   * @param id account id
   * @returns Observable<iAccountBalanceSheet>
   */
  getBalanceSheet(id: string): Observable<iAccountBalanceSheet> {
    return super.__query(`accounts/${id}/balance_sheet`).pipe(map((response) => response));
  }

  /**
   * Retrieve the account goal
   * @param id account id
   * @returns Observable<Goal>
   */
  getGoal(id: string): Observable<Goal> {
    return super.__query(`accounts/${id}/goal`).pipe(map((response) => new Goal(response?.goal) || null));
  }

  /**
   * Get the account index rates
   *
   * !todo create data models
   * @param id Account id
   * @returns Array<{ rate_at: string; value: number }>
   *
   */
  getRates(id: string): any {
    return super.__query(`accounts/${id}/rates`).pipe(map((response) => (response && response['rates'] ? response['rates'] : null)));
  }

  /**
   * Get the account index performance
   *
   * !todo create data models
   * @param id Account id
   * @returns Array<{ performance_at: string, rate_at: string; value: number }>
   *
   */
  getPerformances(id: string): Observable<Performance[] | null> {
    return super
      .__query(`accounts/${id}/performances`)
      .pipe(map((response) => (response && response['performances'] ? response['performances'] : null)));
  }

  /**
   * Returns account(s) *Linked Accounts*
   *
   * @param id account_id
   * @returns Observable<Array<LinkedAccount>>
   */
  getLinkedAccounts(id: string): Observable<Array<LinkedAccount>> {
    return super
      .__query(`accounts/${id}/linked_accounts`)
      .pipe(map((response) => response.linked_accounts.map((linked_account: LinkedAccount) => new LinkedAccount(linked_account))));
  }

  /**
   * Post linked account to account
   *
   * @param id account_id
   * @param params params: Partial<LinkedAccount>
   * @returns Observable<LinkedAccount>
   */
  postLinkedAccount(id: string, params: Partial<LinkedAccount>, return_url: string) {
    return super
      .__post(`accounts/${id}/linked_accounts`, { linked_account: { ...params, return_url } })
      .pipe(map((response) => (response && response?.linked_account ? new LinkedAccount(response.linked_account) : null)));
  }

  /**
   * Make linked bank account destroy request from account
   *
   * @param id account_id
   * @param params params: Partial<LinkedAccount>
   * @returns Observable<any>
   */
  destroyLinkedAccount(id: string, params: Partial<LinkedAccount>, return_url: string): Observable<DestroyLinkedAccount> {
    return super
      .__post(`accounts/${id}/linked_accounts/${params.id}/request_destroy`, { linked_account: { return_url } })
      .pipe(map((response) => response));
  }

  getDirectDebitList(id: string): Observable<PaginatedResponse<iDirectDebit>> {
    return super.__query(`accounts/${id}/auto_incassos`).pipe(
      map((response) => {
        return {
          data: response?.auto_incassos.map((b: DirectDebit) => new DirectDebit(b)),
          meta: response?.meta,
        };
      }),
    );
  }

  getDirectDebitById(accountId: string, directDebitId: string) {
    return super
      .__query(`accounts/${accountId}/auto_incassos/${directDebitId}`)
      .pipe(map((response) => (response && response.auto_incasso ? new DirectDebit(response.auto_incasso) : null)));
  }

  postDirectDebit(accountId: string, params: Partial<iDirectDebitPayload>) {
    return super
      .__post(`accounts/${accountId}/auto_incassos/`, { auto_incasso: params })
      .pipe(map((response) => (response && response.auto_incasso ? new DirectDebit(response.auto_incasso) : null)));
  }

  /**
   * Update an Direct debit
   *
   * @param accountId Account id
   * @param directDebitId Direct debit id
   * @param params Direct debit data
   * @returns Observable<DirectDebit>
   */
  updateDirectDebit(accountId: string, directDebitId: string, params: Partial<DirectDebit>): Observable<DirectDebit> {
    return super
      .__patch(`accounts/${accountId}/auto_incassos/${directDebitId}`, { auto_incasso: params })
      .pipe(map((response) => new DirectDebit(response?.auto_incasso)));
  }

  /**
   * Delete a Direct debit
   *
   * @param accountId Account id
   * @param directDebitId Direct debit id
   * @returns Observable<boolean>
   */
  deleteDirectDebit(accountId: string, directDebitId: string): Observable<boolean> {
    return super.__delete(`accounts/${accountId}/auto_incassos/${directDebitId}`).pipe(map(() => true));
  }

  getRiskProfiles(
    id: string,
    params?: Partial<{
      importance: number;
      risk_appetite: number;
      max_loss: number;
      period: number;
      focus: GoalFocus;
      ends_at: string;
    }>,
  ): Observable<Array<RiskProfile>> {
    const nonNullProfileParams = Object.fromEntries(Object.entries(params ?? {}).filter(([, v]) => Utils.isNotNil(v)));
    const queryParams = Utils.urlEncodeObject(nonNullProfileParams);
    return super
      .__query(`accounts/${id}/risk_profiles${params ? `?${queryParams}` : ''}`)
      .pipe(map((response) => response?.profiles?.map((p) => new RiskProfile(p)) || null));
  }

  getStrategies(id: string): Observable<Array<Strategy>> {
    return super.__query(`accounts/${id}/strategies`).pipe(map((response) => response?.strategies?.map((p) => new Strategy(p)) || null));
  }

  sign(id: string): Observable<Account> {
    return super.__patch(`accounts/${id}/sign`).pipe(map((response) => response?.opening || null));
  }

  invite(id: string, invitation: Partial<Invitation>): Observable<Invitation> {
    return super.__post(`accounts/${id}/invitations`, { invitation }).pipe(map((response) => new Invitation(response?.invitation) || null));
  }

  updateInvitation(accountId: string, inviteId: string, params: Partial<Invitation>): Observable<Invitation> {
    return super
      .__patch(`accounts/${accountId}/invitations/${inviteId}`, { invitation: params })
      .pipe(map((response) => new Invitation(response?.invitation) || null));
  }

  deleteInvitation(accountId: string, inviteId: string): Observable<boolean> {
    return super.__delete(`accounts/${accountId}/invitations/${inviteId}`).pipe(map(() => true));
  }

  /**
   * List account messages, optionally with params to more specifically filter your request
   *
   * ? Developer note: Explicitly remap data here for consistency
   *
   * @param params
   * @returns
   */
  listMessages(accountId: string, page: number, size: number): Observable<PaginatedResponse<Message>> {
    return super.__query(`accounts/${accountId}/messages?per_page=${size}&page=${page}`).pipe(
      map((response) => {
        return {
          data: response['messages'] ? response['messages'].map((a) => new Message(a)) : [],
          meta: response.meta,
        };
      }),
    );
  }

  /**
   * Get the account messages
   *
   * @param accountId ID of the account
   * @param page Page number
   * @param size Amount of entities to return
   * @returns Observable<PaginatedResponse<Message>>
   *
   */
  getAccountMessages(accountId: string, page: number, size: number): Observable<PaginatedResponse<Message>> {
    return super.__query(`accounts/${accountId}/messages?per_page=${size}&page=${page}`);
  }

  /**
   * Get the account messages
   *
   * @param accountId ID of the account
   * @param messageId ID of the message
   * @returns Observable<Message>
   *
   */
  getAccountMessage(accountId: string, messageId: string): Observable<MessageDetail> {
    return super.__query(`accounts/${accountId}/messages/${messageId}`).pipe(pluck('message'));
  }

  getTransactions(accountId: string, amountPerPage: number, page: number, kind?: string[]): Observable<any> {
    const addedTypes = kind ? `&kind=${kind.join(',')}` : '';
    return super.__query(`accounts/${accountId}/transactions?per_page=${amountPerPage}&page=${page}${addedTypes}`).pipe(pluck('message'));
  }

  getInvestmentPlanUrl(accountId: string) {
    return super.__query<{ url: string }>(`accounts/${accountId}/goal/pdf`).pipe(map((response) => response.url));
  }

  terminate(id: Account['id'], termination: iTermination): Observable<iTerminationResponse> {
    return this.__post(`accounts/${id}/termination`, { termination }).pipe(map((response) => response['termination']));
  }

  getOpeningPositionDocument(accountId: string, date: string): Observable<string> {
    return super.__query(`accounts/${accountId}/opening_position?date=${date}`).pipe(map((response) => response.url));
  }

  getSigningDocuments(accountId: string): Observable<Array<iSigningDocument>> {
    return super.__query(`accounts/${accountId}/signing_documents`);
  }

  acceptAdvisorProposal(accountId: string): Observable<void> {
    return super.__post(`accounts/${accountId}/advisor/accept`);
  }

  sendAdvisorProposal(accountId: string): Observable<void> {
    return super.__post(`accounts/${accountId}/advisor/invite`);
  }

  listAccountUsers(accountId: string): Observable<AccountUser[]> {
    return super.__query<{ users: AccountUser[] }>(`accounts/${accountId}/users`).pipe(map((response) => response.users));
  }

  clear(): void {
    this.__clear();
  }
}
