import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authApiActions } from '../auth-api.actions';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { AuthProvider } from '@onyxx/provider/auth';
import { ToastService } from '@semmie/services';
import { GenericErrorToast } from '@semmie/schemas/components/toast';

export class PasswordResetEffects {
  private readonly actions$ = inject(Actions);
  private readonly authProvider = inject(AuthProvider);
  private readonly toastService = inject(ToastService);

  readonly $passwordResetRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.requestPasswordReset),
      exhaustMap(({ email }) => {
        return this.authProvider.requestPasswordReset(email).pipe(
          map(() => authApiActions.requestPasswordResetSuccess()),
          catchError((error: Error) => of(authApiActions.requestPasswordResetFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  readonly passwordResetRequestFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authApiActions.requestPasswordResetFailure),
        tap(() => {
          this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );
}
