import { AfterViewInit, Component, Input, Optional, Self, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CarouselComponent } from '@semmie/components/presentational/core/carousel/carousel.component';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { NgControl } from '@angular/forms';
import { Utils } from '@semmie/shared/utils';
import { iCarouselSelectFormField } from '@semmie/schemas/components/dynamic-form/form-fields';

/**
 * TODO(carousel-select): add subtypes for different slide cards
 */
@Component({
  selector: 'semmie-carousel-select',
  templateUrl: './carousel-select.component.html',
})
export class CarouselSelectComponent extends BaseFormComponent implements AfterViewInit {
  @ViewChild(CarouselComponent, { static: false }) carousel: CarouselComponent;
  @Input() options: iCarouselSelectFormField['options'] = [];
  @Input() form: any;
  Icon = Icon;
  private destroy$: Subject<boolean> = new Subject();

  get initialIndex() {
    const initIndex = this.options.length >= 1 ? 1 : 0;
    return Utils.isNullOrUndefined(this.value) ? initIndex : this.valueIndex;
  }

  get valueIndex() {
    return Utils.isNonNullOrUndefined(this.value) ? this.options.findIndex((o) => o.value === this.value) : undefined;
  }

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
  }

  ngAfterViewInit(): void {
    this.carousel.selectedIndex$.pipe(filter(Utils.isNonNullOrUndefined), takeUntil(this.destroy$)).subscribe((index) => {
      this.writeValue(this.options[index].value);
    });
  }

  isActive(index: number): boolean {
    return index === this.valueIndex;
  }
}
