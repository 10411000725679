import { createReducer, on, createFeature } from '@ngrx/store';

import { NotificationMessage, NotificationMessagesResponse } from '@onyxx/model/notifications';

import { NotificationsAPIActions } from './notifications-api.actions';
import { NotificationsCommonActions } from './notifications-common.actions';

import * as moment from 'moment';
import { Utils } from '@onyxx/utility/general';

export interface State {
  notifications: NotificationMessagesResponse<NotificationMessage> | null;
  // NOTE: copy the unread count from the meta object because the paginated response is too complex of an object for the state lib to keep track of changes
  unreadCount: number;
  loading: boolean;
  loaded: boolean;
}

const initialState: State = { notifications: null, loading: false, loaded: false, unreadCount: 0 };

const reducer = createReducer(
  initialState,
  on(
    NotificationsAPIActions.loadSuccess,
    (state, { notifications }): State => ({
      ...state,
      notifications,
      loaded: true,
      loading: false,
      unreadCount: notifications.meta?.unread_count ?? 0,
    }),
  ),
  on(NotificationsAPIActions.load, (state): State => ({ ...state, loading: true })),
  on(NotificationsAPIActions.loadFailure, (state): State => ({ ...state, loading: false })),
  on(NotificationsAPIActions.markAsReadSuccess, (state, { notificationIds, unread_count }): State => {
    const notifications = { ...state.notifications };
    if (notifications.data) {
      notifications.data = notifications.data.map((notification) => {
        if (notificationIds.includes(notification.id)) {
          return { ...notification, read_at: moment().toISOString() };
        }
        return notification;
      });
    }
    if (Utils.isNotNil(notifications.meta)) {
      notifications.meta = {
        ...notifications.meta,
        unread_count,
      };
    }
    return { ...state, ...notifications, unreadCount: unread_count };
  }),
  on(NotificationsAPIActions.markAllAsReadSuccess, (state, { unread_count }): State => {
    const notifications = { ...state.notifications };
    if (notifications.data) {
      notifications.data = notifications.data.map((notification) => {
        if (!notification.read_at) {
          return { ...notification, read_at: moment().toISOString() };
        }
        return notification;
      });
    }
    if (Utils.isNotNil(notifications.meta)) {
      notifications.meta = {
        ...notifications.meta,
        unread_count,
      };
    }
    return { ...state, ...notifications, unreadCount: unread_count };
  }),
  on(NotificationsAPIActions.updateUnreadCount, (state, { unread_count }): State => ({ ...state, unreadCount: unread_count })),
  on(NotificationsCommonActions.clear, (): State => ({ ...initialState })),
);

export const notificationsFeature = createFeature({
  name: 'notifications',
  reducer,
});
