import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from '@semmie/components/containers/card/card.module';

import { FormInputModule } from '@semmie/components/containers/form-input/form-input.module';
import { ToggleModule } from '@semmie/components/presentational/core/toggle/toggle.module';

import { FormGroupComponent } from './form-group.component';

@NgModule({
  declarations: [FormGroupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormInputModule, CardModule, ToggleModule],
  exports: [FormGroupComponent],
})
export class FormGroupModule {}
