import { Injectable, inject } from '@angular/core';
import { AccountHelpers } from '@onyxx/model/account';
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';
import { Utils } from '@semmie/shared/utils';
import { ConfigStore } from '@semmie/store';
import { combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentConfigurationService {
  private readonly configStore = inject(ConfigStore);
  private readonly accountStoreFacade = inject(AccountStoreFacade);

  calculateFirstMinimumPayment(options: { accountId: string; IsInvitee?: 'true' | 'false' }) {
    const isInvitee = Utils.isStringTruthy(options.IsInvitee ?? '');

    return combineLatest([this.configStore.payment$, this.accountStoreFacade.account(options.accountId).pipe(filterNil())]).pipe(
      map(([paymentConfig, account]) => {
        if (isInvitee || AccountHelpers.hasAdvisor(account)) return 0.01;

        const origin = account.origin?.name;
        const originPaymentConfig = Utils.isNonNullOrUndefined(origin) ? (paymentConfig.origin?.[origin] ?? paymentConfig) : paymentConfig;
        const accountKindPaymentConfig = originPaymentConfig[account.kind];
        return accountKindPaymentConfig?.firstMinimumPayment ?? 500;
      }),
    );
  }
}
